import React, { FC } from "react";
import styles from "./TableControlsASN.module.scss";
import { useOrderedPortsContext } from "../OrderedPortsContext";
import {
  APPROVED_CAPITAL,
  DECLINED_CAPITAL,
  PENDING_CAPITAL,
} from "../../../helpers/consts";

type Props = {
  onAdd?: () => void;
  noSearch?: boolean;
};

const MODES = [
  { label: "Pending", mode: PENDING_CAPITAL },
  { label: "Approved", mode: APPROVED_CAPITAL },
  { label: "Declined", mode: DECLINED_CAPITAL },
];

const TableControlsASN: FC<Props> = () => {
  const {
    selectedASNMode,
    setSelectedASNMode,
    fetchASNData,
    combinedASNList,
  } = useOrderedPortsContext();

  const handleModeChange = (mode: string) => {
    setSelectedASNMode(mode);
    fetchASNData();
  };

  const pendingCount = combinedASNList?.filter(
    (asn) => !asn.approved && !asn.labels?.reason_for_decline
  ).length;

  return (
    <div className={styles.tableControls}>
      {MODES.map(({ label, mode }, index) => (
        <React.Fragment key={mode}>
          <div
            className={`${styles.portsModeSelector} ${
              selectedASNMode === mode ? styles.active : ""
            }`}
            onClick={() => handleModeChange(mode)}
          >
            {label}
            {mode === PENDING_CAPITAL && pendingCount > 0 && (
              <span> ({pendingCount})</span>
            )}
          </div>
          {index < MODES.length - 1 && (
            <span className={styles.separator}>|</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TableControlsASN;
