import { FWDiaRuleType } from "../../helpers/api/apiTypes";

export const getGroupedDiaFW = (
  rules: Array<FWDiaRuleType>
): { [key: string]: Array<FWDiaRuleType> } => {
  return rules.reduce(
    (acc: { [key: string]: Array<FWDiaRuleType> }, rule: FWDiaRuleType) => {
      const { gate_vi_name, gate_vi_names } = rule;
      if (gate_vi_name) {
        if (!acc[gate_vi_name]) {
          acc[gate_vi_name] = [];
        }
        acc[gate_vi_name].push(rule);
      } else if (gate_vi_names.length) {
        gate_vi_names.forEach((key) => {
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(rule);
        });
      }
      return acc;
    },
    {}
  );
};
