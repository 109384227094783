/* eslint-disable prettier/prettier */
import React, { FC, ReactNode, useState } from "react";

import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { RequestStatus } from "../../helpers/types";
import { SearchData, UniqueMetric } from "./types";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";
import { elasticApi } from "../../helpers/api/ElasticApi";
import {
  METRICS_MAP,
  parsingSearchUniqueMetricsResponse,
} from "./helpers/parsingSearchUniqueMetricsResponse";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { fetchNatRulesByName } from "./helpers/fetchNatRulesByName";
import { fetchFwRulesByName } from "./helpers/fetchFwRulesByName";

const DEFAULT_DATA_FIELDS = {
  fwRules: [],
  natRules: [],
  sourceIps: [],
  destinationIps: [],
  cyberThreats: [],
  dnsServers: [],
  dnsDomains: [],
  httpsHostnames: [],
};

type RequestStatuses = {
  fwRulesStatus?: RequestStatus;
  natRulesStatus?: RequestStatus;
  sourceIpsStatus?: RequestStatus;
  destinationIpsStatus?: RequestStatus;
  cyberThreatsStatus?: RequestStatus;
  dnsServersStatus?: RequestStatus;
  dnsDomainsStatus?: RequestStatus;
  httpsHostnamesStatus?: RequestStatus;
};

type IFunc = {
  fetchData: (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => Promise<void>;
};

type IState = {
  data?: SearchData | null;
  statuses: RequestStatuses | null;
  uniqueMetrics?: Array<UniqueMetric>;
  uniqueMetricsStatus?: RequestStatus;
};

type Props = {
  children: ReactNode;
};

const [SearchContext, useContext] = createContextAndUse<IState, IFunc>();
export const useSearchContext = useContext;

export const SearchContextContainer: FC<Props> = ({ children }) => {
  const [data, setData] = useState<SearchData>(DEFAULT_DATA_FIELDS);
  const [statuses, setStatuses] = useState<RequestStatuses | null>();
  const [uniqueMetrics, setUniqueMetrics] = useState<Array<UniqueMetric>>(
    METRICS_MAP
  );
  const [
    uniqueMetricsStatus,
    setUniqueMetricsStatus,
  ] = useState<RequestStatus | null>();

  const fetchData = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    fetchUniqueMetrics(ip, tenant, timeRange);
    fetchFwRules(ip, tenant, timeRange);
    fetchNatRules(ip, tenant, timeRange);
    fetchSourceIps(ip, tenant, timeRange);
    fetchDestinationIps(ip, tenant, timeRange);
    fetchCyberThreats(ip, tenant, timeRange);
    fetchDnsServers(ip, tenant, timeRange);
    fetchDnsDomains(ip, tenant, timeRange);
    fetchHttpsHostnames(ip, tenant, timeRange);
  };

  const fetchUniqueMetrics = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setUniqueMetricsStatus(setPending());
    const res = await elasticApi.searchUniqueQueries(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      const data = parsingSearchUniqueMetricsResponse(res?.result);
      setUniqueMetrics(data);
    }
    setUniqueMetricsStatus(resToState(res));
  };

  const fetchFwRules = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, fwRulesStatus: setPending() }));
    const res = await elasticApi.searchFwRules(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      const result = await fetchFwRulesByName(res?.result, tenant);
      setData((prev) => ({ ...prev, fwRules: result }));
    }
    setStatuses((prev) => ({
      ...prev,
      fwRulesStatus: resToState(res),
    }));
  };

  const fetchNatRules = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, natRulesStatus: setPending() }));
    const res = await elasticApi.searchNatRules(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      const result = await fetchNatRulesByName(res?.result, tenant);
      setData((prev) => ({ ...prev, natRules: result }));
    }
    setStatuses((prev) => ({
      ...prev,
      natRulesStatus: resToState(res),
    }));
  };

  const fetchSourceIps = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, sourceIpsStatus: setPending() }));
    const res = await elasticApi.searchSourceIps(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      setData((prev) => ({ ...prev, sourceIps: res?.result || [] }));
    }
    setStatuses((prev) => ({
      ...prev,
      sourceIpsStatus: resToState(res),
    }));
  };

  const fetchDestinationIps = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, destinationIpsStatus: setPending() }));
    const res = await elasticApi.searchDestinationIps(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      setData((prev) => ({ ...prev, destinationIps: res?.result || [] }));
    }
    setStatuses((prev) => ({
      ...prev,
      destinationIpsStatus: resToState(res),
    }));
  };

  const fetchCyberThreats = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, cyberThreatsStatus: setPending() }));
    const res = await elasticApi.searchCyberThreats(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      setData((prev) => ({ ...prev, cyberThreats: res?.result || [] }));
    }
    setStatuses((prev) => ({
      ...prev,
      cyberThreatsStatus: resToState(res),
    }));
  };

  const fetchDnsServers = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, dnsServersStatus: setPending() }));
    const res = await elasticApi.searchDnsServers(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      setData((prev) => ({ ...prev, dnsServers: res?.result || [] }));
    }
    setStatuses((prev) => ({
      ...prev,
      dnsServersStatus: resToState(res),
    }));
  };

  const fetchDnsDomains = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, dnsDomainsStatus: setPending() }));
    const res = await elasticApi.searchDnsDomains(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      setData((prev) => ({ ...prev, dnsDomains: res?.result || [] }));
    }
    setStatuses((prev) => ({
      ...prev,
      dnsDomainsStatus: resToState(res),
    }));
  };

  const fetchHttpsHostnames = async (
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setStatuses((prev) => ({ ...prev, httpsHostnamesStatus: setPending() }));
    const res = await elasticApi.searchHttpsHostnames(ip, tenant, timeRange);
    if (res?.ok && res?.result) {
      setData((prev) => ({ ...prev, httpsHostnames: res?.result || [] }));
    }
    setStatuses((prev) => ({
      ...prev,
      httpsHostnamesStatus: resToState(res),
    }));
  };

  return (
    <SearchContext.Provider
      value={{
        fetchData,
        data,
        statuses,
        uniqueMetrics,
        uniqueMetricsStatus,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
