import React, { FC } from "react";
import Label from "../../../../components/common/table/Label";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import getIconFromValue from "../../../../helpers/getIconFromValue";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./StatusLabel.module.scss";

type Props = {
  status: string;
};

const StatusLabel: FC<Props> = ({ status }) => {
  const value = status.toUpperCase();
  const className = getClassFromStatus(status);
  return (
    <Label className={styles[className]}>
      <TextWithIcon icon={getIconFromValue(status)} text={value} />
    </Label>
  );
};

export default StatusLabel;

const getClassFromStatus = (status: string): string => {
  switch (status.toLowerCase()) {
    case "up":
    case "ready":
    case "approved":
      return "green";
    case "degared":
      return "orange";
    case "failed":
    case "declined":
      return "red";
    case "syncing":
      return "orange";
    default:
      return "greyText";
  }
};
