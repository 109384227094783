import { FC, useState } from "react";
import Input from "../../../components/common/formComponents/Input";
import Section from "../../../components/common/Section";
import styles from "./RuleSection.module.scss";
import { UserGroups } from "../../../contexts/servicesContext/FirewallContext";
import {
  ECN_MAP,
  PROTOCOLS_TYPES_MAP,
} from "../../../helpers/common/constantsAlias";
import { Checkbox } from "../../../components/common/formComponents/Checkbox";
import { OptionalLabel } from "../../../components/common/OptionalLabel";
import CheckboxCrossIcon from "../../../components/icons/CheckboxCrossIcon";
import VIDropdown from "../../../components/common/OneSystem/VIDropdown";
import { VRFType } from "../../../helpers/api/apiTypes";
import { System } from "../../Systems/Provisioning/types";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";
import { TCP_STATES, tcpCheckboxes } from "../../PBMPage/PBMConfig/PBMConfig";
import { CreateDDoS } from "../../DDoSSystemPage/DDoSConfig/types";
import { getEndOfRangeByBitMask } from "../../PBMPage/PBMConfig/getEndOfRangeByBitMask";
import SourceBlock from "../../DDoSSystemPage/DDoSConfig/SourceBlock";
import PortDropdown from "../../DDoSSystemPage/DDoSConfig/PortDropdown";
import { SourceInterfaces } from "./types";

type Props = {
  fields: CreateDDoS;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
  groups: UserGroups | undefined;
  gridTemplate: string;
  selectedVRF?: VRFType;
  systems?: Array<System>;
  selectedSourceInterfaces: SourceInterfaces;
  setSelectedSourceInterfaces: (value: SourceInterfaces) => void;
  viewOnly?: boolean;
};

const RuleSection: FC<Props> = ({
  fields,
  errors,
  onChange,
  className,
  groups,
  gridTemplate,
  selectedVRF,
  systems,
  selectedSourceInterfaces,
  setSelectedSourceInterfaces,
  viewOnly,
}) => {
  const getNewState = (prevState: string): string => {
    switch (prevState) {
      case TCP_STATES.ANY:
        return TCP_STATES.SET;
      case TCP_STATES.SET:
        return TCP_STATES.UNSET;
      case TCP_STATES.UNSET:
        return TCP_STATES.ANY;
      default:
        return TCP_STATES.SET;
    }
  };

  const [dscpError, setDscpError] = useState<boolean>(false);
  const [ttlStart, setTtlStart] = useState<number>(fields.ttl?.[0] || 0);
  const [ttlFinish, setTtlFinish] = useState<number>(fields.ttl?.[1] || 0);
  const [activeVRF] = useState<VRFType | any | undefined>({
    value: selectedVRF?.name,
    vrf_type: selectedVRF?.vrf_type,
    name: selectedVRF?.name,
  });

  const handleSetVI = (
    system: string,
    val?: Partial<VirtualInterfaceApi>
  ): void => {
    if (!val) return;
    const newSelectedSourceInterfaces = {
      ...selectedSourceInterfaces,
      [system]: val.name ?? "",
    };
    setSelectedSourceInterfaces(newSelectedSourceInterfaces);
  };

  const getCheckbox = (label: string) => {
    return (
      <Checkbox
        onChange={() =>
          onChange(
            label,
            getNewState(fields[label as keyof CreateDDoS] as string)
          )
        }
        isChecked={fields[label as keyof CreateDDoS] === TCP_STATES.SET}
        indeterminate={fields[label as keyof CreateDDoS] === TCP_STATES.UNSET}
        label={label.toUpperCase()}
        indeterminateIcon={<CheckboxCrossIcon />}
        tooltip={fields[label as keyof CreateDDoS]?.toString()}
        id={label}
        disabled={viewOnly}
      />
    );
  };

  const handleTTLStartInput = (val: number) => {
    const prevHop: Array<number> = fields?.ttl || [];
    prevHop[0] = Number(val);
    if (prevHop.length == 0) prevHop[1] = Number(val);
    setTtlStart(val);
    if (val !== ttlFinish) {
      setTtlFinish(val);
    }
    onChange("ttl", prevHop);
  };

  const handleTTLEnd = (val: number) => {
    const prevHop: Array<number> = fields?.ttl || [];
    if (prevHop.length === 0) {
      prevHop[0] = 0;
    }
    prevHop[1] = val;
    setTtlFinish(val);
    onChange("ttl", prevHop);
  };

  const handleDSCP = (val?: number) => {
    if (!val || !(val % 8)) {
      if (dscpError) setDscpError(false);
    } else {
      setDscpError(true);
    }
    onChange("dscp", val);
  };

  return (
    <Section
      title={"Policy"}
      className={className}
      columnsCount={3}
      gridTemplateString={gridTemplate}
    >
      <div className={styles.column}>
        <SourceBlock
          fields={fields}
          errors={errors}
          onChange={onChange}
          groups={groups}
          disabled={viewOnly}
        />
        {systems?.map((system) => (
          <div key={system.name} className={styles.systemsWrapper}>
            <VIDropdown
              systemName={system.name}
              vrf={activeVRF}
              selected={selectedSourceInterfaces[system.name]}
              onSelect={(val) => handleSetVI(system.name, val)}
              className={styles.portWrapper}
              label="Source Interface"
              force={!system}
              withClearIcon
              filterPredicate={(value) => {
                return value.members?.every(
                  (inter) =>
                    !inter.interfaces?.some((element) => element.tenant_name)
                );
              }}
              disabled={viewOnly}
            />

            <div className={styles.systemsWrapperName}>
              <span>-</span>
              {system.name}
            </div>
          </div>
        ))}
        <div className={styles.checkboxWrapper}>
          <OptionalLabel
            isShown
            isOptional
            text={"TCP Flags (Any, Set, Unset)"}
          >
            <div className={styles.line}>
              {tcpCheckboxes.map((label) => {
                return getCheckbox(label);
              })}
            </div>
          </OptionalLabel>
        </div>
      </div>
      <div className={styles.column}>
        <Input
          label="Protocol"
          className={styles.portWrapper}
          name={"ip_protocol"}
          handleFieldChange={onChange}
          value={fields.ip_protocol}
          placeholder={fields.ip_protocol || "Protocol"}
          error={errors && errors["ip_protocol"]}
          valuesList={PROTOCOLS_TYPES_MAP}
          medium
          disabled={viewOnly}
        />
        <div className={styles.checkboxWrapperWidth}>
          <OptionalLabel isShown isOptional text={"Fragment"}>
            <Checkbox
              isChecked={fields.is_fragmented}
              onChange={() => onChange("is_fragmented", !fields.is_fragmented)}
              label={"Enable"}
              disabled={viewOnly}
            />
          </OptionalLabel>
        </div>
        <OptionalLabel
          className={styles.text}
          isShown
          isOptional
          text={"TTL (Range)"}
        >
          <div className={styles.twoBlocksWrapper}>
            <Input
              type="number"
              name={"ttl_start"}
              onChange={(e: any) => handleTTLStartInput(+e.target.value)}
              value={ttlStart}
              error={errors && errors["ttl"]}
              medium
              placeholder="Start"
              min={0}
              max={256}
              disabled={viewOnly}
            />
            <Input
              type="number"
              name={"ttl_finish"}
              handleFieldChange={(name: string, value: number) =>
                handleTTLEnd(value)
              }
              value={ttlFinish}
              valuesList={getEndOfRangeByBitMask(ttlStart)}
              medium
              placeholder="End"
              disabled={viewOnly}
            />
          </div>
        </OptionalLabel>
      </div>
      <div className={styles.column}>
        <PortDropdown
          errors={errors}
          label={"Source Port"}
          fields={fields}
          onChange={onChange}
          name={"src_l4_port"}
          className={styles.portWrapper}
          disabled={viewOnly}
        />
        <Input
          label="Destination Port"
          name="dst_l4_port"
          className={styles.portWrapper}
          placeholder="xxx"
          handleFieldChange={onChange}
          value={fields.dst_l4_port}
          medium
          error={errors && errors["dst_l4_port"]}
          isOptional
          disabled={viewOnly}
        />
        <OptionalLabel className={styles.text} isShown isOptional text={"TOS"}>
          <div className={styles.twoBlocksWrapper}>
            <Input
              type="number"
              name={"dscp"}
              onChange={(e: any) => handleDSCP(e.target.value)}
              value={fields.dscp}
              medium
              placeholder="DSCP"
              min={0}
              max={56}
              errorWithTooltip
              error={dscpError ? "Incorrect DSCP" : ""}
              disabled={viewOnly}
            />
            <Input
              name={"esn"}
              value={fields.esn}
              medium
              handleFieldChange={onChange}
              placeholder="ECN"
              valuesList={ECN_MAP}
              listWrapperClass={styles.listWrapper}
              disabled={viewOnly}
            />
          </div>
        </OptionalLabel>
      </div>
    </Section>
  );
};
export default RuleSection;
