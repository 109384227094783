import { FC, useEffect, useMemo, useState } from "react";
import styles from "./DIADialog.module.scss";
import {
  VirtualInetrfaceDIA,
  VirtualInetrfaceDIACreate,
} from "../../helpers/api/TenantVirtualInterfaceApi/types";
import { useFormField } from "../../helpers/hooks/useFormField";
import DialogTemplate from "../../components/dialogs/common/DialogTemplate";
import SummaryInfoBlock from "../../components/leftInfoBlock/SummaryInfoBlock";
import DIAConnectionIcon from "../../components/icons/DIAConnectionIcon";
import UnifiedTextPair from "../../components/common/UnifiedTextPair";
import Input from "../../components/common/formComponents/Input";
import { Checkbox } from "../../components/common/formComponents/Checkbox";
import DropdownBasic from "../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../helpers/mapStringToItem";
import { FieldsType } from "../ZayoPages/Connections/ConnectionConfigurator/types";
import { useConnectionsContext } from "../WizardToolPage/ConnectionStep/ConnectionsContext";
import { useValidation } from "../../helpers/validators/Validator";
import validateDIA from "../../helpers/validators/DIAValidator";
import { useVRFList } from "../../helpers/api/hooks/useVRFList";

export type DIAProps = {
  onClose: () => void;
  onAdd: (data: VirtualInetrfaceDIA) => void;
  onEdit?: (data: Partial<VirtualInetrfaceDIA>) => void;
  onDelete?: (dia: VirtualInetrfaceDIA) => void;
  onChange?: (field: string, value: any) => void;
  className?: string;
  location?: string;
};

export const DIA_DEFAULT_FIELDS: VirtualInetrfaceDIACreate = {
  name: "",
  ip_pool_size: "",
  ipv4_pool_size: "",
  ipv6_pool_size: "",
  is_nat: true,
  is_snat: true,
  is_ipsec: false,
  tenant: "",
  system: { name: "", location: "" },
  prefix_assignment: "auto",
  gate_ipv4: "",
  vrf_name: "",
  administrative_state: "Up",
  system_name: "",
};

export const DIADialog: FC<DIAProps> = ({ onClose, className, location }) => {
  const {
    fetchPoolList,
    fetchSystems,
    createDiaService,
    fetchDIAList,
    systemsList,
    poolListIPv4 = [],
    poolListIPv6 = [],
    clearPoolList,
  } = useConnectionsContext();

  const [fields, handleFieldChange] = useFormField<VirtualInetrfaceDIACreate>(
    DIA_DEFAULT_FIELDS
  );

  const [vrfList] = useVRFList(fields.system?.name || "");
  const filteredVrfList = useMemo(
    () =>
      vrfList
        ?.filter((vrf) => vrf.vrf_type === "internet")
        .map((vrf) => vrf.name) || [],
    [vrfList]
  );

  const [systemName, setSystemName] = useState<string | undefined>();
  const [isManual, setIsManual] = useState<boolean>(false);

  useEffect(() => {
    fetchSystems();
  }, []);

  useEffect(() => {
    if (systemName) {
      fetchPoolList(systemName);
    }
  }, [systemName]);

  const handleCancel = () => {
    clearPoolList();
    resetState();
    onClose();
  };

  const resetState = () => {
    setSystemName(undefined);
    handleFieldChange("system", { name: "", location: "" });
  };

  const handleLocationChange = (val: any) => {
    const selectedSystem = systemsList?.find(
      (system) => system.location === val.key
    );
    if (selectedSystem) {
      setSystemName(selectedSystem.name);
      handleFieldChange("system", {
        name: selectedSystem.name,
        location: selectedSystem.location,
      });
    } else {
      resetState();
    }
  };

  const [errors, validate] = useValidation<VirtualInetrfaceDIACreate>(
    validateDIA,
    [fields]
  );

  const submitForm = async () => {
    const { isOk } = validate();

    if (isOk && location) {
      const updatedFields: VirtualInetrfaceDIACreate = {
        ...fields,
        ip_addresses: isManual
          ? ([fields.gate_ipv4, fields.gate_ipv6].filter(
              Boolean
            ) as Array<string>) // get rid of undefined
          : [],
      };

      const res = await createDiaService(updatedFields, location);
      if (res) {
        resetState();
        onClose();
        fetchDIAList(location);
      }
    }
  };

  return (
    <DialogTemplate
      controls={{
        okText: "Add",
        cancelText: "Cancel",
        onOk: submitForm,
        onCancel: handleCancel,
      }}
      onClose={handleCancel}
      leftColumn={
        !className ? (
          <SummaryBlock fields={fields} location={location || ""} />
        ) : undefined
      }
      title={"Add DIA Connection"}
      wrapperClassName={className}
    >
      <div className={styles.form}>
        <div className={styles.column}>
          <Input
            label="Name"
            name="name"
            value={fields.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            medium
            error={errors?.name}
          />
          <DropdownBasic
            id={"system_location"}
            label="System location"
            onChange={handleLocationChange}
            itemsList={systemsList?.map((system) =>
              mapStringToItem(system.location)
            )}
            selected={mapStringToItem(fields.system?.location || "")}
            isMedium
            error={errors?.system}
          />
          <div>
            <label className={styles.label}>Enabled NaaS Services</label>
            <div className={styles.checkboxGroup}>
              <Checkbox
                onChange={() => handleFieldChange("is_nat", !fields.is_nat)}
                isChecked={fields.is_nat}
                label={"NAT"}
              />
              <Checkbox
                onChange={() => handleFieldChange("is_snat", !fields.is_snat)}
                isChecked={fields.is_snat}
                label={"SNAT"}
              />
              <Checkbox
                onChange={() => handleFieldChange("is_ipsec", !fields.is_ipsec)}
                isChecked={fields.is_ipsec}
                label={"IPSEC"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div>
            <label className={styles.label}>Prefix Assignment</label>
            <div className={styles.toggle}>
              <button
                className={isManual ? styles.active : ""}
                onClick={() => {
                  setIsManual(true);
                  handleFieldChange("prefix_assignment", "manual");
                }}
              >
                Manually
              </button>
              <button
                className={!isManual ? styles.active : ""}
                onClick={() => {
                  setIsManual(false);
                  handleFieldChange("prefix_assignment", "auto");
                }}
              >
                Auto
              </button>
            </div>
          </div>

          {isManual ? (
            <Input
              label="Gate IPv4"
              name="gate_ipv4"
              placeholder="X.X.X.X/X"
              value={fields.gate_ipv4}
              onChange={(e) => handleFieldChange("gate_ipv4", e.target.value)}
              medium
              error={errors?.gate_ipv4}
            />
          ) : (
            <DropdownBasic
              id={"ipv4PoolSize"}
              label="IPv4 pool Size"
              itemsList={poolListIPv4.map((key) => mapStringToItem(key))}
              onChange={(item) => handleFieldChange("ipv4_pool_size", item.key)}
              selected={mapStringToItem(fields.ipv4_pool_size)}
              isMedium
              error={errors?.ip_pool_size}
            />
          )}

          {isManual ? (
            <Input
              label="Gate IPv6"
              name="gate_ipv6"
              isOptional={true}
              placeholder="X::X/X"
              value={fields.gate_ipv6}
              onChange={(e) => handleFieldChange("gate_ipv6", e.target.value)}
              medium
              error={errors?.gate_ipv6}
            />
          ) : (
            poolListIPv6?.length > 0 && (
              <DropdownBasic
                id={"ipv6PoolSize"}
                label="IPv6 pool Size"
                itemsList={poolListIPv6.map((key) => mapStringToItem(key))}
                onChange={(item) =>
                  handleFieldChange("ipv6_pool_size", item.key)
                }
                selected={mapStringToItem(fields.ipv6_pool_size)}
                isMedium
                error={errors?.ip_pool_size}
              />
            )
          )}
          {isManual && (
            <DropdownBasic
              id={"vrf"}
              label="VRF"
              itemsList={filteredVrfList?.map((key) => mapStringToItem(key))}
              onChange={(item) => handleFieldChange("vrf_name", item.key)}
              selected={mapStringToItem(fields.vrf_name || "")}
              isMedium
              error={errors?.vrf_name}
            />
          )}
          {isManual && (
            <DropdownBasic
              id={"administrative_state"}
              label="Gateway Admin State"
              itemsList={[mapStringToItem("Up"), mapStringToItem("Down")]}
              onChange={(item) =>
                handleFieldChange("administrative_state", item.key)
              }
              selected={mapStringToItem(fields.administrative_state || "")}
              isMedium
            />
          )}
        </div>
      </div>
    </DialogTemplate>
  );
};

const SummaryBlock: FC<{ fields: FieldsType; location: string }> = ({
  fields,
  location,
}) => (
  <SummaryInfoBlock
    icon={<DIAConnectionIcon color={"var(--dialog-background)"} />}
    title={"DIA"}
  >
    <UnifiedTextPair subtitle="Name" text={fields.name} />
    <UnifiedTextPair subtitle="Tenant" text={location} />
  </SummaryInfoBlock>
);
