import React, { FC } from "react";
import CollapsibleButton from "../../../../../../../../components/common/buttons/CollapsibleButton";
import DeleteButton from "../../../../../../../../components/common/buttons/DeleteButton";
import {
  ACCENT,
  GREY_DISABLED,
} from "../../../../../../../../helpers/common/colorAliases";
import styles from "./ItemControls.module.scss";
import { useConnectionConfiguratorContext } from "../../../../ConnectionConfiguratorContext";

type Props = {
  isExpand: boolean;
  toggleExpand: () => void;
  onDelete?: () => void;
  idx: number;
  isEdit?: boolean;
};

const ItemControls: FC<Props> = ({
  isExpand,
  toggleExpand,
  onDelete,
  idx,
  isEdit,
}) => {
  const { portType } = useConnectionConfiguratorContext();
  const showDeleteIcon = portType.key !== "azPorts" || isEdit;
  const iconColor = isExpand ? ACCENT : GREY_DISABLED;
  return (
    <div className={styles.wrapper}>
      {showDeleteIcon && onDelete && (
        <DeleteButton
          id={`delete${idx}`}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          color={iconColor}
        />
      )}
      <CollapsibleButton
        onClick={toggleExpand}
        toCollapse={!isExpand}
        color={iconColor}
        id={"events"}
      />
    </div>
  );
};

export default ItemControls;
