import React, { FC, useEffect, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { withContexts } from "../../helpers/hocs/withContexts";
import { EventsContextContainer, useEventsContext } from "./EventsContext";
import {
  getTimeRangeByDiff,
  useTimerangeContext,
} from "../../contexts/TimerangeContext";
import { bgpEventsColumns, eventsColumns } from "./table/eventsColumns";
import styles from "./EventsTable.module.scss";

import { classNames } from "../../helpers/common/classNames";
import EventsHeader from "./components/EventsHeader";
import EventLogs from "./components/EventLogs";
import { useHistory, useParams } from "react-router-dom";
import { EVENTS } from "../../helpers/navigation/entries";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import { EventsType } from "./types";
import { useUserContext } from "../../contexts/UserContext";
import { setEventsStartTime } from "../../helpers/setEventsStartTime";
import { EVENT_START_TIME } from "../../contexts/AuthContext";

type Props = {
  isCollapsible?: boolean;
  type: EventsType;
  matchParam?: string;
  className?: string;
};

const EventsTable: FC<Props> = ({
  isCollapsible,
  type,
  matchParam,
  className,
}) => {
  const history = useHistory();
  const { id } = useParams<any>();
  const { getSelectedTenant } = useGlobalFilterContext();
  const { timeRange, setTimeRange, setActiveTitle } = useTimerangeContext();
  const { user } = useUserContext();
  const {
    fetchEvents,
    events,
    eventsRequestStatus,
    selectEvent,
    selectedEvent,
    size,
  } = useEventsContext();

  const [tenant, setTenant] = useState<string | undefined>();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!!isCollapsible);
  const [hoveredId, setHoveredId] = useState<number | undefined>(undefined);
  const searchParams = window.location.search;

  useEffect(() => {
    setTenant(getSelectedTenant());
    if (searchParams === "?fromIcon") {
      const to = new Date().getTime();
      const from = +(localStorage.getItem(EVENT_START_TIME) || to - 30_000);
      const diff = getTimeRangeByDiff(to - from);
      setTimeRange({
        ...timeRange,
        general: { from: diff[0], to: "now" },
      });
      setActiveTitle(diff[1]);
    }
  }, []);

  useEffect(() => {
    if (type === "system" && matchParam) {
      fetchEvents(type, timeRange.general, size, matchParam);
    }
    if (type === "tenant" && tenant) {
      if (user?.role === "tenant") {
        setEventsStartTime();
      }
      fetchEvents(type, timeRange.general, size, tenant);
    }
    if (type === "bgp" && tenant) {
      fetchEvents(type, timeRange.general, size, tenant);
    }
    if (type === "systems") {
      if (user?.role === "admin") {
        setEventsStartTime();
      }
      fetchEvents(type, timeRange.general, size);
    }
  }, [tenant, timeRange.general, matchParam, type, size]);

  useEffect(() => {
    if (events && +id >= 0) {
      selectEvent(+id);
    }
  }, [events, id, user]);

  const handleClickRow = (id: number): void => {
    selectEvent(selectedEvent?.id === id ? undefined : id);
    isCollapsible &&
      type === "tenant" &&
      history.replace("/" + EVENTS().path + "/" + id.toString());
  };

  if (+id >= 0 && !selectedEvent) return <PageLoader />;

  const isBgp = type === "bgp";

  return (
    <div
      className={classNames(
        styles.wrapper,
        selectedEvent && styles.twoCols,
        isCollapsible && !isCollapsed && styles.notFullHeight
      )}
    >
      <EventsHeader
        title={isBgp ? "Neighbor Events" : "Events"}
        isCollapsible={!!isCollapsible}
        toggleCollapse={() => setIsCollapsed((prev) => !prev)}
        isCollapsed={isCollapsed}
        className={classNames(styles.header, className)}
        type={type}
      />
      {!isCollapsed && (
        <>
          <TableWrapper
            dataStatus={eventsRequestStatus}
            tablePlaceholder={["Events", "Event"]}
            className={styles.table}
          >
            <Table
              columns={
                isBgp
                  ? bgpEventsColumns(hoveredId, selectedEvent?.id)
                  : eventsColumns(hoveredId, selectedEvent?.id)
              }
              header={TableHeader}
              data={events || []}
              gridColumnTemplate={
                isBgp ? `1fr 80px 1fr 1fr 1fr` : `repeat(4, 1fr) 40px`
              }
              totalCount={(events || []).length}
              count={20}
              withFullData
              isRowHighlighted={(row) => row.id === selectedEvent?.id}
              onClick={isBgp ? undefined : (row) => handleClickRow(row.id)}
              onHover={(row) => setHoveredId(row?.id)}
            />
          </TableWrapper>
          {selectedEvent && <EventLogs className={styles.logs} />}
        </>
      )}
    </div>
  );
};

export default withContexts<Props>(EventsTable, [EventsContextContainer]);
