/* eslint-disable css-modules/no-unused-class */
import { FC, useEffect, useState } from "react";
import WarningMessage from "../../components/common/WarningMessage";
import WireGuardConfig from "../../components/common/WireGuardComponents/WireGuardConfig/WireGuardConfig";
import WireGuardQRCode from "../../components/common/WireGuardComponents/WireGuardQRCode/WireGuardQRCode";
import SystemAndroidIcon from "../../components/icons/WireGuardIcons/SystemAndroidIcon";
import SystemWindIcon from "../../components/icons/WireGuardIcons/SystemWindIcon";
import SystemIOSIcon from "../../components/icons/WireGuardIcons/SystemIOSIcon";
import SystemLinuxIcon from "../../components/icons/WireGuardIcons/SystemLinuxIcon";
import SystemMacOSIcon from "../../components/icons/WireGuardIcons/SystemMacOSIcon";
import SystemUbuntuIcon from "../../components/icons/WireGuardIcons/SystemUbuntuIcon";
import WorkFromHomeIcon from "../../components/icons/WorkFromHomeIcon";
import { useUserContext } from "../../contexts/UserContext";
import { withLayout } from "../../helpers/hocs/withLayout";
import InstructionPage from "./components/InstructionPage";
import styles from "./RemoteUserPage.module.scss";
import UserDetails from "../UsersPage/components/UserDetails/UserDetails";
import { withContexts } from "../../helpers/hocs/withContexts";
import UserEditDialogContextContainer, {
  useUserEditDialogContext,
} from "../../components/dialogs/UserEditDialogs/UserEditDialogContext";
import { ConnectionContextContainer } from "../Network/Connections/ConnectionContext";
import { parseQuery } from "../../helpers/common/parseQuery";
import WireGuardContextContainer from "../../components/common/WireGuardComponents/WireGuardContext";

const RemoteUserPage: FC = () => {
  const [activeInstruction, setActiveInstruction] = useState<string>("Windows");
  const [localUser, setLocalUser] = useState({ name: "", secondName: "" });
  const {
    fetchCurrUser,
    fetchTenantUser,
    currUser,
  } = useUserEditDialogContext();
  const userName = parseQuery("name");
  const userTenant = parseQuery("tenant");
  const { user } = useUserContext();

  useEffect(() => {
    if (userName && userTenant) {
      fetchTenantUser(userTenant, userName);
      setLocalUser({
        name: userName,
        secondName: userTenant,
      });
    } else {
      if (user && user.secondName) {
        fetchCurrUser();
        setLocalUser({
          name: user.secondName,
          secondName: user.name,
        });
      }
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.titleBox}>
        <WorkFromHomeIcon />
        <div className={styles.title}>Work from Home Configuration</div>
      </div>
      <div className={styles.main}>
        <div className={styles.qrColumn}>
          <WireGuardQRCode
            user={localUser.name}
            tenant={localUser.secondName}
          />
          <WireGuardConfig
            user={localUser.name}
            tenant={localUser.secondName}
            className={styles.showConfigBtn}
          />
        </div>
        <div className={styles.instrColumn}>
          <div>
            <SystemWindIcon
              onClick={() => setActiveInstruction("Windows")}
              className={styles.systemButton}
              color={activeInstruction}
            />
            <SystemMacOSIcon
              onClick={() => setActiveInstruction("MacOS")}
              className={styles.systemButton}
              color={activeInstruction}
            />
            <SystemUbuntuIcon
              onClick={() => setActiveInstruction("Ubuntu")}
              className={styles.systemButton}
              color={activeInstruction}
            />
            <SystemIOSIcon
              onClick={() => setActiveInstruction("IOS")}
              className={styles.systemButton}
              color={activeInstruction}
            />
            <SystemAndroidIcon
              onClick={() => setActiveInstruction("Android")}
              className={styles.systemButton}
              color={activeInstruction}
            />
            <SystemLinuxIcon
              onClick={() => setActiveInstruction("Linux")}
              className={styles.systemButton}
              color={activeInstruction}
            />
          </div>
          <InstructionPage activeInstruction={activeInstruction} />
        </div>
        <div className={styles.userInfoColumn}>
          <UserDetails tenant={user?.name} user={currUser} />
          <WarningMessage
            className={styles.warnMessage}
            message={
              "Reinstall the Tunnel according the instructions on the left to apply Internet Access changes."
            }
          />
        </div>
      </div>
    </div>
  );
};
export default withContexts(withLayout<any>(RemoteUserPage), [
  UserEditDialogContextContainer,
  WireGuardContextContainer,
  ConnectionContextContainer,
]);
