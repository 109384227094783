import React, { useEffect, useState } from "react";
import FilterForm from "./FilterForm";
import FilterList from "./FilterList";
import { Filter } from "./filterTypes";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { useApplicationsDashboardContext } from "../../../../pages/ApplicationsDashboard/ApplicationObservabilityContext";
import styles from "./AdvancedFilters.module.scss";
import { getIsApplicationOrNetworkURL } from "./getIsApplicationOrNetworkURL";
import {
  APPLICATIONS_STRING,
  CYBER_THREATS_STRING,
  DNS_STRING,
} from "../../../../helpers/consts";
import { useTrafficContext } from "../../../../pages/TrafficDashboard/TrafficContext";
import { calculateAdvancedFiltersObject } from "../../../../helpers/calculateAdvancedFiltersObject";
import { AVAILABLE_FIELDS_NETWORK } from "../../../../pages/TrafficDashboard/consts";
import { AVAILABLE_FIELDS_APPLICATION } from "../../../../pages/ApplicationsDashboard/consts";
import { AVAILABLE_FIELDS_DNS } from "../../../../pages/DnsDashboard/consts";
import { useDnsDashboardContext } from "../../../../pages/DnsDashboard/DnsContext";
import { useCyberThreatsContext } from "../../../../pages/CyberThreatsPage/CyberThreatsContext";

const AdvancedFilters: React.FC = () => {
  const [currentFilters, setCurrentFilters] = useState<Array<Filter>>([]);
  const [isFormOpened, setIsFormOpened] = useState<boolean>(false);

  const isDNS = getIsApplicationOrNetworkURL() === DNS_STRING;
  const isApplication = getIsApplicationOrNetworkURL() === APPLICATIONS_STRING;
  const isCyberThreats =
    getIsApplicationOrNetworkURL() === CYBER_THREATS_STRING;

  const getDataContext = () => {
    if (isDNS) {
      return useDnsDashboardContext();
    }
    if (isApplication) {
      return useApplicationsDashboardContext();
    }
    if (isCyberThreats) {
      return useCyberThreatsContext();
    }
    return useTrafficContext();
  };

  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();
  const { filtersData, fetchData, setFilters } = getDataContext();

  useEffect(() => {
    if (selectedTenant) fetchData(selectedTenant, timeRange.general);
  }, [selectedTenant, timeRange]);

  useEffect(() => {
    if (selectedTenant) fetchData(selectedTenant, timeRange.general);

    const storageKey = isDNS
      ? "LOCALSTORAGE_DNS_KEY"
      : isApplication
      ? "LOCALSTORAGE_APPLICATION_KEY"
      : "LOCALSTORAGE_TRAFFIC_KEY";

    const storedFilters = localStorage.getItem(storageKey);

    if (storedFilters) {
      const parsedFilters = JSON.parse(storedFilters);
      const filtersArray: Array<Filter> = Object.keys(parsedFilters).map(
        (key) => ({
          field: key.startsWith("!") ? key.slice(1) : key,
          value: parsedFilters[key],
          condition: key.startsWith("!") ? "is not" : "is",
        })
      );

      setCurrentFilters(filtersArray);

      const filtersObject = calculateAdvancedFiltersObject(filtersArray);
      setFilters(filtersObject);
    }
  }, [selectedTenant, timeRange]);

  const addFilter = (newFilter: Filter) => {
    const existingFilterIndex = currentFilters.findIndex(
      (filter) => filter.field === newFilter.field
    );

    let newFilters;

    if (existingFilterIndex !== -1) {
      newFilters = [...currentFilters];
      newFilters[existingFilterIndex] = newFilter;
    } else {
      newFilters = [...currentFilters, newFilter];
    }

    setCurrentFilters(newFilters);

    const filtersObject = calculateAdvancedFiltersObject(newFilters);
    setFilters(filtersObject);
    toggleForm();
  };

  const toggleForm = () => {
    setIsFormOpened(!isFormOpened);
  };

  const removeFilter = (index: number) => {
    const newFilters = [...currentFilters];
    newFilters.splice(index, 1);
    setCurrentFilters(newFilters);

    const filtersObject = calculateAdvancedFiltersObject(newFilters);
    setFilters(filtersObject);
  };

  const getAvailableFields = () => {
    return isDNS
      ? AVAILABLE_FIELDS_DNS
      : isApplication
      ? AVAILABLE_FIELDS_APPLICATION
      : AVAILABLE_FIELDS_NETWORK;
  };

  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.title}>FILTER</div>
      <FilterList filters={currentFilters} onRemoveFilter={removeFilter} />
      <FilterForm
        onAddFilter={addFilter}
        availableFields={getAvailableFields()}
        filtersData={filtersData}
        isFormOpened={isFormOpened}
        toggleForm={toggleForm}
      />
    </div>
  );
};

export default AdvancedFilters;
