import React, { useEffect, useMemo, useState } from "react";
import styles from "./ASNDropdown.module.scss";
import DropdownBasic from "../../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../../components/common/formComponents/Input";
import Button from "../../../../../../../../components/common/buttons/Button";
import { withContexts } from "../../../../../../../../helpers/hocs/withContexts";
import OrderedPortsContextContainer, {
  useOrderedPortsContext,
} from "../../../../../../../OrderedPortsPage/OrderedPortsContext";
import parseEnrichedData from "../../../../../../../../helpers/ASNEnrichedDataParser";

type DropdownOption = string;

type ASNDropdownProps = {
  options: Array<DropdownOption>;
  selected?: string;
  onSelect: (key: string) => void;
  onRegister: (asn: string, name: string) => void;
  onCancel?: () => void;
  isStraightRegister?: boolean;
  asnForSpecificTenantList: any;
};

const ASNDropdown: React.FC<ASNDropdownProps> = ({
  options,
  selected,
  onSelect,
  onRegister,
  isStraightRegister,
  onCancel,
  asnForSpecificTenantList,
}) => {
  const { getSingleASN, singleASNInfo } = useOrderedPortsContext();
  const [isRegisterNew, setIsRegisterNew] = useState(false);
  const [newASN, setNewASN] = useState("");
  const [asnName, setAsnName] = useState("");
  const [pendingList, setPendingList] = useState([]);
  const [asnError, setAsnError] = useState(""); // validation error
  const branding = localStorage.getItem("niroBranding");
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    if (singleASNInfo?.enriched_data) {
      const { asName } = parseEnrichedData(singleASNInfo.enriched_data);
      setAsnName(asName);
    }
  }, [singleASNInfo]);

  const filteredOptions = useMemo(() => {
    console.log(options, "qqq");

    return options
      ?.filter(
        (opt) =>
          !asnForSpecificTenantList.some(
            (asn: any) =>
              asn.asn == opt && !asn.approved && asn.labels?.reason_for_decline // remove declined asns
          )
      )
      .map((opt) => ({
        key: opt,
        customItemClassname: asnForSpecificTenantList.some(
          (asn: any) =>
            asn.asn == opt && !asn.approved && !asn.labels?.reason_for_decline
        )
          ? styles.grey // add custom grey color for pending asn
          : "",
      }));
  }, [options, asnForSpecificTenantList]);

  const isValidASN = (asn: number): boolean => {
    if (asn === 0 || asn === 23456 || asn === 65535 || asn === 4294967295)
      return false;
    if (asn >= 1 && asn <= 23455) return true;
    if (asn >= 23457 && asn <= 64495) return true;
    if (asn >= 131072 && asn <= 4199999999) return true;
    return false;
  };

  const handleSelect = (key: string) => {
    if (key === "Set up new") {
      setIsRegisterNew(true);
    }
    onSelect(key);
  };

  const validateASN = (asn: string) => {
    setAsnError("");

    if (!asn.match(/^\d+$/)) {
      setAsnError("ASN should contain only numbers.");
      return;
    }

    const asnNumber = parseInt(asn, 10);
    if (!isValidASN(asnNumber)) {
      setAsnError("ASN is not available for registration.");
    }
  };

  const handleRegister = () => {
    if (newASN.trim() && !asnError) {
      onRegister(newASN, asnName || `Name for ASN ${newASN}`);
      setNewASN("");
      setAsnName("");
      setIsRegisterNew(false);
    }
  };

  const getASNInfo = (asn: string) => {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    const newTimeout = setTimeout(() => {
      getSingleASN(asn);
    }, 800);

    setDebounceTimeout(newTimeout);
  };

  const renderForm = () => {
    return (
      <div className={styles.asnDropdown}>
        <div className={styles.customForm}>
          <div className={styles.title}>
            {`Set up new ASN ${branding !== null ? `with ${branding}` : ""}`}
          </div>
          <label className={styles.label}>
            <Input
              id="vlan_id"
              onChange={(e) => {
                setNewASN(e.target.value);
                validateASN(e.target.value);
                getASNInfo(e.target.value);
              }}
              value={newASN}
              className={styles.input}
              placeholder="Enter ASN"
              medium
            />
          </label>
          {asnError && <div className={styles.validationError}>{asnError}</div>}
          <label className={styles.label}>
            <Input
              id="vlan_id"
              onChange={(e) => setNewASN(e.target.value)}
              value={`AS Name: ${asnName || "Unassigned"}`}
              className={styles.asnNameInput}
              medium
              disabled
            />
          </label>
          <div className={styles.actions}>
            <Button
              onClick={() => {
                setIsRegisterNew(false);
                onCancel && onCancel();
              }}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={handleRegister}
              isPrimaryBtn
              disabled={!!asnError || !newASN}
            >
              {"Set up"}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  if (isStraightRegister) {
    return renderForm();
  }

  // add "Set up new" button with custom classname
  const finalOptions = useMemo(() => {
    return [
      ...filteredOptions,
      {
        key: "Set up new",
        customItemClassname: styles.setUpNewItem,
      },
    ];
  }, [filteredOptions]);

  return (
    <div className={styles.asnDropdown}>
      <DropdownBasic
        id="asn-dropdown"
        label="ASN"
        isMedium
        disabled={isRegisterNew}
        listClassName={styles.list}
        itemsList={isRegisterNew ? [] : finalOptions}
        selected={selected}
        onChange={(item: any) => handleSelect(item.key)}
      />
      {isRegisterNew ? renderForm() : null}
    </div>
  );
};

export default withContexts<ASNDropdownProps>(ASNDropdown, [
  OrderedPortsContextContainer,
]);
