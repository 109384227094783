import React, { FC, useEffect, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { withContexts } from "../../helpers/hocs/withContexts";
import TableControls from "../TenantsPage/table/TableControls";
import { diaTableHeader } from "./table/diaTableHeader";
import ConnectionsContextContainer, {
  useConnectionsContext,
} from "../WizardToolPage/ConnectionStep/ConnectionsContext";
import { DIADialog } from "./DIADialog";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import { useParams } from "react-router-dom";
import ConfirmDialog from "../../components/dialogs/common/confirmDialog/ConfirmDialog";
import {
  VirtualInetrfaceDIA,
  VirtualInetrfaceGate,
} from "../../helpers/api/TenantVirtualInterfaceApi/types";
import { CellHoverTooltip } from "./CellHoverTooltip";

type Props = {
  className?: string;
  notFull?: boolean;
  grid?: string;
  withoutPagination?: boolean;
  tenant?: string;
};

const DIATable: FC<Props> = ({
  className,
  notFull,
  grid,
  withoutPagination,
  tenant,
}) => {
  const {
    fetchDIAList,
    deleteDIAService,
    diaList,
    diaListStatus,
    setTenantVIName,
  } = useConnectionsContext();
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [selectedDIA, setSelectedDIA] = useState<
    VirtualInetrfaceGate | undefined
  >();

  const { tenant_name } =
    (tenant && { tenant_name: tenant }) || useParams<{ tenant_name: string }>();

  const tryDIADelete = async () => {
    if (tenant_name && selectedDIA) {
      const res = await deleteDIAService(tenant_name, selectedDIA.name);
      if (res) {
        setShowDelete(false);
      }
    }
  };

  useEffect(() => {
    handleListUpdate();
  }, []);

  const rowActions: ActionFuncs<any> = useMemo(
    () => ({
      onDelete: (rowOriginal) => {
        setShowDelete(true);
        setSelectedDIA(rowOriginal);
      },
    }),
    [tenant_name, setTenantVIName]
  );

  const shortRowActions: ActionFuncs<any> = useMemo(
    () => ({
      hoverTooltip: (row: VirtualInetrfaceDIA) => (
        <CellHoverTooltip rowOriginal={row} />
      ),
    }),
    [tenant_name, setTenantVIName]
  );

  const handleListUpdate = () => {
    fetchDIAList(tenant_name);
  };

  if (diaListStatus?.state === "pending" && !showAdd) return <PageLoader />;

  if (showAdd) {
    return (
      <DIADialog
        onClose={() => setShowAdd(false)}
        onAdd={() => setShowAdd(false)}
        onEdit={() => setShowAdd(false)}
        location={tenant_name}
      />
    );
  }

  return (
    <>
      <TableWrapper
        titleProps={{
          title: `${(diaList || []).length} Connections`,
          children: <TableControls onAdd={() => setShowAdd(true)} noSearch />,
          noTitle: notFull,
        }}
        dataStatus={diaListStatus}
        tablePlaceholder={["Connections", "Connection"]}
        isEmpty={diaList && diaList.length === 0}
        className={className}
      >
        <Table
          data={diaList || []}
          columns={diaTableHeader(notFull)}
          header={TableHeader}
          gridColumnTemplate={grid || "150px 150px 100px 200px 200px 200px 1fr"}
          rowActions={notFull ? shortRowActions : rowActions}
          onClick={() => !notFull && setShowDelete(true)}
          withoutPagination={withoutPagination}
          isBodyScrollable
        />
        {showDelete && (
          <ConfirmDialog
            title={"Delete DIA service"}
            message={`Are you sure you want to delete ${selectedDIA?.name} ?`}
            controls={{
              okText: "Cancel",
              onOk: () => setShowDelete(false),
              cancelText: "Delete",
              onCancel: () => tryDIADelete(),
            }}
          />
        )}
      </TableWrapper>
    </>
  );
};

export default withContexts<any>(DIATable, [ConnectionsContextContainer]);
