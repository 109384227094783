import React from "react";
import { useHistory } from "react-router-dom";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  ARPS,
  BGP,
  CLOUD_ROUTER,
  DIA_ROUTER,
  NETWORK,
  PBR,
  STATIC_ROUTES,
} from "../../../../helpers/navigation/entries";
import { PathEntry } from "../../../../helpers/types";
import TimerangOverlayComponent from "../../timerange/TimerangeOverlayComponent";
import NavigationTabs from "../NavigationTabs";
import ActiveTenantSelector from "../tenantSelector/ActiveTenantSelector";

import styles from "./CloudRouterTab.module.scss";

export const CloudRouterTab: React.FC<{
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  hideControls?: boolean;
  isCloud?: boolean;
}> = ({ showTimeRange, isCloud = true }) => {
  return (
    <div className={styles.tabsWrapper}>
      <ServiceTabs isCloud={isCloud} />
      <div className={styles.controlsWrapper}>
        <ActiveTenantSelector />
        {showTimeRange && (
          <TimerangOverlayComponent
            id={"cloudRouterTab"}
            className={styles.timerangeWrapper}
          />
        )}
      </div>
    </div>
  );
};
export const ServiceTabs = ({ isCloud }: { isCloud?: boolean }) => {
  const history = useHistory();
  const handleClick = (path: PathEntry) => {
    history.replace(
      formPathFromArray([
        NETWORK(),
        isCloud ? CLOUD_ROUTER() : DIA_ROUTER(),
        path,
      ])
    );
  };
  const servicesArray = isCloud
    ? [STATIC_ROUTES(), BGP(), ARPS(), PBR()]
    : [BGP(), ARPS()];
  return (
    <NavigationTabs
      entries={servicesArray}
      disabledTabKeys={[]}
      onClick={handleClick}
    />
  );
};
