import { VtepVni } from "../../pages/VNIPage/types";
import { UserRoles } from "../rolesHelpers";
import { TrafficType } from "../types";

export type PaginateListResponse<P> = {
  count: number;
  items: Array<P>;
  total_count?: number;
  visible_count?: number;
};

export type Version = {
  version: string;
  buildNumber: string;
  buildSHA: string;
};

export type SystemTypes = "NSOS-500" | "NSOS-1000" | "NSOS-6000";
export type SystemSatuses =
  | "REGISTERED"
  | "SYNCING"
  | "INVALID_CERT"
  | "UNAVAILABLE"
  | "READY";
export type SystemSecureType = "OPEN" | "AUTO" | "STRICT";

export type OneSystemType = {
  id: number;
  name: string;
  address: string;
  type: SystemTypes;
  version: string;
  status: SystemSatuses;
  location: string;
  description: string;
  secure_type: SystemSecureType;
  system_name?: string;
  alias?: string;
  physical_port_name?: string;
};

// Eventually remove. Don't have api like this.
export type SystemOverviewType = {
  address: string;
  description: string;
  id: number;
  location: string;
  ssh_key: string;
  status: string;
  hostName: string;
  hostIP: string;
  bandwidth: number;
  memoryAVBL: number;
  diskAVBL: number;
  CPUusage: number;
  name: string;
  availabilityScore: number;
  boxValue: object;
  titleLabels: Array<string>;
  traffic?: string;
};

export type VRFTypes = "system" | "tenant";

export type VRFType = {
  id: number;
  system_name: string;
  tenant_id: number;
  name: string;
  vrf_type: string;
  description: string;
  is_sdr?: boolean;
  sdr_rate?: number;
  rate?: number;
  is_dns?: boolean;
};

export type VLANType = {
  id: number;
  vrf_name: string;
  ipv4_addresses: Array<string>;
  ipv6_addresses: Array<string>;
  description: string;
  customer_port_name?: string;
};

export type PortInterfaceType = {
  administrative_state: string;
  mtu: number;
  virtual_interface_id: number;
  operational_state: string;
  alias: string;
  id: number;
  name: string;
  physical_port_id: number;
};

export type NewPortInterfaceType = {
  administrative_state: string;
  mtu: number;
  virtual_interface?: VirtualInterfaceType;
  operational_state: string;
  alias: string;
  id: number;
  name: string;
  physical_port_id: number;
};

export type PortVlanType = {
  administrative_state: string;
  operational_state: string;
  mtu: number;
  virtual_interface_id: number;
  alias: string;
  id: number;
  name: string;
  physical_port_id: number;
  vlan_id: number;
};

export type DeletePortVlanType = {
  physical_port_id: number;
  vlan_id: number;
};

export type VirtualInterfaceTypes = "BRIDGED" | "ROUTED" | "remote" | "ipsec";
export type MemberInterfaceType = "port-vlan" | "vtep_vni" | "port_interface";
export type MemberType = {
  interface_type: string;
  interfaces: any | VLANType | VtepVni | PortInterfaceType;
};
export type VirtualInterfaceType = {
  id: number;
  name: string;
  ipv4_addresses: Array<string>;
  ipv6_addresses: Array<string>;
  virtual_interface_type: string;
  members: Array<MemberType>;
  mtu: number;
  administrative_state: string;
  operational_state: string;
  description: string;
  gateway: string;
};

export type NatVirtualInterfaceType = {
  nat_port_block_size: number;
  nat_virtual_interface_name: string;
};

export type CreateVirtualInterfaceType = Partial<
  VirtualInterfaceType & NatVirtualInterfaceType
> & {
  ipv4_addresses: Array<string>;
  ipv6_addresses: Array<string>;
};

export type EditVirtualInterfaceIPType = {
  add_ip_addresses: Array<string>;
  delete_ip_addresses: Array<string>;
};

export type EditVirtualInterfaceType = {
  add_ip_addresses: Array<string>;
  delete_ip_addresses: Array<string>;
  mtu: number;
  administrative_state: string;
  description: string;
};

export type LoopbackType = {
  id: number;
  status: string;
  name: string;
  interfaceDescription: string;
  vrfMapping: string;
  ipv4: Array<string>;
  ipv6: Array<string>;
};

export type NatpoolType = {
  id: number;
  status: string;
  name: string;
  interfaceDescription: string;
  vrfMapping: string;
  ipv4: Array<string>;
  ipv6: Array<string>;
};

export type Characteristic = {
  v: number;
  as: number;
  msgRcvd: number;
  msgSent: number;
  tblVer: number;
  inQ: number;
  outQ: number;
  upDown: Date;
  statePfxRcd: string;
  pfxSnt: number;
};

export type Prefixes = {
  v4: number;
  v6: number;
};

export type BGPUnderlay = { conf: string; system_name: string };

export type ASType = {
  id: number;
  asn: number;
  vrf_id: number;
  router_id: string;
};

export type NeighborType = {
  id: number;
  autonomous_system_id: number;
  remote_asn: number;
  remote_router_ip: string;
  password?: string;
  description?: string;
};

export type BGPRowType = {
  vrf_id: string;
  vrf_name: string;
  asn: string;
  neighbor_id?: string;
  router_id?: string;
  remote_asn?: string;
  remote_router_ip?: string;
  status?: string;
  addressFamily?: string;
  peerUptime?: string;
  description?: string;
  afParams: Array<AFParameter>;
};

export type AFParameter = {
  remoteAs?: number;
  localAs?: number;
  version?: number;
  msgRcvd?: number;
  msgSent?: number;
  tableVersion?: number;
  outq?: number;
  inq?: number;
  peerUptime?: string;
  peerUptimeMsec?: number;
  pfxRcd?: number;
  pfxSnt?: number;
  state?: string;
  peerState?: string;
  connectionsEstablished?: number;
  connectionsDropped?: number;
  idType?: string;
  description?: string;
};

export type BGPType = {
  id: number;
  state: {
    status: string;
    characteristics: Array<Characteristic>;
  };
  neighbor: string;
  vrf: string;
  addressFamily: Array<string>;
  maxPrefixes?: number;
  currentPrefixes: Array<Prefixes>;
  gateway: string;
  localAS: number;
  remoteAS: number;
  distance: number;
};

export type StaticRouteType = {
  destination: string;
  gateway_ip_address: string;
  distance: number;
  description: string;
  type?: StaticRoutesType;
  enable: boolean;
};

export type ServiceStaticRouteBase = {
  destination: string;
  distance: number;
  description: string;
};

export type ServiceStaticRouteFields = {
  destination: string;
  distance: number;
  description: string;
  gateway_ip_address: string;
  gateway_interface: string;
  type: StaticRoutesType;
  enable: boolean;
};

export type ServiceStaticRouteType =
  | ServiceStaticRouteIpType
  | ServiceStaticRouteInterfaceType;

export type ServiceStaticRouteIpType = {
  gateway_ip_address: string;
  destination: string;
  distance: number;
  description: string;
  enable: boolean;
};

export type ServiceStaticRouteInterfaceType = {
  gateway_interface: string;
  destination: string;
  distance: number;
  description: string;
  enable: boolean;
};

export type DeleteStaticRouteType = {
  destination: string;
  gateway_ip_address: string;
};

export type StaticRoutesBodyType = {
  vrf: number | string;
} & StaticRouteType;

export enum StaticRoutesType {
  STATIC = "IP Address",
  INTERFACE = "Interface",
}

export type AdministrativeStateType = "up" | "down";
export type OperationalStateType =
  | "up"
  | "link_down"
  | "not_present"
  | "err_disable"
  | "down"
  | "admin_down";

export type LAGType = {
  id: number;
  status: string;
  name: string;
  type: string;
  links: {
    configured: number;
    active: {
      curr: number;
      min: number;
    };
  };
  members: Array<number>;
  vlans: {
    untagged: undefined | number;
    tagged: undefined | Array<number>;
  };
  speed: number;
  adminState: string;
};

export type SortType = { id: string; desc: boolean } | undefined;

export type FilterType = {
  search?: string;
  fields?: { [key: string]: string };
};

export type FilterAndSort = {
  sort?: SortType;
  filter?: FilterType;
};

export type LogsListType = {
  status: "critical" | "warning";
  time: Date;
  state: string;
  ip: string;
};

export type NatRulesList = PaginateListResponse<NatRuleType>;
export type NatRuleParams = {
  rule_type: string;
  inside: string;
  outside?: string;
  ip?: string;
  rate_limit_cps: number;
  rate_limit_ccn: number;
  overload: boolean | null;
  name: string;
  description: string;
  outside_type?: any;
  outside_ip?: string;
  outside_name?: string;
  filters?: Array<FWFilters>;
};

export type NatType = "SOURCE_ONE_TO_ONE" | "SOURCE_NAT" | "NOP";

export type NatRuleType = NatRuleParams & {
  vrf_id?: number;
  id?: string;
};
export type NatInsideType = {
  inside_type: "IP" | "Interface" | "Prefix";
  value: string | number;
};
export type NatOutsideType = {
  outside_type: "IP" | "Interface";
  value: string | number;
};
export type NatFilterType = {
  filter_type: "IP" | "Interface";
  value: string;
};

export type IdxResponse = {
  id: string | number;
};

export type FWFilterTypes =
  | "src_network"
  | "src_segment"
  | "dst_network"
  | "dst_segment"
  | "src_l4_port"
  | "dst_l4_port"
  | "dst_group"
  | "src_group"
  | "ip_protocol";

export type FWFilters = {
  filter_type: string;
  values: string | number | boolean;
};
export type FWPolicy = "ACCEPT" | "DROP";
export type FWRuleType = {
  id: number;
  enable: boolean;
  name: string;
  priority: number;
  filters: Array<FWFilters>;
  forwarding_policy: string;
  description: string;
  created_at: string;
  updated_at: string;
  user_ip_address: string | null;
  username: string | null;
  categories: Array<string>;
  allowed_domains: Array<string>;
  denied_domains: Array<string>;
  gate_vi_name?: string;
  gate_vi_names?: Array<string>;
};

export type DIAFWRuleEdit = {
  gate_vi_names: Array<string>;
  add_gate_vi_names: Array<string>;
  remove_gate_vi_names: Array<string>;
};

export type FWDiaRuleType = {
  id: number;
  enable: boolean;
  name: string;
  priority: number;
  filters: Array<FWFilters>;
  forwarding_policy: string;
  description: string;
  created_at: string;
  updated_at: string;
  user_ip_address: string | null;
  username: string | null;
  categories: Array<string>;
  allowed_domains: Array<string>;
  denied_domains: Array<string>;
  gate_vi_name: string;
  gate_vi_names: Array<string>;
  time_policies_names: Array<string>;
  policy_name: string;
};

export type PBRActionType = "ROUTE" | "NO_ROUTE";
export type PBRType = {
  id: number;
  enable: boolean;
  name: string;
  priority: number;
  filters: Array<FWFilters>;
  gateway_ip_address: string;
  gateway_interface: string;
  gateway_MAC: string;
  description: string;
  labels: object;
  action: PBRActionType;
  gateway_vrf_name?: string;
  tenant_name?: string;
  vrf_name?: string;
};

export type PBMType = {
  id: number;
  enable: boolean;
  name: string;
  priority: number;
  filters: Array<FWFilters>;
  fragment: boolean;
  description: string;
  unit: string;
  count: number;
};

export type DDoSOptionType = {
  name: string;
  flood_detect_rate: number;
  flood_cooldown_rate: number;
  hysteresis_seconds: number;
  flood_rate: number;
  icon: "";
};
export type DDoSType = {
  id: number;
  name: string;
  priority: number;
  enable: boolean;
  filters: Array<FWFilters>;
  max_rate: number;
  flood_detect_rate: number;
  flood_cooldown_rate: number;
  hysteresis_seconds: number;
  flood_rate: number;
  unit: string;
  description: string;
  options: Array<DDoSOptionType>;
  scope_type: string | null;
  /**
   * Only for dia ddos
   */
  system_name?: string;
};

export type TenantType = {
  id: number;
  name: string;
  full_name: string;
  contact_info: {
    name: string;
    email: string;
  };
  systems: Array<string>;
  quota_package_name: string;
  services_package_name: string | null;
} & TenantServices;

export type ThresholdType = { max_bandwidth: number };

export type TenantServices = {
  is_firewall_service: boolean;
  is_nat_service: boolean;
  is_ids_service: boolean;
  is_dns_service: boolean;
  is_ddos_service: boolean;
  is_segments_service: boolean;
  is_remote_users_service: boolean;
  is_fqdn_service: boolean;
  is_load_balancing_service: boolean;
  is_secure_web_gw_service: boolean;
  is_sdr_service: boolean;
  is_mesh_enabled: boolean;
  is_dia_fw_service: boolean;
};

export type TenantPlansType = {
  name: string;
  package: string;
  fw_rules: number;
  nat_rules: number;
  bgp_neighbors: number;
  routes_per_bgp_neighbor: number;
  total_routes: number;
};

export type TenantContactInfo = {
  contact_name: string;
  contact_email: string;
};

export type TenantQuotas = {
  bandwidth: number;
  static_routes: number;
  dynamic_routes: number;
  neighbors: number;
  fw_rules: number;
  nat_rules: number;
};

export type TenantServicesType = {
  fw: boolean;
  ddos: boolean;
  lb: boolean;
  nat: boolean;
  ips_ids: boolean;
  swg: boolean;
};

export type TenantSystemParam = {
  name: string;
  type: string;
  value: number;
  ip: string;
};

export type TenantSystemType = {
  system_id: string | number;
  system_name: string | number;
  name: string;
  max_throughput: string | number;
  interfaces: Array<TenantSystemParam>;
};

// Statistics types

export type StatisticsType = "connections" | TrafficType;
export type InterfaceType = "vlan" | "vni";

export type StatisticsMap<T> = {
  [key: string]: Array<T>;
};

export type FWRulesHitsBodyStatistics = { rule_id: string; hits: number };

export type FWRulesHitCountsStatistics = {
  items: Array<FWRulesHitsBodyStatistics>;
};

export type SingleRuleTrafficStatistics = {
  rule_id: string;
  metrics: PaginateListResponse<TrafficStatisticsType>;
};

export type RulesTrafficStatistics = {
  items: Array<SingleRuleTrafficStatistics>;
};

export type ConnectionStatisticsType = {
  timestamp: string;
  cps: number;
  ccn: number;
};
export type TrafficStatisticsType = {
  timestamp: string;
  rx: number;
  tx: number;
};

export type InterfaceStatProps = {
  sysID: string;
  interfaceType: InterfaceType;
  interfaceId: string;
  statType: StatisticsType;
};

export type VRouter_MAC = {
  mac: string;
  interface_name: string;
  is_static: boolean;
  is_aged: boolean;
  age_minutes: number;
};

export type VRouter_ARP = {
  age_sec: number;
  bridge_domain_id: number;
  ip: string;
  is_static: false;
  mac: string;
  vrf_id: number;
};

export type LoginProfileType = {
  username: string;
  password: string;
  role: UserRoles;
};

export type QuotaType = {
  name: string;
  fw_rules: number;
  nat_rules: number;
  maximum_bgp_neighbors: number;
};

export type QuotaNames =
  | "Essentials"
  | "Premium"
  | "Pro"
  | "Unlimited"
  | "Custom";

export type ChangeQuota = {
  quota_package_name: string;
};

export type ChangeServicesPackage = {
  services_package_name: string;
};

export type TenantQuotaType = {
  name: string;
  quota: Partial<QuotaType>;
  current_state: Partial<QuotaType>;
};

export type ServiceIdsType = {
  tenant_name: string;
  all: boolean;
  interfaces: Array<any>;
};

export type ServiceDpiType = {
  tenant_name: string;
  enabled: boolean;
};

export type StatusPeersType = {
  remoteAs: number;
  localAs: number;
  version: number;
  msgRcvd: number;
  msgSent: number;
  tableVersion: number;
  outq: number;
  inq: number;
  peerUptime: string;
  peerUptimeMsec: number;
  peerUptimeEstablishedEpoch: number;
  pfxRcd: number;
  pfxSnt: number;
  state: string;
  peerState: string;
  connectionsEstablished: number;
  connectionsDropped: number;
  idType: string;
  description?: string;
};

export type PeerStatus = {
  ip: string;
  peerState: string;
};

export type StatusNeighborsType = {
  remote_router_ip: string;
} & StatusPeersType;

export type StatusTypeType = {
  routerId: string;
  as: number;
  vrfId: number;
  vrfName: string;
  tableVersion: number;
  ribCount: number;
  ribMemory: number;
  peerCount: number;
  peerMemory: number;
  peers: { [key: string]: StatusPeersType };
  failedPeers: number;
  displayedPeers: number;
  totalPeers: number;
  dynamicPeers: number;
  bestPath: {
    multiPathRelax: string;
  };
};

export type StatusVRFType = {
  [key: string]: { [key: string]: StatusTypeType };
};

export type AccessTokenType = {
  access_token: string;
};

export type UserDTO = {
  id?: string;
  username: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  description?: string;
  role: UserRoles;
  enabled?: boolean;
  remaining_failed_attempts?: number;
  name: string;
};

export type ServiceBGPPeersStatus = {
  remoteAs: number;
  localAs: number;
  version: number;
  msgRcvd: number;
  msgSent: number;
  tableVersion: number;
  outq: number;
  inq: number;
  peerUptime: string;
  peerUptimeMsec: number;
  pfxRcd: number;
  pfxSnt: number;
  state: string;
  peerState: string;
  connectionsEstablished: number;
  connectionsDropped: number;
  idType: string;
};
export type ServiceBGPStatus = {
  router_id: string;
  autonomous_system_number: number;
  tenant_name: string;
  peers: {
    [key: string]: ServiceBGPPeersStatus;
  };
};

export type ServiceBGPNeighbor = {
  tenant_name: string | null;
  remote_router_ip: string;
  remote_asn: number;
  password: string;
  description: string;
  id: number;
  autonomous_system_id: number;
  autonomous_system: {
    asn: number;
    id: number;
    tenant_name: string;
  };
  connected_vi: string;
};
export type ServiceBGPNeighborWithStatus = ServiceBGPNeighbor & {
  status: ServiceBGPPeersStatus;
};

export type SiteRegionConnection = "DC" | "Cloud" | "FTTB" | "Splice Point";
export type SiteRegion = {
  name: string;
  connection_type: SiteRegionConnection;
  operator: string;
  address: string;
  cloud_region: string | null;
  network_status: string;
  naas_location: string;
  naas_systems: Array<string>;
  labels: { [key: string]: any };
  handover_bw: Array<{
    bw: number;
    hw: number | null | string;
  }>;
};
