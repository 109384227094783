/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { DDoSType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import AbstractCrudContextContainer, {
  createContextAndUseWithExtra,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";
import withTimerangeContext from "../../helpers/hocs/withTimerangeContext";
import { RequestStatus, TimerangeContextType } from "../../helpers/types";
import {
  ERROR,
  OK,
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { VRF_INTERNET } from "../../helpers/consts";
import { Res } from "../../helpers/api/Api";

type IState = CrudState<DDoSType, DDoSType> & {
  ddosGroups: Record<string, Array<DDoSType>>;
  ddosGroupsStatus: RequestStatus;
  lastFetchedTenant: string;
  selectedDDoS: DDoSType | undefined;
};

type AdditionMultiple = {
  selectedSystems?: Array<string>;
  ddosName?: string;
  isTemplate?: boolean;
};

type IFunc = {
  fetchList: (isTemplate?: boolean) => void;
  removeMultiple: (addition?: AdditionMultiple) => Promise<Res<any>>;
  addMultiple: (
    params: Record<string, Partial<DDoSType>>,
    addition?: AdditionMultiple
  ) => Promise<Res<any>>;
  editMultiple: (
    params: Record<string, Partial<DDoSType>>,
    addition?: AdditionMultiple
  ) => Promise<Res<any>>;
};

export type DDoSDeleteArgs = DDoSType & {
  tenant: string;
};

const [Context, useContext] = createContextAndUseWithExtra<
  IState,
  IFunc & CrudFunc<DDoSType, DDoSType, AdditionMultiple>,
  TimerangeContextType
>();

export const useDDoSDIAContext = useContext;

type Props = React.PropsWithChildren<{
  timerangeContext: TimerangeContextType;
}>;

class DDoSDIAContextContainer extends AbstractCrudContextContainer<
  DDoSType,
  DDoSType,
  IState,
  DDoSType
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.funcs = {
      fetchList: this.fetchList,
      removeMultiple: this.removeMultiple,
      addMultiple: this.addMultiple,
      editMultiple: this.editMultiple,
    };
  }

  listReq = async () => {
    const res = await configApi.getDDoSDIA();
    // TEMP SOLUTION FOR SORTING
    if (res.ok) {
      const sortedResult = res.result?.sort(
        (prev, next) => prev.priority - next.priority || prev.id - next.id
      );
      return { ...res, result: sortedResult };
    }
    return res;
  };

  templateListReq = async () => {
    const res = await configApi.getDDoSTemplates();
    // TEMP SOLUTION FOR SORTING
    if (res.ok) {
      const sortedResult = res.result?.sort(
        (prev, next) => prev.priority - next.priority || prev.id - next.id
      );
      return { ...res, result: sortedResult };
    }
    return res;
  };

  fetchList = async (isTemplate?: boolean): Promise<void> => {
    this.setState({ ddosGroupsStatus: setPending("Fetching") });
    let res: Res<Array<DDoSType>> = {
      ok: false,
    };
    if (isTemplate) {
      res = await this.templateListReq();
    } else {
      res = await this.listReq();
    }

    if (res.ok && res.result) {
      const group = (arr: Array<DDoSType>) =>
        arr.reduce((res: Record<string, Array<DDoSType>>, curr) => {
          const { name, description } = curr;
          const key = [name, description].join("|");
          if (!res[key]) res[key] = [];

          res[key].push(curr);

          return res;
        }, {});

      this.setState({
        ddosGroups: group(res.result),
      });
    }
    this.setState({
      ddosGroupsStatus: resToState(res),
    });
  };

  selectedReq = async (
    ip_flood?: string | number,
    addition?: AdditionMultiple
  ) => {
    const { selectedSystems = [] } = addition ?? { selectedSystems: [] };
    const [system = ""] = selectedSystems;
    return await configApi.getDDoS(
      system,
      VRF_INTERNET,
      (ip_flood ?? "").toString()
    );
  };

  addMultiple = async (
    params: Record<string, Partial<DDoSType>>,
    addition?: AdditionMultiple
  ) => {
    this.setState({ addStatus: setPending("Fetching") });
    const { selectedSystems = [], isTemplate } = addition ?? {
      selectedSystems: [],
      isTemplate: false,
    };
    const promises = selectedSystems.map((system) =>
      configApi.addDDoS(system, VRF_INTERNET, params[system])
    );
    const results = await Promise.all(promises);
    if (results.some((res) => res.ok)) {
      await this.fetchList(isTemplate);
    }
    if (results.every((res) => res.ok)) {
      this.setState({ addStatus: { state: OK } });
    } else {
      this.setState({ addStatus: { state: ERROR } });
    }

    this.setupTimeout(
      "removeStatus",
      () => this.setState({ addStatus: undefined }),
      2000
    );
    return {
      ok: true,
      result: results,
    };
  };

  removeMultiple = async (addition?: AdditionMultiple) => {
    this.setState({ removeStatus: setPending("Fetching") });
    const { selectedSystems = [], isTemplate, ddosName = "" } = addition ?? {
      selectedSystems: [],
      isTemplate: false,
      ddosName: "",
    };
    const promises = selectedSystems.map((system) =>
      isTemplate
        ? configApi.deleteDDoSTemplate(system, VRF_INTERNET, ddosName)
        : configApi.deleteDDoS(system, VRF_INTERNET, ddosName)
    );
    const results = await Promise.all(promises);
    if (results.some((res) => res.ok)) {
      await this.fetchList(isTemplate);
    }
    if (results.every((res) => res.ok)) {
      this.setState({ removeStatus: { state: OK } });
    } else {
      this.setState({ removeStatus: { state: ERROR } });
    }

    this.setupTimeout(
      "removeStatus",
      () => this.setState({ removeStatus: undefined }),
      2000
    );
    return {
      ok: true,
      result: results,
    };
  };

  editMultiple = async (
    params: Record<string, Partial<DDoSType>>,
    addition?: AdditionMultiple
  ) => {
    this.setState({ editStatus: setPending("Fetching") });
    const { selectedSystems = [], isTemplate, ddosName = "" } = addition ?? {
      selectedSystems: [],
      isTemplate: false,
      ddosName: "",
    };
    const promises = selectedSystems.map((system) =>
      configApi.editDDoS(system, VRF_INTERNET, ddosName, params[system])
    );
    const results = await Promise.all(promises);
    if (results.some((res) => res.ok)) {
      await this.fetchList(isTemplate);
    }
    if (results.every((res) => res.ok)) {
      this.setState({ editStatus: { state: OK } });
    } else {
      this.setState({ editStatus: { state: ERROR } });
    }

    this.setupTimeout(
      "removeStatus",
      () => this.setState({ editStatus: undefined }),
      2000
    );
    return {
      ok: true,
      result: results,
    };
  };

  addReq = async () => {
    return { ok: true };
  };

  removeReq = async () => {
    return { ok: true };
  };

  editReq = async () => {
    return { ok: true };
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.baseFuncs,
          ...this.funcs,
          ...this.props.timerangeContext,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default withTimerangeContext(DDoSDIAContextContainer);
