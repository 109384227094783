import Section from "../../../components/common/Section";
import styles from "./SystemsSection.module.scss";
import DropdownAutocomplete from "../../../components/common/Dropdown/DropdownAutocomplete";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { RequestState } from "../../../helpers/types";
import { PENDING } from "../../../helpers/common/RequestStateHelpers";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";

type Props = {
  errors?: any;
  className: string;
  gridTemplate: string;
  selectedSystems: Array<string>;
  setSelectedSystems: (value: Array<string>) => void;
  systemList?: Array<string>;
  systemListRequestStatus: RequestState;
  viewOnly?: boolean;
};

const SystemsSection = ({
  className,
  gridTemplate,
  selectedSystems,
  setSelectedSystems,
  systemList = [],
  systemListRequestStatus,
  viewOnly,
}: Props) => {
  return (
    <>
      <Section
        title="Systems"
        className={className}
        columnsCount={2}
        gridTemplateString={gridTemplate}
      >
        <div className={styles.column}>
          <div className={styles.blocksWrapper}>
            {systemListRequestStatus === PENDING ? (
              <LoaderIcon />
            ) : (
              <DropdownAutocomplete
                id="systems"
                valuesList={systemList}
                placeholder="System name"
                onChange={(val: Array<string>) => {
                  setSelectedSystems(val);
                }}
                currentValues={selectedSystems}
                selected={mapStringToItem("")}
                isMedium
                className={styles.listWrapper}
                listClassName={styles.listWrapper}
                emptyListPlaceholder="No systems available"
                error={selectedSystems.length ? undefined : "Select a system"}
                disabled={viewOnly}
              />
            )}
          </div>
        </div>
      </Section>
    </>
  );
};
export default SystemsSection;
