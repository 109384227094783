import { FC } from "react";
import Input from "../../../../../../../../components/common/formComponents/Input";
import BWDropdown from "../../../../common/formComponents/BWDropdown";
import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
import styles from "./DIAItem.module.scss";
import IPAddress from "../../../../common/formComponents/IPAddress";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: (idx: number, fields: FieldsType) => void;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};

const DIAItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  handleChangeBW,
}) => {
  return (
    <ItemContainer
      type={type}
      title={"DIA"}
      idx={idx}
      onDelete={() => onDelete(idx, fields)}
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      <BWDropdown
        field={fields.BW}
        onChange={handleChangeBW}
        error={errors?.[idx + "BW"]}
        idx={idx}
      />
      <Input
        label="Subnet"
        name="Subnet"
        placeholder="0.0.0.0/0"
        value={fields.Subnet}
        medium
        error={errors?.[idx + "Subnet"]}
        onChange={(e) => onChange("Subnet", e.target.value)}
        isNotEditable={isEdit}
      />
      <Input
        label="GW"
        name="GW"
        placeholder=""
        value={fields.GW}
        medium
        error={errors?.[idx + "GW"]}
        onChange={(e) => onChange("GW", e.target.value)}
        isNotEditable={isEdit}
      />
      <IPAddress
        field={fields.ip_addresses}
        onChange={onChange}
        error={errors?.[idx + "ip_addresses"]}
      />
    </ItemContainer>
  );
};

export default DIAItem;
