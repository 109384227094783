import { CellProps } from "react-table";
import styles from "./diaTableHeader.module.scss";
import Label from "../../../components/common/table/Label";
import { VirtualInetrfaceDIA } from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import React from "react";
import DIAConnectionIcon from "../../../components/icons/DIAConnectionIcon";
import LabelArray from "../../../components/common/table/LabelArray";

export const diaTableHeader = (notFull = false): Array<any> => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return (
          <div className={styles.labelName}>
            <DIAConnectionIcon />
            <div data-tip data-for={value}>
              <Label className={styles.noBack}>{value}</Label>
            </div>
          </div>
        );
      },
    },
    {
      Header: "IP pool assigned",
      accessor: "ip_address",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return value ? <Label>{value}</Label> : null;
      },
    },

    {
      Header: notFull ? "AVBL IPs" : "Available IPs",
      accessor: "availableIPs",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "GW IP",
      accessor: "ip_addresses",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return <LabelArray values={value} isOutlined />;
      },
    },
  ];
  if (!notFull) {
    columns.push(
      {
        Header: "Allowed Services",
        accessor: "allowed_services",
        Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
          return (
            <div className={styles.labelName}>
              {value.is_nat && <Label isOutlined>NAT</Label>}
              {value.is_snat && <Label isOutlined>SNAT</Label>}
              {value.is_ipsec && <Label isOutlined>IPSEC</Label>}
            </div>
          );
        },
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
          return <div>{value}</div>;
        },
      }
    );
  }
  return columns.map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInetrfaceDIA,
  }));
};
