import { CellProps } from "react-table";
import Label from "../../../../components/common/table/Label";
import { StateStatus } from "../../types/connections";
import { getIconAndText } from "../helpers/getConnectionType";
import { RemoteConfigButton } from "./RemoteConfigButton";
import { StateCell } from "./StateCell";
import LabelArray from "../../../../components/common/table/LabelArray";
import {
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
} from "../../../../helpers/validators/Validator";
import { ReactElement } from "react";
import { getBandwidthStringForTable } from "../../../../helpers/getBandwidthStringForTable";
import ReactTooltip from "react-tooltip";
import styles from "./TableColumnsConfig.module.scss";
import { parsePortVlan } from "../../UnitedConnections/components/Clouds/helpers";

/* eslint-disable @typescript-eslint/no-unused-vars */

export function getConnectionNameDisplay(value: any): ReactElement {
  const [
    name,
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    is_dia,
    interface_name,
  ] = value;
  const [icon, _] = getIconAndText(
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    undefined,
    undefined,
    is_dia
  );
  const isRemote = remote_type === "remote_users";
  const displayName = isRemote ? "Remote users" : name;

  return (
    <>
      {interface_name && (
        <ReactTooltip
          id={interface_name}
          aria-haspopup="true"
          place="bottom"
          type="light"
        >
          {interface_name}
        </ReactTooltip>
      )}
      <div className={styles.connectionName} data-tip data-for={interface_name}>
        {icon}
        <span className={(isRemote && styles.remote) || ""}>{displayName}</span>
      </div>
    </>
  );
}

export function getConnectionTypeDisplay(value: any): ReactElement {
  const [
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    is_dia,
  ] = value;
  const [_, displayType] = getIconAndText(
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    undefined,
    undefined,
    is_dia
  );
  return <Label>{displayType || "-"}</Label>;
}

export const TableColumnsConfig: Array<any> = [
  {
    accessor: (row: any) => [
      row.name,
      row.type,
      row?.virtual_interface_type,
      row?.labels?.remote_type,
      row?.is_ipsec_service,
      row?.is_nat_service,
      row?.is_snat_service,
      row?.is_dia,
      row?.interface_name,
    ],
    Header: "Name",
    Cell: ({ cell: { value } }: CellProps<any>) =>
      getConnectionNameDisplay(value),
  },
  {
    accessor: (row: any) => [
      row.type,
      row?.virtual_interface_type,
      row?.labels?.remote_type,
      row?.is_ipsec_service,
      row?.is_nat_service,
      row?.is_snat_service,
      row?.is_dia,
    ],
    Header: "Type",
    Cell: ({ cell: { value } }: CellProps<any>) =>
      getConnectionTypeDisplay(value),
  },
  {
    Header: "Customer Port",
    accessor: "customer_port_name",
  },
  {
    accessor: (row: any) => [row.vlan_ctag_id, row.vlan_id],
    Header: "VLAN",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      const [vlan_ctag_id, vlan_id] = value;
      const vlan = parsePortVlan({ vlan_ctag_id, vlan_id });

      return vlan;
    },
  },
  {
    accessor: "ip_addresses",
    Header: "Interface IP",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      const ipv4 = value?.filter((val: string) => checkIPv4WithPrefix(val));
      const ipv6 = value?.filter((val: string) => checkIPv6WithPrefix(val));
      return (
        <div>
          <LabelArray values={ipv4} withWrap={true} />
          <LabelArray
            values={ipv6}
            singleLabelClassname={styles.ips}
            withWrap={true}
          />
        </div>
      );
    },
  },
  {
    Header: "Max Bandwidth",
    accessor: "max_bandwidth",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      return value ? getBandwidthStringForTable(value) : "Unlimited";
    },
  },
  {
    accessor: "description",
    Header: "Description",
  },
  {
    accessor: "state",
    Header: "Admin/Oper State",
    Cell: ({
      cell: { value },
    }: {
      cell: {
        value: {
          administrative_state: StateStatus;
          operational_state: StateStatus;
        };
      };
    }) => {
      return <StateCell {...value} />;
    },
  },
  {
    accessor: "remote_config",
    Header: "",
    Cell: (cell: any) => {
      const vi = cell.row.original;

      return <RemoteConfigButton vi={vi} />;
    },
  },
];
