/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";
import {
  VI_GATE,
  VI_IPSEC,
  VI_IPSEC_GATEWAY,
} from "../../../components/tenants/TenantPackages/components/constAlias";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../contexts/UserContext";
import { Res, ResultStatus } from "../../../helpers/api/Api";
import { networkApi } from "../../../helpers/api/networkApi";
import { systemApi } from "../../../helpers/api/SystemApi";
import { DropdownItem } from "../../../helpers/types";
import { System, SystemAPI } from "../../Systems/Provisioning/types";

type Status = "ok" | "pending" | "error" | "idle";

import {
  VirtualInterface,
  VirtualInterfaceApi,
} from "../../VirtualInterfacePage/types";
import { tenantVirtualInterfacesApi } from "../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import { getConnections } from "./helpers/getConnections";
import { configApi } from "../../../helpers/api/ConfigApi";
import { VlanData } from "../UnitedConnections/components/Clouds/CloudsTabInfo";

type IFunc = {
  fetchConnections: () => void;
  fetchSystems: () => void;
  fetchCurrentSystem: () => void;
  fetchLocations: () => void;
  deleteConnection: (
    type: string,
    name: string,
    isDia?: boolean,
    vlan?: VlanData
  ) => Promise<Res<ResultStatus>>;
};

type IState = {
  connections: Array<VirtualInterfaceApi>;
  systems: Array<System>;
  connectionFetchStatus: Status;
  locations: Array<DropdownItem>;
  configApi: string;
};

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

export type Connection = VirtualInterface & {
  state?: {
    operational_state: string | null;
    administrative_state: string | null;
  };
};

export type Segmentation = {
  name: string;
  prefixes: Array<SegmentationPrefix>;
};

export type SegmentationPrefix = {
  subnet: string;
  interface_type?: string;
  interface_name: string;
};

const [ConnectionContext, useContext] = createContextAndUse<IState, IFunc>();
export const useConnectionContext = useContext;

export const ConnectionContextContainer: FC<Props> = ({ children }) => {
  const { user } = useUserContext();
  const { selectedTenant } = useGlobalFilterContext();
  const [connections, setConnections] = useState<Array<VirtualInterfaceApi>>(
    []
  );

  const [systems, setSystems] = useState<Array<System | SystemAPI>>([]);
  const [locations, setLocations] = useState<Array<DropdownItem>>([]);
  const [connectionFetchStatus, setConnectionFetchStatus] = useState<Status>(
    "pending"
  );

  const tenant = selectedTenant || user?.name;

  const fetchConnections = async () => {
    if (!tenant) return;

    setConnectionFetchStatus("pending");
    const { result, ok } = await networkApi.getVirtualInterfaces(tenant);

    if (!ok || !result) {
      setConnectionFetchStatus("error");
      return;
    }

    setConnections([...getConnections(result.items).flat()]);
    setConnectionFetchStatus("ok");
  };

  const deleteConnection = async (
    type: string,
    name: string,
    isDia?: boolean,
    vlan?: VlanData
  ): Promise<Res<ResultStatus>> => {
    if (!selectedTenant) {
      return { ok: false };
    }

    const { ok, error } = await deleteViByType(
      type,
      name,
      selectedTenant,
      isDia,
      vlan
    );

    if (!ok) {
      return { ok: false, error };
    }

    const updatedConnections = connections.filter((c) => c.name !== name);
    setConnections(updatedConnections);

    return { ok };
  };

  const deleteViByType = async (
    type: string,
    vi_name: string,
    tenant: string,
    isDia?: boolean,
    vlan?: VlanData
  ) => {
    switch (type) {
      case VI_IPSEC:
        return await networkApi.deleteIpsecInterface(tenant, vi_name);
      case VI_IPSEC_GATEWAY:
        return await networkApi.deleteIpsecGatewayInterface(tenant, vi_name);
      case VI_GATE:
        if (isDia) {
          if (!vlan?.alias) return { ok: false, error: "No vlan alias" };

          return await configApi.deleteTenantPort(
            tenant,
            vlan.name,
            vlan.alias
          );
        }

        return await tenantVirtualInterfacesApi.deleteVirtualInterfaceGate(
          tenant,
          vi_name
        );
      default:
        return await networkApi.deleteBridgedInterface(tenant, vi_name);
    }
  };

  const fetchLocations = async (): Promise<void> => {
    const { ok, result } = await systemApi.getSystemsList();
    if (ok && result) {
      const locations = result.map((system) => ({
        key: system.name,
        value: system.location,
      }));
      setLocations(locations);
    }
  };

  const fetchSystems = async () => {
    const { result } = await systemApi.getSystemsList();

    if (!result) {
      return;
    }
    setSystems(result);
  };

  return (
    <ConnectionContext.Provider
      value={{
        connections,
        systems,
        connectionFetchStatus,
        locations,
        fetchLocations,
        fetchConnections,
        fetchSystems,
        deleteConnection,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};
