import React, { useEffect, useMemo } from "react";
import DropdownBasic, { DropDownBasicProps } from "../Dropdown/DropdownBasic";
import { withContexts } from "../../../helpers/hocs/withContexts";
import PhysicalPortsContextContainer, {
  usePhysicalPortsContext,
} from "./contexts/PhysicalPortsContext";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { PortType } from "../../../pages/PortsPage/types";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./PhysicalPortsDropdown.module.scss";

type Props = {
  system?: string;
  selected?: string;
  onSelect: (port: string) => void;
  disabled?: boolean;
  hideLabel?: boolean;
  id: string;
  isNotEditable?: boolean;
  setSelectedPort?: (port: PortType | undefined) => void;
  withDescription?: boolean;
  role?: string;
} & Omit<DropDownBasicProps<PortType>, "itemsList">;

const PhysicalPortsDropdown = ({
  selected,
  onSelect,
  system,
  disabled,
  hideLabel,
  id,
  isNotEditable,
  setSelectedPort,
  withDescription,
  role,
  ...dropdownProps
}: Props) => {
  const { fetchList, list } = usePhysicalPortsContext();
  useEffect(() => {
    if (system) {
      fetchList(system, role);
    }
  }, [system, role]);

  const items = useMemo(
    () =>
      (list || []).map((item) => {
        const isDown =
          item.administrative_state !== "up" || item.operational_state !== "up";

        return {
          value: (
            <span
              className={classNames(
                isDown && withDescription && styles.portDown
              )}
            >
              {item.labels?.description && withDescription
                ? `${item.name} - ${item.labels.description}`
                : item.name}
            </span>
          ),
          key: item.name,
          data: item,
        };
      }),
    [list]
  );
  return (
    <DropdownBasic
      id={id}
      onChange={(val) => {
        onSelect(val.key);
        setSelectedPort?.(val.data);
      }}
      selected={mapStringToItem(selected || "")}
      label={hideLabel ? undefined : "Port name"}
      placeholder="Physical Port"
      itemsList={items}
      isMedium
      disabled={disabled}
      isNotEditable={isNotEditable}
      {...dropdownProps}
    />
  );
};

export default withContexts<Props>(PhysicalPortsDropdown, [
  PhysicalPortsContextContainer,
]);
