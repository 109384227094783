import { FC, useEffect, useMemo, useState } from "react";
import styles from "./SearchBar.module.scss";
import SearchField from "./components/SearchField";
import SearchResultsList from "./components/SearchResultsList";
import { useClickOutside } from "../../helpers/hooks/useClickOutside";
import { NavItem } from "./helpers/types";
import { getPagesMap } from "./helpers/consts";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { getSearchType } from "./helpers/getSearchType";
import { usePackagesContext } from "../../contexts/PackagesContext";
import { useSystemsList } from "../../helpers/api/systems/useSystemsList";
import { useOneSystemContext } from "../../contexts/systemsContext/OneSystemContext";
import { System } from "../../pages/Systems/Provisioning/types";
import { checkIsFullSystem } from "../common/navigation/OneSystemTabs";
import { PENDING } from "../../helpers/common/RequestStateHelpers";

const SearchBar: FC = () => {
  const history = useHistory();
  const [showList, setShowList] = useState<boolean>(false);
  const elementRef = useClickOutside(() => setShowList(false));
  const { isPackagesVisible } = usePackagesContext();
  const [systemList, systemListStatus] = useSystemsList();
  const { system, node } = useOneSystemContext();
  const currentSystem = useMemo(() => {
    if (system) return { full: checkIsFullSystem(node?.type), system };

    if (checkIsFullSystem(node?.type)) return { full: true, system };

    const fullSystem = systemList?.find((s) =>
      checkIsFullSystem((s as System).type)
    );
    if (fullSystem) return { full: true, system: fullSystem };

    return { full: false, system: system ?? systemList?.[0] };
  }, [node, system, systemList]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [navList, setNavList] = useState<Array<NavItem> | undefined>(undefined);

  const { isZayo, isTenant } = useUserContext();
  const searchType = getSearchType(isTenant, isZayo);

  useEffect(() => {
    if (searchValue) {
      const { full: isFullSystem, system } = currentSystem;
      const newList = getPagesMap({
        isPackagesVisible,
        system: {
          value: system,
          isFull: isFullSystem,
        },
      })[searchType].filter((page) =>
        page.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setNavList(newList);
    }
  }, [searchValue, isPackagesVisible, currentSystem]);

  const handleClick = () => setShowList(true);

  const handleChooseValue = (value: string, goto: string) => {
    setSearchValue(value);
    setShowList(false);
    history.replace(goto);
  };

  return (
    <div
      className={styles.wrapper}
      ref={elementRef as React.RefObject<HTMLDivElement>}
    >
      <SearchField
        searchValue={searchValue}
        onChange={setSearchValue}
        onEnter={handleChooseValue}
        onClick={handleClick}
      />
      {showList && (
        <SearchResultsList
          searchValue={searchValue}
          navList={navList}
          onClick={handleChooseValue}
          isLoading={systemListStatus === PENDING}
        />
      )}
    </div>
  );
};

export default SearchBar;
