import { User } from "../types";
import Validator from "./Validator";

export default function validatedUserAdd(
  data: Partial<User>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.password, "password");
  validator.checkEmpty(data.username, "username");
  validator.checkEmpty(data.email, "email");
  validator.checkEmail(data.email || "", "email");
  validator.checkEmpty(data.first_name, "first_name");
  validator.checkEmpty(data.last_name, "last_name");

  return validator;
}
