import React, { useEffect, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table, {
  DEFAUTL_OFFSET,
  DEFAUTL_LIMIT,
} from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { ddosTableHeader } from "./table/ddosTableHeader";
import TableControls from "../TenantsPage/table/TableControls";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import DDoSConfig from "./DDoSConfig/DDoSConfig";
import { ADD, EDIT } from "../../helpers/common/constantsAlias";
import { useFirewallContext } from "../../contexts/servicesContext/FirewallContext";
import { DDoSType } from "../../helpers/api/apiTypes";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import { useDDoSContext } from "../../contexts/servicesContext/DDoSContext";
import { useVRFSelectorContext } from "../../contexts/systemsContext/VRFSelectorContext";
import { useOneSystemContext } from "../../contexts/systemsContext/OneSystemContext";
import styles from "./DDoSTable.module.scss";

type DDoSTableProps = {
  className?: string;
  showEdit: boolean;
  setShowEdit: (value: boolean) => void;
  showAdd: boolean;
  setShowAdd: (value: boolean) => void;
};

const DDoSTable = ({
  className,
  showEdit,
  setShowEdit,
  showAdd,
  setShowAdd,
}: DDoSTableProps) => {
  const {
    fetchList,
    ddosList,
    ddosListStatus,
    count,
    removeDDoS,
    removeStatus,
    selectDDoS,
    selectedDDoS,
    changeDDoSEnable,
  } = useDDoSContext();

  const { servicesStatusRequest } = useTenantContext();
  const { system } = useOneSystemContext();
  const { selectedVRF: vrf, list: vrfList } = useVRFSelectorContext();
  const selectedVRFData = useMemo(() => {
    return vrfList?.find((el) => el.name === vrf);
  }, [vrf]);

  const { fetchUserGroups } = useFirewallContext();

  const [selected, setSelected] = useState();

  useEffect(() => {
    fetchWrapper();
    vrf && fetchUserGroups(vrf);
  }, [vrf]);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    vrf &&
      system?.name &&
      fetchList({ vrf, system: system.name }, offset, limit);
  };

  const handleClickRow = (ddos: any): void => {
    setSelected(ddos);
    setShowEdit(true);
    selectDDoS(ddos);
  };

  const [tryDelete, selectedToDelete, sureDeleteProps] = useDeleteDialog<any>(
    (ddos) => removeDDoS(ddos, { vrf, system: system?.name }),
    removeStatus
  );

  const rowActions: ActionFuncs<DDoSType> = useMemo(
    () => ({
      onEdit: handleClickRow,
      onDelete: tryDelete,
    }),
    [handleClickRow, tryDelete]
  );

  if (showAdd) {
    return (
      <DDoSConfig
        type={ADD}
        onClose={() => setShowAdd(false)}
        selectedVRF={selectedVRFData}
        system={system}
      />
    );
  }

  if (showEdit) {
    return (
      <DDoSConfig
        type={EDIT}
        onClose={() => setShowEdit(false)}
        data={selected}
        selectedVRF={selectedVRFData}
        system={system}
      />
    );
  }

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"DDoS"}
        name={`${JSON.stringify(selectedToDelete?.name)}`}
      />
      <TableWrapper
        className={className}
        titleProps={{
          title: `DDoS`,
          children: <TableControls onAdd={() => setShowAdd(true)} noSearch />,
        }}
        dataStatus={ddosListStatus}
        tablePlaceholder={["DDoS", "DDoS"]}
        isEmpty={ddosList && ddosList.length === 0}
      >
        <Table
          fetchAction={fetchWrapper}
          data={ddosList || []}
          count={count}
          columns={ddosTableHeader(selectDDoS, selectedDDoS)}
          header={TableHeader}
          gridColumnTemplate="70px 120px 60px 1fr 140px 160px 60px 80px"
          rowActions={rowActions}
          contentClassName={styles.tableContent}
          clickFeatures={[
            {
              elementClass: "toggle",
              clb: (ddos: DDoSType) =>
                changeDDoSEnable(ddos, { system: system?.name, vrf }),
            },
          ]}
        />
      </TableWrapper>
    </>
  );
};

export default DDoSTable;
