/* eslint-disable prettier/prettier */
import React, { FC, useEffect, useMemo, useState } from "react";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../contexts/UserContext";
import { commandApi } from "../../../helpers/api/CommandApi";
import { systemApi } from "../../../helpers/api/SystemApi";
import { tenantApi } from "../../../helpers/api/TenantApi";
import { UUID } from "../../../helpers/api/types";
import { RequestStatus } from "../../../helpers/types";
import { LookingGlassCommands, LookingGlassFields } from "./types";
import { tenantVirtualInterfacesApi } from "../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import { VirtualInetrfaceGate } from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";

export type SystemWithLocation = { location?: string; system?: string };

type CurrentCommand = {
  cmd?: LookingGlassCommands;
  l2Interface?: string;
  system?: string;
  uuid: UUID | null;
};

type IFunc = {
  runCommand: (params: LookingGlassFields) => void;
  setSelectedSystem: (system: SystemWithLocation | null) => void;
  setIsDia: (value: boolean) => void;
  selectVi: (vi: Partial<VirtualInterfaceApi> | undefined) => void;
};

type IState = {
  commandOutput: string | null;
  commandExecStatus: RequestStatus;
  fetchAttempts: number;
  currentCommand?: CurrentCommand;
  userSystems: Array<SystemWithLocation>;
  dia: VirtualInetrfaceGate;
  isDia: boolean;
  tenant: string;
  selectedSystem: SystemWithLocation | null;
  vi?: Partial<VirtualInterfaceApi>;
  l2Interfaces: Array<any> | undefined;
};

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const [LookingGlassContext, useContext] = createContextAndUse<IState, IFunc>();
export const useLookingGlassContext = useContext;

export const MAX_FETCH_ATTEMPTS = 10;
const FETCH_DELAY = 2000;
const PING_COUNT = 3;

export const LookingGlassContextContainer: FC<Props> = ({ children }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { user } = useUserContext();
  const [commandOutput, setCommandOutput] = useState<string | null>(null);
  const [commandExecStatus, setCommandExecStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [currentCommand, setCurrentCommand] = useState<CurrentCommand>();
  const [userSystems, setUserSystems] = useState<Array<SystemWithLocation>>([]);
  const [
    selectedSystem,
    setSelectedSystem,
  ] = useState<SystemWithLocation | null>(null);
  const [gates, setGates] = useState<Array<VirtualInetrfaceGate>>();
  const [dia, setDia] = useState<VirtualInetrfaceGate>();
  const [isDia, setIsDia] = useState(false);
  const [vi, selectVi] = useState<Partial<VirtualInterfaceApi>>();

  const l2Interfaces = useMemo(() => {
    return vi?.members
      ?.map((member) => {
        return member.interfaces.map((int) => int);
      })
      .flat();
  }, [vi]);

  const tenant = selectedTenant || user?.name;

  const [fetchAttempts, setFetchAttempts] = useState(0);

  const runCommand = async ({
    cmd,
    ip,
    protocol,
    max_mtu,
    min_mtu,
    pcapType,
    size,
    maxPackets,
    l2Interface,
    timeoutSec,
    l2InterfaceLabel,
  }: LookingGlassFields) => {
    const params: Record<string, any> = {
      address: ip,
      system_name: selectedSystem?.system ?? "",
      protocol,
      vrf_name: tenant,
      max_mtu: +max_mtu,
      min_mtu: +min_mtu,
      pcap_type: pcapType,
      size,
      max_packets: maxPackets,
      l2_interface: l2Interface || l2InterfaceLabel,
      timeoutSec,
    };

    if (isDia && dia?.name) {
      params.gate_vi_name = dia.name;
      delete params.system_name;
    }

    setCommandExecStatus({ state: "pending" });
    const { ok, result, error } = await commandApi.execute(cmd, params);

    if (!ok || !result) {
      return setCommandExecStatus({ state: "error", message: error });
    }

    setCurrentCommand({
      uuid: result.message,
      cmd,
      l2Interface: l2InterfaceLabel,
      system: selectedSystem?.location,
    });
  };

  const getCommandResult = async (uuid: UUID) => {
    setCommandExecStatus({ state: "pending" });

    let fetchCounter = 0;
    const interval = setInterval(async () => {
      const params: Record<string, any> = {
        vrf_name: tenant,
        system_name: selectedSystem?.system,
        uuid,
      };

      if (isDia && dia?.name) {
        params.gate_vi_name = dia.name;
        delete params.system_name;
      }
      const { ok, result } = await commandApi.getCommandResult(params);

      const isFailed =
        !ok ||
        !result ||
        (result.status === "error" && result.result !== "result not found");

      if (isFailed) {
        setCurrentCommand(undefined);
        setFetchAttempts(0);
        clearInterval(interval);
        return setCommandExecStatus({
          state: "error",
          message: result?.result,
        });
      }

      const { status, result: output } = result;

      const isDone = status === "done";
      const stopInterval = isDone || fetchCounter >= MAX_FETCH_ATTEMPTS;

      if (stopInterval) {
        setCurrentCommand((prev) => ({ ...prev, uuid: null }));
        setFetchAttempts(0);
        clearInterval(interval);
        setCommandOutput(isDone ? output : null);
        return setCommandExecStatus(
          isDone ? { state: "ok" } : { state: "error", message: "Timeout" }
        );
      }

      setFetchAttempts((v) => v + 1);
      fetchCounter++;
    }, FETCH_DELAY * 5);
  };

  const fetchSystems = async () => {
    if (!tenant) return;

    let systemList = [];
    const { result } = await tenantApi.getTenant(tenant);
    const { result: systems } = await systemApi.getSystemsList();

    const allSystems = systems || [];

    const tenantSystems = result?.systems;

    if (!tenantSystems) {
      return setUserSystems([]);
    }

    systemList = tenantSystems
      ? allSystems
          .filter((s) => tenantSystems.includes(s.name))
          .map((s) => ({ location: s.location, system: s.name }))
      : [];

    setUserSystems(systemList);
  };

  const fetchDiaGate = async () => {
    if (!tenant) return;

    const gates = await tenantVirtualInterfacesApi.getVirtualInterfacesGate(
      tenant
    );
    setGates(gates.result?.items);
  };

  const selectDiaExist = () => {
    if (!selectedSystem || !gates?.length) {
      setDia(undefined);
      return;
    }

    const diaGate = gates.find(
      (gate) => gate.system_name === selectedSystem.system && gate.is_dia
    );
    setDia(diaGate);
  };

  useEffect(() => {
    if (!currentCommand?.uuid) {
      return;
    }

    getCommandResult(currentCommand.uuid);
  }, [currentCommand]);

  useEffect(() => {
    fetchSystems();
    fetchDiaGate();

    return () => {
      setGates(undefined);
      setUserSystems([]);
    };
  }, [tenant]);

  useEffect(() => {
    selectDiaExist();
  }, [gates, selectedSystem]);

  return (
    <LookingGlassContext.Provider
      value={{
        commandOutput,
        commandExecStatus,
        fetchAttempts,
        currentCommand,
        userSystems,
        selectedSystem,
        runCommand,
        setSelectedSystem,
        dia,
        isDia,
        setIsDia,
        tenant,
        vi,
        selectVi,
        l2Interfaces,
      }}
    >
      {children}
    </LookingGlassContext.Provider>
  );
};
