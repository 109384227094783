import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./CloudsTabInfo.module.scss";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import { useUnitedConnectionContext } from "../../UnitedConnectionContext";
import Table from "../../../../../components/common/table/newTable/Table";
import TableHeader from "../../../../../components/common/table/newTable/rows/TableHeader";
import TableWrapper from "../../../../../components/common/table/newTable/layout/TableWrapper";
import { cloudTableHeader } from "./cloudTableHeader";
import AddCloud from "../Connections/AddCloud";
import AddDirect from "../Connections/AddDirect";
import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import { classNames } from "../../../../../helpers/common/classNames";
import { CloudDiagram } from "./CloudDiagram";
import { getAPortRowInfo, getAZPortRowInfo } from "./renderSubRow";
import { getCorrectBandwidthUnit } from "../../../../../helpers/getCorrectBandwidthUnit";
import { transferFromKbps } from "../../../../../helpers/transferFromKbps";
import { PortVlan } from "../../../../VLANPage/types";
import { TenantPort } from "../../types";
import { ActionFuncs } from "../../../../../components/common/table/newTable/RowControls";
import { useConnectionContext } from "../../../Connections/ConnectionContext";
import ConfirmDialog from "../../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../../../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../../../../helpers/getDialogControls";
import { RequestStatus } from "../../../../../helpers/types";
import { NATIVE } from "../../../../../helpers/consts";
import { checkIfInternet } from "../../helpers";
import NoDataPlaceholder from "../../../../../components/common/loadStates/NoDataPlaceholder";
import EmptyConnectionsPlaceholderIcon from "../../../../../components/icons/EmptyConnectionsPlaceholderIcon";
import { useUserContext } from "../../../../../contexts/UserContext";

const BASE_VLAN_DATA: VlanData = {
  name: "",
  vlan_id: undefined,
  max_bandwidth: "0 Gbps",
};

type Props = {
  isAdd: boolean;
  onAction: () => void;
  isCloud?: boolean;
  addFrom?: string;
  onlyDiagram?: boolean;
};

export type VlanData = {
  vlan_id?: number | typeof NATIVE;
  name: string;
  max_bandwidth: string | number;
  vlan_ctag_id?: number;
  alias?: string;
  type?: string;
};

export function parseVlan(
  connection: VirtualInterfaceApi,
  withOriginBW = false,
  port?: string
): VlanData {
  let vlanData = { ...BASE_VLAN_DATA };

  connection?.members?.forEach((item, i) => {
    if (
      ["port_vlan", "qinq_port_vlan"].includes(item.interface_type) &&
      item.interfaces.length
    ) {
      const vlanInterfaces = connection.members[i]
        .interfaces as Array<PortVlan>;

      vlanInterfaces.forEach((vlan) => {
        if (vlan.customer_port_name === port || !port) {
          vlanData = formVlanData(vlan, withOriginBW);
        }
      });
    }
  });
  if (connection?.labels?.remote_type === "aws") {
    vlanData.vlan_id = NATIVE;
  }

  return vlanData;
}

export function parseAllVLans(
  connection: VirtualInterfaceApi,
  withOriginBW = false,
  port?: string
): Array<VlanData> {
  const vlanDataList: Array<VlanData> = [];
  connection?.members?.forEach((item, i) => {
    if (
      ["port_vlan", "qinq_port_vlan"].includes(item.interface_type) &&
      item.interfaces.length
    ) {
      const vlanInterfaces = connection.members[i]
        .interfaces as Array<PortVlan>;
      vlanInterfaces.forEach((vlan) => {
        if (
          vlan.customer_port_name === port ||
          !port ||
          (!vlan.customer_port_name && vlan.alias === port)
        ) {
          const vlanData = formVlanData(vlan, withOriginBW);
          vlanDataList.push(vlanData);
        }
      });
    }
  });
  return vlanDataList;
}

export function formVlanData(vlan: PortVlan, withOriginBW = false): VlanData {
  const unit = getCorrectBandwidthUnit(vlan.max_bandwidth);
  const transferred = transferFromKbps(vlan.max_bandwidth, unit);
  const bw = withOriginBW
    ? vlan.max_bandwidth
    : `${
        Number.isInteger(transferred) ? transferred : transferred.toFixed(1)
      } ${unit}`;
  return {
    name: vlan.customer_port_name,
    vlan_id: vlan.vlan_id,
    max_bandwidth: bw,
    alias: vlan.alias,
    vlan_ctag_id: vlan.vlan_ctag_id,
  };
}

export function getAllVlan(
  connection: VirtualInterfaceApi,
  l2Ports: Array<TenantPort> = []
): Array<VlanData> {
  const vlanList: Array<VlanData> = [];
  connection?.members?.forEach((item, i) => {
    if (
      ["port_vlan", "qinq_port_vlan"].includes(item.interface_type) &&
      item.interfaces.length
    ) {
      const vlanInterfaces = connection.members[i]
        .interfaces as Array<PortVlan>;

      vlanInterfaces.forEach((vlan) => {
        const port = formVlanData(vlan);
        if (!port.vlan_id && l2Ports.length) {
          const l2port = l2Ports.find(
            (tenantPort) => tenantPort.name === port.name
          );
          if (l2port) port.vlan_id = l2port.vlan_id;
        }
        vlanList.push(port);
      });
    }
  });
  return vlanList;
}

const CloudsTabInfo: FC<Props> = ({
  isAdd,
  onAction,
  isCloud,
  addFrom,
  onlyDiagram,
}) => {
  const {
    connectionFetchStatus,
    fetchConnections,
    fetchSystems,
    directConnections,
    getTenantPorts,
    ports,
    connections,
    cloudConnections,
    getRegions,
    regions = [],
    customerPorts,
  } = useUnitedConnectionContext();
  const { selectedTenant } = useGlobalFilterContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchSystems();
      fetchConnections();
      !ports.length && getTenantPorts();
      !regions.length && getRegions();
    }
  }, [selectedTenant]);
  const { deleteConnection } = useConnectionContext();
  const { fetchNotifications } = useUserContext();

  const [expanded, setExpanded] = useState<Array<number>>([]);
  const [activeConnection, setActiveConnection] = useState<string>("");
  const [viewItem, setViewItem] = useState<VirtualInterfaceApi | undefined>();
  const [
    connectionToDelete,
    setConnectionToDelete,
  ] = useState<VirtualInterfaceApi | null>(null);
  const [deleteRequestStatus, setDeleteRequestStatus] = useState<
    RequestStatus | undefined
  >();

  const handleConnectionDelete = async () => {
    if (!connectionToDelete) {
      return;
    }

    const { type, name, is_dia } = connectionToDelete;
    const vlan = getAllVlan(connectionToDelete);
    const { ok } = await deleteConnection(type, name, is_dia, vlan[0]);
    if (ok) {
      setConnectionToDelete(null);
      await fetchConnections();
      await getTenantPorts();
      fetchNotifications(selectedTenant || "");
    }
  };

  const handleEdit = (rowOriginal: VirtualInterfaceApi) => {
    setViewItem(rowOriginal);
  };

  const rowActions: ActionFuncs<VirtualInterfaceApi> = useMemo(
    () => ({
      onEdit: handleEdit,
      onDelete: setConnectionToDelete,
    }),
    [setViewItem]
  );

  useEffect(() => {
    const connections = isCloud ? cloudConnections : directConnections;
    if (connections?.[0] && connections?.[0].id) {
      setExpanded([connections[0].id]);
    }
  }, [connections.length, isCloud]);

  useEffect(() => setViewItem(undefined), [isCloud]);

  if (isAdd && selectedTenant) {
    return isCloud ? (
      <AddCloud onClose={onAction} addFrom={addFrom} />
    ) : (
      <AddDirect onClose={onAction} addFrom={addFrom} />
    );
  }

  if (!!viewItem) {
    return isCloud ? (
      <AddCloud
        onClose={() => setViewItem(undefined)}
        addFrom={addFrom}
        isEdit={!!viewItem}
        connectionData={viewItem}
      />
    ) : (
      <AddDirect
        onClose={() => setViewItem(undefined)}
        addFrom={addFrom}
        connectionData={viewItem}
      />
    );
  }

  const getData = () => {
    if (onlyDiagram && isCloud) {
      return [];
    }
    const cloud = cloudConnections.filter(
      (connection) => !checkIfInternet(connection)
    );
    return isCloud ? cloud : directConnections;
  };

  const onClick = (row: VirtualInterfaceApi) => {
    let filtered: Array<number>;
    if (expanded.includes(row.id)) {
      filtered = expanded.filter((item) => item !== row.id);
    } else {
      filtered = [...expanded];
      filtered.push(row.id);
    }
    setExpanded(filtered);
  };

  const renderSubrows = (row: VirtualInterfaceApi) => {
    const style = classNames(
      styles.subRow,
      !isCloud && styles.az,
      !expanded.includes(row.id) && styles.noVisible
    );
    return (
      <div className={style}>
        {isCloud
          ? getAPortRowInfo(row, ports, customerPorts)
          : getAZPortRowInfo(row, ports, onlyDiagram, customerPorts)}
      </div>
    );
  };

  const renderTable = () => {
    if (onlyDiagram && !empty && isCloud) {
      return (
        <CloudDiagram
          connections={cloudConnections}
          activeConnection={activeConnection}
          onClick={setActiveConnection}
          onlyDiagram={onlyDiagram}
        />
      );
    }

    return (
      <>
        {connectionToDelete && (
          <ConfirmDialog
            status={deleteRequestStatus}
            icon={DeleteIcon}
            title="Delete Connection"
            message={`Are you sure you want to delete ${connectionToDelete?.name}?`}
            controls={getDeleteDialogControls({
              onDelete: handleConnectionDelete,
              onCancel: () => setConnectionToDelete(null),
            })}
          />
        )}
        {empty ? (
          <NoDataPlaceholder
            title={"There are no connections yet"}
            subTitle="Use the Add button to add new connection"
            customIcon={<EmptyConnectionsPlaceholderIcon />}
          />
        ) : (
          <TableWrapper
            dataStatus={{ state: connectionFetchStatus }}
            isEmpty={empty}
            titleProps={{
              title: ``,
              noTitle: true,
            }}
            className={classNames(styles.tableBlock, empty && styles.empty)}
          >
            <Table
              header={!onlyDiagram ? TableHeader : undefined}
              columns={cloudTableHeader(
                customerPorts,
                isCloud,
                onClick,
                expanded
              )}
              data={getData()}
              gridColumnTemplate={grid}
              withoutPagination
              renderRowSubComponent={renderSubrows}
              rowActions={!onlyDiagram ? rowActions : undefined}
              rowClassName={styles.rowClassName}
              extraContent={
                !empty && isCloud ? (
                  <CloudDiagram
                    connections={cloudConnections}
                    activeConnection={activeConnection}
                    onClick={setActiveConnection}
                    onlyDiagram={onlyDiagram}
                  />
                ) : undefined
              }
            />
          </TableWrapper>
        )}
      </>
    );
  };

  const grid = isCloud
    ? "250px 200px repeat(4, 1fr) 120px"
    : "repeat(4, 1fr) 120px";

  const empty = !(isCloud ? cloudConnections : directConnections).length;
  return (
    <div className={classNames(styles.wrapper, empty && styles.empty)}>
      {connectionFetchStatus === "pending" || !selectedTenant ? (
        <PageLoader />
      ) : (
        renderTable()
      )}
    </div>
  );
};
export default CloudsTabInfo;
