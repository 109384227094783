import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { configApi } from "../../helpers/api/ConfigApi";
import { networkApi } from "../../helpers/api/networkApi";
import {
  resToState,
  setError,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { BRIDGED, ROUTED, VIRTUAL_INTERFACE_GATE } from "../../helpers/consts";
import { RequestStatus } from "../../helpers/types";
import { checkIfInternet } from "../Network/UnitedConnections/helpers";
import { ARPItem } from "./types";

type RequestStatuses = {
  listStatus?: RequestStatus;
};
type State = {
  list: Array<ARPItem>;
  count: number;
  totalCount: number;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (
    tenant: string,
    isCloud: boolean,
    offset: number,
    limit: number
  ) => Promise<void>;
};

const [ARPsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useARPsContext = useContext;

export default class ARPsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = ARPsContext;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = { list: [], count: 0, totalCount: 0 };
  }

  fetchList = async (
    tenant: string,
    isCloud: boolean,
    offset: number,
    limit: number
  ): Promise<void> => {
    this.setState({ listStatus: setPending() });
    const allTypes = await networkApi.getVirtualInterfaces(tenant);

    if (!allTypes.ok || !allTypes.result) {
      this.setState({ listStatus: setError() });
      return;
    }

    const res = await configApi.getServiceARPList(tenant, offset, limit);

    if (res.ok && res.result) {
      const list = res.result.map((item) => {
        const vi =
          allTypes.result?.items.find(
            (viItem) =>
              viItem.name === item.virtual_interface_name ||
              viItem.virtual_interface_bridge_domain_name ===
                item.virtual_interface_name
          ) || {};
        return {
          ...vi,
          ...item,
        };
      });
      const direct = list.filter(
        (item) =>
          item?.virtual_interface_type === BRIDGED ||
          (item?.type === VIRTUAL_INTERFACE_GATE &&
            item?.is_dia === true &&
            item.members.some((member: any) => member?.interfaces?.length > 0))
      );
      const cloud = list.filter(
        (item) =>
          item?.virtual_interface_type === ROUTED || checkIfInternet(item)
      );

      this.setState({
        list: isCloud ? cloud : direct,
        count: res.count || 0,
        totalCount: res.total_count || 0,
      });
    }
    this.setState({ listStatus: resToState(res) });
  };

  funcs = {
    fetchList: this.fetchList,
  };
}
