import React, { FC, useEffect, useState } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import { Toggle } from "../../../../../../../components/common/Toggle";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import {
  MAX_BANDWIDTH_LIST,
  SUBSCRIPTION_PERIOD_LIST,
} from "../../../../../utils/wizardToolConsts";
import ConnectionTypeGroup from "../../common/ConnectionTypeGroup";
import FormFooter from "../../common/FormFooter/FormFooter";
import SegmentsComponent from "../../common/SegmentsComponent";
import styles from "./CloudForm.module.scss";
import VPCList from "../../common/VPCList";
import { useValidation } from "../../../../../../../helpers/validators/Validator";
import { CloudFields } from "../../utils/formConsts";
import validateCloud from "../../../../../../../helpers/validators/CloudValidator";
import FormTitle from "../../common/FormTitle/FormTitle";
import { useConnectionsContext } from "../../../../ConnectionsContext";
import VnetList from "../../common/VnetList";
import { EDIT } from "../../../../../../../helpers/common/constantsAlias";
import {
  AWS_LOWERCASE,
  GCP_LOWERCASE,
} from "../../../../../../../helpers/consts";

export type CloudFieldsProps = CloudFields & {
  max_bandwidth: string;
  subscription: string;
};

type Props = {};

const EditCloudForm: FC<Props> = () => {
  const { user } = useUserContext();
  const {
    activeConnectionType,
    activeConnection,
    activeLocation,
    addAwsService,
  } = useConnectionsContext();

  const [cloudSelected, setCloudSelected] = useState(false);
  const [connectioTypeField, setConnectionTypeField] = useState<string>(
    "ipsec"
  );

  const [fields, handleFieldChange, resetFields] = useFormField<any>(
    activeConnection
  );
  const [errors, validate] = useValidation<CloudFieldsProps>(validateCloud, [
    fields,
  ]);

  useEffect(() => {
    if (activeConnection) resetFields(activeConnection);
  }, [activeConnection]);

  useEffect(() => {
    return () => {
      resetFields(activeConnection);
    };
  }, [activeConnectionType]);

  useEffect(() => {
    if (connectioTypeField === "directConnection") {
      setCloudSelected(false);
    }
  }, [connectioTypeField]);

  const handleAddConnection = () => {
    const { isOk } = validate();

    if (isOk && activeConnectionType && activeLocation && user?.name) {
      const labels = {
        remote_type: activeConnectionType,
        vpc_id: fields.vpc_id,
        regigon: fields.region,
      };
      const newFields = { ...fields, labels };
      addAwsService(newFields, user.name, activeLocation);
    }
  };

  return (
    <>
      <FormTitle />
      <div className={styles.formContainer}>
        <Input
          value={fields.name}
          label="Name"
          onChange={(e) => {
            handleFieldChange("name", e.target.value);
          }}
          isFullValue
          medium
          error={errors?.name}
        />
        <ConnectionTypeGroup
          value={connectioTypeField}
          onChange={(val) => setConnectionTypeField(val)}
          type={activeConnectionType}
        />
        <Toggle
          isChecked={cloudSelected}
          onChange={() => setCloudSelected(!cloudSelected)}
          label="Agent"
          disabled
        />
        <DropdownBasic
          id={"cloudFormConnectionBandwidth"}
          onChange={(val) => handleFieldChange("max_bandwidth", val.key)}
          selected={mapStringToItem(MAX_BANDWIDTH_LIST[0])}
          label="Connection Bandwidth"
          itemsList={MAX_BANDWIDTH_LIST.map((key) => mapStringToItem(key))}
          isMedium
        />
        <div className={styles.fieldsGroup}>
          {activeConnectionType === AWS_LOWERCASE ||
          activeConnectionType === GCP_LOWERCASE ? (
            <VPCList
              fields={{
                vpc_id: fields?.labels?.vpc_id,
                region: fields?.labels?.region,
              }}
              onChange={handleFieldChange}
              error={errors?.vpc_id}
              mode={EDIT}
            />
          ) : (
            <VnetList
              fields={fields}
              onChange={handleFieldChange}
              error={errors?.vpc_id}
            />
          )}
        </div>
        <DropdownBasic
          id={"cloudFormSubscriptionPeriod"}
          onChange={(val) => handleFieldChange("subscription", val.key)}
          selected={mapStringToItem(SUBSCRIPTION_PERIOD_LIST[0])}
          label="Subscription period"
          itemsList={SUBSCRIPTION_PERIOD_LIST.map((key) =>
            mapStringToItem(key)
          )}
          isMedium
        />
        <SegmentsComponent
          list={fields.network_segments || []}
          onChange={handleFieldChange}
          error={errors?.network_segments}
        />
      </div>
      <FormFooter onAdd={handleAddConnection} />
    </>
  );
};
export default EditCloudForm;
