import React, { FC, useEffect, useMemo, useState } from "react";
import Input from "../../../../../../../../components/common/formComponents/Input";
import BWDropdown from "../../../../common/formComponents/BWDropdown";
import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
import styles from "./DIAItem.module.scss";
import { mapStringToItem } from "../../../../../../../../helpers/mapStringToItem";
import DropdownBasic from "../../../../../../../../components/common/Dropdown/DropdownBasic";
import ConnectionsContextContainer, {
  useConnectionsContext,
} from "../../../../../../../WizardToolPage/ConnectionStep/ConnectionsContext";
import { DropdownItem } from "../../../../../../../../helpers/types";
import { withContexts } from "../../../../../../../../helpers/hocs/withContexts";
import { useGlobalFilterContext } from "../../../../../../../../contexts/GlobalFilterContext";
import { TenantCustomerPort } from "../../../../../../../Network/UnitedConnections/types";
import TenantVirtualInterfaceContextContainer, {
  useTenantVirtualInterfaceContext,
} from "../../../../../../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import ASNDropdown from "./ASNDropdown";
import OrderedPortsContextContainer, {
  useOrderedPortsContext,
} from "../../../../../../../OrderedPortsPage/OrderedPortsContext";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: (idx: number, fields: FieldsType) => void;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
  selectedPort?: TenantCustomerPort;
  disabled?: boolean;
};

const DIAConnectionItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  handleChangeBW,
  disabled,
}) => {
  const {
    diaList,
    fetchDIAList,
    poolListIPv4 = [],
    poolListIPv6 = [],
    fetchPoolList,
  } = useConnectionsContext();

  const { selectedTenant } = useGlobalFilterContext();
  const { membersList, fetchMembersList } = useTenantVirtualInterfaceContext();
  const {
    asnForSpecificTenantList = [],
    registerNewASN,
    fetchASNForSpecificTenantData,
  } = useOrderedPortsContext();
  const [isASNApproved, setIsASNApproved] = useState<boolean>(true);

  const filteredDia = diaList?.filter(
    (dia) => dia.is_dia && dia.location === fields.availableLocation
  );

  useEffect(() => {
    fetchASNForSpecificTenantData(selectedTenant || "");
  }, [selectedTenant]);

  useEffect(() => {
    let isApproved = false;
    asnForSpecificTenantList?.forEach((asn) => {
      if (asn.asn == fields.asn && asn.approved) {
        isApproved = true;
      }
    });
    setIsASNApproved(isApproved);
  }, [fields.asn, asnForSpecificTenantList]);

  useEffect(() => {
    if (!diaList?.length && selectedTenant) {
      fetchDIAList(selectedTenant);
    }
    if (!membersList?.length && selectedTenant) {
      fetchMembersList(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (fields.availableLocation) {
      fetchPoolList(fields.availableLocation, true, fields.availableLocation);
    }
  }, [fields.availableLocation]);

  const names: Array<DropdownItem> = useMemo(() => {
    const diaNames: Array<DropdownItem> =
      filteredDia
        ?.filter((dia) => {
          return !membersList?.some(
            (member) => member.virtual_interface_name === dia.name
          );
        })
        .map((dia) => ({
          key: dia.name,
        })) || [];

    diaNames.push({
      key: "Use BGP",
      customItemClassname: styles.useBGPItem,
    });
    diaNames.push({
      key: "Request New",
      customItemClassname: styles.useBGPItem,
    });

    return diaNames;
  }, [filteredDia, membersList]);

  const asnNames = useMemo(() => {
    return asnForSpecificTenantList.map((asn) => String(asn.asn));
  }, [asnForSpecificTenantList]);

  const handleDiaSelect = (val: DropdownItem) => {
    onChange("name", val.key);
  };

  // check for subnet
  const subnet = useMemo(() => {
    return (
      filteredDia?.find((dia) => dia.name === fields.name)?.ip_address || ""
    );
  }, [fields.name, filteredDia]);

  const renderRequestNew = () => {
    const hasIPv4 = poolListIPv4.length > 0;
    const hasIPv6 = poolListIPv6.length > 0;

    if (!hasIPv4 && !hasIPv6) {
      return (
        <div>
          No available IPs in this location. Please choose a different one.
        </div>
      );
    }

    return (
      <div className={styles.requestNewWrapper}>
        {hasIPv4 && (
          <div className={styles.ipTypeDropdown}>
            <DropdownBasic
              id="ipv4Size"
              label="IPv4 Size"
              itemsList={poolListIPv4.map((val) => mapStringToItem(val))}
              selected={mapStringToItem(fields.ipv4_pool_size || "")}
              onChange={(val) => onChange("ipv4_pool_size", val.key)}
              isMedium
              disabled={disabled || poolListIPv4.length === 1}
            />
          </div>
        )}
        {hasIPv6 && (
          <div className={styles.ipTypeDropdown}>
            <DropdownBasic
              id="ipv6Size"
              label="IPv6 Size"
              itemsList={poolListIPv6.map((val) => mapStringToItem(val))}
              selected={mapStringToItem(fields.ipv6_pool_size || "")}
              onChange={(val) => onChange("ipv6_pool_size", val.key)}
              isMedium
              disabled={disabled || poolListIPv6.length === 1}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <ItemContainer
      type={type}
      title={""}
      idx={idx}
      onDelete={() => onDelete(idx, fields)}
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      <DropdownBasic
        id="name"
        onChange={handleDiaSelect}
        selected={mapStringToItem(fields.name)}
        label="Public IP"
        itemsList={names}
        isMedium
        disabled={isEdit || disabled}
        error={errors?.[idx + "name"]}
        listClassName={styles.list}
      />
      {fields.name === "Use BGP" ? (
        <div>
          <ASNDropdown
            options={asnNames}
            selected={fields.asn}
            asnForSpecificTenantList={asnForSpecificTenantList}
            onSelect={(asn) => onChange("asn", asn)}
            onRegister={(asn) => {
              onChange("asn", asn);
              registerNewASN(selectedTenant || "", asn);
            }}
          />
          {fields.asn && !isASNApproved && fields.asn !== "Set up new" ? (
            <span className={styles.asnApprovalMessage}>
              {`ASN ${fields.asn} awaiting admin approval`}
            </span>
          ) : null}
        </div>
      ) : fields.name === "Request New" ? (
        renderRequestNew()
      ) : (
        <Input
          label="Subnet"
          name="subnet"
          value={subnet}
          medium
          disabled={disabled}
          isNotEditable
          onChange={() => {
            /* don't edit subnet field */
          }}
        />
      )}
      <BWDropdown
        field={fields.max_bandwidth}
        onChange={handleChangeBW}
        error={errors?.[idx + "max_bandwidth"]}
        idx={idx}
        disabled={isEdit || disabled}
      />
    </ItemContainer>
  );
};

export default withContexts<Props>(DIAConnectionItem, [
  ConnectionsContextContainer,
  TenantVirtualInterfaceContextContainer,
  OrderedPortsContextContainer,
]);
