import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./PublicIPs.module.scss";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useConnectionsContext } from "../../../../WizardToolPage/ConnectionStep/ConnectionsContext";
import TableGrouped from "../../../../../components/common/table/newTable/TableGrouped";
import TableHeader from "../../../../../components/common/table/newTable/rows/TableHeader";
import TableWrapper from "../../../../../components/common/table/newTable/layout/TableWrapper";
import { ActionFuncs } from "../../../../../components/common/table/newTable/RowControls";
import { VirtualInetrfaceDIA } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { CellHoverTooltip } from "../../../../DIAPage/CellHoverTooltip";
import { diaTableHeader } from "./diaTableHeader";
import AddButton from "../../../../../components/common/buttons/AddButton";
import { ShortCreateForm } from "./ShortCreateForm";
import { classNames } from "../../../../../helpers/common/classNames";
import ConfirmDialog from "../../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../../../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../../../../helpers/getDialogControls";
import DNSResolvers from "./DNSResolvers";
import { useUnitedConnectionContext } from "../../UnitedConnectionContext";
import ASNBottomTable from "../../../../EventsPage/ASNBottomTable";
import { useUserContext } from "../../../../../contexts/UserContext";

export const cleanAndSplitIPs = (ips: Array<string>): DNSResolversListType => {
  const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)(\/[0-9]+)?$/;
  const ipv6Regex = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

  const cleanedIPs = ips
    .filter((ip) => typeof ip === "string")
    .map((ip) => ip.replace(/\/[0-9]+$/, ""));

  const ipv4 = cleanedIPs.filter((ip) => ipv4Regex.test(ip));
  const ipv6 = cleanedIPs.filter((ip) => ipv6Regex.test(ip));

  return { ipv4, ipv6 };
};

type Props = {
  isAdditional: boolean;
  onAction: (val?: boolean) => void;
  isRequest: boolean;
  isDNSResolversOpened?: boolean;
  setIsAdditional: (val: boolean) => void;
};

type DNSResolversListType = {
  ipv4: Array<string>;
  ipv6: Array<string>;
};

const PublicIPs: FC<Props> = ({
  isAdditional,
  onAction,
  isRequest,
  isDNSResolversOpened,
  setIsAdditional,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    diaListStatus,
    diaList,
    fetchDIAList,
    groupedDia,
    fetchSystems,
    fetchPoolList,
    systemsList,
    deleteDIAService,
    deleteDiaStatus,
    groups,
    getGroups,
    editPublicIPDescription,
  } = useConnectionsContext();
  const { fetchConnections } = useUnitedConnectionContext();
  const { fetchNotifications } = useUserContext();

  const [location, setLocation] = useState<string>("");
  const [
    DNSResolversList,
    setDNSResolversList,
  ] = useState<DNSResolversListType>({ ipv4: [], ipv6: [] });
  const [
    connectionToDelete,
    setConnectionToDelete,
  ] = useState<VirtualInetrfaceDIA | null>(null);

  const [systemName] = useState<string | undefined>();
  const [editingRowId, setEditingRowId] = useState<string | null>(null);

  useEffect(() => {
    if (systemName) {
      fetchPoolList(systemName);
    }
  }, [systemName]);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (!groups) return;

    const dnsGroup = groups.find(
      (group: any) => group.name === "DNS_resolvers"
    );
    if (!dnsGroup || !dnsGroup.subnets) {
      return;
    }

    setDNSResolversList(cleanAndSplitIPs(dnsGroup.subnets));
  }, [groups]);

  useEffect(() => {
    if (selectedTenant) fetchDIAList(selectedTenant);
    if (!systemsList?.length) fetchSystems();
    if (systemName) fetchPoolList(systemName);
  }, [selectedTenant, systemName]);

  const shortRowActions: ActionFuncs<any> = useMemo(
    () => ({
      hoverTooltip: (row: VirtualInetrfaceDIA) => (
        <CellHoverTooltip rowOriginal={row} />
      ),
      onDelete: (row: VirtualInetrfaceDIA) => setConnectionToDelete(row),
      onEditDescription: (row: VirtualInetrfaceDIA) => {
        editPublicIPDescription(
          selectedTenant || "",
          row.name,
          row.description || ""
        );
      },
    }),
    [selectedTenant]
  );

  const closeForm = () => {
    setIsAdditional(false);
    onAction(false);
  };

  const handleConnectionDelete = async () => {
    if (!connectionToDelete || !selectedTenant) return;

    const { name } = connectionToDelete;
    const ok = await deleteDIAService(selectedTenant, name);
    if (ok) {
      setConnectionToDelete(null);
      fetchNotifications(selectedTenant);
      fetchConnections();
    }
  };

  const empty = !Boolean(groupedDia) || !diaList?.length;

  if (empty && isRequest) {
    return (
      <ShortCreateForm
        onClose={closeForm}
        onAdd={closeForm}
        location={undefined}
      />
    );
  }

  return (
    <div className={classNames(styles.wrapper, empty && styles.empty)}>
      {isDNSResolversOpened && (
        <DNSResolvers
          resolversipv4={DNSResolversList.ipv4}
          resolversipv6={DNSResolversList.ipv6}
        />
      )}
      {connectionToDelete && (
        <ConfirmDialog
          status={deleteDiaStatus}
          icon={DeleteIcon}
          title="Delete Public IPs pool"
          message={`Are you sure you want to delete the public IP pool and associated connection ${connectionToDelete?.name}?`}
          controls={getDeleteDialogControls({
            onDelete: handleConnectionDelete,
            onCancel: () => setConnectionToDelete(null),
          })}
        />
      )}
      <TableWrapper
        titleProps={{ title: "", noTitle: true }}
        dataStatus={diaListStatus}
        tablePlaceholder={["Public IP", "Public IPs"]}
        isEmpty={empty}
        className={classNames(styles.noBorder, empty && styles.empty)}
      >
        <TableGrouped
          columns={diaTableHeader()}
          header={TableHeader}
          data={groupedDia}
          location={location}
          isDescriptionEditable
          editingRowId={editingRowId}
          setEditingRowId={setEditingRowId}
          gridColumnTemplate={
            "110px 150px minmax(max-content, 40px) 160px 1fr 30px"
          }
          gridTemplateRows={"auto max-content auto"}
          rowActions={shortRowActions}
          commonActions
          collapsedExtra
          collapsed={groupedDia ? Object.keys(groupedDia).slice(1) : []}
          withoutInitialCollapsed={
            groupedDia ? Object.keys(groupedDia).slice(0, 1) : []
          }
          extraTitleHeader={
            <AddButton
              className={styles.addBtn}
              isIconOnly
              onClick={() => setIsAdditional(!isAdditional)}
              disabled={isAdditional || isRequest}
            />
          }
          extraContentClb={setLocation}
          extraContent={
            isAdditional ? (
              <ShortCreateForm
                onClose={closeForm}
                onAdd={closeForm}
                location={location}
              />
            ) : null
          }
        />
        {isRequest && (
          <ShortCreateForm
            onClose={closeForm}
            onAdd={closeForm}
            location={undefined}
          />
        )}
      </TableWrapper>
      <ASNBottomTable isCollapsible />
    </div>
  );
};

export default PublicIPs;
