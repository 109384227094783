import { createContextAndUse } from "../../../../contexts/common/AbstractCrudContext";
import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { RequestStatus } from "../../../../helpers/types";
import { configApi } from "../../../../helpers/api/ConfigApi";
import { PortType } from "../../../../pages/PortsPage/types";
import { getPortsSchemeData } from "../../../../pages/PortsPage/helpers/getPortsSchemeData";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = { list?: Array<PortType> };

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (system: string, role?: string) => void;
};

const [PhysicalPortsContext, useContext] = createContextAndUse<IState, IFunc>();
export const usePhysicalPortsContext = useContext;

export default class PhysicalPortsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = PhysicalPortsContext;

  fetchList = async (system: string, role?: string): Promise<void> => {
    const { ok, result } = await this._fetchWithStatus(
      () => configApi.getPortsList(system),
      "listStatus"
    );
    if (ok && result) {
      let filteredLists = [];

      if (role) {
        filteredLists = getPortsSchemeData(result).filter(
          (port) => port.role === role
        );
      } else {
        filteredLists = getPortsSchemeData(result).filter(
          (port) => port.role !== "backplane"
        );
      }
      this.setState({ list: filteredLists });
      return;
    }
    this.setState({ list: undefined });
  };

  funcs = {
    fetchList: this.fetchList,
  };
}
