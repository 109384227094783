import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { commonTableColumns } from "../table/commonTableColumns";
import { groupTableStyles } from "../helpers/consts";
import { formPathFromArray } from "../../../helpers/navigation";
import { APPLICATIONS, INSIGHTS } from "../../../helpers/navigation/entries";

const HTTPSHostnamesTable: FC = () => {
  const { data, statuses } = useSearchContext();
  return (
    <ResultTable
      title="HTTPS Hostnames"
      data={data?.httpsHostnames}
      columns={commonTableColumns("Hostname")}
      status={statuses?.httpsHostnamesStatus}
      style={groupTableStyles}
      goto={formPathFromArray([INSIGHTS(), APPLICATIONS()])}
      serviceRedirectPlaceholderType="dpi"
    />
  );
};
export default HTTPSHostnamesTable;
