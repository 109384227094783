import { FC } from "react";
import styles from "./ListItem.module.scss";
import ItemControls from "./ItemControls";
import { DropdownItem } from "../../../../../../helpers/types";
import { Checkbox } from "../../../../formComponents/Checkbox";
import BlankButton from "../../../../buttons/BlankButton";
import { classNames } from "../../../../../../helpers/common/classNames";

type Props = {
  value?: DropdownItem;
  onEditOption?: (value?: string) => void;
  onDeleteOption?: (value?: string) => void;
  onSelectOption: (value?: DropdownItem) => void;
  withCheckbox: boolean;
  withControls: boolean;
  spanClassName?: string;
};

const ListItem: FC<Props> = ({
  value,
  onEditOption = () => undefined,
  onDeleteOption = () => undefined,
  onSelectOption,
  withCheckbox,
  withControls,
  spanClassName,
}) => {
  return (
    <div
      className={classNames(styles.row, withControls && styles.withControls)}
    >
      {withCheckbox ? (
        <>
          {value?.icon && <span className={styles.icon}>{value?.icon}</span>}
          <Checkbox
            isChecked={false}
            onChange={() => undefined}
            value={value?.key}
            label={value?.key}
            className={styles.checkboxes}
            disabled={value?.disabled}
          />
        </>
      ) : (
        <BlankButton
          id={"dropdownItem" + value?.key}
          className={classNames(
            styles.listItem,
            value?.disabled && styles.disabled,
            value?.customItemClassname
          )}
          key={value?.key}
          onClick={() => onSelectOption(value)}
        >
          {value?.icon && <span className={styles.icon}>{value?.icon}</span>}
          {value?.value || (typeof value?.key === "string" ? value?.key : "")}
          {value?.subtitle && (
            <span className={classNames(spanClassName && styles.spanSubtitle)}>
              {value.subtitle}
            </span>
          )}
        </BlankButton>
      )}
      {withControls && (
        <ItemControls
          onEdit={() => onEditOption(value?.key)}
          onDelete={() => onDeleteOption(value?.key)}
          id={value?.key}
          className={styles.controlsBtn}
        />
      )}
    </div>
  );
};

export default ListItem;
