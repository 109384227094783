import { FC, ReactNode, useMemo } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps, ServiceType } from "../../helpers/types";
import { withContexts } from "../../helpers/hocs/withContexts";
import { SearchContextContainer } from "./SearchContext";
import styles from "./SearchPage.module.scss";
import FWRulesTable from "./components/FwRulesTable";
import NatRulesTable from "./components/NatRulesTable";
import SourceIpsTable from "./components/SourceIpsTable";
import DestinationIpsTable from "./components/DestinationIpsTable";
import CyberThreatsTable from "./components/CyberThreatsTable";
import DNSServersTable from "./components/DNSServersTable";
import DNSDomainsTable from "./components/DNSDomainsTable";
import HTTPSHostnamesTable from "./components/HTTPSHostnamesTable";
import SearchControls from "./components/SearchControls/SearchControls";
import UniqueMetricsCard from "./components/UniqueMetricCard/UniqueMetricsCard";
import TablesGroup from "./common/TablesGroup/TablesGroup";
import {
  ServicesPreferencesContextContainer,
  useServicesPreferencesContext,
} from "../ServicesPreferencesPage/ServicesPreferencesContext";
import Loader from "../../components/common/Loader/Loader";

const SearchPage: FC<PageProps> = () => {
  const { getService, list, fetchStatus } = useServicesPreferencesContext();

  const tables = useMemo(() => {
    const tablesList: Array<ReactNode> = [];
    const insertElement = (
      type: ServiceType,
      element: ReactNode,
      arr: Array<ReactNode>
    ) => {
      const service = getService(type);
      if (!service) return;

      const method = service.enable ? "unshift" : "push";
      arr[method](element);
    };

    const groupElements: Array<ReactNode> = [];
    insertElement("dpi", <HTTPSHostnamesTable />, groupElements);
    insertElement("dns", <DNSDomainsTable />, groupElements);
    insertElement("dns", <DNSServersTable />, groupElements);
    insertElement("ids", <CyberThreatsTable />, groupElements);
    insertElement("sdr", <DestinationIpsTable />, groupElements);
    insertElement("sdr", <SourceIpsTable />, groupElements);

    tablesList.push(<TablesGroup nodes={groupElements.slice(0, 3)} />);
    tablesList.push(<TablesGroup nodes={groupElements.slice(3)} />);

    insertElement("nat", <NatRulesTable />, tablesList);
    insertElement("firewall", <FWRulesTable />, tablesList);

    return tablesList;
  }, [list]);

  return (
    <div className={styles.wrapper}>
      <SearchControls />
      <UniqueMetricsCard />
      {fetchStatus?.state === "pending" ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        tables
      )}
    </div>
  );
};
export default withContexts<PageProps>(withLayout<PageProps>(SearchPage), [
  SearchContextContainer,
  ServicesPreferencesContextContainer,
]);
