import React, { ReactElement } from "react";

function EmptyConnectionsPlaceholderIconSmall(): ReactElement {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="38" cy="38" r="38" fill="#6B55F0" />
      <rect
        x="20.1445"
        y="19.6836"
        width="35.7108"
        height="38"
        rx="6"
        fill="#DED9FF"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <rect
        x="16.4805"
        y="19.6836"
        width="43.0361"
        height="33.4217"
        rx="6"
        fill="#DED9FF"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <rect
        x="16.4805"
        y="19.6836"
        width="43.0361"
        height="33.4217"
        rx="6"
        fill="white"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <rect
        x="16.4805"
        y="23.8086"
        width="43.0361"
        height="0.640964"
        fill="#BDB2FF"
      />
      <rect
        x="21.9727"
        y="32.5039"
        width="32.0482"
        height="1.83133"
        fill="#DED9FF"
      />
      <rect
        x="21.9727"
        y="37.082"
        width="32.0482"
        height="1.83133"
        fill="#DED9FF"
      />
      <rect
        x="21.9727"
        y="41.6562"
        width="32.0482"
        height="1.83133"
        fill="#DED9FF"
      />
      <circle
        cx="52.1918"
        cy="50.8246"
        r="9.15663"
        fill="white"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.6442 47.6211H51.7285V50.3672H48.9824L48.9824 51.2828H51.7285V54.0307H52.6442V51.2829H55.3921V50.3672H52.6442V47.6211Z"
        fill="#BDB2FF"
      />
    </svg>
  );
}

export default EmptyConnectionsPlaceholderIconSmall;
