import { PROTOCOL_ANY } from "../../../helpers/common/constantsAlias";
import {
  TCP_STATES,
  PBM_UNIT as DDOS_UNIT,
} from "../../PBMPage/PBMConfig/PBMConfig";
import { CreateDDoS } from "./types";

export const MIN_RATE_VALUE = 125;
const METER_RATE_VALUE = 1000000000;
export const DEFAULT_HYSTERESIS_SECONDS = 30;

export const DDOS_CONFIG_DEFAULT_FIELDS: CreateDDoS = {
  name: "",
  enable: true,
  priority: 1,
  description: "",
  ip_protocol: PROTOCOL_ANY,
  src_network: "",
  src_segment: "",
  dst_segment: "",
  src_l4_port: "",
  dst_network: "",
  dst_l4_port: "",
  src_group: "",
  dst_group: "",
  fin: TCP_STATES.ANY,
  ack: TCP_STATES.ANY,
  urg: TCP_STATES.ANY,
  syn: TCP_STATES.ANY,
  rst: TCP_STATES.ANY,
  psh: TCP_STATES.ANY,
  is_fragmented: false,
  unit: DDOS_UNIT.PACKETS_PER_SECOND,
  max_rate: METER_RATE_VALUE,
  flood_detect_rate: MIN_RATE_VALUE,
  flood_cooldown_rate: MIN_RATE_VALUE,
  hysteresis_seconds: 30,
  flood_rate: MIN_RATE_VALUE,
};
