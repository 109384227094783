import { DDoSOptionType } from "../../../helpers/api/apiTypes";
import { DDOS_CONFIG_DEFAULT_FIELDS } from "../../DDoSSystemPage/DDoSConfig/constants";
import { CreateDDoS } from "../../DDoSSystemPage/DDoSConfig/types";

export const COMMON_OPTION_FIELDS: DDoSOptionType = {
  name: "",
  flood_detect_rate: 125,
  flood_cooldown_rate: 125,
  hysteresis_seconds: 30,
  flood_rate: 125,
  icon: "",
};

export const INITIAL_OPTIONS: Array<DDoSOptionType> = [
  {
    ...COMMON_OPTION_FIELDS,
    name: "Monitor",
    flood_rate: 1250,
  },
];

export const PROPOSED_OPTIONS = [
  INITIAL_OPTIONS[0],
  {
    ...COMMON_OPTION_FIELDS,
    name: "Server",
  },
  {
    ...COMMON_OPTION_FIELDS,
    name: "User",
  },
  {
    ...COMMON_OPTION_FIELDS,
    name: "IoT",
  },
];

export const DDOS_DIA_CONFIG_DEFAULT_FIELDS: CreateDDoS = {
  ...DDOS_CONFIG_DEFAULT_FIELDS,
  options: INITIAL_OPTIONS,
};
