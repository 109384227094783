import React, { FC, useState, useEffect, useMemo } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { withContexts } from "../../helpers/hocs/withContexts";
import styles from "./ASNBottomTable.module.scss";

import { classNames } from "../../helpers/common/classNames";
import OrderedPortsContextContainer, {
  useOrderedPortsContext,
} from "../OrderedPortsPage/OrderedPortsContext";
import ASNHeader from "./components/ASNHeader";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { CustomerPortType } from "../Network/UnitedConnections/types";
import { asnBottomInventoryHeader } from "../OrderedPortsPage/table/asnBottomInventoryHeader";
import ASNDropdown from "../ZayoPages/Connections/ConnectionConfigurator/components/ZPort/components/DIAConnectionItem/ASNDropdown";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import ConfirmDialog from "../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../helpers/getDialogControls";

type Props = {
  isCollapsible?: boolean;
  className?: string;
};

const ASNBottomTable: FC<Props> = ({ isCollapsible, className }) => {
  const {
    asnListStatus,
    fetchASNForSpecificTenantData,
    asnForSpecificTenantList,
    asnForSpecificTenantCount,
    deleteASN,
    registerNewASN,
    deleteRequestStatus,
  } = useOrderedPortsContext();
  const [connectionToDelete, setConnectionToDelete] = useState<any>();
  const [editingRowId, setEditingRowId] = useState<string | null>(null);

  const { selectedTenant } = useGlobalFilterContext();

  const [isRegisterASNOpen, setIsRegisterASNOpen] = useState(false);

  useEffect(() => {
    fetchASNForSpecificTenantData(selectedTenant || "");
  }, []);

  useEffect(() => {
    fetchASNForSpecificTenantData(selectedTenant || "");
  }, [selectedTenant]);

  const handleRegisterASN = () => {
    setIsRegisterASNOpen((prev) => !prev);
  };

  const tableColumns = asnBottomInventoryHeader();
  const gridTemplate = "50px 150px 100px 150px 50px 50px";

  const [isCollapsed, setIsCollapsed] = useState<boolean>(!!isCollapsible);

  const rowActions: ActionFuncs<CustomerPortType> | undefined = useMemo(() => {
    return {
      onDelete: (row: any) => setConnectionToDelete(row),
    };
  }, [connectionToDelete]);

  const renderConnectionToDelete = () => {
    return (
      <ConfirmDialog
        status={deleteRequestStatus}
        icon={DeleteIcon}
        title="Delete ASN Record"
        message={`Are you sure you want to delete ${connectionToDelete?.asn}?`}
        controls={getDeleteDialogControls({
          onDelete: handleASNDelete,
          onCancel: () => setConnectionToDelete(null),
        })}
      />
    );
  };

  const handleASNDelete = async () => {
    if (!connectionToDelete) {
      return;
    }

    const { tenant_name, asn } = connectionToDelete;
    const { ok } = await deleteASN(tenant_name, asn);
    if (ok) {
      setConnectionToDelete(null);
    }
  };

  return (
    <>
      {connectionToDelete && renderConnectionToDelete()}
      <div
        className={classNames(
          styles.wrapper,
          isCollapsible && !isCollapsed && styles.notFullHeight
        )}
      >
        <ASNHeader
          title={"ASN"}
          isCollapsible={!!isCollapsible}
          toggleCollapse={() => setIsCollapsed((prev) => !prev)}
          isCollapsed={isCollapsed}
          className={classNames(styles.header, className)}
          amount={asnForSpecificTenantCount}
          onRegisterASN={handleRegisterASN}
        />

        {!isCollapsed && (
          <TableWrapper
            dataStatus={asnListStatus}
            tablePlaceholder={["ASN Records", "ASN"]}
          >
            <Table
              fetchAction={() =>
                fetchASNForSpecificTenantData(selectedTenant || "")
              }
              data={asnForSpecificTenantList}
              count={asnForSpecificTenantCount}
              totalCount={asnForSpecificTenantCount}
              columns={tableColumns}
              editingRowId={editingRowId}
              isDescriptionPresent
              setEditingRowId={setEditingRowId}
              header={TableHeader}
              gridColumnTemplate={gridTemplate}
              rowActions={rowActions}
            />
          </TableWrapper>
        )}

        {isRegisterASNOpen && (
          <div
            style={{
              position: "absolute",
              top: "calc(100% - 250px)",
              right: "0px",
              zIndex: 1000,
            }}
          >
            <ASNDropdown
              options={asnForSpecificTenantList}
              isStraightRegister
              asnForSpecificTenantList={asnForSpecificTenantList}
              onCancel={() => setIsRegisterASNOpen(false)}
              onSelect={() => {
                console.log("1");
              }}
              onRegister={(asn) => {
                registerNewASN(selectedTenant || "", asn);
                setIsRegisterASNOpen(false);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default withContexts<Props>(ASNBottomTable, [
  OrderedPortsContextContainer,
]);
