import React, { useEffect, useMemo } from "react";

import DropdownBasic from "../Dropdown/DropdownBasic";
import { withContexts } from "../../../helpers/hocs/withContexts";
import VIListContextContainer, {
  useVIListContext,
} from "./contexts/VIListContext";
import { VirtualInterfaceApi } from "../../../pages/VirtualInterfacePage/types";

type Props = {
  systemName?: string;
  vrf?: { name: string; vrf_type: string };
  selected?: Partial<VirtualInterfaceApi> | string;
  onSelect: (vrf?: Partial<VirtualInterfaceApi>) => void;
  className?: string;
  label?: string;
  force?: boolean;
  withClearIcon?: boolean;
  disabled?: boolean;
  error?: string;
  filterBySystemName?: boolean;
  filterPredicate?: (value: VirtualInterfaceApi) => boolean;
};
const VIDropdown: React.FC<Props> = ({
  selected,
  onSelect,
  systemName,
  vrf,
  className,
  label,
  force,
  withClearIcon,
  disabled,
  error,
  filterBySystemName,
  filterPredicate,
}) => {
  const { fetchList, list } = useVIListContext();

  useEffect(() => {
    if (systemName && vrf) {
      fetchList(vrf, systemName);
    }
    if (vrf && force) {
      fetchList(vrf, systemName);
    }
  }, [fetchList, systemName, vrf]);
  const selectedIem = useMemo(() => {
    if (!selected) return undefined;

    return typeof selected !== "string"
      ? {
          value: selected.name ?? "",
          key: selected.name ?? "",
          data: selected,
        }
      : {
          value: selected ?? "",
          key: selected ?? "",
        };
  }, [selected]);
  const items = useMemo(() => {
    const filteredList = filterPredicate
      ? (list || []).filter(filterPredicate)
      : list;
    return (filteredList || [])
      .filter(
        (vi) =>
          (filterBySystemName && vi.system_name === systemName) ||
          !filterBySystemName
      )
      .map((item) => ({
        value: item.name,
        key: item.name,
        data: item,
      }));
  }, [list, systemName]);
  return (
    <DropdownBasic
      id={"VIDrop"}
      onChange={(val) => {
        if (val.data) {
          onSelect(val.data);
        } else {
          onSelect({ name: "" });
        }
      }}
      selected={selectedIem}
      label={label || "Virtual Interface"}
      itemsList={items}
      isMedium
      disabled={!vrf || disabled}
      className={className}
      withClearIcon={withClearIcon}
      placeholder={selectedIem?.value}
      error={error}
    />
  );
};

export default withContexts<Props>(VIDropdown, [VIListContextContainer]);
