import React, { useState } from "react";
import styles from "./EditableRowForm.module.scss";
import Button from "../../../buttons/Button";
import EditButton from "../../../buttons/EditButton";

type EditableRowFormProps = {
  description: string;
  onSave: (newDescription: string) => void;
  onCancel: () => void;
  isDescriptionEditable?: boolean;
};

const EditableRowForm: React.FC<EditableRowFormProps> = ({
  description,
  onSave,
  onCancel,
  isDescriptionEditable,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newDescription, setNewDescription] = useState(description);

  const handleSave = () => {
    onSave(newDescription);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setNewDescription(description);
    setIsEditing(false);
    onCancel();
  };

  return isEditing ? (
    <div className={styles.form}>
      <div className={styles.label}>Description</div>
      <textarea
        value={newDescription}
        onChange={(e) => setNewDescription(e.target.value)}
        className={styles.textarea}
      />
      <div className={styles.actions}>
        <Button onClick={handleCancel} className={styles.button}>
          Cancel
        </Button>
        <Button onClick={handleSave} className={styles.button} isPrimaryBtn>
          Save
        </Button>
      </div>
    </div>
  ) : (
    <div className={styles.descriptionView}>
      <p className={styles.descriptionText}>
        {description || "No Description"}
      </p>
      {isDescriptionEditable ? (
        <button
          className={styles.editButton}
          onClick={() => setIsEditing(true)}
        >
          <EditButton
            onClick={() => setIsEditing(true)}
            id={`${description}-id`}
          />
        </button>
      ) : null}
    </div>
  );
};

export default EditableRowForm;
