import React, { FC, useEffect, useMemo, useState } from "react";
import Input from "../../../components/common/formComponents/Input";
import Section from "../../../components/common/Section";
import { CreateDiaFWRule } from "./DiaFirewallRuleConfig";
import styles from "./RuleSection.module.scss";
import { UserGroups } from "../../../contexts/servicesContext/FirewallContext";
import {
  ADD_MULTIPLE,
  EDIT_MULTIPLE,
  PROTOCOLS_TYPES_MAP,
  PROTOCOL_TCP,
} from "../../../helpers/common/constantsAlias";
import SourceBlock from "../../Firewall/config/SourceBlock";
import DestinationBlock from "../../Firewall/config/DestinationBlock";
import { DiaBlock } from "./DiaBlock";
import RadioGroup from "../../../components/common/formComponents/RadioGroup";
import { VirtualInetrfaceGate } from "../../../helpers/api/TenantVirtualInterfaceApi/types";

import {
  IPPoliciesType,
  IP_ACTIONS,
} from "../../PBRPage/PBRConfig/RuleSection";
import { checkIPv4WithPrefix } from "../../../helpers/validators/Validator";
import { DialogType } from "../../../helpers/types";
import { isEditMode } from "../../../helpers/isEditMode";
import { DiaConnectionsBlock } from "./DiaConnectionsBlock";
import { CreateFWRule } from "../../Firewall/config/FirewallRuleConfig";

type Props = {
  fields: CreateDiaFWRule;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
  groups: UserGroups | undefined;
  isSNI: boolean;
  isFQDN: boolean;
  gates: Array<VirtualInetrfaceGate>;
  type: DialogType;
};

const options = [
  { value: "incoming", label: "Incoming" },
  { value: "outgoing", label: "Outgoing" },
];

const RuleSection: FC<Props> = ({
  fields,
  errors,
  onChange,
  className,
  groups,
  isSNI,
  isFQDN,
  gates,
  type,
}) => {
  const isEdit = isEditMode(type);
  const isIncoming = useMemo(() => fields.policy_name === options[0].value, [
    fields.policy_name,
  ]);

  const [policies, setPolicies] = useState<string>(fields?.ip_type || "ipv4");
  const setPort = () => {
    isSNI && isFQDN && onChange("dst_l4_port", "443");
  };
  const gate = gates.find((gate) => gate.name === fields.gate_vi_name);
  const gateWithChoice =
    (gate &&
      gate.ip_addresses?.length > 1 &&
      !gate?.ip_addresses.every(checkIPv4WithPrefix)) ||
    false;

  const handleChangePolicies = (val: string): void => {
    setPolicies(val as IPPoliciesType);
    onChange("ip_type", val);
  };

  const setProtocol = () => {
    isSNI && isFQDN && onChange("ip_protocol", PROTOCOL_TCP);
  };

  useEffect(() => {
    setPort();
    setProtocol();
  }, [isSNI, isFQDN]);

  const handleChangePolicy = (val: string) => {
    onChange("src_dia", "");
    onChange("dst_dia", "");
    onChange("policy_name", val);
  };

  const getDiaBlock = (isSource: boolean) =>
    type === ADD_MULTIPLE || type === EDIT_MULTIPLE ? (
      <DiaConnectionsBlock
        errors={errors}
        onChange={onChange}
        fields={fields}
      />
    ) : (
      <DiaBlock
        gates={gates}
        errors={errors}
        onChange={onChange}
        isSource={isSource}
        fields={fields}
        gateWithChoice={gateWithChoice}
      />
    );

  const getSourceAndDestination = () => {
    if (isIncoming) {
      return (
        <>
          <SourceBlock
            fields={fields}
            errors={errors}
            onChange={onChange}
            groups={groups}
          />
          {getDiaBlock(false)}
        </>
      );
    }
    return (
      <>
        {getDiaBlock(true)}
        <DestinationBlock
          fields={fields}
          errors={errors}
          onChange={onChange}
          groups={groups}
        />
      </>
    );
  };

  return (
    <Section title={"Rule"} className={className} columnsCount={3}>
      <div className={styles.column}>
        {!isEdit && gateWithChoice && (
          <RadioGroup
            className={styles.radioWrapper}
            label={"IP protocol"}
            isOneRow
            options={IP_ACTIONS}
            value={policies}
            setActiveValue={handleChangePolicies}
            radioContainerClassName={styles.radioContainer}
            isNotEditable={isEdit}
          />
        )}
        <RadioGroup
          options={options}
          value={fields.policy_name}
          radioContainerClassName={styles.radio}
          setActiveValue={handleChangePolicy}
        />
        {getSourceAndDestination()}
      </div>
      <div className={styles.column}>
        <Input
          label="Protocol"
          name={"ip_protocol"}
          className={styles.inputWrapperCenter}
          handleFieldChange={onChange}
          value={fields.ip_protocol}
          placeholder={fields.ip_protocol}
          error={errors && errors["ip_protocol"]}
          valuesList={PROTOCOLS_TYPES_MAP}
          medium
          isNotEditable={isSNI && isFQDN}
        />
      </div>
      <div className={styles.column}>
        <Input
          label="Source Port"
          name="src_l4_port"
          className={styles.portWrapper}
          placeholder="xxx"
          handleFieldChange={onChange}
          value={fields.src_l4_port}
          medium
          error={errors && errors["src_l4_port"]}
          isOptional
        />
        <Input
          label="Destination Port"
          name="dst_l4_port"
          className={styles.portWrapper}
          placeholder="xxx"
          handleFieldChange={onChange}
          value={fields.dst_l4_port}
          medium
          error={errors && errors["dst_l4_port"]}
          isOptional
          isNotEditable={isSNI && isFQDN}
        />
      </div>
    </Section>
  );
};
export default RuleSection;
