import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./ConnectionsBlock.module.scss";
import BlockTitle from "./common/BlockTitle";
import ContentBlock from "./common/ContentBlock";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import ConnectionsContextContainer from "../../../ZayoPages/Connections/ConnectionContext";
import CloudsTabInfo from "./Clouds/CloudsTabInfo";
import { ConnectionContextContainer as BaseConnectionContextContainer } from "../../Connections/ConnectionContext";
import { classNames } from "../../../../helpers/common/classNames";
import ActiveTenantSelector from "../../../../components/common/navigation/tenantSelector/ActiveTenantSelector";
import IconBlankButton from "../../../../components/common/buttons/IconBlankButton";
import PieChartIcon from "../../../../components/icons/PieChartIcon";
import { WHITE } from "../../../../helpers/common/colorAliases";
import { Link } from "react-router-dom";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  CONNECTIONS,
  CONNECTIVITY,
  NETWORK,
  REPORTS,
} from "../../../../helpers/navigation/entries";
import { useUnitedConnectionContext } from "../UnitedConnectionContext";
import { ROUTED } from "../../../../helpers/consts";
import { checkIfInternet } from "../helpers";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";

type Props = {
  addFrom?: string;
  addConnectionCallback: () => void;
  onlyDiagram?: boolean;
  onTabChange?: (value: string) => void;
};

const tabs = ["Cloud Router", "Direct"];

const ConnectionsBlock: FC<Props> = ({
  addFrom,
  addConnectionCallback,
  onlyDiagram,
  onTabChange,
}) => {
  const { connections } = useUnitedConnectionContext();

  const searchParamsTab = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("tab");
  }, []);

  const tab = useMemo(() => {
    if (searchParamsTab) return searchParamsTab;

    const onlyCloudRouterConnection = connections.every(
      (item) => item?.virtual_interface_type === ROUTED || checkIfInternet(item)
    );

    if (onlyCloudRouterConnection) return tabs[0];

    return tabs[1];
  }, [connections, searchParamsTab]);

  const [activeTab, setActiveTab] = useState<string>(tab);
  const [showDialog, setShowDialog] = useState<boolean>(!!searchParamsTab);

  useEffect(() => setShowDialog(!!addFrom), [addFrom]);

  useEffect(() => setShowDialog(!!searchParamsTab), [searchParamsTab]);

  useEffect(() => setActiveTab(tab), [tab]);

  useEffect(() => onTabChange?.(activeTab), [activeTab]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setShowDialog(false);
    addConnectionCallback?.();
  };

  const handleControl = () => {
    setShowDialog(!showDialog);
    addFrom && addConnectionCallback();
  };

  const labels = {
    [tabs[0]]: (
      <ServicesRedirectPlaceholder type="mesh" withoutInfo>
        <CloudsTabInfo
          isAdd={showDialog}
          onAction={handleControl}
          addFrom={addFrom}
          isCloud
          onlyDiagram={onlyDiagram}
        />
      </ServicesRedirectPlaceholder>
    ),
    [tabs[1]]: (
      <CloudsTabInfo
        isAdd={showDialog}
        onAction={handleControl}
        addFrom={addFrom}
        onlyDiagram={onlyDiagram}
      />
    ),
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, onlyDiagram && styles.fullWidth)}
      >
        <div className={styles.controls}>
          {!onlyDiagram && (
            <BlockTitle
              title={"connections"}
              label={"Add Connection"}
              disabled={showDialog}
              onClick={handleControl}
              additionalContent={
                <div className={styles.additionalControls}>
                  <ActiveTenantSelector />
                  <Link
                    to={formPathFromArray([
                      NETWORK(),
                      CONNECTIVITY(),
                      CONNECTIONS(),
                      REPORTS(),
                    ])}
                  >
                    <IconBlankButton
                      id="statistics-redirect-button"
                      className={styles.statisticsBtn}
                      hint="Statistics"
                      icon={PieChartIcon}
                      color={WHITE}
                      hoverColor={WHITE}
                    />
                  </Link>
                </div>
              }
              additionalContentClassname={styles.selector}
            />
          )}
        </div>
        <ContentBlock
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          content={labels[activeTab] as React.ReactElement}
          showDialog={showDialog}
          color={activeTab === tabs[0] ? "blue" : "orange"}
        />
      </div>
    </>
  );
};

export default withContexts<Props>(ConnectionsBlock, [
  ConnectionsContextContainer,
  BaseConnectionContextContainer,
]);
