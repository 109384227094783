import styles from "./DiaConnectionsBlock.module.scss";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { useConnectionsContext } from "../../WizardToolPage/ConnectionStep/ConnectionsContext";
import DropdownAutocomplete from "../../../components/common/Dropdown/DropdownAutocomplete";
import { CreateDiaFWRule } from "./DiaFirewallRuleConfig";

type Props = {
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  fields: CreateDiaFWRule;
};

export const DiaConnectionsBlock = ({ errors, onChange, fields }: Props) => {
  const { diaList = [] } = useConnectionsContext();

  return (
    <DropdownAutocomplete
      id="diaConnections"
      label="DIA IP connections"
      valuesList={diaList?.map((dia) => dia.name)}
      placeholder=""
      onChange={(val: Array<string>) => {
        onChange("gate_vi_names", val);
      }}
      currentValues={fields.gate_vi_names}
      selected={mapStringToItem("")}
      isMedium
      listClassName={styles.listWrapper}
      emptyListPlaceholder="No dia connections available"
      error={errors?.["gate_vi_names"]}
    />
  );
};
