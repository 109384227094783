import { CustomerPortType } from "../../Network/UnitedConnections/types";
import { getDateWithTime } from "../../../helpers/common/dateHelpers";
import { UserThumbnail } from "../../UsersPage/components/shared/UserThumbnail";
import styles from "./asnTableHeader.module.scss";
import parseEnrichedData from "../../../helpers/ASNEnrichedDataParser";
import { renderItemWithPopup } from "./asnBottomInventoryHeader";

export const asnTableHeader = (): Array<any> => {
  return [
    {
      Header: "Timestamp (request created)",
      accessor: "created_at",
      Cell: ({ cell: { value } }: any) => {
        return getDateWithTime(value) || "";
      },
    },
    {
      Header: "Tenant name",
      accessor: "tenant_name",
      Cell: ({ cell: { value } }: any) => {
        return <UserThumbnail username={value || ""} />;
      },
    },
    {
      Header: "ASN",
      accessor: "asn",
      Cell: ({ cell: { value } }: any) => {
        return value || "";
      },
    },
    {
      Header: "AS-Name",
      accessor: "as_name_parsed", // non-existing accessor to avoid duplication, we use parser
      Cell: ({ row }: any) => {
        const { asName } = parseEnrichedData(row.original.enriched_data);
        return asName || "";
      },
    },
    {
      Header: "IP Prefixes (#)",
      accessor: "ip_prefixes", // non-existing accessor to avoid duplication, we use parser
      Cell: ({ row }: any) => {
        const ipv4_length = row.original.ipv4_prefix_list?.length;
        const ipv6_length = row.original.ipv6_prefix_list?.length;
        return (
          <div className={styles.prefixesWrapper}>
            {renderItemWithPopup(
              ipv4_length,
              row.original.ipv4_prefix_list,
              styles.ipv4
            )}
            {renderItemWithPopup(
              ipv6_length,
              row.original.ipv6_prefix_list,
              styles.ipv6
            )}
          </div>
        );
      },
    },
    {
      Header: "Source",
      accessor: "source_parsed", // non-existing accessor to avoid duplication, we use parser
      Cell: ({ row }: any) => {
        const { source } = parseEnrichedData(row.original.enriched_data);
        return source || "";
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof CustomerPortType,
  }));
};
