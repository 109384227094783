import { LookingGlassCommands } from "./types";

export const CMD_LIST = [
  { label: "Route lookup", value: LookingGlassCommands.ROUTE_LOOKUP },
  { label: "Ping", value: LookingGlassCommands.PING },
  { label: "Traceroute", value: LookingGlassCommands.TRACERT },
  { label: "Path MTU", value: LookingGlassCommands.MTU },
  { label: "Packet Capture (PCAP)", value: LookingGlassCommands.PCAP },
];

export const PROTOCOL_LIST = ["TCP", "UDP"] as const;

export const PCAP_TYPE_LIST = ["all", "rx", "tx", "drop"] as const;
