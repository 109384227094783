import { useEffect } from "react";
import DDoSInfo from "./DDoSInfo";
import styles from "./DDoSConfig.module.scss";
import GeneralSection from "./GeneralSection";
import { ADD, EDIT } from "../../../helpers/common/constantsAlias";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import sendRequest from "../../../helpers/sendRequest";
import DialogBtmButtons from "../../../components/dialogs/common/DialogBtmButtons";
import { isEditMode } from "../../../helpers/isEditMode";
import RuleSection from "./RuleSection";
import ActionSection from "./ActionSection";
import validateDDoS from "../../../helpers/validators/DDoSValidator";
import { MAX_FW_PRIORITY_FOR_MANUAL_CREATION } from "../../PBRPage/PBRConfig/PBRConfig";
import { generatePriority } from "../../Firewall/config/FirewallRuleConfig";
import { CollapsableTwoColumnLayout } from "../../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { useDDoSContext } from "../../../contexts/servicesContext/DDoSContext";
import { getFlatData, getPreparedData } from "./helpers";
import { CreateDDoS, DDoSConfigProps } from "./types";
import { DDOS_CONFIG_DEFAULT_FIELDS } from "./constants";

const DDoSConfig = ({
  onClose,
  type,
  data,
  selectedVRF,
  system,
}: DDoSConfigProps) => {
  const {
    add,
    addStatus,
    edit,
    editStatus,
    fetchList,
    ddosList,
    selectedDDoS,
  } = useDDoSContext();
  const { userGroups } = useFirewallContext();

  const [fields, handleFieldChange] = useFormField<CreateDDoS>(
    getFlatData(data) || DDOS_CONFIG_DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<CreateDDoS>(validateDDoS, [fields]);

  useEffect(() => {
    selectedVRF && fetchList({ vrf: selectedVRF?.name, system: system?.name });
  }, [selectedVRF]);

  useEffect(() => {
    if (ddosList) {
      const priority = data ? data.priority : generatePriority(ddosList);
      handleFieldChange("priority", priority);
    }
  }, [ddosList]);

  const handleAdd = async () => {
    const { isOk } = validate();

    if (!isOk) return;

    const newFields = getPreparedData(fields);
    await sendRequest(
      isOk,
      add(newFields, { vrf: selectedVRF?.name, system: system?.name }),
      onClose
    );
  };

  const handleEdit = async () => {
    if (!data) return;
    const { isOk } = validate();
    if (!isOk) return;

    const newFields = getPreparedData(fields);

    await sendRequest(
      isOk,
      edit(newFields, data.name, {
        vrf: selectedVRF?.name,
        system: system?.name,
      }),
      onClose
    );
    await fetchList({ vrf: selectedVRF?.name, system: system?.name });
  };

  const gridTemplate = "1fr 0.5fr 0.5fr";

  return (
    <CollapsableTwoColumnLayout
      InfoBlock={() => (
        <DDoSInfo tenant={selectedVRF?.name} selectedDDoS={selectedDDoS} />
      )}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <GeneralSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            maxPriority={MAX_FW_PRIORITY_FOR_MANUAL_CREATION}
            gridTemplate={gridTemplate}
          />
          <RuleSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            groups={userGroups}
            gridTemplate={gridTemplate}
            selectedVRF={selectedVRF}
            system={system}
          />
          <ActionSection
            className={styles.contentWrapper}
            fields={fields}
            onChange={handleFieldChange}
            gridTemplate={gridTemplate}
            errors={errors}
          />
        </div>
        <div className={styles.footer}>
          <DialogBtmButtons
            controls={{
              okText: isEditMode(type) ? EDIT : ADD,
              onOk: isEditMode(type) ? handleEdit : handleAdd,
              cancelText: "Cancel",
              onCancel: onClose,
            }}
            errorDisplay={addStatus || editStatus}
          />
        </div>
      </div>
    </CollapsableTwoColumnLayout>
  );
};

export default DDoSConfig;
