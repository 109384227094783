import BlankButton from "../../../../components/common/buttons/BlankButton";
import { LookingGlassCommands } from "../types";
import styles from "./CommandOutput.module.scss";
import { PcapIcon } from "./PcapIcon";
import { useMemo } from "react";
import moment from "moment";

type CommandOutputProps = {
  commandOutput: string | null;
  cmd?: LookingGlassCommands;
  system?: string;
  l2Interface?: string;
};

export const CommandOutput = ({
  commandOutput,
  cmd,
  system,
  l2Interface,
}: CommandOutputProps) => {
  const filename = useMemo(() => {
    const timestamp = moment().format("DD-MM-YY-HH:mm:ss");
    return `${timestamp}–${system}–${l2Interface}.pcap`;
  }, [system, l2Interface]);

  const handlePcapDownload = () => {
    if (!commandOutput) return;
    const blob = new Blob([Buffer.from(commandOutput, "base64")], {
      type: "application/vnd.tcpdump.pcap",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  };

  if (cmd === LookingGlassCommands.PCAP) {
    return (
      <div className={styles.downloadWrapper}>
        <PcapIcon />
        <div className={styles.fileName}>{filename}</div>
        <BlankButton
          id="pcap-download"
          className={styles.downloadBtn}
          onClick={handlePcapDownload}
        >
          Download
        </BlankButton>
      </div>
    );
  }

  return <div className={styles.output}>{commandOutput}</div>;
};
