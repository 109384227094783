import {
  LookingGlassCommands,
  LookingGlassFields,
} from "../../pages/Network/LookingGlass/types";
import Validator from "./Validator";

export default function validateLookingGlass(
  data: Partial<LookingGlassFields>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.cmd, "cmd");

  validator.checkEmpty(data.location, "location");

  if (data.cmd === LookingGlassCommands.MTU) {
    validator.checkNumber(`${data.max_mtu}`, [64, 20000], "max_mtu");
    validator.checkNumber(`${data.min_mtu}`, [64, 20000], "min_mtu");
  }

  if (data.cmd === LookingGlassCommands.PCAP) {
    validator.checkEmpty(data.pcapType, "pcapType");
    validator.checkEmpty(data.size, "size");
    validator.checkEmpty(data.maxPackets, "maxPackets");
    validator.checkEmpty(
      data.l2Interface || data.l2InterfaceLabel,
      "l2Interface"
    );
    validator.checkEmpty(data.timeoutSec, "timeoutSec");

    validator.checkNumber(`${data.size}`, [256, 10000], "size");
    validator.checkNumber(`${data.maxPackets}`, [200, 10000], "maxPackets");
    validator.checkNumber(`${data.timeoutSec}`, [1, 20], "timeoutSec");
  } else {
    validator.checkEmpty(data.ip, "ip");
    validator.checkIP(data.ip, "ip");
  }

  return validator;
}
