import { ReactElement } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  PROVISIONING,
  USERS,
  PACKAGES,
  TENANT,
  USER_GROUPS,
  IP_GROUPS,
  REMOTE_USERS_STATUS,
  ORDERS_AND_REQUESTS,
  SERVICE_CATALOG,
} from "../../helpers/navigation/entries";
import QuotaContextContainer from "../../contexts/tenantsContext/QuotaContext";
import PackagesPage from "../../components/tenants/TenantPackages/PackagesPage";
import TenantsPage from "../../pages/TenantsPage";
import OneTenant from "./components/OneTenant";
import IPGroupsPage from "../../pages/IPGroupsPage";
import UserGroupsPage from "../../pages/UserGroupsPage";
import UsersPage from "../../pages/UsersPage";
import RemoteUserStatusPage from "../../pages/RemoteUserStatusPage";
import OrderedPortsPage from "../../pages/OrderedPortsPage";
import ServiceCatalogPage from "../../pages/ServiceCatalogPage";
import DashboardHeader from "../../pages/InsightsPage/components/header/DashboardHeader";

function Tenants(): ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path + "/" + PROVISIONING().path}>
        <TenantsPage />
      </Route>
      <Route path={path + "/" + USERS().path}>
        <UsersPage />
      </Route>
      <Route path={path + "/" + USER_GROUPS().path}>
        <UserGroupsPage />
      </Route>
      <Route path={path + "/" + IP_GROUPS().path}>
        <IPGroupsPage />
      </Route>
      <Route path={path + "/" + REMOTE_USERS_STATUS().path}>
        <RemoteUserStatusPage />
      </Route>
      <Route path={path + "/" + SERVICE_CATALOG().path}>
        <ServiceCatalogPage tabs={DashboardHeader} />
      </Route>
      <Route path={path + "/" + PACKAGES().path}>
        <QuotaContextContainer tenant={""}>
          <PackagesPage />
        </QuotaContextContainer>
      </Route>
      <Route path={path + "/" + ORDERS_AND_REQUESTS().path}>
        <OrderedPortsPage />
      </Route>
      <Route path={path + "/" + TENANT().path + "_:tenant_name"}>
        <OneTenant />
      </Route>
      <Route path={path}>
        <Redirect to={path + "/" + PROVISIONING().path} />
      </Route>
    </Switch>
  );
}

export default Tenants;
