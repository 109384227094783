import { FC, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import { orderedPortsTableHeader } from "./table/orderedPortsTableHeader";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { useOrderedPortsContext } from "./OrderedPortsContext";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { CustomerPortType } from "../Network/UnitedConnections/types";
import ConfirmDialog from "../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../helpers/getDialogControls";
import TableControlsOrderedPorts from "./table/TableControlsOrderedPorts";
import { PENDING_CAPITAL } from "../../helpers/consts";
import CreateASN from "./dialog/CreateASN";
import { asnTableHeader } from "./table/asnTableHeader";
import CreateL2Dialog from "./dialog/CreateL2Dialog";
import TableControlsASN from "./table/TableControlsASN";
import { Tabs } from "../../components/common/table/Tabs";

const PORTS = "ports";
const ASN = "asn";
const TABS = [
  { key: PORTS, label: "Ports" },
  { key: ASN, label: "ASNs" },
];

const OrderedPortsPageContent: FC = () => {
  const {
    listStatus,
    asnListStatus,
    list = [],
    count,
    asnList = [],
    fetchASNData,
    asnCount,
    fetchData,
    deleteCustomerPort,
    deleteRequestStatus,
    selectedOrderedPortsMode,
    approveOrDeclineASN,
    deleteASN,
  } = useOrderedPortsContext();
  const [activeTab, setActiveTab] = useState(PORTS);
  const [viewItem, setViewItem] = useState<any>();
  const [
    isViewItemControlButtonsVisible,
    setIsViewItemControlButtonsVisible,
  ] = useState<boolean>(true);
  const [connectionToDelete, setConnectionToDelete] = useState<any>();
  const [editingRowId, setEditingRowId] = useState<string | null>(null);

  const isPortsTab = activeTab === PORTS;
  const tableData = isPortsTab ? list : asnList;
  const tableCount = isPortsTab ? count : asnCount;
  const tableColumns = isPortsTab
    ? orderedPortsTableHeader()
    : asnTableHeader();
  const gridTemplate = isPortsTab
    ? "180px repeat(8, 150px) 170px 200px"
    : "180px 150px 150px 150px 250px 150px 150px 170px 200px";

  const fetchWrapper = () => {
    fetchData();
    fetchASNData();
  };

  const rowActions: ActionFuncs<CustomerPortType> | undefined = useMemo(() => {
    if (!(selectedOrderedPortsMode === PENDING_CAPITAL || activeTab === ASN)) {
      return undefined;
    }

    return {
      onCreatePlus: setViewItem,
      createText: "Approve",
      onDiveIn: (data) => {
        setViewItem(data), setIsViewItemControlButtonsVisible(false);
      },
      onDelete: (row) => setConnectionToDelete(row),
    };
  }, [selectedOrderedPortsMode, activeTab]);

  const handleConnectionDelete = async () => {
    if (!connectionToDelete) {
      return;
    }

    const { tenant_name, name } = connectionToDelete;
    const { ok } = await deleteCustomerPort(tenant_name, name);
    if (ok) {
      setConnectionToDelete(null);
    }
  };

  const handleASNDelete = async () => {
    if (!connectionToDelete) {
      return;
    }

    const { tenant_name, asn } = connectionToDelete;
    const { ok } = await deleteASN(tenant_name, asn);
    if (ok) {
      setConnectionToDelete(null);
    }
  };

  const renderConnectionToDelete = () => {
    if (activeTab === PORTS) {
      return (
        <ConfirmDialog
          status={deleteRequestStatus}
          icon={DeleteIcon}
          title="Delete Customer Port"
          message={`Are you sure you want to delete ${connectionToDelete?.name}?`}
          controls={getDeleteDialogControls({
            onDelete: handleConnectionDelete,
            onCancel: () => setConnectionToDelete(null),
          })}
        />
      );
    }
    return (
      <ConfirmDialog
        status={deleteRequestStatus}
        icon={DeleteIcon}
        title="Delete ASN Record"
        message={`Are you sure you want to delete ${connectionToDelete?.asn}?`}
        controls={getDeleteDialogControls({
          onDelete: handleASNDelete,
          onCancel: () => setConnectionToDelete(null),
        })}
      />
    );
  };

  return (
    <>
      {connectionToDelete && renderConnectionToDelete()}
      {viewItem ? (
        activeTab === PORTS ? (
          <CreateL2Dialog
            type={"add"}
            systemsList={[]}
            onClose={() => {
              setViewItem(undefined);
            }}
            data={viewItem}
          />
        ) : (
          <CreateASN
            onClose={() => {
              setViewItem(undefined);
              setIsViewItemControlButtonsVisible(true);
            }}
            onAction={approveOrDeclineASN}
            data={viewItem}
            isViewItemControlButtonsVisible={isViewItemControlButtonsVisible}
          />
        )
      ) : (
        <TableWrapper
          titleProps={{
            title: (
              <Tabs
                tabs={TABS}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            ),
            children:
              activeTab === PORTS ? (
                <TableControlsOrderedPorts />
              ) : (
                <TableControlsASN />
              ),
          }}
          dataStatus={activeTab === PORTS ? listStatus : asnListStatus}
          tablePlaceholder={
            activeTab === PORTS ? ["Ports", "Port"] : ["ASN Records", "ASN"]
          }
        >
          <Table
            fetchAction={fetchWrapper}
            data={tableData}
            count={tableCount}
            totalCount={tableCount}
            columns={tableColumns}
            header={TableHeader}
            editingRowId={editingRowId}
            isDescriptionPresent
            setEditingRowId={setEditingRowId}
            gridColumnTemplate={gridTemplate}
            rowActions={rowActions}
            isBodyScrollable
          />
        </TableWrapper>
      )}
    </>
  );
};
export default OrderedPortsPageContent;
