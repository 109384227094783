import React, { useState, useEffect, useRef } from "react";
import { useUserContext } from "../../contexts/UserContext";
import NotificationPopup from "./NotificationPopup";
import NotificationIcon from "../icons/NotificationIcon";
import styles from "./NotificationBell.module.scss";

export type Notification = {
  id: string;
  message: string;
  title: string;
  type?: string;
};

function NotificationBell() {
  const { notificationsList } = useUserContext();
  const notifications: Array<Notification> = notificationsList || [];

  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const prevCountRef = useRef<number>(notifications.length);

  useEffect(() => {
    if (notifications.length > prevCountRef.current) {
      setIsOpen(true);
    }
    prevCountRef.current = notifications.length;
  }, [notifications]);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={styles.notificationBellWrapper}>
      <button
        className={styles.notificationBell}
        onClick={handleClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <NotificationIcon />
        {notifications.length > 0 && (
          <span className={styles.notificationBellCount}>
            {notifications.length}
          </span>
        )}
        {hover && notifications.length === 0 && (
          <div className={styles.emptyTooltip}>No new notifications</div>
        )}
      </button>
      {isOpen && (
        <NotificationPopup
          notifications={notifications}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
}

export default React.memo(NotificationBell);
