import React from "react";
import ReactTooltip from "react-tooltip";
import { CellProps } from "react-table";
import styles from "./diaTableHeader.module.scss";
import { VirtualInetrfaceDIA } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import Label from "../../../../../components/common/table/Label";
import DIAConnectionIcon from "../../../../../components/icons/DIAConnectionIcon";
import LabelArray from "../../../../../components/common/table/LabelArray";
import NaaSIconLabel from "../../../../../components/icons/NaaSIconLabel";
import {
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
} from "../../../../../helpers/validators/Validator";

/* eslint-disable @typescript-eslint/no-var-requires */
const { Netmask } = require("netmask");

export const diaTableHeader = (): Array<any> => {
  const calculateIPsFromPool = (
    ipWithPrefix: string,
    count: number
  ): Array<string> => {
    if (!checkIPv4WithPrefix(ipWithPrefix)) {
      return [];
    }

    const block = new Netmask(ipWithPrefix);
    const availableIPs: Array<string> = [];
    let ipCount = 0;

    block.forEach((ip: string) => {
      if (ipCount < count) {
        availableIPs.push(ip);
        ipCount++;
      }
    });

    return availableIPs;
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({
        cell: { value },
        row: { original },
      }: CellProps<VirtualInetrfaceDIA>) => {
        return (
          <div className={styles.labelName}>
            {original?.is_dia ? <DIAConnectionIcon /> : <NaaSIconLabel />}
            <div data-tip data-for={value}>
              <Label className={styles.noBack}>{value}</Label>
            </div>
          </div>
        );
      },
    },
    {
      Header: "IP pool assigned",
      accessor: "ip_address",
      Cell: ({
        cell: { value },
        row: { original },
      }: CellProps<VirtualInetrfaceDIA>) => {
        const poolValue = original?.is_dia ? value : value?.split("/")?.[0];
        return value ? <Label>{poolValue}</Label> : null;
      },
    },
    {
      Header: "v4 IPs",
      accessor: "availableIPs",
      Cell: ({
        cell: { value: availableCount },
        row: { original },
      }: CellProps<VirtualInetrfaceDIA>) => {
        const ipPool = original.ip_address;
        const availableIPs = ipPool
          ? calculateIPsFromPool(ipPool, availableCount || 0)
          : [];

        return (
          <div>
            <div data-tip data-for={`tooltip-${original?.name}`}>
              <div className={styles.underPopupElement}>
                {" "}
                {original?.is_dia ? availableCount : null}{" "}
              </div>
            </div>
            {availableIPs.length > 0 && (
              <ReactTooltip
                id={`tooltip-${original?.name}`}
                aria-haspopup="true"
                place="top"
                type="light"
                className={styles.tooltip}
              >
                <div className={styles.tooltipTitle}>Available IPs:</div>
                {availableIPs.map((ip, idx) => (
                  <div key={idx} className={styles.tooltipValue}>
                    {ip}
                  </div>
                ))}
              </ReactTooltip>
            )}
          </div>
        );
      },
    },
    {
      Header: "GW IP",
      accessor: "ip_addresses",
      Cell: ({ cell: { value }, row: { original } }: CellProps<any>) => {
        const valuesList = original?.is_dia ? value : [];
        const ipv4 = valuesList
          ?.filter((val: string) => checkIPv4WithPrefix(val))
          ?.map((val: string) => val.replace(/\/\d+$/, ""));
        const ipv6 = valuesList
          ?.filter((val: string) => checkIPv6WithPrefix(val))
          ?.map((val: string) => val.replace(/\/\d+$/, ""));

        return (
          <div>
            <LabelArray values={ipv4} withWrap={true} />
            <LabelArray
              values={ipv6}
              singleLabelClassname={styles.ips}
              withWrap={true}
            />
          </div>
        );
      },
    },
  ];

  return columns.map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInetrfaceDIA,
  }));
};
