import React, { FC, useEffect, useMemo, useState } from "react";
// eslint-disable-next-line css-modules/no-unused-class
import cloudStyles from "./AddCloud.module.scss";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import ConnectionConfiguratorContext from "../../../../ZayoPages/Connections/ConnectionConfigurator/ConnectionConfiguratorContext";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import {
  DIA_UNITED_CONNECTIONS,
  INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS,
  CLOUD_CONNECTIONS,
  CUSTOMER_PORTS,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/consts";
import {
  ChangeConnectionsFields,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { getDataObjectCopy } from "../../../../../helpers/getDataObjectCopy";
import { useValidation } from "../../../../../helpers/validators/Validator";
import validateConnection from "../../../../../helpers/validators/ZayoConnectionValidator";
import {
  ADD,
  CANCEL,
  EDIT,
} from "../../../../../helpers/common/constantsAlias";
import DialogBtmButtons from "../../../../../components/dialogs/common/DialogBtmButtons";
import { withContexts } from "../../../../../helpers/hocs/withContexts";
import Input from "../../../../../components/common/formComponents/Input";
import styles from "./AddDirect.module.scss";
import ZPort from "./ZPort";
import { useUnitedConnectionContext } from "../../UnitedConnectionContext";
import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import { getAllVlan, VlanData } from "../Clouds/CloudsTabInfo";
import {
  AZURE_LOWERCASE,
  MBPS,
  NATIVE,
  VIRTUAL_INTERFACE_GATE,
} from "../../../../../helpers/consts";
import { calcBandwidthWithUnits } from "../../../../../helpers/calcBandwidthWithUnits";
import { parseBandwidth } from "../../../../../helpers/parseBandwidth";
import { updateConnectionsFromInventory } from "./updateConnectionsFromInventory";
import { usePricingDialog } from "../../../../../contexts/pricingDialogContext/PricingDialogContext";
import { PRICING_PRODUCTS } from "../../../../../contexts/pricingDialogContext/constants";
import usePrevious from "../../../../../helpers/hooks/usePrevious";
import ConnectionsContext, {
  useConnectionsContext,
} from "../../../../WizardToolPage/ConnectionStep/ConnectionsContext";
import { useUserContext } from "../../../../../contexts/UserContext";

type Props = {
  onClose: () => void;
  addFrom?: string;
  connectionData?: VirtualInterfaceApi;
};

const defaultExclude = [DIA_UNITED_CONNECTIONS];
const ONLY_CUSTOMER_PORT = [CLOUD_CONNECTIONS, DIA_UNITED_CONNECTIONS];
const ONLY_DIRECT = [CLOUD_CONNECTIONS, CUSTOMER_PORTS];

export type PortData = {
  max_bandwidth: string | number;
  name: string;
  vlan_id: number | typeof NATIVE;
  bw_unit: string;
  alias?: string;
  vlan_ctag_id?: number;
};

const defaultPortData = {
  max_bandwidth: "0",
  name: "",
  vlan_id: 0,
  bw_unit: MBPS,
  alias: "",
  vlan_ctag_id: 0,
};

const updatePortData = (vlan: VlanData): PortData => {
  if (!vlan) {
    return defaultPortData;
  }
  const { unit } = parseBandwidth(vlan.max_bandwidth as string);
  return {
    name: vlan.name,
    max_bandwidth: vlan.max_bandwidth,
    bw_unit: unit,
    vlan_ctag_id: vlan.vlan_ctag_id,
    vlan_id: vlan.vlan_id ?? 0,
    alias: vlan.alias,
  };
};

const isDIA = (connectionData: any) => {
  if (
    connectionData?.is_dia === true &&
    connectionData.type === VIRTUAL_INTERFACE_GATE
  ) {
    return true;
  }
  return false;
};

const isBGPDIA = (connectionData: any) => {
  if (connectionData?.name === "Use BGP") {
    return true;
  }
  return false;
};

const isRequestNewDIA = (connectionData: any) => {
  if (connectionData?.name === "Request New") {
    return true;
  }
  return false;
};

const AddDirect: FC<Props> = ({ onClose, addFrom, connectionData }) => {
  const {
    parentPorts,
    addDirectConnection,
    addDirectStatus,
    selectPort,
    selectedPort,
    regions,
    getRegions,
    customerPorts,
    getCustomerPorts,
    updateCustomerPort,
    fetchConnections,
    getTenantPorts,
    handleDirectConnection,
    deleteL2FromConnection,
  } = useUnitedConnectionContext();
  const {
    createDiaService,
    systemsList,
    fetchSystems,
  } = useConnectionsContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { openDialog } = usePricingDialog();
  const { fetchNotifications } = useUserContext();

  useEffect(() => {
    if (selectedTenant) {
      !customerPorts.length && getCustomerPorts();
      !regions.length && getRegions();
      !systemsList?.length && fetchSystems();
    }
  }, [selectedTenant]);

  // Fields
  const [fields, onChangeFields] = useFormField({
    ...INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS,
  });
  const [zFields, onChangeZFields] = useFormField({
    ...INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS,
  });

  const [zPortIsDia, setZPortIsDia] = useState<boolean>(false);
  const [isPortASelected, setIsPortASelected] = useState<boolean>(false);
  const [aPortData, setAPortData] = useState<PortData>(defaultPortData);
  const [zPortData, setZPortData] = useState<Array<PortData>>([]);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [hasPortErrors, setHasPortErrors] = useState(false);
  const selectedPortA = fields.connections[0]?.name;
  const selectedPortZ = zFields.connections[0]?.name;
  const filteredPortsForA = useMemo(() => {
    return customerPorts.filter((port) => port.name !== selectedPortZ);
  }, [customerPorts, selectedPortZ]);

  const filteredPortsForZ = useMemo(() => {
    return customerPorts.filter((port) => port.name !== selectedPortA);
  }, [customerPorts, selectedPortA]);

  const prevMaxBandwidthA = usePrevious<string>(
    fields?.connections?.[0]?.max_bandwidth,
    ""
  );
  const prevMaxBandwidthZ = usePrevious<string>(
    zFields?.connections?.[0]?.max_bandwidth,
    ""
  );

  const commonFields = useMemo(() => {
    const commonConnections = fields.connections.concat(zFields.connections);
    return {
      ...fields,
      connections: commonConnections,
    };
  }, [fields, zFields]);

  useEffect(() => {
    if (connectionData) {
      const vlansList = getAllVlan(connectionData);

      // update for 1 and more than 1 leg for ZPort
      if (vlansList.length > 1) {
        const aPort = updatePortData(vlansList[0]);
        const zPorts = vlansList.slice(1).map(updatePortData);

        setAPortData(aPort);
        setZPortData(zPorts);
        onChangeFields("name", connectionData.name);

        const updatedZConnections = zPorts.map((portData) => ({
          ...INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS.connections[0],
          name: portData.name,
          max_bandwidth: portData.max_bandwidth,
          bw_unit: portData.bw_unit,
          vlan_id: portData.vlan_id,
          vlan_ctag_id: portData.vlan_ctag_id,
          alias: portData.alias,
        }));

        onChangeZFields("connections", updatedZConnections);
      } else {
        setAPortData(updatePortData(vlansList[0]));
      }
    }
  }, [connectionData]);

  useEffect(() => {
    const maxBandwidthA = fields?.connections?.[0]?.max_bandwidth;
    const maxBandwidthZ = zFields?.connections?.[0]?.max_bandwidth;

    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    // sync A to Z bandwidth only if there is not more than 1 z-leg
    if (
      maxBandwidthA !== undefined &&
      maxBandwidthA !== prevMaxBandwidthA &&
      zFields.connections.length === 1
    ) {
      const updatedZConnections = zFields.connections.map((zConnection) => ({
        ...zConnection,
        max_bandwidth: maxBandwidthA,
      }));
      onChangeZFields("connections", updatedZConnections);
    }

    // sync Z to A bandwidth only if there is not more than 1 z-leg
    if (
      maxBandwidthZ !== undefined &&
      maxBandwidthZ !== prevMaxBandwidthZ &&
      zFields.connections.length === 1
    ) {
      const updatedAConnections = fields.connections.map((aConnection) => ({
        ...aConnection,
        max_bandwidth: maxBandwidthZ,
      }));
      onChangeFields("connections", updatedAConnections);
    }
  }, [
    fields.connections[0]?.max_bandwidth,
    zFields.connections[0]?.max_bandwidth,
    zFields.connections.length, // if more than 1 leg, do not sync
  ]);

  useEffect(() => {
    const updateConnections = (
      fields: typeof INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS,
      portData: PortData | Array<PortData>,
      onChange: (key: string, value: any) => void
    ) => {
      const updatedConnections = fields.connections.map(
        (el: FieldsType, index: number) => ({
          ...el,
          name: Array.isArray(portData) ? portData[index]?.name : portData.name,
          max_bandwidth: Array.isArray(portData)
            ? portData[index]?.max_bandwidth
            : portData.max_bandwidth,
          bw_unit: Array.isArray(portData)
            ? portData[index]?.bw_unit
            : portData.bw_unit,
          vlan_id: Array.isArray(portData)
            ? portData[index]?.vlan_id
            : portData.vlan_id,
          vlan_ctag_id: Array.isArray(portData)
            ? portData[index]?.vlan_ctag_id
            : portData.vlan_ctag_id,
          alias: Array.isArray(portData)
            ? portData[index]?.alias
            : portData.alias,
        })
      );
      onChange("connections", updatedConnections);
    };

    if (aPortData) {
      updateConnections(fields, aPortData, onChangeFields);
    }
    if (zPortData.length > 0) {
      updateConnections(zFields, zPortData, onChangeZFields);
    }
  }, [aPortData, zPortData]);

  useEffect(() => {
    if (isDIA(connectionData)) {
      const ipAddresses = connectionData?.ip_addresses || [];

      // check if we need to update the fields
      const shouldUpdate = zFields.connections.some(
        (connection, idx) =>
          connection.innerType !== DIA_UNITED_CONNECTIONS ||
          connection.max_bandwidth !== fields.connections[idx]?.max_bandwidth ||
          connection.name !== connectionData?.name ||
          JSON.stringify(connection.ip_addresses) !==
            JSON.stringify(ipAddresses)
      );

      if (shouldUpdate) {
        const updatedConnections = zFields.connections.map(
          (connection, idx) => ({
            ...connection,
            innerType: DIA_UNITED_CONNECTIONS,
            max_bandwidth:
              connection.cloudType === AZURE_LOWERCASE
                ? connection.max_bandwidth
                : fields.connections[idx]?.max_bandwidth,
            name: connectionData?.name,
            ip_addresses: ipAddresses,
          })
        );

        onChangeZFields("connections", updatedConnections);
      }
    }
  }, [connectionData, fields, zFields, onChangeZFields]);

  useEffect(() => {
    return () => {
      fields.connections[0] = {
        innerType: CUSTOMER_PORTS,
      };
    };
  }, []);

  useEffect(() => {
    const updatedFields = updateConnectionsFromInventory(
      fields,
      addFrom,
      customerPorts
    );
    onChangeFields("connections", updatedFields.connections);
  }, [addFrom, customerPorts]);

  useEffect(() => {
    const isSelected = Boolean(
      fields.connections?.[0]?.innerType !== CUSTOMER_PORTS ||
        fields.connections?.[0]?.name
    );
    setIsPortASelected(isSelected);

    const allPorts = customerPorts;
    const availableLocation = findAvailableLocation(allPorts, fields);
    zFields.connections[0].availableLocation = availableLocation;

    // cloud to dia is impossible, so change to customer ports if dia
    if (
      fields.connections[0]?.innerType === CLOUD_CONNECTIONS &&
      zFields.connections[0]?.innerType === DIA_UNITED_CONNECTIONS
    ) {
      const updatedZConnections = zFields.connections.map((connection) => ({
        ...connection,
        innerType: CUSTOMER_PORTS,
      }));
      onChangeZFields("connections", updatedZConnections);
    }
  }, [fields.connections, zFields.connections, customerPorts]);

  const findAvailableLocation = (
    allPorts: Array<any>,
    currentPortFields: any
  ) => {
    const selectedPortName = currentPortFields.connections[0].name;
    const foundPort = allPorts.find((port) => port.name === selectedPortName);
    return foundPort ? foundPort.location : undefined;
  };

  const findPortSystem = (allPorts: Array<any>, connections: any) => {
    const selectedPortName = connections[0].name;
    const foundPort = allPorts.find((port) => port.name === selectedPortName);

    return foundPort ? foundPort.system_name : undefined;
  };

  const handleZPortErrorChange = (error: boolean) => {
    setHasPortErrors(error);
  };

  // Change fields
  const handleChangeConnectionsFields: ChangeConnectionsFields = (
    idx: number,
    connectionFields: FieldsType
  ): void => {
    const connections = getDataObjectCopy(fields.connections);
    connections[idx] = { ...connectionFields };
    onChangeFields("connections", connections);

    // Sync Z port max_bandwidth value
    const newZConnections = zFields.connections.map((el: FieldsType, zIdx) => ({
      ...el,
      max_bandwidth:
        el.cloudType !== AZURE_LOWERCASE
          ? fields.connections[zIdx]?.max_bandwidth ||
            connectionFields.max_bandwidth
          : el.max_bandwidth,
      BW:
        el.cloudType === AZURE_LOWERCASE
          ? el.BW
          : fields.connections[zIdx]?.max_bandwidth ||
            connectionFields.max_bandwidth,
    }));
    onChangeZFields("connections", newZConnections);
  };

  const handleChangeZConnectionsFields: ChangeConnectionsFields = (
    idx: number,
    connectionFields: FieldsType
  ): void => {
    let updatedConnections = getDataObjectCopy(zFields.connections);
    const isInnerTypeChanged =
      zFields.connections?.[idx] &&
      connectionFields.innerType !== zFields.connections?.[idx]?.innerType;

    updatedConnections[idx] = {
      ...updatedConnections[idx],
      ...connectionFields,
    };

    // when change inner type -> delete all legs except the actual one
    // the reason is that only customer port is allowed to have more than 1 leg
    if (isInnerTypeChanged) {
      updatedConnections = [updatedConnections[updatedConnections.length - 1]];
    }
    onChangeZFields("connections", updatedConnections);

    const isZDia =
      connectionFields.innerType === DIA_UNITED_CONNECTIONS ||
      isDIA(connectionData);

    if (
      isZDia &&
      connectionFields.name !== "Use BGP" &&
      connectionFields.name !== "Request New"
    ) {
      onChangeFields("name", connectionFields.name);
      setZPortIsDia(true);
    } else {
      setZPortIsDia(false);
    }
  };

  // Change BW Fields
  const handleChangeBW = (idx: number, value: string): void => {
    const newConnections = fields.connections.map((el: FieldsType) => ({
      ...el,
      max_bandwidth: value,
    }));
    onChangeFields("connections", newConnections);
  };

  const handleChangeZBW = (idx: number, value: string): void => {
    const newConnections = zFields.connections.map((el: FieldsType) => ({
      ...el,
      max_bandwidth: value,
    }));
    onChangeZFields("connections", newConnections);
  };

  // errors
  const [errors, validate] = useValidation(validateConnection, [
    {
      ...commonFields,
      isCloudRouter: false,
      skip: ["ip_addresses"],
    },
  ]);

  const signPricingAndTerms = async (connections: Array<FieldsType>) => {
    const priceConnection: {
      diaConnection: FieldsType | undefined;
      cloudConnection: FieldsType | undefined;
      customerPort: FieldsType | undefined;
    } = {
      diaConnection: undefined,
      cloudConnection: undefined,
      customerPort: undefined,
    };
    const priceBandwidth = {
      max: 0,
      label: "",
    };
    const connectionName = fields.name;

    connections.forEach((el) => {
      const { value, unit } = parseBandwidth(el.max_bandwidth);
      const max_bandwidth = String(calcBandwidthWithUnits(value, unit));

      if (el.innerType === DIA_UNITED_CONNECTIONS)
        priceConnection.diaConnection = el;
      if (el.innerType === CLOUD_CONNECTIONS)
        priceConnection.cloudConnection = el;
      if (el.innerType === CUSTOMER_PORTS) priceConnection.customerPort = el;
      if (priceBandwidth.max < +max_bandwidth) {
        priceBandwidth.max = +max_bandwidth;
        priceBandwidth.label = el.max_bandwidth;
      }
    });

    const isCustomerPortConnection = connections.every(
      (con) => con.innerType === CUSTOMER_PORTS
    );

    let isSigned = true;

    if (priceConnection.diaConnection) {
      isSigned = (
        await openDialog(PRICING_PRODUCTS.DIA_CONNECTION, {
          ...(priceConnection.diaConnection as FieldsType),
          connectionName,
          max_bandwidth: priceBandwidth.label,
        })
      ).ok;
    }

    if (priceConnection.cloudConnection) {
      isSigned = (
        await openDialog(PRICING_PRODUCTS.DIRECT_CLOUD_LINK, {
          ...(priceConnection.cloudConnection as FieldsType),
          connectionName,
          max_bandwidth: priceBandwidth.label,
        })
      ).ok;
    }

    if (
      priceConnection.customerPort &&
      isCustomerPortConnection &&
      connections.length > 2
    ) {
      isSigned = (
        await openDialog(PRICING_PRODUCTS.DIRECT_ELAN_CONNECTION, {
          ...(priceConnection.customerPort as FieldsType),
          connectionName,
          max_bandwidth: priceBandwidth.label,
        })
      ).ok;
    } else if (priceConnection.customerPort && isCustomerPortConnection) {
      isSigned = (
        await openDialog(PRICING_PRODUCTS.DIRECT_CONNECTION, {
          ...(priceConnection.customerPort as FieldsType),
          connectionName,
          max_bandwidth: priceBandwidth.label,
        })
      ).ok;
    }

    return isSigned;
  };

  const handleAddConnections = async (
    providedConnections?: any,
    withoutSign?: boolean
  ) => {
    // check that is called properly(not as event)
    if (providedConnections?.type === "click") {
      providedConnections = undefined;
    }

    const { isOk } = validate();
    if (!selectedTenant || !isOk || hasPortErrors) return;

    const connections =
      (providedConnections as Array<FieldsType>) ||
      commonFields.connections ||
      [];

    const newConnections = connections.map((el) => {
      const { value, unit } = parseBandwidth(el.max_bandwidth);
      const max_bandwidth = String(calcBandwidthWithUnits(value, unit));

      return {
        ...el,
        max_bandwidth,
      };
    });

    if (!withoutSign) {
      const isSigned = await signPricingAndTerms(connections);

      if (!isSigned) return;
    }
    if (isBGPDIA(newConnections?.[1])) {
      const dia_service_data = {
        system_name: findPortSystem(customerPorts, newConnections),
        name: fields.name,
        ipv4_prefix_length: 31,
        ipsec_service: false,
        nat_service: true,
        snat_service: true,
        shared: false,
        bgp_asn: Number((newConnections?.[1] as any)?.asn) || undefined,
      };

      const addedVI = await createDiaService(
        dia_service_data,
        selectedTenant,
        true
      );
      if (addedVI) {
        onClose();
        const added = await addDirectConnection(
          fields.name,
          newConnections,
          addFrom
        );

        if (added) {
          onClose();
          fetchConnections();
          getTenantPorts();
          fetchNotifications(selectedTenant);
        }
      }
      return;
    }

    if (isRequestNewDIA(newConnections?.[1])) {
      const dia_service_data = {
        system: { name: findPortSystem(customerPorts, newConnections) },
        name: fields.name,
        ipv4_pool_size: (newConnections?.[1] as any)?.ipv4_pool_size,
        ipv6_pool_size: (newConnections?.[1] as any)?.ipv6_pool_size || "0",
        is_ipsec: false,
        is_nat: true,
        is_snat: true,
        shared: false,
      };

      const addedVI = await createDiaService(
        dia_service_data,
        selectedTenant,
        false
      );
      if (addedVI) {
        onClose();
        const added = await addDirectConnection(
          fields.name,
          newConnections,
          addFrom
        );

        if (added) {
          onClose();
          fetchConnections();
          getTenantPorts();
        }
        return;
      }
    }

    const added = await addDirectConnection(
      fields.name,
      newConnections,
      addFrom
    );

    if (added) {
      onClose();
      fetchConnections();
      getTenantPorts();
    }
  };

  const handleEditConnections = async () => {
    const port = fields.connections[0];
    if (port.innerType !== CUSTOMER_PORTS || hasPortErrors) return;

    const newConnections = commonFields?.connections?.filter(
      (port) => port.isForCreate
    );

    const existingConnections = commonFields?.connections?.filter(
      (port) => !port.isForCreate
    );

    const connections: Array<FieldsType> = existingConnections?.map((port) => {
      const { value, unit } = parseBandwidth(port.max_bandwidth);
      const max_bandwidth = calcBandwidthWithUnits(value, unit);

      return {
        ...port,
        max_bandwidth,
      };
    });

    const isSigned = await signPricingAndTerms([
      ...existingConnections,
      ...newConnections,
    ]);

    if (!isSigned) return;

    if (newConnections.length > 0) {
      handleDirectConnection(fields.name, newConnections, addFrom);
    }

    // update non-dia = customer ports
    const updates = await Promise.all(
      connections
        .filter((port) => port.innerType !== DIA_UNITED_CONNECTIONS)
        .map((port) =>
          updateCustomerPort(port.name, port.alias, port.max_bandwidth)
        )
    );

    if (updates.every(Boolean)) {
      onClose();
      fetchConnections();
      getTenantPorts();
      fetchNotifications(selectedTenant || "");
    }
  };

  const getRightControls = () => {
    if (connectionData) {
      return {
        okText: EDIT,
        onOk: handleEditConnections,
        cancelText: CANCEL,
        onCancel: onClose,
      };
    }
    return {
      okText: ADD,
      onOk: handleAddConnections,
      cancelText: CANCEL,
      onCancel: onClose,
    };
  };

  const calcZPortExcluded = () => {
    if (isDIA(connectionData)) {
      return ONLY_DIRECT;
    }

    const isCloud = fields?.connections?.some(
      (connection) => connection.innerType === CLOUD_CONNECTIONS
    );

    if (isCloud) {
      return defaultExclude;
    }

    if (connectionData) {
      return ONLY_CUSTOMER_PORT;
    }

    return [];
  };

  const handleDeleteL2FromConnection = async (
    idx: number,
    fields: FieldsType
  ) => {
    try {
      const isDeleted = await deleteL2FromConnection(
        fields.name,
        selectedTenant || "",
        fields.alias
      );

      if (isDeleted) {
        onClose();
      }
    } catch (error) {
      console.error("Error deleting port:", error);
    }
  };

  return (
    <div className={cloudStyles.mainBlock}>
      <div className={cloudStyles.title}>Connection</div>
      <div className={cloudStyles.wrapper}>
        <div className={styles.portLabel}>
          <div className={styles.label}>{"“A” Port"}</div>
        </div>
        <ZPort
          fields={fields.connections}
          onChange={handleChangeConnectionsFields}
          onDelete={handleDeleteL2FromConnection}
          className={styles.zPort}
          errors={errors}
          handleChangeBW={handleChangeBW}
          withoutType
          excluded={connectionData ? ONLY_CUSTOMER_PORT : defaultExclude}
          ports={filteredPortsForA}
          onErrorChange={handleZPortErrorChange}
          parentPorts={parentPorts}
          selectedPort={selectedPort}
          selectPort={selectPort}
          regions={regions}
          isEdit={Boolean(connectionData)}
          addFrom={addFrom}
          commonFields={commonFields}
          addPortDisabled={true}
        />
        <div className={styles.portLabel}>
          <div className={styles.label}>{"“Z” Port"}</div>
        </div>
        <ZPort
          fields={zFields.connections}
          onChange={handleChangeZConnectionsFields}
          onDelete={handleDeleteL2FromConnection}
          className={styles.zPort}
          errors={errors}
          excluded={calcZPortExcluded()}
          handleChangeBW={handleChangeZBW}
          ports={filteredPortsForZ}
          onErrorChange={handleZPortErrorChange}
          parentPorts={parentPorts}
          selectedPort={selectedPort}
          withoutType
          selectPort={selectPort}
          regions={regions}
          // if port A is not selected, we should block port Z fields
          disabledProp={
            !isPortASelected || (connectionData && isDIA(connectionData))
          }
          isEdit={Boolean(connectionData)}
          commonFields={commonFields}
          isZPort={true}
          addPortDisabled={
            !(
              zFields?.connections?.[0]?.innerType === CUSTOMER_PORTS &&
              fields?.connections?.[0]?.innerType === CUSTOMER_PORTS
            )
          }
        />
      </div>
      <div className={cloudStyles.footer}>
        <Input
          name="name"
          className={zPortIsDia ? styles.disabled : undefined}
          placeholder="Connection name:"
          isPlaceholderAlwaysVisible
          value={connectionData?.name || fields.name}
          id={"connectionName"}
          errorWithTooltip
          medium
          onChange={(e) => onChangeFields("name", e.target.value)}
          error={errors && errors["name"]}
          disabled={!!connectionData}
        />
        <DialogBtmButtons
          className={cloudStyles.controls}
          controls={getRightControls()}
          errorDisplay={addDirectStatus}
        />
      </div>
    </div>
  );
};

export default withContexts<Props>(AddDirect, [
  ConnectionConfiguratorContext,
  ConnectionsContext,
]);
