import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

export const elasticSearchNatRules = (
  ip: string,
  tenant: string,
  timeRange: GTimeRange
): { [key: string]: any } => {
  const gte = parseGTime(timeRange.from);
  const lte = parseGTime(timeRange.to);

  return {
    aggs: {
      "2": {
        terms: {
          field: "NAT Rule Name.keyword",
          order: {
            _count: "desc",
          },
          size: 500,
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            multi_match: {
              type: "best_fields",
              query: ip,
              lenient: true,
            },
          },
          {
            match_phrase: {
              "tenant_name.keyword": tenant,
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte,
                lte,
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};
