import { CSSProperties, FC, useEffect, useState } from "react";
import styles from "./ResultTable.module.scss";
import Table from "../../../../components/common/table/newTable/Table";
import SeacrhPlaceholder from "../SeacrhPlaceholder";
import ResultTableHeader from "./components/ResultTableHeader";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { RequestStatus, ServiceType } from "../../../../helpers/types";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import { ServicesRedirectPlaceholder } from "../../../../components/ServicesRedirectPlaceholder";

type Props = {
  title: string;
  count?: number;
  isCollapsible?: boolean;
  data?: Array<any>;
  columns: Array<any>;
  grid?: string;
  status?: RequestStatus;
  style?: CSSProperties;
  goto: string;
  serviceRedirectPlaceholderType?: ServiceType;
};

const ResultTable: FC<Props> = ({
  title,
  count,
  isCollapsible,
  data,
  columns,
  grid,
  status,
  style,
  goto,
  serviceRedirectPlaceholderType,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const handleToggleCollapse = () => setIsCollapsed((prev) => !prev);

  const isPending = status?.state === "pending";
  const isEmpty = (!data || data?.length === 0) && !isPending;

  useEffect(() => {
    if (data && data?.length === 0 && isCollapsible) {
      setIsCollapsed(true);
    }
  }, [data, isCollapsible]);

  const content = (
    <>
      {isPending && <PageLoader />}
      {isEmpty ? (
        <SeacrhPlaceholder />
      ) : (
        <Table
          columns={columns}
          header={TableHeader}
          data={data || []}
          gridColumnTemplate={grid || "1fr 1fr"}
          count={data?.length || 0}
          paginationLimit={10}
          withFullData
        />
      )}
    </>
  );

  return (
    <div className={styles.tableContainer} style={style}>
      <ResultTableHeader
        title={title}
        isCollapsible={isCollapsible}
        isCollapsed={isCollapsed}
        toggleCollapse={handleToggleCollapse}
        count={count}
        goto={goto}
      />
      {!isCollapsed && serviceRedirectPlaceholderType && (
        <ServicesRedirectPlaceholder
          type={serviceRedirectPlaceholderType}
          withoutInfo
        >
          {content}
        </ServicesRedirectPlaceholder>
      )}
      {!isCollapsed && !serviceRedirectPlaceholderType && content}
    </div>
  );
};

export default ResultTable;
