import React, { useEffect, useMemo, useState } from "react";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useConnectionsContext } from "../../../../WizardToolPage/ConnectionStep/ConnectionsContext";
import TableGrouped from "../../../../../components/common/table/newTable/TableGrouped";
import TableHeader from "../../../../../components/common/table/newTable/rows/TableHeader";
import TableWrapper from "../../../../../components/common/table/newTable/layout/TableWrapper";
import { classNames } from "../../../../../helpers/common/classNames";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./OrderLog.module.scss";
import NavigationTabs from "../../../../../components/common/navigation/NavigationTabs";
import { getGroupedHeader, getGroupedOrderLog } from "./helpers";
import Table from "../../../../../components/common/table/newTable/Table";
import { Tabs } from "./types";
import { TABLE_HEADER_BY_TAB, tabs } from "./constants";

const OrderLog = () => {
  const [activeTab, setActiveTab] = useState(Tabs.ALL);

  const { selectedTenant } = useGlobalFilterContext();
  const {
    orderLogStatus,
    fetchOrderLogList,
    orderLog,
  } = useConnectionsContext();

  useEffect(() => {
    selectedTenant && fetchOrderLogList(selectedTenant);
  }, [selectedTenant]);

  const { groupedOrderLog, isGrouped, orderLogList } = useMemo(() => {
    return getGroupedOrderLog(orderLog, activeTab);
  }, [orderLog, activeTab]);

  const empty = !Object.keys(groupedOrderLog).length && !orderLogList.length;

  return (
    <div className={styles.panelWrapper}>
      <div className={styles.tabsWrapper}>
        <NavigationTabs
          entries={tabs}
          active={activeTab}
          onClick={(path) => {
            setActiveTab(path.path as Tabs);
          }}
          className={styles.tabs}
        />
      </div>
      <div className={classNames(styles.wrapper, empty && styles.empty)}>
        <TableWrapper
          titleProps={{
            title: ``,
            noTitle: true,
          }}
          dataStatus={orderLogStatus}
          isEmpty={empty}
          isScrollable
        >
          {isGrouped ? (
            <TableGrouped
              columns={TABLE_HEADER_BY_TAB[activeTab]}
              header={TableHeader}
              data={groupedOrderLog}
              location={location}
              gridColumnTemplate={"144px 80px 140px minmax(140px, 1fr)"}
              gridTemplateRows={"auto max-content auto"}
              collapsedExtra
              collapsed={
                groupedOrderLog ? Object.keys(groupedOrderLog).slice(1) : []
              }
              withoutInitialCollapsed={
                groupedOrderLog ? Object.keys(groupedOrderLog).slice(0, 1) : []
              }
              groupClassName={styles.tableGrouped}
              getTitle={(value) => {
                const { row, className } = getGroupedHeader(activeTab, value);

                return (
                  <div
                    className={classNames(
                      styles.groupHeader,
                      styles[activeTab],
                      styles[className]
                    )}
                  >
                    {row.map((el, index) => (
                      <span
                        key={`${el?.toString()}-${index}`}
                        className={styles.groupHeaderItem}
                      >
                        {el}
                      </span>
                    ))}
                  </div>
                );
              }}
              extraTitleHeader={<></>}
            />
          ) : (
            <Table
              withoutPagination
              data={orderLogList}
              header={TableHeader}
              columns={TABLE_HEADER_BY_TAB[Tabs.ALL]}
              gridColumnTemplate={"144px 80px 150px 220px"}
            />
          )}
        </TableWrapper>
      </div>
    </div>
  );
};

export default OrderLog;
