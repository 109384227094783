import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { Cell, CellValue, Row } from "react-table";
import { classNames } from "../../../../../helpers/common/classNames";
import {
  EXPANDER,
  SETTINGS,
} from "../../../../../helpers/common/constantsAlias";
import styles from "./TableRow.module.scss";
import RowControls, { ActionFuncs } from "../RowControls";
import CheckMarkIcon from "../../../../icons/CheckMarkIcon";
import CloseIcon from "../../../../icons/CloseIcon";
import { useValidation } from "../../../../../helpers/validators/Validator";
import IconBlankButton from "../../../buttons/IconBlankButton";
import { ORDERED_PORTS_PATH } from "../../../../../helpers/consts";
import EditableRowForm from "./EditableRowForm";
import EditButton from "../../../buttons/EditButton";
import InfoIcon from "../../../../icons/InfoIcon";

type ClickFeature = {
  elementClass: string;
  clb: (row?: any) => void;
};
export type ClickFeatures = Array<ClickFeature>;

type TableRowProps<D extends { [key: string]: any }> = {
  prepareRow: (row: Row<D>) => void;
  row: Row<D>;
  actions: ActionFuncs<D>;
  isRowClickable?: boolean;
  grid: CSSProperties | undefined;
  onClick?: (row: D) => void;
  onHover?: (row?: D) => void;
  clickFeatures?: ClickFeatures;
  className?: string;
  isRowDND?: boolean;
  moveRow?: any;
  isParentRowBasic?: boolean;
  noBorder?: boolean;
  isHighlighted?: boolean;
  isFullRowExpand?: boolean;
  isDescriptionEditable?: boolean;
  description?: string;
};

function TableRow<D extends { [key: string]: any }>({
  prepareRow,
  row,
  actions,
  grid,
  onClick,
  isDescriptionEditable,
  editingRowId,
  setEditingRowId,
  clickFeatures,
  className,
  noBorder,
  isHighlighted,
  isFullRowExpand,
  description = "",
  onHover = () => undefined,
}: TableRowProps<D> & {
  editingRowId: string | null;
  setEditingRowId: (id: string | null) => void;
}) {
  prepareRow(row);
  const dropRef = React.useRef(null);
  const [hovered, setHovered] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<D>(row.original);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAlwaysHovered, setIsAlwaysHovered] = useState<boolean>(false);
  const [errors, validate] = useValidation<D>(actions.updateValidator, [
    newValue,
  ]);
  const isEditing = editingRowId === row.id;

  useEffect(() => {
    const path = window.location.pathname.split("/").filter(Boolean).pop();

    setIsAlwaysHovered(path === ORDERED_PORTS_PATH);
  }, [window.location.pathname]);

  const handleUpdate = useCallback(async () => {
    const { isOk } = validate();
    if (isOk && actions.onUpdate) {
      const success = await actions.onUpdate(newValue);
      if (success) setIsEditMode(false);
    }
  }, [validate, setIsEditMode, newValue, actions.onUpdate]);

  const tableBodyRow = classNames(
    className,
    isHighlighted && styles.highlighted,
    styles.row,
    noBorder && styles.noBorders,
    (onClick || isFullRowExpand) && !isEditMode && styles.clickableRow
  );

  const handleRowClick = (row: Row<D>, e: React.MouseEvent<HTMLElement>) => {
    if (isEditMode) return;
    if (isFullRowExpand) {
      row.toggleRowExpanded();
    }
    let elementHasFeature;
    if (clickFeatures && e.target) {
      clickFeatures.forEach((clickFeature) => {
        elementHasFeature =
          e.target instanceof HTMLElement &&
          e.target.className.includes(clickFeature.elementClass);
        if (elementHasFeature) {
          if (!e.defaultPrevented) e.preventDefault();
          clickFeature.clb(row.original);
        }
      });
    }
    if (!clickFeatures || !elementHasFeature) onClick && onClick(row.original);
  };

  return (
    <>
      <div
        ref={dropRef}
        {...row.getRowProps()}
        style={grid}
        className={tableBodyRow}
        onClick={(e) => handleRowClick(row, e)}
        onMouseEnter={() => {
          onHover(row.original);
          setHovered(true);
          actions.onHover && actions.onHover(row.original);
        }}
        onMouseLeave={() => {
          onHover(undefined);
          setHovered(false);
        }}
      >
        {row.cells.map((cell: Cell<D>) => {
          const tdStyles = classNames(
            styles.td,
            isEditMode && styles.editMode,
            cell.column.id === SETTINGS && styles.settingsCell,
            cell.column.id === EXPANDER && styles.expandCell
          );
          return (
            <div
              {...cell.getCellProps()}
              className={tdStyles}
              key={cell.column.id}
            >
              {cell.render(isEditMode ? "EditCell" : "Cell", {
                value: newValue[cell.column.id],
                onChange: (value: CellValue) => {
                  setNewValue((v) => ({ ...v, [cell.column.id]: value }));
                },
                error: errors && errors[cell.column.id],
              })}
            </div>
          );
        })}
        {isDescriptionEditable || description ? (
          <button onClick={() => setEditingRowId(isEditing ? null : row.id)}>
            {<InfoIcon />}
          </button>
        ) : null}
        {isEditMode && (
          <EditControls
            id={row.id}
            onOk={handleUpdate}
            onCancel={() => setIsEditMode(false)}
          />
        )}
        {!isEditMode &&
          !!Object.keys(actions).length &&
          (hovered || isAlwaysHovered) && (
            <RowControls
              row={row}
              actions={{
                ...actions,
                onEdit: actions.onUpdate
                  ? () => setIsEditMode(true)
                  : actions.onEdit,
              }}
            />
          )}
      </div>

      {isEditing && (
        <div>
          <EditableRowForm
            description={description}
            isDescriptionEditable={isDescriptionEditable}
            onSave={(newDescription: string) => {
              actions.onEditDescription?.({
                ...row.original,
                description: newDescription,
              });
              setEditingRowId(null);
            }}
            onCancel={() => setEditingRowId(null)}
          />
        </div>
      )}
    </>
  );
}

function EditControls({
  id,
  onOk,
  onCancel,
}: {
  id: string;
  onOk: () => void;
  onCancel: () => void;
}) {
  return (
    <div className={styles.editControls}>
      <IconBlankButton
        id={`update-${id}`}
        icon={CheckMarkIcon}
        title="Update"
        onClick={(e) => {
          e.stopPropagation();
          onOk();
        }}
      />
      <IconBlankButton
        id={`cancel-${id}`}
        icon={CloseIcon}
        title="Cancel"
        onClick={(e) => {
          e.stopPropagation();
          onCancel();
        }}
      />
    </div>
  );
}

export default TableRow;
