import { DDoSType, FWFilters } from "../../../helpers/api/apiTypes";
import { CE, ECT_0, ECT_1, NECT } from "../../../helpers/common/constantsAlias";
import {
  getPotocolName,
  getProtocolByValue,
} from "../../../helpers/getProtocolByValue";
import { PBM_UNIT, TCP_STATES } from "../../PBMPage/PBMConfig/PBMConfig";
import { CreateDDoS } from "./types";

const ECN_VALUES: { [key: string]: string } = {
  [ECT_0]: "10",
  [ECT_1]: "01",
  [CE]: "11",
  [NECT]: "00",
};

function prepareTCPFlags(flags: Array<string>): string {
  let value = "";
  let mask = "";
  flags.map((flag) => {
    const flagBit = flag === TCP_STATES.SET ? "1" : "0";
    const flagMask = flag === TCP_STATES.ANY || !flag ? "0" : "1";
    value += flagBit;
    mask += flagMask;
  });
  return `${parseInt(value, 2)}/${parseInt(mask, 2)}`;
}

export const getPreparedData = (rule: CreateDDoS): Partial<DDoSType> => {
  const {
    name,
    priority,
    description,
    ip_protocol,
    unit,
    max_rate,
    urg,
    ack,
    psh,
    rst,
    syn,
    fin,
    src_l4_port,
    dst_l4_port,
    flood_cooldown_rate,
    flood_detect_rate,
    hysteresis_seconds,
    flood_rate,
    options,
  } = rule;
  const filters_ip_protocol = getProtocolByValue(
    ip_protocol,
    !!src_l4_port || !!dst_l4_port
  );

  const otherFilters: any = [];
  [
    "src_l4_port",
    "src_network",
    "src_segment",
    "dst_segment",
    "dst_l4_port",
    "dst_network",
    "dst_group",
    "src_group",
    "is_fragmented",
    "hop_limit",
    "ttl",
    "ingress_vi",
  ].map((val) => {
    if (rule[val as keyof CreateDDoS]) {
      let values = rule[val as keyof CreateDDoS] || 0;
      if (["ttl", "hop_limit"].includes(val)) {
        const formatted = values as Array<number>;
        values = `${formatted[0]}/${formatted[1]}`;
      }
      if (values) otherFilters.push({ filter_type: val, values });
    }
  });

  if (rule.dscp || rule.esn) {
    const esn = rule?.esn || NECT;
    const values = (Number(rule.dscp) || 0).toString(2) + ECN_VALUES?.[esn];
    otherFilters.push({ filter_type: "tos", values });
  }

  const tcpFlags = prepareTCPFlags([urg, ack, psh, rst, syn, fin]);
  otherFilters.push({ filter_type: "tcp_control", values: tcpFlags });

  const [realUnit, realMaxRate] = parseUnit(unit, max_rate);

  return {
    name,
    priority,
    filters: [...filters_ip_protocol, ...otherFilters],
    description,
    unit: realUnit as string,
    max_rate: realMaxRate as number,
    flood_cooldown_rate,
    flood_detect_rate,
    hysteresis_seconds,
    flood_rate,
    options,
  };
};

type DDoSFilters = {
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  src_group: string;
  dst_group: string;
  fin: string;
  ack: string;
  urg: string;
  syn: string;
  ece: string;
  rst: string;
  cwr: string;
  psh: string;
  is_fragmented: boolean;
  hop_limit?: Array<number>;
  ttl?: Array<number>;
  tos: string;
};

export function parseTOS(tos: string): Array<string | number> {
  const esn = tos.slice(tos.length - 2);
  let esnString = "";
  Object.keys(ECN_VALUES).map((val) => {
    if (ECN_VALUES[val] === esn) esnString = val;
  });
  const dscp = parseInt(tos.slice(0, -2), 2);
  return [dscp, esnString];
}

export function parseTTL(ttl: string): Array<number> {
  const ttlRange = ttl.split("/");
  return [parseInt(ttlRange[0]), parseInt(ttlRange[1])];
}

export function parseTCPFlags(flags: string): Array<string> {
  const parsed: Array<string> = [];
  const parsedArr = flags.split("/").map((flag) => {
    return parseInt(flag).toString(2).padStart(6, "0");
  });
  const values = parsedArr[0].split("");
  const masks = parsedArr[1].split("");
  values.map((value, i) => {
    let parsedVal = TCP_STATES.ANY;
    if (value === "1" && masks[i] === "1") {
      parsedVal = TCP_STATES.SET;
    } else if (value === "0" && masks[i] === "1") {
      parsedVal = TCP_STATES.UNSET;
    }
    parsed.push(parsedVal);
  });
  return parsed;
}

export function parseUnit(
  unit: string,
  max_rate: number
): Array<string | number> {
  if (![PBM_UNIT.MBITS_PER_SECOND, PBM_UNIT.GBITS_PER_SECOND].includes(unit)) {
    return [unit, max_rate];
  }
  return [
    PBM_UNIT.KBITS_PER_SECOND,
    unit === PBM_UNIT.MBITS_PER_SECOND ? max_rate * 1000 : max_rate * 1000 ** 2,
  ];
}

export function prepareUnit(
  unit: string,
  max_rate: number
): Array<string | number> {
  if (unit !== PBM_UNIT.KBITS_PER_SECOND) {
    return [unit, max_rate];
  }
  if (!(max_rate % 1000 ** 2)) {
    return [PBM_UNIT.GBITS_PER_SECOND, max_rate / 1000 ** 2];
  }
  return [PBM_UNIT.MBITS_PER_SECOND, max_rate / 1000];
}

export const tcpCheckboxes = ["urg", "ack", "psh", "rst", "syn", "fin"];

export const getFlatData = (
  data: DDoSType | undefined
): CreateDDoS | undefined => {
  if (!data) return;
  const filters: { [key: string]: any } = {};
  data.filters.map((el: FWFilters) => {
    if (el.filter_type !== "ip_protocol") {
      if (el.filter_type === "ttl") {
        filters[el.filter_type] = parseTTL(el.values as string);
      } else {
        filters[el.filter_type] = Number.isNaN(+el.values)
          ? el.values
          : +el.values;
      }
    }
  });
  const protocols = data.filters.filter(
    (el) => el.filter_type === "ip_protocol"
  );

  let dscp, esn;
  const tos = data.filters.filter((el) => el.filter_type === "tos");
  if (tos.length > 0) {
    [dscp, esn] = parseTOS(tos[0].values as string);
  }

  let urg, ack, psh, rst, syn, fin;
  const tcp_control = data.filters.filter(
    (el) => el.filter_type === "tcp_control"
  );
  if (tcp_control.length > 0) {
    [urg, ack, psh, rst, syn, fin] = parseTCPFlags(
      tcp_control[0].values as string
    );
  }

  const [unit, max_rate] = prepareUnit(data?.unit, data?.max_rate);

  return {
    name: data?.name,
    enable: data?.enable,
    priority: data?.priority,
    description: data?.description,
    ...(filters as DDoSFilters),
    ip_protocol: getPotocolName(protocols),
    unit: unit as string,
    max_rate: max_rate as number,
    dscp: dscp as number,
    esn: esn as string,
    urg: urg || TCP_STATES.ANY,
    ack: ack || TCP_STATES.ANY,
    psh: psh || TCP_STATES.ANY,
    rst: rst || TCP_STATES.ANY,
    syn: syn || TCP_STATES.ANY,
    fin: fin || TCP_STATES.ANY,
    flood_detect_rate: data?.flood_detect_rate,
    flood_cooldown_rate: data?.flood_cooldown_rate,
    hysteresis_seconds: data?.hysteresis_seconds,
    flood_rate: data?.flood_rate,
    options: data?.options,
  };
};
