import { tenantApi } from "./api/TenantApi";

type StatusType = {
  overall: boolean;
  adminSide: boolean;
};

export type TenantStatuses = {
  firewall: StatusType;
  nat: StatusType;
  ids: StatusType;
  segments: StatusType;
  dpi: StatusType;
  sdr: StatusType;
  dns: StatusType;
  fqdn: StatusType;
  dia_fw: StatusType;
  mesh: StatusType;
};

export const getTenantServicesStatuses = async (
  tenant: string
): Promise<TenantStatuses | undefined> => {
  const adminStatuses = await tenantApi.getTenant(tenant);
  const tenantStatuses = await tenantApi.getEnabledServices(tenant);

  if (
    adminStatuses.ok &&
    adminStatuses.result &&
    tenantStatuses.ok &&
    tenantStatuses.result
  ) {
    const aStatuses: { [key: string]: any } = adminStatuses.result;
    const tStatuses = tenantStatuses.result;
    const statuses = STATUSES_MAP.reduce((acc: any, el) => {
      const aKey: any = el[0];
      const tKey: any = el[1];
      const adminStatus = aStatuses[aKey] === undefined || aStatuses[aKey];
      const overall =
        adminStatus &&
        tStatuses.services.find((el) => el.type === tKey)?.enable;
      acc[tKey] = { overall: !!overall, adminSide: !adminStatus };
      return acc;
    }, {});

    // for now always display enabled cloud router without possibility to change https://gitlab.com/insidepacket/planning/-/issues/3461
    if (
      !aStatuses["mesh"] &&
      !tStatuses.services.find((el) => el.type === "mesh")
    ) {
      statuses["mesh"] = {
        overall: true,
        adminSide: true,
      };
    }

    return statuses;
  }
};

const STATUSES_MAP = [
  ["is_firewall_service", "firewall"],
  ["is_nat_service", "nat"],
  ["is_ids_service", "ids"],
  ["is_segments_service", "segments"],
  ["is_secure_web_gw_service", "dpi"],
  ["", "sdr"],
  ["", "dns"],
  ["", "fqdn"],
  ["is_dia_fw_service", "dia_fw"],
  ["is_mesh_enabled", "mesh"],
  ["is_mesh_service", "mesh"],
];
