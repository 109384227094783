import { useEffect, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import {
  DEFAUTL_OFFSET,
  DEFAUTL_LIMIT,
} from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { getDdosTableHeader } from "./table/ddosTableHeader";
import TableControls from "../TenantsPage/table/TableControls";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import DDoSDIAConfig from "./DDoSConfig/DDoSDIAConfig";
import { ADD, EDIT } from "../../helpers/common/constantsAlias";
import { DDoSType } from "../../helpers/api/apiTypes";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import styles from "./DDoSTable.module.scss";
import { useDDoSDIAContext } from "../../contexts/servicesContext/DDoSDIAContext";
import TableGrouped from "../../components/common/table/newTable/TableGrouped";
import { VRF_INTERNET } from "../../helpers/consts";
import { useSystemsList } from "../../helpers/api/systems/useSystemsList";
import { Tabs } from "../../components/common/table/Tabs";
import { useFirewallContext } from "../../contexts/servicesContext/FirewallContext";
import { Row } from "react-table";
import { SourceInterfaces } from "./DDoSConfig/types";

const DDOS = "DDoS";
const TEMPLATES = "Templates";
const TABS = [
  { key: DDOS, label: "DDoS" },
  { key: TEMPLATES, label: "Templates" },
];

type DDoSGroup = [string | undefined, Array<Row<DDoSType>>];

const getSelectedSystems = (rows: Array<Row<DDoSType>>) =>
  rows.map((row) => row.original.system_name);
const getDDoSNameFromGroupName = (groupName: string | undefined) =>
  groupName?.split("|")?.[0];
const getSourceInterfaces = (rows: Array<Row<DDoSType>>) =>
  rows.reduce((res: SourceInterfaces, curr) => {
    const key = curr.original.system_name ?? "";
    res[key] =
      curr.original.filters
        .find((filter) => filter.filter_type === "ingress_vi")
        ?.values.toString() ?? "";

    return res;
  }, {});

const DDoSDIATable = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [showDiveIn, setShowDiveIn] = useState(false);
  const [systemList, systemListRequestStatus] = useSystemsList();
  const [activeTab, setActiveTab] = useState(DDOS);

  const { fetchUserGroups } = useFirewallContext();

  const {
    fetchList,
    ddosGroups = {},
    ddosGroupsStatus,
    removeMultiple,
    removeStatus,
  } = useDDoSDIAContext();

  const { servicesStatusRequest } = useTenantContext();

  const [selected, setSelected] = useState<DDoSGroup>();

  useEffect(() => {
    fetchUserGroups(VRF_INTERNET);
  }, []);

  useEffect(() => {
    fetchWrapper(activeTab === TEMPLATES);
  }, [activeTab]);

  const fetchWrapper = (
    isTemplate = false,
    offset = DEFAUTL_OFFSET,
    limit = DEFAUTL_LIMIT
  ) => {
    fetchList(isTemplate);
  };

  const handleClickGroup = (group: DDoSGroup): void => {
    setSelected(group);
    setShowEdit(true);
  };

  const handleClickDiveIn = (group: DDoSGroup): void => {
    setSelected(group);
    setShowDiveIn(true);
  };

  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<DDoSGroup>(
    ([groupName, rows]: DDoSGroup) =>
      removeMultiple({
        selectedSystems: getSelectedSystems(rows).filter(
          Boolean
        ) as Array<string>,
        ddosName: getDDoSNameFromGroupName(groupName),
        isTemplate: activeTab === TEMPLATES,
      }),
    removeStatus
  );

  const groupActions: ActionFuncs<DDoSGroup> = useMemo(
    () =>
      activeTab === DDOS
        ? {
            onEdit: handleClickGroup,
            onDelete: tryDelete,
          }
        : {
            onDiveIn: handleClickDiveIn,
            onDelete: tryDelete,
          },
    [handleClickGroup, tryDelete, activeTab]
  );

  if (showAdd) {
    return (
      <DDoSDIAConfig
        type={ADD}
        onClose={() => setShowAdd(false)}
        systemList={systemList}
        systemListRequestStatus={systemListRequestStatus}
      />
    );
  }

  if (showAddTemplate) {
    return (
      <DDoSDIAConfig
        type={ADD}
        onClose={() => setShowAddTemplate(false)}
        systemList={systemList}
        systemListRequestStatus={systemListRequestStatus}
        isTemplate
      />
    );
  }

  if (showEdit && selected?.[1]) {
    return (
      <DDoSDIAConfig
        type={EDIT}
        onClose={() => setShowEdit(false)}
        systemList={systemList}
        systemListRequestStatus={systemListRequestStatus}
        systems={
          getSelectedSystems(selected[1]).filter(Boolean) as Array<string>
        }
        sourceInterfaces={getSourceInterfaces(selected[1])}
        data={selected[1]
          .map((row) => row.original)
          .find((element) => !!element)}
        isTemplate={activeTab === TEMPLATES}
        groupName={getDDoSNameFromGroupName(selected[0])}
      />
    );
  }

  if (showDiveIn && selected?.[1]) {
    return (
      <DDoSDIAConfig
        type={EDIT}
        onClose={() => setShowDiveIn(false)}
        systemList={systemList}
        systemListRequestStatus={systemListRequestStatus}
        systems={
          getSelectedSystems(selected[1]).filter(Boolean) as Array<string>
        }
        sourceInterfaces={getSourceInterfaces(selected[1])}
        data={selected[1]
          .map((row) => row.original)
          .find((element) => !!element)}
        isTemplate={activeTab === TEMPLATES}
        groupName={getDDoSNameFromGroupName(selected[0])}
        viewOnly
      />
    );
  }

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"DDoS"}
        name={getDDoSNameFromGroupName(selectedToDelete?.[0])}
      />
      <TableWrapper
        titleProps={{
          title: (
            <Tabs
              tabs={TABS}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          ),
          children: (
            <TableControls
              onAdd={() =>
                activeTab === DDOS ? setShowAdd(true) : setShowAddTemplate(true)
              }
              noSearch
            />
          ),
        }}
        dataStatus={ddosGroupsStatus}
        tablePlaceholder={
          activeTab === DDOS ? ["DDoS", "DDoS"] : ["Templates", "Templates"]
        }
        isEmpty={!Object.keys(ddosGroups).length}
      >
        <TableGrouped
          fetchAction={fetchWrapper}
          data={ddosGroups}
          columns={getDdosTableHeader(activeTab === TEMPLATES)}
          header={TableHeader}
          gridColumnTemplate={
            activeTab === DDOS
              ? "140px 120px 60px 1fr 1fr 1fr 60px"
              : "140px 120px 60px 1fr 1fr 1fr 80px 60px"
          }
          groupActions={groupActions}
          contentClassName={styles.tableContent}
          collapsed={ddosGroups ? Object.keys(ddosGroups).slice(1) : []}
          extraTitleHeader="affected system(s)"
          extraTitleWrapperClassName={styles.extraTitleWrapper}
          getTitle={(value) => {
            const [name, ...descriptionArray] = value.split("|");
            const description = descriptionArray.join("");
            return (
              <div className={styles.tableGroupTitle}>
                <div className={styles.tableGroupTitleName}>
                  Rule name: {name}
                </div>
                {description && (
                  <div className={styles.tableGroupTitleDescription}>
                    Description: {description}
                  </div>
                )}
              </div>
            );
          }}
          showFullTitle
        />
      </TableWrapper>
    </>
  );
};

export default DDoSDIATable;
