import { formPathFromArray } from "../../../helpers/navigation";
import {
  APPLICATIONS,
  APPLICATIONS_STAT,
  CYBER_THREATS,
  DNS,
  DNS_THREATS,
  INSIGHTS,
  OVERIVIEW,
  TRAFFIC,
} from "../../../helpers/navigation/entries";
import { UniqueMetric } from "../types";

export const METRICS_MAP: Array<UniqueMetric> = [
  {
    metric: "Hostname",
    value: 0,
    link: formPathFromArray([INSIGHTS(), APPLICATIONS_STAT()]),
  },
  {
    metric: "DNS Hostname",
    value: 0,
    link: formPathFromArray([INSIGHTS(), APPLICATIONS(), DNS()]),
  },
  {
    metric: "Alert.signature",
    value: 0,
    link: formPathFromArray([INSIGHTS(), CYBER_THREATS(), DNS_THREATS()]),
  },
  {
    metric: "Client traffic",
    value: 0,
    link: formPathFromArray([INSIGHTS(), TRAFFIC(), OVERIVIEW()]),
  },
  {
    metric: "Server traffic",
    value: 0,
    link: formPathFromArray([INSIGHTS(), TRAFFIC(), OVERIVIEW()]),
  },
];

type ResponseType = { [key: string]: { value: number } };

export const parsingSearchUniqueMetricsResponse = (
  response: ResponseType
): Array<UniqueMetric> => {
  const result = METRICS_MAP.map((metric, i) => {
    const value = response[i + 1]?.value;
    return { ...metric, value };
  }, [] as Array<UniqueMetric>);
  return result;
};
