export const KBPS = "Kbps";
export const MBPS = "Mbps";
export const GBPS = "Gbps";
export const BANDWIDTH_UNITS_LIST: Array<string> = [MBPS, GBPS];

export const ORDERED_PORTS_PATH = "orders_and_requests";

export const PORT_VLAN_STRING = "port_vlan";
export const PORT_INTERFACE_STRING = "port_interface";
export const PORT_QINQ_STRING = "qinq_port_vlan";
export const VTEP_VNI_STRING = "vtep_vni";

export const APPLICATIONS_STRING = "applications";
export const DNS_STRING = "DNS";
export const NETWORK = "network";
export const CYBER_THREATS_STRING = "cyber_threats";
export const OTHER = "other";
export const NATIVE = "Native" as const;
export const PENDING_CAPITAL = "Pending";
export const APPROVED_CAPITAL = "Approved";
export const DECLINED_CAPITAL = "Declined";
export const ASSIGNED_CAPITAL = "Assigned";

export const BRIDGED = "bridged";
export const ROUTED = "routed";
export const VIRTUAL_INTERFACE_GATE = "Virtual Interface gate";
export const AWS_LOWERCASE = "aws";
export const AZURE_LOWERCASE = "azure";
export const GCP_LOWERCASE = "gcp";

export const VRF_INTERNET = "internet";

export const CONNECTION_DEFAULT_BANDWIDTH_VALUES = [
  "50 Mbps",
  "100 Mbps",
  "200 Mbps",
  "300 Mbps",
  "400 Mbps",
  "500 Mbps",
  "600 Mbps",
  "700 Mbps",
  "800 Mbps",
  "900 Mbps",
  "1 Gbps",
  "2 Gbps",
  "3 Gbps",
  "4 Gbps",
  "5 Gbps",
  "6 Gbps",
  "7 Gbps",
  "8 Gbps",
  "9 Gbps",
  "10 Gbps",
];

export const CONNECTION_AWS_BANDWIDTH_VALUES = [
  "50 Mbps",
  "100 Mbps",
  "200 Mbps",
  "300 Mbps",
  "400 Mbps",
  "500 Mbps",
  "1 Gbps",
  "2 Gbps",
  "5 Gbps",
  "10 Gbps",
];
