import React, { ReactElement, useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteEntry } from "../pages/types";
import {
  PORTS,
  VRF,
  VTEP,
  VIRT_INTERFACES,
  PORT_INTERFACES,
  VLAN,
  VNI,
  STATIC_ROUTES,
  BGP,
  LOGICAL_INTERFACES,
  PBR,
  PBM,
  DDoSSystem,
} from "../helpers/navigation/entries";
import VRFPage from "../components/systems/oneSystem/VRFPage";
import VLANPage from "../pages/VLANPage";
import VNIPage from "../pages/VNIPage";
import StaticRoutesPage from "../components/systems/oneSystem/StaticRoutesPage";
import BGPPage from "../components/systems/oneSystem/BGPPage";
import LogicalInterfacesPage from "../components/systems/oneSystem/LogicalInterfacesPage";
import { useMatchPath } from "../helpers/hooks/navigationHooks";
import { PathEntry } from "../helpers/types";
import { addPrefix } from "../helpers/navigation";
import { Routes } from "../pages/common";
import {
  checkIsFullSystem,
  OneSystemTabs,
} from "../components/common/navigation/OneSystemTabs";
import { useOneSystemContext } from "../contexts/systemsContext/OneSystemContext";
import VTEPPage from "../pages/VTEPPage";
import PortInterfacePage from "../pages/PortInterfacePage";
import VirtualInterfacePage from "../pages/VirtualInterfacePage";
import PortsPage from "../pages/PortsPage";
import PBRPage from "../pages/PBRPage";
import PBMPage from "../pages/PBMPage";
import DDoSSystemPage from "../pages/DDoSSystemPage";

const networkRoutes: Array<RouteEntry> = [
  { pathFunc: PORTS, component: PortsPage },
  { pathFunc: VRF, component: VRFPage },
  { pathFunc: VTEP, component: VTEPPage },
  { pathFunc: VIRT_INTERFACES, component: VirtualInterfacePage },
  { pathFunc: PORT_INTERFACES, component: PortInterfacePage },
  { pathFunc: VLAN, component: VLANPage },
  { pathFunc: VNI, component: VNIPage },
  { pathFunc: STATIC_ROUTES, component: StaticRoutesPage },
  { pathFunc: BGP, component: BGPPage },
  { pathFunc: PBR, component: PBRPage },
  { pathFunc: LOGICAL_INTERFACES, component: LogicalInterfacesPage },
];

function SystemConfigContainer(): ReactElement {
  const path = useMatchPath();

  // todo remove from here (retrieve directly, where it is needed)
  const { system, node } = useOneSystemContext();
  const routes = [...networkRoutes];
  if (system && checkIsFullSystem(node?.type)) {
    routes.push({ pathFunc: PBM, component: PBMPage });
    routes.push({ pathFunc: DDoSSystem, component: DDoSSystemPage });
  }

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  const redirectPaths: Array<{ from: string; to: string }> = [
    { from: path, to: fullPath(PORTS()) },
  ];

  return (
    <Switch>
      {redirectPaths.map(({ from, to }) => (
        <Route exact path={from} key={from}>
          <Redirect to={to} />
        </Route>
      ))}
      <Routes
        routes={routes}
        tabs={OneSystemTabs}
        pathFormer={(fn) => fullPath(fn())}
        additionParameter={system?.name}
      />
    </Switch>
  );
}
export default SystemConfigContainer;
