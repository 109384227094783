import React from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../components/common/formComponents/Input";
import Section from "../../../../../../components/common/Section";
import CopyIcon from "../../../../../../components/icons/CopyIcon";
import { copyToClipboard } from "../../../../../../helpers/copyToClipboard";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import {
  IpsecBranchAgent as Fields,
  IpsecTypeProps,
} from "../../../types/ipsec";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";

const BranchAgent = ({ className }: IpsecTypeProps) => {
  const {
    fields: contextFields,
    fieldsOnChange,
  } = useConnectionConfiguratorContext();

  const onChange = (field: keyof Fields, value: string) =>
    fieldsOnChange<Fields>(field, value);

  const fields = contextFields as Fields;

  return (
    <Section title={"Agent"} columnsCount={2} className={className}>
      <DropdownBasic
        id={"remote_type"}
        onChange={(val) => {
          onChange("remote_type", val.key);
        }}
        selected={mapStringToItem(fields.remote_type)}
        label="Remote type"
        itemsList={[]}
        isMedium
        error={""}
      />
      <Input
        label="Remote IP"
        name="remote_ip"
        placeholder="Leave empty for auto detect"
        value={fields.remote_ip}
        medium
        error={""}
        onChange={(e) => onChange("remote_ip", e.target.value)}
      />
      <Input
        label="Agent ID"
        name="remote_id"
        placeholder="Leave empty for auto detect"
        value={fields.remote_id}
        medium
        error={""}
        onChange={(e) => onChange("remote_id", e.target.value)}
        rightButtonIcon={CopyIcon}
        rightButtonClick={() => copyToClipboard(fields.remote_id)}
      />
      <Input
        label="Agent download link"
        name="agent_download_link"
        placeholder="Leave empty for auto detect"
        value={fields.agent_download_link}
        medium
        error={""}
        onChange={(e) => onChange("agent_download_link", e.target.value)}
        rightButtonIcon={CopyIcon}
        rightButtonClick={() => copyToClipboard(fields.agent_download_link)}
      />
    </Section>
  );
};

export default BranchAgent;
