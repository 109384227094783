import React, { FC, useState } from "react";
import Input from "../../../components/common/formComponents/Input";
import Section from "../../../components/common/Section";
import { CreateDDoS } from "./types";
import styles from "./GeneralSection.module.scss";
import BlankButton from "../../../components/common/buttons/BlankButton";

type Props = {
  fields: CreateDDoS;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
  maxPriority: number;
  gridTemplate: string;
  viewOnly?: boolean;
};

const GeneralSection: FC<Props> = ({
  fields,
  errors,
  onChange,
  className,
  maxPriority,
  gridTemplate,
  viewOnly,
}) => {
  const [descriptionShow, setDescriptionShow] = useState<boolean>(
    !!fields.description
  );

  const handleHideDescription = () => {
    setDescriptionShow(false);
  };

  return (
    <Section
      title={"General"}
      className={className}
      columnsCount={3}
      gridTemplateString={gridTemplate}
    >
      <div className={styles.column}>
        <Input
          label="Name"
          name="name"
          placeholder=""
          value={fields.name}
          medium
          error={errors && errors["name"]}
          handleFieldChange={onChange}
          disabled={viewOnly}
        />
        {!descriptionShow && (
          <BlankButton
            id={"desc"}
            key={"desc"}
            className={styles.descriptionButton}
            onClick={() => setDescriptionShow(true)}
            disabled={viewOnly}
          >
            {"+ Add description"}
          </BlankButton>
        )}
      </div>
      <div className={styles.column}>
        <Input
          type="number"
          className={styles.inputWrapper}
          min={1}
          max={maxPriority}
          label={"Priority"}
          name="priority"
          placeholder=""
          value={fields.priority}
          medium
          error={errors && errors["priority"]}
          handleFieldChange={onChange}
          disabled={viewOnly}
        />
      </div>
      <div className={styles.column}>
        {descriptionShow && (
          <Input
            label="Description"
            name="description"
            placeholder=""
            value={fields.description}
            medium
            error={errors && errors["description"]}
            handleFieldChange={onChange}
            isFullValue
            isOptional
            withDeleteButton
            onDelete={handleHideDescription}
            disabled={viewOnly}
          />
        )}
      </div>
    </Section>
  );
};
export default GeneralSection;
