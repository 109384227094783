import React, { FC, ReactElement } from "react";
import { classNames } from "../../helpers/common/classNames";
import styles from "./TitleWithIcon.module.scss";

type TitleWithIconProps = {
  icon: ReactElement;
  title?: string;
  isSmaller?: boolean;
};

const TitleWithIcon: FC<TitleWithIconProps> = ({ icon, title, isSmaller }) => {
  const wrapperClassNames = classNames(
    styles.wrapper,
    isSmaller && styles.smallerSize
  );
  return (
    <div className={wrapperClassNames}>
      {icon}
      <div className={styles.title} title={title}>
        {title || "—"}
      </div>
    </div>
  );
};

export default TitleWithIcon;
