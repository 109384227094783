import React, { FC, useEffect, useMemo, useState } from "react";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import { useValidation } from "../../../../../helpers/validators/Validator";
import DialogTemplate from "../../../../../components/dialogs/common/DialogTemplate";
import styles from "./ShortCreateForm.module.scss";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";
import { DIAProps } from "../../../../DIAPage/DIADialog";
import { useUnitedConnectionContext } from "../../UnitedConnectionContext";
import { RequestCustomerPort, TenantCustomerPort } from "../../types";
import validateRequestPort from "../../../../../helpers/validators/RequestPortValidator";
import DropdownWithSearch from "../../../../../components/common/Dropdown/DropdownWithSearch";
import { DropdownItem } from "../../../../../helpers/types";
import { classNames } from "../../../../../helpers/common/classNames";
import { SiteRegion } from "../../../../../helpers/api/apiTypes";
import { transferFromKbps } from "../../../../../helpers/transferFromKbps";
import { getCorrectBandwidthUnit } from "../../../../../helpers/getCorrectBandwidthUnit";
import { BuildingTable } from "./BuildingTable/BuildingTable";
import Input from "../../../../../components/common/formComponents/Input";
import SearchIcon from "../../../../../components/icons/SearchIcon";
import { GREY_FONT } from "../../../../../helpers/common/colorAliases";
import { usePricingDialog } from "../../../../../contexts/pricingDialogContext/PricingDialogContext";
import { PRICING_PRODUCTS } from "../../../../../contexts/pricingDialogContext/constants";
import { useUserContext } from "../../../../../contexts/UserContext";

export const REQUEST_LABELS = {
  building_type: "",
  naas_location: "",
  operator: "",
  building: "",
  country: "",
  city: "",
};

const REQUEST_DEFAULT_FIELDS: RequestCustomerPort = {
  name: "",
  max_bandwidth: 0,
  system_name: "system",
  labels: REQUEST_LABELS,
};

export const CONNECTION_TYPES_FOR_PORT = [
  { key: "DC", value: "Data Center" },
  { key: "FTTB", value: "Fibre" },
];

type Regions = {
  regions: Array<SiteRegion>;
  customerPorts: Array<TenantCustomerPort>;
};

type Props = Regions & DIAProps;

export const OrderPortForm: FC<Props> = ({
  onClose,
  regions,
  customerPorts,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const [initialRenderCompleted, setInitialRenderCompleted] = useState<boolean>(
    false
  );
  const { fetchNotifications } = useUserContext();
  const { requestPort, requestPortStatus } = useUnitedConnectionContext();
  const [fields, handleFieldChange] = useFormField<RequestCustomerPort>(
    REQUEST_DEFAULT_FIELDS
  );
  const [, validate] = useValidation<RequestCustomerPort>(validateRequestPort, [
    fields,
  ]);
  const [tableIsHidden, setTableIsHidden] = useState<boolean>(
    !fields.labels.building
  );
  const [regionError, setRegionError] = useState<string>("");
  const [selectedName, setSelectedName] = useState("");
  const { openDialog } = usePricingDialog();

  useEffect(() => {
    if (selectedTenant) {
      handleFieldChange("tenant", selectedTenant);
    }
    if (!fields.labels?.building_type) {
      updateLabels("building_type", CONNECTION_TYPES_FOR_PORT[0].key);
    }
  }, []);

  const getFilter = (region: SiteRegion) => {
    const [type, city, building, country] = [
      fields.labels.building_type,
      fields.labels.city,
      fields.labels.building,
      fields.labels.country,
    ];
    if (!type && !city && !building && !country) return true;
    return (
      region.connection_type === type &&
      (city && region.labels.city
        ? region.labels.city.toLowerCase().includes(city.toLowerCase())
        : true) &&
      ((building
        ? region.name.toLowerCase().includes(building.toLowerCase())
        : true) ||
        (building
          ? region.address.toLowerCase().includes(building.toLowerCase())
          : true)) &&
      (country ? region.labels?.country === country : true)
    );
  };

  const filteredRegions = useMemo(() => {
    if (selectedName) {
      return regions.filter((region) => region.name === selectedName);
    }

    return regions.filter(getFilter);
  }, [
    fields.labels.building_type,
    fields.labels.city,
    fields.labels.building,
    fields.labels.country,
    selectedName,
  ]);

  const countries = () =>
    useMemo(() => {
      const countryList: Array<string> = [];
      filteredRegions.forEach((region) => {
        if (region.labels?.country) countryList.push(region.labels.country);
      });
      return countryList;
    }, [regions.length]);

  const submitForm = async () => {
    const region = filteredRegions.find(
      (region) => region.name === fields.labels.building
    );
    if (!region || !selectedName) {
      setRegionError("Please select Building");
      return;
    }
    const building = region.name;
    // TODO: change logic to filtering by port
    const buildingConnections = customerPorts.filter(
      (item) => item.labels.building === fields.labels.building
    );
    const lastConnectionName =
      buildingConnections[buildingConnections.length - 1]?.name ?? "";
    const lastConnectionNameParts = lastConnectionName.split("_");
    const lastConnectionCount =
      lastConnectionNameParts[lastConnectionNameParts.length - 1];
    fields.name = `${building}_port_${
      lastConnectionCount !== undefined ? +lastConnectionCount + 1 : 0
    }`;
    fields.labels.building = building;
    fields.labels.naas_location = region.naas_location;
    const { isOk } = validate();

    if (!isOk) return;

    const { ok: isSigned } = await openDialog(PRICING_PRODUCTS.CUSTOMER_PORT, {
      name: fields.name,
      bandwidth: fields.max_bandwidth,
      network_status: region.network_status,
    });

    if (!isSigned) return;

    if (isOk && selectedTenant) {
      await requestPort(fields);
      if (requestPortStatus.state !== "error") {
        fetchNotifications(selectedTenant);
        onClose();
      }
    }
  };

  const updateLabels = (key: string, val: string) => {
    switch (key) {
      case "building_type":
        handleFieldChange("labels", { [key]: val });
        handleFieldChange("max_bandwidth", 0);
        !initialRenderCompleted &&
          setTimeout(() => setInitialRenderCompleted(true), 100);
        break;
      case "country":
        handleFieldChange("labels", {
          building_type: fields.labels.building_type,
          country: val,
          building: "",
        });
        handleFieldChange("max_bandwidth", 0);
        break;
      case "city":
        handleFieldChange("labels", {
          ...fields.labels,
          city: val,
          building: "",
        });
        handleFieldChange("max_bandwidth", 0);
        break;
      default:
        handleFieldChange("labels", { ...fields.labels, [key]: val });
    }
  };

  const handleChangeBuilding = (val: DropdownItem | string) => {
    const value = typeof val === "string" ? val : val.key;
    const region = filteredRegions.find((region) => region.name === value);
    if (!region) {
      updateLabels("building", value);
      return;
    }
    handleFieldChange("labels", {
      ...fields.labels,
      city: region?.labels?.city,
      country: region?.labels?.country,
      building: value,
      operator: region?.operator,
    });
    handleFieldChange("max_bandwidth", region?.handover_bw[0].bw || 0);
    if (region?.naas_systems[0]) {
      handleFieldChange("system_name", region?.naas_systems[0]);
    }
    !!regionError && setRegionError("");
    setSelectedName(value);
  };

  function getParsedBW(bw: number): DropdownItem {
    const unit = getCorrectBandwidthUnit(bw, true);
    return {
      key: bw ? bw.toString() : "",
      value: bw ? `${transferFromKbps(bw, unit)} ${unit}` : "",
    };
  }

  const leftPart = () => {
    const region = filteredRegions.find(
      (region) => region.name === fields.labels.building
    );

    const bwList =
      region?.handover_bw.map((bw) => {
        return getParsedBW(bw.bw);
      }) || [];
    return (
      <DropdownBasic
        id={"max_bandwidth"}
        selected={getParsedBW(fields.max_bandwidth)}
        onChange={(value) => {
          handleFieldChange("max_bandwidth", parseInt(value.key));
        }}
        itemsList={bwList}
        disabled={bwList.length <= 1}
        isMedium
        listClassName={classNames(styles.dropdown, styles.relative)}
        hideEmptyListPlaceholder
      />
    );
  };

  const parsedType = useMemo(() => {
    return (
      CONNECTION_TYPES_FOR_PORT.find(
        (connection) => connection.key === fields.labels?.building_type
      )?.value || ""
    );
  }, [fields.labels?.building_type]);

  return (
    <>
      <DialogTemplate
        controls={{
          okText: "Order",
          cancelText: "Cancel",
          leftPart: leftPart(),
          onOk: submitForm,
          onCancel: onClose,
        }}
        errorDisplay={requestPortStatus}
        onClose={onClose}
        title={"Order Port"}
        wrapperClassName={styles.dialog}
        containerClassName={styles.container}
        withoutLine
        noOverlay
      >
        <div className={styles.form}>
          <div className={styles.column}>
            <DropdownBasic
              id={"building_type"}
              label="Connection type"
              onChange={(val) => {
                updateLabels("building_type", val.key);
              }}
              itemsList={CONNECTION_TYPES_FOR_PORT}
              selected={
                fields?.labels?.building_type
                  ? mapStringToItem(parsedType)
                  : CONNECTION_TYPES_FOR_PORT[0]
              }
              isMedium
              listClassName={styles.dropdown}
            />
          </div>
          <div className={styles.column}>
            <DropdownBasic
              id={"country"}
              label="Country"
              onChange={(val) => {
                updateLabels("country", val.key);
              }}
              itemsList={[...new Set(countries())].map(mapStringToItem)}
              selected={mapStringToItem(fields.labels.country)}
              isMedium
              listClassName={styles.dropdown}
            />
          </div>
          <div className={styles.row}>
            <DropdownWithSearch
              selected={mapStringToItem(fields.labels.city)}
              valuesList={
                [
                  ...new Set(
                    filteredRegions.map((region) => region.labels.city)
                  ),
                ] || []
              }
              label={"City"}
              onChange={(val) =>
                updateLabels("city", typeof val === "string" ? val : val.key)
              }
              placeholder={fields.labels.city}
              id={"city"}
              error={""}
              isMedium
              listClassName={styles.dropdown}
              handleFieldChange={handleFieldChange}
              withClearIcon
              disabled={!fields.labels.country}
            />
          </div>
          <div className={styles.row}>
            <Input
              value={fields.labels.building}
              onChange={(event) => {
                handleChangeBuilding(event.target?.value || "");
                setSelectedName("");

                if (!event.target?.value) {
                  handleFieldChange("max_bandwidth", 0);
                }
              }}
              placeholder={"Building Name / Address"}
              id={"building"}
              medium
              listWrapperClass={styles.dropdown}
              onClick={() => {
                setTableIsHidden(!tableIsHidden);
              }}
              leftIcon={
                <SearchIcon color={GREY_FONT} className={styles.icon} />
              }
              error={regionError}
              errorWithTooltip
              withClearIcon
            />
          </div>
          <BuildingTable
            regions={!initialRenderCompleted ? [] : filteredRegions}
            onSelect={handleChangeBuilding}
            hide={setTableIsHidden}
            isDC={
              fields.labels.building_type === CONNECTION_TYPES_FOR_PORT[0].key
            }
          />
        </div>
      </DialogTemplate>
    </>
  );
};
