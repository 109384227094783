import React, { useEffect } from "react";
import { withWizardToolLayout } from "../../../helpers/hocs/withWizardToolLayout";
import { PageProps } from "../../../helpers/types";
import MapView from "./MapView";
import styles from "./LocationsPage.module.scss";
import StepCounter from "../common/StepCounter";
import { useLocationContext } from "./LocationContext";
import { useUserContext } from "../../../contexts/UserContext";
import { PageLoader } from "../../../components/common/loadStates/LoaderIcon";

function LocationsPage(): React.ReactElement {
  const {
    locations,
    fetchLocations,
    toggleLocation,
    selectedLocations,
  } = useLocationContext();
  const { user } = useUserContext();

  useEffect(() => {
    if (user) {
      fetchLocations(user.name);
    }
  }, [user]);

  if (!locations) return <PageLoader />;

  return (
    <div className={styles.content}>
      <StepCounter />
      <MapView
        locations={locations}
        toggleLocation={toggleLocation}
        selectedLocations={selectedLocations}
      />
    </div>
  );
}

export default withWizardToolLayout<PageProps>(LocationsPage);
