import React, { useState } from "react";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import styles from "./CreateASN.module.scss";
import Button from "../../../components/common/buttons/Button";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import ASNIcon from "../../../components/icons/ASNIcon";
import Modal from "../../../components/common/formComponents/Modal";
import ModalTextArea from "../../../components/common/formComponents/ModalTextArea";
import parseEnrichedData from "../../../helpers/ASNEnrichedDataParser";

const TABS = [
  { label: "WHOIS data", key: "whois" },
  { label: "Prefixes (IPv4 | IPv6)", key: "prefixes" },
];

const CreateASN: React.FC<{
  onClose: () => void;
  onAction: (
    tenant: string,
    name: string,
    approved: boolean,
    reason?: string
  ) => any;
  data: any;
  isViewItemControlButtonsVisible?: boolean;
}> = ({ onClose, data, onAction, isViewItemControlButtonsVisible }) => {
  const [activeTab, setActiveTab] = useState(TABS[0].key);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const { asName } = parseEnrichedData(data?.enriched_data);

  const leftColumn = (
    <SummaryInfoBlock icon={<ASNIcon />} title={"ASN"}>
      <UnifiedTextPair
        subtitle={"Timestamp"}
        text={data?.updated_at}
        textClassName={styles.leftColumnText}
      />
      <UnifiedTextPair
        subtitle={"Tenant Name"}
        text={data?.tenant_name}
        textClassName={styles.leftColumnText}
      />
      <UnifiedTextPair
        subtitle={"ASN"}
        text={data.asn}
        textClassName={styles.leftColumnText}
      />
      <UnifiedTextPair
        subtitle={"AS-Name"}
        text={asName || "-"}
        textClassName={styles.leftColumnText}
      />
    </SummaryInfoBlock>
  );

  const renderIPBlock = (
    title: string,
    list: Array<string>,
    className: string
  ) => {
    if (!list?.length) return null;

    return (
      <div className={styles.ipColumn}>
        <div className={styles.ipTitle}>{title}</div>
        <div className={styles.ipList}>
          {list.map((ip, index) => (
            <div key={index} className={className}>
              {ip}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <DialogTemplate
        leftColumn={leftColumn}
        onClose={onClose}
        contentClassName={styles.contentClassName}
        className={styles.dialogWrapper}
      >
        <div className={styles.container}>
          <div className={styles.rightColumn}>
            <div className={styles.tabContainer}>
              {TABS.map((tab) => (
                <button
                  key={tab.key}
                  className={`${styles.tab} ${
                    activeTab === tab.key ? styles.active : ""
                  }`}
                  onClick={() => setActiveTab(tab.key)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            <div className={styles.contentBlock}>
              {activeTab === "whois" ? (
                <div className={styles.whoisContent}>{data.enriched_data}</div>
              ) : (
                <div className={styles.ipContent}>
                  <div className={styles.ipContent}>
                    {renderIPBlock(
                      "IPv4",
                      data?.ipv4_prefix_list,
                      styles.ipv4Item
                    )}
                    {renderIPBlock(
                      "IPv6",
                      data?.ipv6_prefix_list,
                      styles.ipv6Item
                    )}
                  </div>
                </div>
              )}
            </div>
            {isViewItemControlButtonsVisible ? (
              <div className={styles.footer}>
                <Button onClick={() => setIsDeclineModalOpen(true)}>
                  Decline
                </Button>
                <Button
                  onClick={() => {
                    onAction(data?.tenant_name, data?.asn, true);
                    onClose();
                  }}
                  isPrimaryBtn
                >
                  Approve
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </DialogTemplate>

      {/* modal window for decline reason text */}
      {isDeclineModalOpen && (
        <Modal
          title="Reason for decline"
          onClose={() => setIsDeclineModalOpen(false)}
        >
          <ModalTextArea
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
            className={styles.textArea}
          />
          <div className={styles.modalFooter}>
            <Button onClick={() => setIsDeclineModalOpen(false)}>Cancel</Button>
            <Button
              isPrimaryBtn
              onClick={() => {
                onAction(data?.tenant_name, data?.asn, false, declineReason);
                setIsDeclineModalOpen(false);
                onClose();
              }}
            >
              Decline
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default CreateASN;
