import {
  DIAFWRuleEdit,
  FWDiaRuleType,
  FWPolicy,
} from "../../../helpers/api/apiTypes";
import { getProtocolByValue } from "../../../helpers/getProtocolByValue";
import { CreateDiaFWRule } from "./DiaFirewallRuleConfig";

export const getDiaFWPreparedData = (
  rule: CreateDiaFWRule
): Partial<FWDiaRuleType> => {
  const {
    name,
    priority,
    forwarding_policy,
    description,
    ip_protocol,
    categories,
    allowed_domains,
    denied_domains,
    src_l4_port,
    dst_l4_port,
    policy_name,
    src_dia,
    dst_dia,
  } = rule;

  const filters_ip_protocol = getProtocolByValue(
    ip_protocol,
    !!src_l4_port || !!dst_l4_port
  );

  const otherFilters: any = [];
  [
    "src_l4_port",
    "src_dia",
    "src_segment",
    "dst_segment",
    "dst_l4_port",
    "dst_dia",
    "dst_group",
    "src_group",
    "src_network",
    "dst_network",
  ].map((val) => {
    if (rule[val as keyof CreateDiaFWRule]) {
      const values = rule[val as keyof CreateDiaFWRule];
      otherFilters.push({
        filter_type: val,
        values,
      });
    }
  });

  // in case of adding for multiple dia src_dia and dst_dia will always be empty
  if (policy_name === "outgoing" && !src_dia) {
    otherFilters.push({
      filter_type: "src_dia",
      values: "",
    });
  } else if (policy_name === "incoming" && !dst_dia) {
    otherFilters.push({
      filter_type: "dst_dia",
      values: "",
    });
  }

  return {
    name,
    priority,
    filters: [...filters_ip_protocol, ...otherFilters],
    forwarding_policy: forwarding_policy as FWPolicy,
    description,
    categories,
    allowed_domains,
    denied_domains,
  };
};

export const getGateViNamesDifference = (
  oldArr: Array<string>,
  newArr: Array<string>
): DIAFWRuleEdit => {
  const add_gate_vi_names = newArr.filter((item) => !oldArr.includes(item));
  const remove_gate_vi_names = oldArr.filter((item) => !newArr.includes(item));

  return {
    gate_vi_names: newArr,
    add_gate_vi_names,
    remove_gate_vi_names,
  };
};
