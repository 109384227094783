import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import styles from "./ARPsPage.module.scss";
import ARPsTable, { ARPsTableProps } from "./ARPsTable";

type ARPsPageProps = PageProps & ARPsTableProps;

const ARPsPage = ({ tabs: Tabs, isCloud }: ARPsPageProps) => {
  return (
    <div className={styles.container}>
      <Tabs showTimeRange />
      <ARPsTable isCloud={isCloud} />
    </div>
  );
};
export default withLayout<ARPsPageProps>(ARPsPage);
