import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import styles from "./DDoSInfo.module.scss";
import PBMIcon from "../../../components/icons/tabs/PBMIcon";
import { DDoSType } from "../../../helpers/api/apiTypes";

type Props = {
  tenant?: string;
  selectedDDoS: DDoSType | undefined;
};

const DDoSInfo: FC<Props> = ({ tenant, selectedDDoS }) => {
  return (
    <SummaryInfoBlock
      icon={<PBMIcon />}
      title="DDoS"
      className={styles.bodyContainer}
    >
      {selectedDDoS && <RuleInfoSection ddos={selectedDDoS} tenant={tenant} />}
    </SummaryInfoBlock>
  );
};
export default DDoSInfo;

type InfoProps = {
  ddos: DDoSType;
  tenant?: string;
};

const RuleInfoSection = ({ tenant }: InfoProps) => {
  return (
    <>
      <UnifiedTextPair subtitle="Tenant" text={tenant} />
    </>
  );
};
