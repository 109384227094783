import React, { FC, useEffect, useState } from "react";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useConnectionsContext } from "../../../../WizardToolPage/ConnectionStep/ConnectionsContext";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import { VirtualInetrfaceDIACreate } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { useValidation } from "../../../../../helpers/validators/Validator";
import validateDIA from "../../../../../helpers/validators/DIAValidator";
import DialogTemplate from "../../../../../components/dialogs/common/DialogTemplate";
import styles from "./ShortCreateForm.module.scss";
import Input from "../../../../../components/common/formComponents/Input";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";
import { DIA_DEFAULT_FIELDS, DIAProps } from "../../../../DIAPage/DIADialog";
import LocationIcon from "../../../../../components/icons/LocationIcon";
import { GREY_DISABLED } from "../../../../../helpers/common/colorAliases";
import { DropdownItem } from "../../../../../helpers/types";
import { usePricingDialog } from "../../../../../contexts/pricingDialogContext/PricingDialogContext";
import { PRICING_PRODUCTS } from "../../../../../contexts/pricingDialogContext/constants";
import { useUserContext } from "../../../../../contexts/UserContext";

const NO_POOLS_MESSAGE =
  "No available IPs in this location. Please choose a different one.";

export const ShortCreateForm: FC<DIAProps> = ({ onClose, location }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    createDiaService,
    fetchDIAList,
    fetchPoolList,
    diaListStatus,
    systemsList = [],
    poolListIPv4 = [],
    poolListIPv6 = [],
  } = useConnectionsContext();
  const { openDialog } = usePricingDialog();
  const { fetchNotifications } = useUserContext();
  const [fields, handleFieldChange] = useFormField<VirtualInetrfaceDIACreate>(
    DIA_DEFAULT_FIELDS
  );

  const [systemName, setSystemName] = useState<string | undefined>();
  const [poolListLoading, setPoolListLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTenant) {
      handleFieldChange("tenant", selectedTenant);
    }
    if (location) {
      setSystemField(mapStringToItem(location));
    }
    if (systemsList && !location) {
      setSystemField(mapStringToItem(systemsList[0]?.location));
    }
  }, [selectedTenant, location]);

  useEffect(() => {
    if (systemName) {
      const system = systemsList?.find((system) => system.name === systemName);
      fetchPoolList(systemName, true, system?.location);
    }
  }, [systemName]);

  useEffect(() => {
    setPoolListLoading(true);
    if (diaListStatus?.state === "ok" || diaListStatus?.state === "error") {
      setPoolListLoading(false);
    }
  }, [diaListStatus]);

  useEffect(() => {
    if (poolListIPv4.length) {
      handleFieldChange("ipv4_pool_size", poolListIPv4[0]);
    } else {
      handleFieldChange("ipv4_pool_size", []);
    }
    if (poolListIPv6.length) {
      handleFieldChange("ipv6_pool_size", poolListIPv6[0]);
    } else {
      handleFieldChange("ipv6_pool_size", []);
    }
  }, [poolListIPv4, poolListIPv6]);

  const [errors, validate] = useValidation<VirtualInetrfaceDIACreate>(
    validateDIA,
    [fields]
  );

  const closeAction = () => {
    handleFieldChange("name", "");
    handleFieldChange("system", undefined);
    handleFieldChange("ipv6_pool_size", undefined);
    handleFieldChange("ipv4_pool_size", undefined);
    setSystemName(undefined);
    onClose();
  };

  const submitForm = async () => {
    const { isOk } = validate();

    if (!isOk) return;

    const { ok: isSigned } = await openDialog(PRICING_PRODUCTS.DIA, fields);

    if (!isSigned) return;

    if (isOk && selectedTenant) {
      const res = await createDiaService(fields, selectedTenant);
      if (res) {
        closeAction();
        fetchNotifications(selectedTenant);
        fetchDIAList(selectedTenant);
      }
    }
  };

  const setSystemField = (val: DropdownItem<any>) => {
    const oneSystemApi = systemsList?.find(
      (system) => system.location === val.key
    );
    if (oneSystemApi) {
      const systemField = {
        name: oneSystemApi.name,
        location: oneSystemApi.location,
      };
      setSystemName(systemField.name);
      handleFieldChange("system", systemField);
    }
  };

  const isSubmitDisabled =
    !systemName ||
    (poolListIPv6.length > 0 && !fields.ipv6_pool_size) ||
    (poolListIPv4.length > 0 && !fields.ipv4_pool_size) ||
    (!poolListIPv4.length && !poolListIPv6.length);

  // check if there is an API request in process and the list is not here yet
  // the request is called when we change a system
  const isLoading = Boolean(
    systemName && poolListLoading && poolListIPv4.length === 0
  );

  // make Location(aka system) field have unique values
  const uniqueSystems = Array.from(
    new Set(systemsList.map((key: any) => key.location))
  ).map((location) => mapStringToItem(location));

  return (
    <>
      {!location && (
        <div className={styles.top}>
          <LocationIcon color={GREY_DISABLED} />
          <span>...</span>
        </div>
      )}
      <DialogTemplate
        controls={{
          okText: "Request",
          cancelText: "Cancel",
          onOk: submitForm,
          onCancel: closeAction,
          disableSubmit: isSubmitDisabled,
        }}
        isLoading={isLoading}
        onClose={closeAction}
        title={"Request IP"}
        wrapperClassName={styles.dialog}
        containerClassName={styles.container}
        contentClassName={styles.noOverflow}
        className={styles.noOverflow}
      >
        <div className={styles.form}>
          <div className={styles.column}>
            <Input
              label="Name"
              name="name"
              placeholder=""
              value={fields.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
              medium
              error={errors?.name}
            />
            {poolListIPv4.length > 0 && systemName && (
              <DropdownBasic
                id={"ipv4PoolSize"}
                label="IPv4 Size"
                isMedium
                itemsList={poolListIPv4.map((key: any) => mapStringToItem(key))}
                onChange={(item) =>
                  handleFieldChange("ipv4_pool_size", item.key)
                }
                selected={mapStringToItem(fields.ipv4_pool_size)}
                error={errors?.ipv4_pool_size}
                disabled={poolListIPv4.length === 1}
              />
            )}
          </div>
          <div className={styles.column}>
            <DropdownBasic
              id={"system_location"}
              label="Location"
              onChange={(val) => setSystemField(val)}
              itemsList={uniqueSystems}
              selected={mapStringToItem(fields.system?.location || "")}
              isMedium
            />
            {poolListIPv6.length > 0 && systemName ? (
              <DropdownBasic
                id={"ipv6PoolSize"}
                label="IPv6 Size"
                isMedium
                wrapperClassName={styles.ipv6field}
                itemsList={poolListIPv6.map((key: any) => mapStringToItem(key))}
                onChange={(item) =>
                  handleFieldChange("ipv6_pool_size", item.key)
                }
                selected={mapStringToItem(fields.ipv6_pool_size)}
                error={errors?.ipv6_pool_size}
                disabled={poolListIPv6.length === 1}
              />
            ) : (
              systemName &&
              poolListIPv4.length === 0 &&
              poolListIPv6.length === 0 &&
              !isLoading && (
                <div className={styles.warningMessage}>{NO_POOLS_MESSAGE}</div>
              )
            )}
          </div>
        </div>
      </DialogTemplate>
    </>
  );
};
