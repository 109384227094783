import React, { FC, ReactElement, useMemo } from "react";
import { useOneSystemContext } from "../../../../contexts/systemsContext/OneSystemContext";
import { getColorFromStatus } from "../../../../helpers/common/colorHelpers";
import StatusIcon from "../../../icons/StatusIcon";
import SystemIcon from "../../../icons/SystemIcon";
import UnifiedTextPair from "../../../common/UnifiedTextPair";
import TextWithIcon from "../../../common/table/TextWithIcon";
import SummaryInformation from "../../../leftInfoBlock/SummaryInformation";
import ParametersLines from "../../../common/ParametersLines";
import TitleWithIcon from "../../../common/TitleWithIcon";
import SystemControls from "./SystemControls";
import styles from "./SystemInfo.module.scss";
import { useHAHealthContext } from "../../../../contexts/systemsContext/HAHealthContext";
import { useSystemsList } from "../../../../helpers/api/systems/useSystemsList";
import DropdownBasic from "../../../common/Dropdown/DropdownBasic";
import {
  System,
  SystemAPI,
  SystemNode,
} from "../../../../pages/Systems/Provisioning/types";
import { DropdownItem } from "../../../../helpers/types";
import { useHistory } from "react-router-dom";
import {
  DDoSSystem,
  NETWORK_RESOURCES,
  PBM,
  PORTS,
  SYSTEM,
} from "../../../../helpers/navigation/entries";
import { checkIsFullSystem } from "../../../common/navigation/OneSystemTabs";
import { getNodeFromSystem } from "../../../../helpers/getNodeFromSystem";

export const getNodeVersion = (node: SystemNode | undefined) => {
  let version = node?.version;

  if (node?.build_number) version += `-${node.build_number}`;

  return version;
};

const SystemInfo: FC = () => {
  const { system } = useOneSystemContext();
  const [systemList] = useSystemsList();
  const history = useHistory();

  const selectedIem = useMemo(
    () =>
      system
        ? { value: system.name, key: system.name, data: system }
        : undefined,
    [system]
  );
  const items = useMemo(
    () =>
      (systemList || []).map((item) => ({
        value: item.name,
        key: item.name,
        data: item,
      })),
    [systemList]
  );

  const handleChangeSystem = (
    selectedSystem: DropdownItem<System | SystemAPI>
  ) => {
    const currentPathSegments = history.location.pathname
      .split("/")
      .filter(Boolean);
    const lastPathSegment = currentPathSegments[currentPathSegments.length - 1];

    // if it's not nsos-6000 and we come from specific nsos-6000 paths like ddos or pbm,
    // then redirect to a tab that is supported
    if (
      !checkIsFullSystem((selectedSystem.data as System)?.type) &&
      (lastPathSegment === DDoSSystem().path || lastPathSegment === PBM().path)
    ) {
      currentPathSegments.splice(-1, 1, PORTS().path);
    }

    const remainingPath = currentPathSegments.slice(2).join("/");

    const networkResourcesPath = NETWORK_RESOURCES().path;
    const systemPath = SYSTEM(selectedSystem.value).path;

    const fullPath = [networkResourcesPath, systemPath, remainingPath].join(
      "/"
    );

    history.push(`/${fullPath}`);
  };

  return (
    <>
      <SummaryInformation className={styles.summaryLayout}>
        <div>
          <TitleWithIcon
            icon={<SystemIcon status={system?.status} />}
            title={system?.name}
          />
          <div className={styles.summarySystems}>
            <DropdownBasic
              id="system"
              onChange={handleChangeSystem}
              selected={selectedIem}
              itemsList={items}
              isMedium
              disabled={!system}
            />
          </div>
        </div>
        <SystemControls onEdit={() => undefined} />
        <ParametersLines params={getParameters()} />
      </SummaryInformation>
    </>
  );
};
export default SystemInfo;

export const HAHealthInfo: FC<{}> = () => {
  const { system } = useOneSystemContext();
  const { systemFromHA } = useHAHealthContext();
  const activeNode = systemFromHA?.nodes?.find(
    (node: any) => node.role === "active"
  );
  const standbyNode = systemFromHA?.nodes?.find(
    (node: any) => node.role === "standby"
  );

  return (
    <>
      <SummaryInformation className={styles.haLayout}>
        <TitleWithIcon
          icon={<SystemIcon status={system?.status} />}
          title={system?.name}
        />
        <div className={styles.location}> {system?.location} </div>
        <UnifiedTextPair subtitle="Type" text={activeNode?.type} />
        <UnifiedTextPair subtitle="Version" text={getNodeVersion(activeNode)} />
        <UnifiedTextPair
          subtitle="Status"
          text={
            <TextWithIcon
              icon={
                <StatusIcon color={getColorFromStatus(activeNode?.status)} />
              }
              text={activeNode?.status?.toUpperCase()}
            />
          }
        />
        <UnifiedTextPair subtitle="Active node" text={activeNode?.name} />
        <div className={styles.subtitle}>
          {getHealthStatus(activeNode?.health_score)}
        </div>
        {standbyNode?.name && (
          <>
            <UnifiedTextPair
              subtitle="Standby node"
              text={standbyNode?.name || "There is no standby node"}
            />
            <div className={styles.subtitle}>
              {getHealthStatus(standbyNode?.health_score)}
            </div>
          </>
        )}
      </SummaryInformation>
    </>
  );
};

const getHealthStatus = (health_score?: number) => {
  if (health_score && health_score > 90) {
    return (
      <span className={styles.healthyColor}>{`Healthy: ${health_score}`}</span>
    );
  } else {
    return (
      <span
        className={styles.unHealthyColor}
      >{`Unhealthy: ${health_score}`}</span>
    );
  }
};

const getParameters = (): ReactElement => {
  const { node } = useOneSystemContext();

  return (
    <>
      <UnifiedTextPair subtitle="Host IP" text={node?.address} />
      <UnifiedTextPair subtitle="Location" text={node?.location} />
      <UnifiedTextPair subtitle="Type" text={node?.type} />
      <UnifiedTextPair subtitle="Version" text={getNodeVersion(node)} />
      <UnifiedTextPair
        subtitle="Status"
        text={
          <TextWithIcon
            icon={<StatusIcon color={getColorFromStatus(node?.status)} />}
            text={node?.status?.toUpperCase()}
          />
        }
      />
    </>
  );
};
