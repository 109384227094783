import { ReactElement } from "react";
import Documentation from "./components/Documentation";
import License from "./components/License";
import NiroConfiguration from "./components/NiroConfiguration";
import NiroVersion from "./components/NiroVersion";
import Preferences from "./components/Preferences";
import ZayoMenu, { AdminIpamMenu } from "./components/ZayoMenu";
import Search from "./components/Search";
import Users from "./components/Users";

export type ControlsType = {
  title: string;
  component: ReactElement;
  onlyAdmin?: boolean;
  onlyTenant?: boolean;
};

export const CONTROLS_MAP: Array<ControlsType> = [
  {
    title: "Preferences",
    component: <Preferences />,
  },
  {
    title: "Search",
    component: <Search />,
  },
  { title: "Documentation", component: <Documentation /> },
  { title: "Licensing", component: <License /> },
  {
    title: "Configuration",
    component: <NiroConfiguration />,
    onlyAdmin: true,
  },
  {
    title: "IPAM",
    component: <AdminIpamMenu />,
    onlyAdmin: true,
  },
  {
    title: "Version",
    component: <NiroVersion />,
    onlyAdmin: true,
  },
];

export const ZAYO_CONTROLS_MAP: Array<ControlsType> = [
  {
    title: "Preferences",
    component: <Preferences />,
  },
  {
    title: "Search",
    component: <Search />,
  },
  { title: "Menu", component: <ZayoMenu /> },
  { title: "Documentation", component: <Documentation /> },
  { title: "Users", component: <Users /> },
  {
    title: "Version",
    component: <NiroVersion />,
    onlyAdmin: true,
  },
];

export const ZAYO_TENANT_CONTROLS_MAP: Array<ControlsType> = [
  {
    title: "Search",
    component: <Search />,
  },
  { title: "Documentation", component: <Documentation /> },
  {
    title: "Services",
    component: <Preferences />,
  },
  { title: "Users", component: <Users /> },
];
