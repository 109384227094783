import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import {
  Column,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
  useExpanded,
  Row,
  ColumnInstance,
} from "react-table";
import { classNames } from "../../../../helpers/common/classNames";
import IndeterminateCheckbox from "../IndeterminateCheckbox";
import styles from "./Table.module.scss";
import TableRow, { ClickFeatures } from "./rows/TableRow";
import { ActionFuncs } from "./RowControls";
import Paginator from "../Paginator";
import IconBlankButton from "../../buttons/IconBlankButton";
import CaretUp from "../../../icons/CaretUp";
import DeleteButton from "../../buttons/DeleteButton";
import EditButton from "../../buttons/EditButton";
import SearchIconSmall from "../../../icons/SearchIconSmall";

export const DEFAUTL_OFFSET = 0;
export const DEFAUTL_LIMIT = 20;

const DEFAULT_ROW_HEIGHT = 41;

export type TableColumnHeadersProps<D extends { [key: string]: any }> = {
  grid?: React.CSSProperties;
  className?: string;
  columns: Array<ColumnInstance<D>>;
  row?: Row<D>;
  onCollapse?: () => void;
};
export type TableProps<D extends { [key: string]: any }> = {
  fetchAction?: (offset?: number, limit?: number) => void;
  data: Array<D>;
  count?: number;
  totalCount?: number;
  withoutPagination?: boolean;
  groupActions?: ActionFuncs<[string | undefined, Array<Row<D>>]>;
  rowActions?: ActionFuncs<D>;
  header?: React.FC<TableColumnHeadersProps<D>>;
  groupHeader?: React.FC<TableColumnHeadersProps<D>>;
  columns: ReadonlyArray<Column<D>>;
  gridColumnTemplate?: string;
  noScroll?: boolean;
  onClick?: (row: D) => void; // todo remove, use rowActions
  clickFeatures?: ClickFeatures;
  onHover?: (row?: D) => void;
  handleSelectRows?: (arg: string) => void;
  withCheckbox?: boolean;
  onRowsSelect?: (rows: Array<Row<D>>) => void;
  extraTitle?: string;
  extraTitleElement?: ReactElement;
  isRowHighlighted?: (row: D) => boolean;
  rowClassName?: string;
  renderRowSubComponent?: (row: D) => ReactElement;
  noInitialRowExpand?: boolean;
  isFullRowExpand?: boolean;
  dynamicNumOfRowsPerPage?: boolean;
  withFullData?: boolean;
  collapsedExtra?: boolean;
  withoutInitialCollapsed?: boolean;
  extraTitleHeader?: string | ReactElement;
  noCollapseBtn?: boolean;
  extraContent?: ReactElement;
  extraContentClb?: (val?: string) => void;
  contentClassName?: string;
  setEditingRowId?: any;
  editingRowId?: any;
  isDescriptionEditable?: boolean;
  isBodyScrollable?: boolean;
  isDescriptionPresent?: boolean;
  paginationLimit?: number;
  groupTitle?: string;
  extraTitleWrapperClassName?: string;
  showFullTitle?: boolean;
};

export const CHECKBOX_ID = "selection";

export default function Table<D extends { [key: string]: any }>(
  props: TableProps<D>
): ReactElement {
  const {
    fetchAction,
    dynamicNumOfRowsPerPage,
    data,
    count,
    totalCount,
    withoutPagination,
    groupActions = {},
    rowActions = {},
    columns,
    gridColumnTemplate,
    // handleSelectRows,
    noScroll,
    onRowsSelect,
    withCheckbox,
    header: Header,
    groupHeader: GroupHeader,
    extraTitle,
    onClick,
    clickFeatures,
    onHover,
    rowClassName,
    isRowHighlighted = () => undefined,
    renderRowSubComponent,
    noInitialRowExpand,
    isFullRowExpand,
    withFullData,
    collapsedExtra,
    withoutInitialCollapsed,
    extraTitleHeader,
    noCollapseBtn,
    setEditingRowId,
    editingRowId,
    isDescriptionEditable,
    isDescriptionPresent,
    extraContent,
    extraContentClb,
    extraTitleElement = null,
    contentClassName,
    isBodyScrollable,
    paginationLimit,
    groupTitle,
    extraTitleWrapperClassName,
    showFullTitle,
    ...restProps
  } = props;

  const limit = paginationLimit ?? DEFAUTL_LIMIT;
  const [isCollapsed, setCollapsed] = useState(collapsedExtra);
  const [requestLimitOffset, setrequestLimitOffset] = useState({
    limit,
    offset: DEFAUTL_OFFSET,
  });

  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;
    setHeight(ref.current.offsetHeight);
    setWidth(ref.current.offsetWidth);
  }, []);

  useEffect(() => {
    if (withFullData)
      setrequestLimitOffset({
        limit,
        offset: DEFAUTL_OFFSET,
      });
  }, [data]);

  useEffect(() => {
    if (!fetchAction || !dynamicNumOfRowsPerPage) return;
    const rowsPerPage = Math.floor(height / DEFAULT_ROW_HEIGHT);
    setrequestLimitOffset((prev) => {
      return {
        limit: rowsPerPage,
        offset: prev.offset,
      };
    });
    if (!withoutPagination) {
      fetchAction(requestLimitOffset.offset, rowsPerPage);
    } else {
      fetchAction();
    }
  }, [height]);

  useEffect(() => {
    fetchAction &&
      !withoutPagination &&
      fetchAction(requestLimitOffset.offset, requestLimitOffset.limit);
  }, [requestLimitOffset.offset]);

  const {
    prepareRow,
    rows,
    selectedFlatRows,
    headers,
    toggleAllRowsExpanded,
    // state: { expanded },
  } = useTable(
    { columns, data },
    useRowState,
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      withCheckbox &&
        hooks.visibleColumns.push((columns) => [
          {
            id: CHECKBOX_ID,
            // eslint-disable-next-line react/prop-types
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: { row: Row<D> }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ]);
    }
  );
  React.useEffect(() => {
    // console.log("INS TABLE data changed", [...data]);
    const isExpand = noInitialRowExpand ? false : true;
    toggleAllRowsExpanded(isExpand);
  }, [data]);

  useEffect(() => {
    if (selectedFlatRows && selectedFlatRows.length > 0) {
      onRowsSelect && onRowsSelect(selectedFlatRows);
    }
  }, [selectedFlatRows, selectedFlatRows.length]);

  const gridStyle = {
    gridTemplateColumns:
      (withCheckbox ? "40px " : "") +
      (gridColumnTemplate || `repeat(${columns.length}, 1fr)`),
  };
  const tableStyle = classNames(styles.table, !noScroll && styles.scroll);
  const rowRender: (row: Row<D>) => React.ReactElement | null = useCallback(
    (row: Row<D>) => {
      const rowDescription =
        (isDescriptionPresent || isDescriptionEditable) &&
        (row.original.description || row.original.labels?.reason_for_decline);
      prepareRow(row);
      if (row.canExpand && row.depth === 0 && GroupHeader) {
        return <GroupHeader key={row.id} row={row} columns={headers} />;
      }
      if (row.canExpand && row.depth === 1) {
        return (
          <div
            key={row.id}
            className={styles.emptyRow}
            style={{ height: 0, minHeight: 0 }}
          />
        );
      }

      return (
        <>
          <TableRow
            isFullRowExpand={isFullRowExpand}
            onClick={onClick}
            clickFeatures={clickFeatures}
            key={row.original?.id ?? row.id}
            prepareRow={prepareRow}
            row={row}
            actions={rowActions}
            description={rowDescription}
            isDescriptionEditable={isDescriptionEditable}
            setEditingRowId={setEditingRowId}
            editingRowId={editingRowId}
            grid={gridStyle}
            noBorder={row.depth === 2}
            isHighlighted={Boolean(
              isRowHighlighted(row.original) ||
                (row.isExpanded && renderRowSubComponent)
            )}
            className={rowClassName}
            onHover={onHover}
          />
          {row.isExpanded && renderRowSubComponent
            ? renderRowSubComponent(row.original)
            : null}
        </>
      );
    },
    [prepareRow, gridStyle, restProps]
  );
  const handlePrevPage = () => {
    setrequestLimitOffset((prev) => {
      return {
        limit,
        offset: prev.offset - limit,
      };
    });
  };

  const handleClick = () => {
    extraContentClb?.(extraTitle);
    setCollapsed(false);
  };

  const handleNextPage = () => {
    setrequestLimitOffset((prev) => {
      return {
        limit,
        offset: prev.offset + limit,
      };
    });
  };
  const pageStartRowIndex = requestLimitOffset.offset + 1;
  const rowsSlice = withFullData
    ? rows.slice(
        requestLimitOffset.offset,
        requestLimitOffset.limit + requestLimitOffset.offset
      )
    : rows;
  let pageEndRowIndex =
    rowsSlice.filter((row) => row.depth === 0).length +
    requestLimitOffset.offset;
  pageEndRowIndex =
    totalCount && pageEndRowIndex > Number(totalCount)
      ? totalCount
      : pageEndRowIndex;
  const hasNext = totalCount
    ? requestLimitOffset.offset + Number(count) < Number(totalCount)
    : pageEndRowIndex < Number(count);
  const getExtraTitle = () => {
    return collapsedExtra || withoutInitialCollapsed || showFullTitle ? (
      <div
        className={classNames(
          styles.extraTitleWrapper,
          extraTitleWrapperClassName,
          "table-extraTitleWrapper"
        )}
      >
        <div className={classNames(styles.extraTitle, "table-extraTitle")}>
          {extraTitle}
          {extraTitleElement}
        </div>
        <div className={styles.right}>
          {typeof extraTitleHeader === "string" || !extraTitleHeader ? (
            <span
              className={classNames(noCollapseBtn && styles.rightWithOutCarret)}
            >
              {rowsSlice.length} {extraTitleHeader || "rows"}
            </span>
          ) : (
            <div className={styles.right} onClick={handleClick}>
              {extraTitleHeader}
            </div>
          )}
          {!noCollapseBtn && (
            <IconBlankButton
              id={"extraTitleCollapse"}
              onClick={() => setCollapsed((c) => !c)}
              className={classNames(
                styles.collapseBtn,
                !isCollapsed && styles.rotate
              )}
              icon={CaretUp}
            />
          )}
          {groupActions && (
            <div className={styles.groupActions}>
              {groupActions.onEdit && (
                <EditButton
                  id="updateGroupBtn"
                  onClick={() => groupActions.onEdit?.([groupTitle, rows])}
                />
              )}
              {groupActions.onDiveIn && (
                <IconBlankButton
                  id="diveInGroupBtn"
                  title={"Dive in"}
                  onClick={(e) => {
                    e.stopPropagation();
                    groupActions.onDiveIn?.([groupTitle, rows]);
                  }}
                  icon={SearchIconSmall}
                />
              )}
              {groupActions.onDelete && (
                <DeleteButton
                  id="deleteGroupBtn"
                  onClick={() => groupActions.onDelete?.([groupTitle, rows])}
                />
              )}
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.extraTitle}>{extraTitle}</div>
    );
  };

  return (
    <>
      <div className={tableStyle}>
        <div className={classNames(styles.tableContent, contentClassName)}>
          {Header && (
            <Header
              columns={headers}
              grid={gridStyle}
              onCollapse={() => setCollapsed((c) => !c)}
            />
          )}
          {extraTitle && getExtraTitle()}
          {!isCollapsed && (
            <div
              ref={ref}
              className={classNames(
                classNames(
                  styles.tableBody,
                  !noScroll && styles.scroll,
                  extraTitle && styles.withExtraHeader,
                  isBodyScrollable && styles.tableBodyScrollable
                )
              )}
            >
              {rowsSlice.map(rowRender)}
            </div>
          )}
        </div>
      </div>
      {extraContent}
      {!withoutPagination && (
        <Paginator
          pageStartRowIndex={pageStartRowIndex}
          pageEndRowIndex={pageEndRowIndex}
          rowsAmount={totalCount || count}
          canPreviousPage={pageStartRowIndex !== 1}
          canNextPage={hasNext}
          nextPage={handleNextPage}
          previousPage={handlePrevPage}
        />
      )}
    </>
  );
}
