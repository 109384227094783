import React, { FC, useEffect, useState } from "react";
import styles from "./PortsTabInfo.module.scss";

import { useUnitedConnectionContext } from "../../UnitedConnectionContext";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import ConfirmDeleteDialog from "../../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { TenantCustomerPort } from "../../types";
import NoDataPlaceholder from "../../../../../components/common/loadStates/NoDataPlaceholder";
import EmptyPortsPlaceholderIcon from "../../../../../components/icons/EmptyPortsPlaceholderIcon";
import { PortItem } from "./PortItem/PortItem";
import { OrderPortForm } from "./ShortCreateForm";
import { useUserContext } from "../../../../../contexts/UserContext";

type Props = {
  onAction: (val?: boolean) => void;
  isRequest: boolean;
  addConnectionCallback: (val: string) => void;
};

const PortsTabInfo: FC<Props> = ({
  onAction,
  isRequest,
  addConnectionCallback,
}) => {
  const {
    getTenantPorts,
    portsFetchStatus,
    customerPorts,
    deleteCustomerPort,
    requestPortStatus,
    connections,
    parentPorts,
    ports,
    regions,
  } = useUnitedConnectionContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchNotifications } = useUserContext();

  useEffect(() => {
    getTenantPorts();
  }, [selectedTenant]);

  const [selectedPort, setSelectedPort] = useState<
    TenantCustomerPort | undefined
  >();

  const closeForm = () => {
    onAction(false);
  };

  if (portsFetchStatus?.state === "pending" || !portsFetchStatus) {
    return <PageLoader />;
  }

  const customerLocationPorts = customerPorts.filter(
    (port) => port.type === "customer_location"
  );

  const handleDelete = async () => {
    if (selectedTenant && !!selectedPort) {
      await deleteCustomerPort(selectedPort.name, selectedPort.type);
      setSelectedPort(undefined);
      fetchNotifications(selectedTenant);
    }
  };

  if (isRequest) {
    return (
      <OrderPortForm
        onClose={closeForm}
        onAdd={closeForm}
        location={undefined}
        regions={regions}
        customerPorts={customerPorts}
      />
    );
  }

  if (customerLocationPorts.length === 0) {
    return (
      <NoDataPlaceholder
        title={"There are no customer location ports yet"}
        subTitle="Use the appropriate button to add a port."
        customIcon={<EmptyPortsPlaceholderIcon />}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <ConfirmDeleteDialog
        isShown={!!selectedPort}
        onOk={handleDelete}
        onCancel={() => setSelectedPort(undefined)}
        deviceType={"Customer Location Port"}
        name={`${selectedPort?.name || ""}`}
        status={requestPortStatus}
      />
      {customerLocationPorts.map((port, i) => (
        <PortItem
          key={port.name}
          port={port}
          i={i}
          skipOpened={false}
          addConnectionCallback={addConnectionCallback}
          parents={parentPorts}
          connections={connections}
          selectPortToDelete={setSelectedPort}
          l2Ports={ports}
        />
      ))}
    </div>
  );
};

export default PortsTabInfo;
