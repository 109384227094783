import React, { FC, useMemo, useState } from "react";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../components/common/formComponents/Input";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import { ADMIN_STATE } from "../../../helpers/common/constantsAlias";
import { formFieldProps } from "../../../helpers/dialogs/FieldProps";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { NewDialogProps } from "../../../helpers/types";
import { useValidation } from "../../../helpers/validators/Validator";
import validateVNI from "../../../helpers/validators/VNIValidator";
import { VtepVni } from "../types";
import SummaryBlock from "./DialogSummaryBlock";

import styles from "./VNIDialog.module.scss";
import VIDropdown from "../../../components/common/OneSystem/VIDropdown";
import VRFDropdown from "../../../components/common/OneSystem/VRFDropdown";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
import { OneSystemType, VRFType } from "../../../helpers/api/apiTypes";
import VTEPDropdown from "../../../components/common/OneSystem/VTEPDropdown";
import { withContexts } from "../../../helpers/hocs/withContexts";
import VNIDialogContextContainer, {
  useVNIDialogContext,
} from "./VNIDIalogContext";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";

const DEFAULT_FIELDS: VtepVni = {
  administrative_state: "up",
  mtu: 9000,
  max_bandwidth: 0,
  virtual_interface_id: 0,
  virtual_interface_name: "",
  vrf_name: "",
  alias: "",
  id: 0,
  name: "",
  operational_state: "up",
  vni_id: 0,
  vtep_id: 0,
  vtep_name: "",
  evpn_type: "VXLAN_L2",
  tunnels: [],
};

const VNIDialog: FC<NewDialogProps<VtepVni>> = ({ onClose }) => {
  const { add, addStatus } = useVNIDialogContext();
  const { system } = useOneSystemContext();

  const [fields, handleFieldChange] = useFormField<VtepVni>(DEFAULT_FIELDS);
  const [errors, validate] = useValidation<VtepVni>(validateVNI, [fields]);

  const [activeVRF, setActiveVRF] = useState<VRFType | undefined>();
  const [activeVI, setActiveVI] = useState<Partial<VirtualInterfaceApi>>();
  const [activeVtep, setActiveVtep] = useState<{
    name: string;
    id: string | number;
  }>();

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();

    if (isOk && fields.vni_id) {
      fields.vni_id = +fields.vni_id;
      const success = await add(fields);
      if (success) onClose();
    }
  };

  const handleSetVRF = (val?: VRFType): void => {
    if (!val) return;
    handleFieldChange("vrf_name", val.name);
    setActiveVRF(val);
  };

  const handleSetVI = (val?: Partial<VirtualInterfaceApi>): void => {
    if (!val) return;
    handleFieldChange("virtual_interface_id", val.id);
    handleFieldChange("virtual_interface_name", val.name);
    setActiveVI({ name: val.name, id: val.id });
  };

  const handleSetVtep = (val?: { name: string; id: string | number }): void => {
    if (!val) return;
    handleFieldChange("vtep_id", val.id);
    handleFieldChange("vtep_name", val.name);
    setActiveVtep({ name: val.name, id: val.id });
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const leftColumn = <SummaryBlock title={"VTEP-VNI"} data={fields} />;

  return (
    <DialogTemplate
      title={"Add Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={addStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <Input {...fieldProps("alias")} label="Port Name" />
      <DropdownBasic
        id={"VNIDialogAdminState"}
        onChange={(val) => handleFieldChange("administrative_state", val.key)}
        selected={mapStringToItem(fields.administrative_state + "")}
        label="Admin State"
        itemsList={ADMIN_STATE.map(mapStringToItem)}
        isMedium
      />
      <Input {...fieldProps("vni_id")} label="VNI" />
      <VRFDropdown
        system={system as OneSystemType}
        selected={activeVRF}
        onSelect={(val) => handleSetVRF(val)}
      />
      <VTEPDropdown
        system={system as OneSystemType}
        selected={activeVtep}
        onSelect={(val) => handleSetVtep(val)}
      />
      <VIDropdown
        systemName={system?.name}
        vrf={activeVRF}
        selected={activeVI}
        onSelect={(val) => handleSetVI(val)}
      />
    </DialogTemplate>
  );
};

export default withContexts<NewDialogProps<VtepVni>>(VNIDialog, [
  VNIDialogContextContainer,
]);
