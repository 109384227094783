import React, { FC, useState } from "react";
import { useBGPServicesContext } from "../../ServicesBGPContext";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import { useValidation } from "../../../../helpers/validators/Validator";
import { validateNeighbor } from "../../../../helpers/validators/BGPServiceValidator";
import styles from "./ServiceBGPConfig.module.scss";
import GeneralSection from "./components/GeneralSection";
import AddressFamilySection from "./components/AddressFamilySection";
import TipsBlock from "./common/TipsBlock";
import BGPInfo from "./common/BGPInfo";
import BGPConfigFooter from "./common/BGPConfigFooter";
import AdvancedSection from "./components/AdvancedSection";
import { BGPNeighborAdd, RouteFiltersFields } from "../../types";
import { useHistory } from "react-router-dom";
import { withLayout } from "../../../../helpers/hocs/withLayout";

const DEFAULT_FIELDS: Partial<BGPNeighborAdd> = {
  max_pfx_in: 1000000,
  max_pfx_out: 1000000,
  shutdown: false,
  keepalive: 60,
  hold: 180,
  open_delay: 0,
};

type ServiceBGPConfigAddProps = {
  isCloud?: boolean;
};

const ServiceBGPConfigAdd = ({ isCloud = false }: ServiceBGPConfigAddProps) => {
  const history = useHistory();
  const { addNeighbor, addStatus, resetStatus } = useBGPServicesContext();
  const [routeFilters, setRouteFilters] = useState<RouteFiltersFields>({
    in: "allow-all",
    out: "allow-all",
  });
  const [fields, handleFieldChange] = useFormField<Partial<BGPNeighborAdd>>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<Partial<BGPNeighborAdd>>(
    validateNeighbor,
    [fields]
  );
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);

  const onClose = () => {
    history.goBack();
  };

  const handleChangeRouteFilters = (key: string, value: string) => {
    const newValues = { ...routeFilters, [key]: value };
    setRouteFilters(newValues);
  };

  const handleSubmit = async () => {
    const { isOk } = validate();
    const selectedTenant = localStorage.getItem("selectedTenant");
    if (isOk && selectedTenant) {
      const pfx_list_in =
        routeFilters.in === "allow-all" ? "" : routeFilters.in;
      const pfx_list_out =
        routeFilters.out === "allow-all" ? "" : routeFilters.out;
      const success = await addNeighbor(
        selectedTenant,
        {
          ...fields,
          pfx_list_in,
          pfx_list_out,
        },
        isCloud
      );
      if (success) onClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  return (
    <div className={styles.content}>
      <BGPInfo
        fields={{ neighbor: fields.remote_router_ip }}
        className={styles.basicBlock}
        shutDown={fields.shutdown}
        toggleShutdown={() => handleFieldChange("shutdown", !fields.shutdown)}
      />
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <GeneralSection
            className={styles.sectionWrapper}
            fields={fields}
            onChange={handleFieldChange}
            errors={errors}
          />
          <AddressFamilySection
            className={styles.sectionWrapper}
            fields={fields}
            onChange={handleFieldChange}
            errors={errors}
            routeFilters={routeFilters}
            onChangeRouteFilters={handleChangeRouteFilters}
          />
          {isAdvanced && (
            <AdvancedSection
              className={styles.sectionWrapper}
              fields={fields}
              onChange={handleFieldChange}
              errors={errors}
            />
          )}
        </div>
        <BGPConfigFooter
          isAddMode={true}
          onAdd={handleSubmit}
          onClose={handleClose}
          className={styles.footerWrapper}
          status={addStatus}
          isAdvanced={isAdvanced}
          onToggleAdvanced={() => setIsAdvanced((prev) => !prev)}
        />
      </div>
      <TipsBlock className={styles.basicBlock} />
    </div>
  );
};

export default withLayout<ServiceBGPConfigAddProps>(ServiceBGPConfigAdd);
