import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import DashboardContextContainer from "../../../../contexts/dashboardContext/DashboardContext";
import OneSystemContextContainer from "../../../../contexts/systemsContext/OneSystemContext";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { ExportToCsv } from "export-to-csv";
import {
  MantineReactTable,
  MRT_Row,
  MRT_SortingState,
  MRT_Virtualizer,
  MRT_ColumnFiltersState,
} from "mantine-react-table";
import { elasticApi } from "../../../../helpers/api/ElasticApi";
import BlankButton from "../../../../components/common/buttons/BlankButton";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";

import moment from "moment";

import styles from "./autosortTable.module.scss";
import { getMsFromDate } from "../../charts/GraphanaTableLinks";

const TABLE_HEADER_HEIGHT = 56;

type AutoSortTableProps = {
  csvTitle: string;
  columns: any;
  tableRequest: () => Promise<any>;
  formatter: (resultArr: any) => any;
  skipBorder?: boolean;
  dropDownFilterContent?: ReactElement;
  syntheticRefreshParam?: any;
};
interface AutoSortTablePhysicProps extends AutoSortTableProps {
  height: number;
  width: number;
  skipBorder?: boolean;
}

const AutoSortPhysic = ({
  height,
  width,
  skipBorder,
  csvTitle,
  tableRequest,
  formatter,
  columns,
  dropDownFilterContent,
  syntheticRefreshParam,
}: AutoSortTablePhysicProps) => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  // const [globalFilter, setGlobalFilter] = useState<string>();
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [data, setData] = useState();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    handleCall();
  }, []);

  useEffect(() => {
    handleCall();
  }, [selectedTenant, syntheticRefreshParam]);

  useEffect(() => {
    mergeColumnFilters(() => []);
  }, [timeRange]);

  const mergeColumnFilters = (filtersArrF: any) => {
    const filtersRaw = [...filtersArrF()];
    const { from, to } = getMsFromDate(timeRange.general);

    let wasTimeFilterUpdated = false;
    filtersRaw.forEach((element: any) => {
      if (element.id === "date") {
        element.value = { from, to };
        wasTimeFilterUpdated = true;
      }
    });
    if (!wasTimeFilterUpdated) {
      filtersRaw.push({ id: "date", value: { from, to } });
    }
    // console.log("filtersRaw", { ...filtersRaw });
    setColumnFilters(filtersRaw);
  };
  const handleCall = async () => {
    const { ok, result } = await tableRequest.bind(elasticApi)();
    if (ok) {
      setData(formatter(result));
    }
  };

  const csvOptions = {
    filename: csvTitle,
    title: csvTitle,
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c: any) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows: Array<MRT_Row<{}>>) => {
    const reRows = rows.map((row) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (row.original.date) {
        return {
          ...row.original,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          date: row.original.date.toString(),
        };
      }
      return {
        ...row.original,
      };
    });
    csvExporter.generateCsv(reRows);
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);
  //optionally access the underlying virtualizer instance
  const rowVirtualizerInstanceRef = useRef<
    MRT_Virtualizer<HTMLDivElement, HTMLTableRowElement>
  >(null);

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    rowVirtualizerInstanceRef.current?.scrollToIndex(0);
  }, [sorting]);

  const isFetching = false;
  const isLoading = false;
  if (!data) return <></>;
  return (
    <MantineReactTable
      mantinePaperProps={{
        withBorder: !skipBorder,
      }}
      columns={columns}
      data={data}
      enableBottomToolbar={false}
      enablePagination={false}
      enableRowVirtualization //optional, but recommended if it is likely going to be more than 100 rows
      onColumnFiltersChange={mergeColumnFilters}
      onSortingChange={setSorting}
      state={{
        columnFilters,
        isLoading,
        showAlertBanner: false,
        showProgressBars: isFetching,
        sorting,
      }}
      mantineTableContainerProps={{
        ref: tableContainerRef, //get access to the table container element
        sx: { height: `${height}px`, width: `${width}px` }, //give the table a max height
      }}
      initialState={{ density: "sm" }}
      enableHiding={false}
      enableFullScreenToggle={false}
      rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //get access to the virtualizer instance
      rowVirtualizerProps={{ overscan: 100 }}
      renderTopToolbarCustomActions={({ table }) => (
        <div className={styles.tableControlsWrapper}>
          <div className={styles.csvWrapper}>
            <BlankButton
              id={`${csvTitle}-tableCSV`}
              onClick={() => {
                handleExportRows(table.getPrePaginationRowModel().rows);
              }}
              className={styles.csvBtnProp}
            >
              CSV
            </BlankButton>
          </div>
          {Boolean(dropDownFilterContent) && dropDownFilterContent}
        </div>
      )}
    />
  );
};
function AutoSortTable({ ...props }: AutoSortTableProps): ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (!ref.current) return;
    setHeight(ref.current.offsetHeight);
    setWidth(ref.current.offsetWidth);
  }, []);

  return (
    <div className={styles.wrap} ref={ref}>
      <AutoSortPhysic
        height={height - TABLE_HEADER_HEIGHT}
        width={width}
        {...props}
      />
    </div>
  );
}

export default withContexts<AutoSortTableProps>(AutoSortTable, [
  DashboardContextContainer,
]);
export const filedAccessor = (el: any, fieldName: any): string => {
  if (!el[fieldName]) {
    return "";
  }
  return el[fieldName].join(",");
};

export const defaultDateColumn = {
  id: "date",
  header: "Date",
  filterVariant: "range",
  size: 150,
  Filter: () => {
    return <></>;
  },
  accessorFn: (row: any) => {
    return moment(row.date).format("YYYY-MM-DD, hh:mm:ss");
  },
  filterFn: (row: any, id: any, filterValue: any) => {
    const rowMilliseconds = row.original.date.getTime();
    const { from, to } = filterValue;
    if (!from || !to || from === to) return true;
    if (from <= rowMilliseconds && rowMilliseconds <= to) return true;
    return false;
  },
};
