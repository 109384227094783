import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./DiaBlock.module.scss";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { VirtualInetrfaceGate } from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { DropdownItem } from "../../../helpers/types";
import { CreateDiaFWRule } from "./DiaFirewallRuleConfig";
import { findAvailableIPs } from "../../WizardToolPage/ConnectionStep/ConnectionsContext";
import {
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
} from "../../../helpers/validators/Validator";
import DropdownWithSearch from "../../../components/common/Dropdown/DropdownWithSearch";

/* eslint-disable @typescript-eslint/no-var-requires */
const Netmask = require("netmask").Netmask;

type Props = {
  gates: Array<VirtualInetrfaceGate>;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  isSource: boolean;
  fields: CreateDiaFWRule;
  gateWithChoice: boolean;
};

const arrayRange = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, index) =>
    (start + index * step).toString()
  );

export const DiaBlock: FC<Props> = ({
  gates,
  errors,
  onChange,
  isSource,
  fields,
  gateWithChoice,
}) => {
  const gate = gates.find((gate) => gate.name === fields.gate_vi_name);
  const field = isSource ? "src_dia" : "dst_dia";

  const getGateIPByProtocolType = useMemo(() => {
    let relevantIP = "";
    const checker =
      fields.ip_type === "ipv4" ? checkIPv4WithPrefix : checkIPv6WithPrefix;
    gate?.ip_addresses.forEach((ip) => {
      if (checker(ip)) relevantIP = ip;
    });
    return relevantIP;
  }, [fields.ip_type]);

  const availableIPs = fields[field] ? findAvailableIPs(fields[field]) : 0;
  const gateIP =
    gate && !gateWithChoice ? gate?.ip_addresses[0] : getGateIPByProtocolType;
  const initialPrefix = gateIP.split("/")?.[1] || "";
  const prefix = fields[field]?.split("/")?.[1] || "";
  const net = fields[field]?.split("/")?.[0] || "";
  const prefixList = useMemo(() => arrayRange(parseInt(initialPrefix), 32), [
    initialPrefix,
  ]);
  const [ipList, setIpList] = useState<Array<string>>([]);

  const range: Array<string> = useMemo(() => {
    const defaultIP = gateIP;
    if (
      !defaultIP ||
      !checkIPv4WithPrefix(defaultIP) ||
      !checkIPv4WithPrefix(fields[field])
    ) {
      return [];
    }
    const mask = new Netmask(defaultIP);
    const ips: Array<string> = [mask.base];
    if (mask.bitmask >= 26) {
      mask.forEach((el: string) => ips.push(el));
    } else {
      return ips;
    }
    ips.push(mask.broadcast);
    return ips;
  }, [gate, availableIPs, gateIP]);

  useEffect(() => {
    if (!fields[field]) {
      onChange(field, gateIP);
    }
  }, [fields]);

  useEffect(() => {
    if (fields[field] === gateIP && ipList[0]) {
      handleDiaSelect(ipList[0]);
    }
  }, [fields[field], gateIP, ipList]);

  const handlePrefixSelect = (val: DropdownItem) => {
    const newPrefix = val.key;
    const re = new RegExp(String.raw`${prefix}$`, "g");
    const newNetwork = fields[field].replace(re, newPrefix);
    onChange(field, newNetwork);
  };

  useEffect(() => {
    if (!range.length) return;
    let mask = new Netmask(`${range[0]}/${prefix}`);
    const masks: Array<string> = [mask.base];
    while (true) {
      mask = mask.next();
      if (!range.includes(mask.base)) {
        break;
      }
      masks.push(mask.base);
    }
    setIpList(masks);
  }, [range, prefix]);

  const handleDiaSelect = (val: DropdownItem | string) => {
    const ip = typeof val === "string" ? val : val.key;
    const re = new RegExp(String.raw`^${net}`, "g");
    const newNetwork = fields[field].replace(re, ip);
    onChange(field, newNetwork);
  };

  const handleInput = (val: string) => {
    onChange(field, val);
  };

  const Wrapper = (props: any) => {
    return !range.length || fields.ip_type !== "ipv4"
      ? DropdownWithSearch({
          ...props,
          valuesList: ipList,
          onChange: (val: any) => handleInput(val),
          placeholder: "",
          selected: mapStringToItem(fields[field]),
          listClassName: styles.hidden,
          isHideIcon: true,
        })
      : DropdownBasic({
          ...props,
          itemsList: ipList.map(mapStringToItem),
          errorWithTooltip: true,
        });
  };

  return (
    <div className={styles.wrapper}>
      <Wrapper
        id={"gate_vi_name"}
        selected={mapStringToItem(net)}
        label={"DIA IP address"}
        error={errors && errors[field]}
        className={styles.ip}
        isMedium
        onChange={handleDiaSelect}
        listClassName={styles.list}
      />
      {range.length && fields.ip_type === "ipv4" ? (
        <>
          <span className={styles.delimiter}>/</span>
          <DropdownBasic
            itemsList={prefixList.map(mapStringToItem)}
            id={"dia_ip"}
            label={"PFL"}
            isMedium
            selected={mapStringToItem(prefix)}
            onChange={handlePrefixSelect}
            className={styles.prefix}
          />
        </>
      ) : null}
    </div>
  );
};
