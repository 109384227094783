import { LookingGlassCommands } from "../../pages/Network/LookingGlass/types";
import Api, { Res } from "./Api";
import { CMD_PATH } from "./apiPaths";
import { CommandResult, UUID } from "./types";

export class CommandApi extends Api {
  constructor() {
    super();
  }

  execute = async (
    cmd: LookingGlassCommands,
    options: Record<string, any>
  ): Promise<Res<{ message: UUID }>> => {
    let PATH = CMD_PATH.PING();
    const params: Record<string, any> = {
      vrf_name: options.vrf_name,
      address: options.address,
      system_name: options.system_name,
    };

    if (options.gate_vi_name) {
      params.gate_vi_name = options.gate_vi_name;
    }

    switch (cmd) {
      case LookingGlassCommands.PING:
        PATH = CMD_PATH.PING();
        break;
      case LookingGlassCommands.TRACERT:
        PATH = CMD_PATH.TRACEROUTE();
        params.protocol = options.protocol;
        break;
      case LookingGlassCommands.MTU:
        PATH = CMD_PATH.MTU();
        params.max_mtu = options.max_mtu;
        params.min_mtu = options.min_mtu;
        break;
      case LookingGlassCommands.ROUTE_LOOKUP:
        PATH = CMD_PATH.ROUTE_LOOKUP();
        delete params.address;
        params.ip_address = options.address;
        break;
      case LookingGlassCommands.PCAP:
        PATH = CMD_PATH.PCAP();
        delete params.address;
        params.pcap_type = options.pcap_type;
        params.size = options.size;
        params.max_packets = options.max_packets;
        params.l2_interface = options.l2_interface;
        params.timeoutSec = options.timeoutSec;
        break;
      default:
    }

    return await this.fetchBroker(PATH, { body: JSON.stringify(params) });
  };

  getCommandResult = async (
    options: Record<string, any>
  ): Promise<Res<CommandResult>> => {
    return await this.fetchBroker(CMD_PATH.GET_RESULT(), {
      body: JSON.stringify(options),
    });
  };
}

export const commandApi = new CommandApi();
