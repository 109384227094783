import React, { FC, useEffect, useState } from "react";
import Section from "../../../components/common/Section";
import { CreateFWRule } from "./FirewallRuleConfig";
import styles from "./ActionSection.module.scss";
import BlankButton from "../../../components/common/buttons/BlankButton";
import ButtonsGroup, {
  Tab,
} from "../../../components/common/buttons/ButtonsGroup";
import CheckboxIcon from "../../../components/icons/CheckboxIcon";
import { classNames } from "../../../helpers/common/classNames";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import { FW_POLICIES } from "../../../helpers/common/constantsAlias";

type Props = {
  fields: CreateFWRule;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
  handleFQDN: () => void;
};

export const ACTIONS_TABS: Array<Tab> = [
  {
    name: "Block",
    icon: CheckboxIcon({}),
    className: styles.blockButton,
    activeStyle: styles.active,
    value: "DENY",
  },
  {
    name: "Allow",
    icon: CheckboxIcon({}),
    className: styles.allowButton,
    activeStyle: styles.active,
    value: "ALLOW",
  },
];

const ActionSection: FC<Props> = ({
  fields,
  onChange,
  className,
  handleFQDN,
}) => {
  const { servicesStatus } = useTenantContext();

  const [activeTab, setActiveTab] = useState<string>(
    fields.forwarding_policy === ACTIONS_TABS[0].value
      ? ACTIONS_TABS[0].name
      : ACTIONS_TABS[1].name
  );
  const [fqdnActive, setFQDNActive] = useState<boolean>(false);

  const handleTabSelect = (tab: Tab) => {
    setActiveTab(tab.name);
    onChange("forwarding_policy", tab.value);
  };

  useEffect(() => {
    if (Object.values(FW_POLICIES).includes(fields.forwarding_policy)) {
      setFQDNActive(true);
      handleFQDN();
    }
  }, []);

  useEffect(() => {
    if (fqdnActive && !(activeTab === ACTIONS_TABS[1].name)) {
      setActiveTab(ACTIONS_TABS[1].name);
    }
  }, [fqdnActive]);

  useEffect(() => {
    if (fqdnActive && !(activeTab === ACTIONS_TABS[1].name)) {
      setFQDNActive(false);
      handleFQDN();
    }
  }, [activeTab]);

  return (
    <Section title={undefined} className={className} columnsCount={2}>
      <div className={styles.buttonsWrapper}>
        <ButtonsGroup
          className={styles.actionButtons}
          tabs={ACTIONS_TABS}
          activeTab={activeTab}
          onChange={handleTabSelect}
          withIcon
        />
        {(servicesStatus?.fqdn.overall ||
          !["ALLOW", "DENY"].includes(fields.forwarding_policy)) && (
          <div className={styles.actionButtons}>
            <BlankButton
              id={"fqdn"}
              key={"fqdn"}
              className={classNames(
                styles.webFilteringButton,
                fqdnActive && styles.active
              )}
              onClick={() => {
                setFQDNActive(!fqdnActive);
                handleFQDN();
              }}
            >
              <CheckboxIcon />
              {"Web Filtering"}
            </BlankButton>
          </div>
        )}
      </div>
    </Section>
  );
};
export default ActionSection;
