import React, { FC, useEffect } from "react";
import Input from "../../../../../../../../components/common/formComponents/Input";
import { useFormField } from "../../../../../../../../helpers/hooks/useFormField";
import FormFooter from "../../../common/FormFooter/FormFooter";
import RadioGroup from "../../../../../../../../components/common/formComponents/RadioGroup";
import { useValidation } from "../../../../../../../../helpers/validators/Validator";
import FormTitle from "../../../common/FormTitle/FormTitle";
import { useConnectionsContext } from "../../../../../ConnectionsContext";
import validateInternet from "../../../../../../../../helpers/validators/InternetValidator";
import { useUserContext } from "../../../../../../../../contexts/UserContext";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../InternetForm.module.scss";

const CONNECTION_TYPES = [
  { value: "workloadsToInternet", label: "Internet Access" },
];

const DEFAULT_FIELDS = {
  name: "",
  description: "internet",
  network_segments: [],
};

type Props = {
  connectionType: string;
  setConnectionType: (val: string) => void;
};

const AddWorkloadsToInternet: FC<Props> = ({
  connectionType,
  setConnectionType,
}) => {
  const { user } = useUserContext();
  const {
    activeConnectionType,
    addInternetService,
    activeLocation,
  } = useConnectionsContext();

  const [fields, handleFieldChange, resetFields] = useFormField<any>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<any>(validateInternet, [fields]);

  useEffect(() => {
    return () => {
      resetFields(DEFAULT_FIELDS);
    };
  }, []);

  const handleAddConnection = () => {
    const { isOk } = validate();
    if (isOk && activeConnectionType && activeLocation?.system && user?.name) {
      addInternetService(fields.name, user.name, activeLocation);
    }
  };

  return (
    <>
      <FormTitle />
      <div className={styles.formContainer}>
        <Input
          value={fields.name}
          label="Name"
          onChange={(e) => {
            handleFieldChange("name", e.target.value);
          }}
          isFullValue
          medium
          error={errors?.name}
        />
        <RadioGroup
          label={"Connection Type"}
          options={CONNECTION_TYPES}
          value={connectionType}
          setActiveValue={(val: string) => setConnectionType(val)}
        />
      </div>
      <FormFooter onAdd={handleAddConnection} />
    </>
  );
};
export default AddWorkloadsToInternet;
