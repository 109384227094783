import Section from "../../../components/common/Section";
import styles from "./OptionsSection.module.scss";
import Input from "../../../components/common/formComponents/Input";
import AddButton from "../../../components/common/buttons/AddButton";
import DeleteButton from "../../../components/common/buttons/DeleteButton";
import { CreateDDoS } from "../../DDoSSystemPage/DDoSConfig/types";
import { DDoSOptionType } from "../../../helpers/api/apiTypes";
import { COMMON_OPTION_FIELDS, PROPOSED_OPTIONS } from "./constants";
import { useEffect, useState } from "react";

const getNextOption = (options: Array<DDoSOptionType>) => {
  const optionsNamesSet = new Set(options.map((o) => o.name));
  const notUsedOptions = PROPOSED_OPTIONS.filter(
    (proposed) => !optionsNamesSet.has(proposed.name)
  );

  return notUsedOptions[0] ?? COMMON_OPTION_FIELDS;
};

type Props = {
  fields: CreateDDoS;
  onChange: (name: string, value: Array<DDoSOptionType> | undefined) => void;
  className: string;
  gridTemplate: string;
  errors?: any;
  viewOnly?: boolean;
};

const OptionsSection = ({
  fields,
  onChange,
  className,
  gridTemplate,
  errors,
  viewOnly,
}: Props) => {
  const [hasMonitor, setHasMonitor] = useState(false);
  const handleFieldChange = (
    name: string,
    value: string | number,
    optionIndex: number
  ) => {
    const newOptions = fields.options?.map((item, index) => {
      if (index === optionIndex) {
        return {
          ...item,
          [name]: value,
        };
      }

      return item;
    });
    onChange("options", newOptions);
  };

  useEffect(() => {
    if (!fields.options?.length) return;

    const monitorIndex = fields.options.findIndex(
      (option) => option.name === "Monitor"
    );

    if (monitorIndex === -1) {
      setHasMonitor(false);
      return;
    }

    setHasMonitor(true);
  }, [fields.options]);

  return (
    <Section
      title="Options"
      subtitle={hasMonitor ? "with Monitor" : ""}
      className={className}
      columnsCount={3}
      gridTemplateString={gridTemplate}
    >
      <div className={styles.section}>
        {fields.options?.map((option, optionIndex) => {
          const handleChange = (name: string, value: string | number) =>
            handleFieldChange(name, value, optionIndex);
          return (
            <div className={styles.blocksWrapper} key={optionIndex}>
              <Input
                name="name"
                handleFieldChange={handleChange}
                value={option.name}
                medium
                label="Name"
                className={styles.wideInput}
                error={errors && errors[`name_${optionIndex}`]}
                disabled={viewOnly}
              />
              <Input
                type="number"
                name="flood_detect_rate"
                handleFieldChange={handleChange}
                value={option.flood_detect_rate}
                medium
                label="Detect rate (PPS)"
                className={styles.wideInput}
                formatNumberWithComma
                error={errors && errors[`flood_detect_rate_${optionIndex}`]}
                disabled={viewOnly}
              />
              <Input
                type="number"
                name="flood_rate"
                handleFieldChange={handleChange}
                value={option.flood_rate}
                medium
                label="Flood rate (PPS)"
                className={styles.wideInput}
                formatNumberWithComma
                error={errors && errors[`flood_rate_${optionIndex}`]}
                disabled={viewOnly}
              />
              <Input
                type="number"
                name="flood_cooldown_rate"
                handleFieldChange={handleChange}
                value={option.flood_cooldown_rate}
                medium
                label="Cooldown rate (PPS)"
                className={styles.wideInput}
                errorWithTooltip
                formatNumberWithComma
                error={errors && errors[`flood_cooldown_rate_${optionIndex}`]}
                disabled={viewOnly}
              />
              <Input
                type="number"
                name="hysteresis_seconds"
                handleFieldChange={handleChange}
                value={option.hysteresis_seconds}
                medium
                label="Hysteresis seconds"
                className={styles.wideInput}
                formatNumberWithComma
                error={errors && errors[`hysteresis_seconds_${optionIndex}`]}
                disabled={viewOnly}
              />
              <DeleteButton
                id={`del-option-${optionIndex}`}
                onClick={() =>
                  onChange(
                    "options",
                    fields.options?.filter((_, index) => index !== optionIndex)
                  )
                }
                className={styles.delBtn}
                disabled={viewOnly}
              />
            </div>
          );
        })}
        <AddButton
          onClick={() =>
            onChange("options", [
              ...(fields.options ?? []),
              getNextOption(fields.options ?? []),
            ])
          }
          className={styles.addBtn}
          disabled={viewOnly}
        />
      </div>
    </Section>
  );
};
export default OptionsSection;
