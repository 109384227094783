export const PcapIcon = () => {
  return (
    <svg
      width="91"
      height="102"
      viewBox="0 0 91 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.5C1 4.18629 3.68629 1.5 7 1.5H53.0559C53.3387 1.5 53.6082 1.6197 53.7978 1.82946L76.7419 27.2144C76.908 27.3982 77 27.6372 77 27.885V79.5C77 82.8137 74.3137 85.5 71 85.5H7C3.68629 85.5 1 82.8137 1 79.5V7.5Z"
        fill="white"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <path
        d="M52 2.61341C52 1.69599 53.1321 1.26278 53.7446 1.94587L76.505 27.3325C77.0823 27.9764 76.6253 29 75.7604 29H58C54.6863 29 52 26.3137 52 23L52 2.61341Z"
        fill="#F0EEFE"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <g opacity="0.7">
        <path
          d="M56.1105 47.4442H57.1147C58.0532 47.4442 58.7555 47.2604 59.2215 46.8929C59.6875 46.5188 59.9205 45.9773 59.9205 45.2685C59.9205 44.5531 59.7236 44.0247 59.3298 43.6834C58.9426 43.3421 58.3322 43.1715 57.4986 43.1715H56.1105V47.4442ZM63.0019 45.1602C63.0019 46.7091 62.5163 47.8938 61.5449 48.7142C60.5801 49.5346 59.2051 49.9448 57.4199 49.9448H56.1105V55.0641H53.0586V40.6709H57.6562C59.402 40.6709 60.7278 41.0483 61.6335 41.8031C62.5458 42.5513 63.0019 43.6703 63.0019 45.1602Z"
          fill="#BDB2FF"
        />
        <path
          d="M47.9469 55.0656L46.9033 51.6396H41.656L40.6124 55.0656H37.3242L42.4042 40.6133H46.1354L51.2351 55.0656H47.9469ZM46.1748 49.0799C45.21 45.9755 44.6652 44.2198 44.5405 43.8129C44.4224 43.4059 44.3371 43.0843 44.2846 42.8481C44.068 43.6882 43.4477 45.7654 42.4239 49.0799H46.1748Z"
          fill="#BDB2FF"
        />
        <path
          d="M32.3942 43.0048C31.2457 43.0048 30.3563 43.438 29.7263 44.3044C29.0962 45.1641 28.7811 46.3652 28.7811 47.9076C28.7811 51.117 29.9855 52.7217 32.3942 52.7217C33.405 52.7217 34.629 52.4691 36.0664 51.9637V54.5234C34.885 55.0156 33.5658 55.2617 32.1087 55.2617C30.015 55.2617 28.4136 54.6284 27.3044 53.3617C26.1952 52.0884 25.6406 50.2638 25.6406 47.8879C25.6406 46.3915 25.913 45.0821 26.4578 43.9598C27.0025 42.8309 27.7835 41.9678 28.8008 41.3706C29.8247 40.7668 31.0225 40.4648 32.3942 40.4648C33.7922 40.4648 35.1967 40.8029 36.6078 41.4789L35.6233 43.9598C35.0852 43.7038 34.5437 43.4807 33.9989 43.2903C33.4542 43.1 32.9193 43.0048 32.3942 43.0048Z"
          fill="#BDB2FF"
        />
        <path
          d="M16.6691 47.4442H17.6733C18.6118 47.4442 19.3141 47.2604 19.7801 46.8929C20.2461 46.5188 20.4791 45.9773 20.4791 45.2685C20.4791 44.5531 20.2822 44.0247 19.8884 43.6834C19.5012 43.3421 18.8908 43.1715 18.0572 43.1715H16.6691V47.4442ZM23.5605 45.1602C23.5605 46.7091 23.0748 47.8938 22.1035 48.7142C21.1387 49.5346 19.7637 49.9448 17.9785 49.9448H16.6691V55.0641H13.6172V40.6709H18.2148C19.9606 40.6709 21.2864 41.0483 22.1921 41.8031C23.1044 42.5513 23.5605 43.6703 23.5605 45.1602Z"
          fill="#BDB2FF"
        />
      </g>
      <circle
        cx="70"
        cy="80.5"
        r="20"
        fill="white"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69 72.5H71V79.4656L72.9656 77.5L74.3798 78.9142L70.0159 83.2782L69.9891 83.2514L69.9622 83.2782L65.5983 78.9142L67.0125 77.5L69 79.4875V72.5ZM77 85.5V87.5L63 87.5V85.5L77 85.5Z"
        fill="#BDB2FF"
      />
    </svg>
  );
};
