import React from "react";
import styles from "./ModalTextArea.module.scss";

interface TextAreaProps {
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  className?: string;
}

const ModalTextArea: React.FC<TextAreaProps> = ({
  placeholder,
  value,
  onChange,
  className,
}) => {
  return (
    <textarea
      className={`${styles.textArea} ${className}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};

export default ModalTextArea;
