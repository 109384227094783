import React, { Component, ErrorInfo, ReactNode } from "react";
import styles from "./ErrorBoundary.module.scss";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <img
            src="/assets/ErrorBoundaryPicture.png"
            alt="Error illustration"
            className={styles.image}
          />
          <div className={styles.errorMessage}>
            <div className={styles.oopsMessage}>
              <span className={styles.title}>Ooops,</span>
              <p className={styles.subtitle}>Something went seriously wrong.</p>
            </div>
            <p className={styles.text}>
              Please try again and contact{" "}
              <a href="mailto:support@insidepacket.com" className={styles.link}>
                support@insidepacket.com
              </a>{" "}
              if the error persists!
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
