import { CellProps } from "react-table";
import { DDoSType, FWFilters } from "../../../helpers/api/apiTypes";
import styles from "./ddosTableHeader.module.scss";
import { Toggle } from "../../../components/common/Toggle";
import Label from "../../../components/common/table/Label";
import LabelOutline from "../../../components/common/table/LabelOutline";
import { getDst, getSrc } from "./commonFilterView";
import ErrorCircleIconFilled from "../../../components/icons/ErrorCircleIconFilled";
import { GREY_DISABLED } from "../../../helpers/common/colorAliases";
import OkCircleIconFilled from "../../../components/icons/OkCircleIconFilled";
import SelectRow from "../../Systems/Provisioning/table/SelectRowComponent";
import { WrappedText } from "../../../components/common/WrappedText";
import { prepareUnit, tcpCheckboxes } from "../DDoSConfig/helpers";
import { parseFilters } from "../../PBMPage/table/parseFilters";
import { getUnitTitle, PBM_UNIT } from "../../PBMPage/PBMConfig/PBMConfig";

export const ddosTableHeader = (
  onSelect: (ddos: DDoSType | undefined) => void,
  selected: DDoSType | undefined
): Array<any> => {
  return [
    {
      Header: "Enabled",
      accessor: "enable",
      Cell: ({ cell: { value } }: CellProps<DDoSType>) => {
        return (
          <div className={styles.toggle}>
            <Toggle
              isChecked={value}
              onChange={() => {
                return;
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: (row: DDoSType) => [row.name, row.description],
      Cell: ({ cell: { value } }: CellProps<DDoSType>) => {
        const [name, description] = value;
        return (
          <div className={styles.nameAndDescription}>
            <WrappedText value={name} width={100} />
            {description && (
              <WrappedText
                value={description}
                width={110}
                className={styles.description}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Priority",
      accessor: "priority",
    },
    {
      Header: "Policy",
      accessor: "filters",
      Cell: ({ cell: { value } }: CellProps<DDoSType>) => {
        const filters = value as Array<FWFilters>;
        const parsedFilters = parseFilters(filters);

        return (
          <div className={styles.filtersBlock}>
            <div className={styles.policyWrapper}>
              {getSrc(parsedFilters)}
              {getDst(parsedFilters)}
              {parsedFilters.fragmented && (
                <LabelOutline className={styles.subFilter} type="lightBlue">
                  FRAGMENTED
                </LabelOutline>
              )}
            </div>
            <div className={styles.policyWrapper}>
              {parsedFilters.hasFlags && (
                <LabelOutline type="lightBlue" className={styles.subFilter}>
                  {parsedFilters.flags?.map((flag, i) => {
                    const val = tcpCheckboxes[i].toUpperCase();
                    if (flag !== undefined) {
                      return (
                        <span key={val}>
                          {flag ? (
                            <OkCircleIconFilled color={GREY_DISABLED} />
                          ) : (
                            <ErrorCircleIconFilled color={GREY_DISABLED} />
                          )}
                          <span>{val}</span>
                        </span>
                      );
                    }
                  })}
                </LabelOutline>
              )}
              {parsedFilters.ttl && (
                <LabelOutline type="lightBlue" className={styles.subFilter}>
                  {`TTL: ${parsedFilters.ttl[0]}-${parsedFilters.ttl[1]}`}
                </LabelOutline>
              )}
              {parsedFilters.tos && (
                <LabelOutline type="lightBlue" className={styles.subFilter}>
                  {`DSCP: ${parsedFilters.dscp}, ECN: ${parsedFilters.esn}`}
                </LabelOutline>
              )}
              {parsedFilters.ingress_vi && (
                <LabelOutline type="font">
                  {"ingress: "}
                  {parsedFilters.ingress_vi}
                </LabelOutline>
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Meter",
      accessor: (row: DDoSType) => [row.max_rate, row.unit],
      Cell: ({ cell: { value } }: CellProps<DDoSType>) => {
        const [meter, max_rate] = prepareUnit(value[1], value[0]);
        const formatted =
          meter === PBM_UNIT.UNSPECIFIED
            ? meter.toUpperCase()
            : `${max_rate?.toLocaleString()} ${getUnitTitle(meter as string)}`;
        return <Label className={styles.noBack}>{formatted}</Label>;
      },
    },
    {
      Header: "Detect rate / Flood rate",
      accessor: (row: DDoSType) => [row.flood_detect_rate, row.flood_rate],
      Cell: ({ cell: { value } }: CellProps<DDoSType>) => {
        const [detectRate, rate] = value;
        return `${detectRate?.toLocaleString()} / ${rate?.toLocaleString()}`;
      },
    },
    {
      accessor: "all",
      Header: () => (
        <SelectRow<DDoSType | undefined>
          onSelect={onSelect}
          currentRow={undefined}
          selected={selected}
        />
      ),
      Cell: ({ row }: CellProps<DDoSType>) => {
        return (
          <SelectRow<DDoSType | undefined>
            onSelect={onSelect}
            currentRow={row.original}
            selected={selected}
          />
        );
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof DDoSType,
  }));
};
