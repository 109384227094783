const parseEnrichedData = (enrichedData: string) => {
  let asName = "";
  let source = "";

  if (!enrichedData) return { asName, source };

  const lines = enrichedData.split("\n").map((line) => line.trim());
  let inAutNumBlock = false;

  for (const line of lines) {
    if (/^aut-num:/i.test(line)) {
      inAutNumBlock = true;
    }

    if (inAutNumBlock) {
      if (/^(AS-Name|as-name|ASName|ownerid):/i.test(line)) {
        asName = line.split(":")[1]?.trim() || "";
      }
      if (/^source:/i.test(line)) {
        source = line.split(":")[1]?.trim() || "";
      }
    }
  }

  // Additional check if we didn't find it in aut-num block
  if (!asName) {
    const fallbackMatch = enrichedData.match(
      /(AS-Name|as-name|ASName|ownerid):\s*(.+)/i
    );
    asName = fallbackMatch ? fallbackMatch[2].trim() : "Unassigned";
  }

  // Check outside of aut-num block
  if (!source) {
    const sourceMatch = enrichedData.match(/^source:\s*(.+)$/im);
    source = sourceMatch ? sourceMatch[1].trim() : "";
  }

  // Source check if not found directly
  if (!source) {
    if (enrichedData.includes("# ARIN WHOIS data")) {
      source = "ARIN";
    } else if (/Ref: .*arin\.net/.test(enrichedData)) {
      source = "ARIN";
    } else if (enrichedData.includes("% LACNIC resource:")) {
      source = "LACNIC";
    } else if (enrichedData.includes("% Copyright LACNIC")) {
      source = "LACNIC";
    } else if (enrichedData.includes("source: RIPE")) {
      source = "RIPE";
    }
  }

  // remove # and trim
  source = source.split("#")[0].trim();

  return { asName, source };
};

export default parseEnrichedData;
