import { FC, useEffect, useMemo } from "react";
import Button from "../../../../components/common/buttons/Button";
import DropDownInput, {
  DropdownItem,
} from "../../../../components/common/formComponents/DropDownInput";
import Input from "../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../components/common/formComponents/RadioGroup";
import LoaderIcon from "../../../../components/common/loadStates/LoaderIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { WHITE } from "../../../../helpers/common/colorAliases";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import validateLookingGlass from "../../../../helpers/validators/LookingGlassValidator";
import { useValidation } from "../../../../helpers/validators/Validator";
import { CMD_LIST, PCAP_TYPE_LIST, PROTOCOL_LIST } from "../const";
import { useLookingGlassContext } from "../LookingGlassContext";
import { LookingGlassCommands, LookingGlassFields } from "../types";
import styles from "./ControlPanel.module.scss";
import { useUserContext } from "../../../../contexts/UserContext";
import { Checkbox } from "../../../../components/common/formComponents/Checkbox";
import VIDropdown from "../../../../components/common/OneSystem/VIDropdown";

const getDefaultFields = (
  location: string,
  cmd?: LookingGlassCommands
): LookingGlassFields => ({
  ip: "",
  location,
  cmd: cmd ?? LookingGlassCommands.ROUTE_LOOKUP,
  protocol: PROTOCOL_LIST[0],
  max_mtu: 64,
  min_mtu: 64,
  pcapType: "all",
  size: 256,
  maxPackets: 200,
  l2Interface: "",
  l2InterfaceLabel: "",
  timeoutSec: 10,
});

const ControlPanel: FC = () => {
  const {
    runCommand,
    commandExecStatus,
    userSystems,
    selectedSystem,
    setSelectedSystem,
    dia,
    isDia,
    setIsDia,
    tenant,
    vi,
    selectVi,
    l2Interfaces,
  } = useLookingGlassContext();

  const formIsDisabled = commandExecStatus.state === "pending";

  const DEFAULT_FIELDS = useMemo(
    () => getDefaultFields(selectedSystem?.location ?? ""),
    [selectedSystem]
  );

  const [fields, setField, resetFields] = useFormField<LookingGlassFields>(
    DEFAULT_FIELDS
  );

  const [errors, validate] = useValidation<Partial<LookingGlassFields>>(
    validateLookingGlass,
    [fields]
  );

  const handleCommandExecution = () => {
    const { isOk } = validate();
    isOk && runCommand(fields);
  };

  const handleLocationUpdate = (system: DropdownItem) => {
    setField("location", system.key);
    setSelectedSystem({ location: system.value, system: system.key });
    setField("isDia", false);
  };

  useEffect(() => {
    resetFields(getDefaultFields(""));
    setSelectedSystem(null);
    setIsDia(false);
    selectVi(undefined);
  }, [tenant]);

  useEffect(() => {
    resetFields(getDefaultFields(selectedSystem?.location ?? "", fields.cmd));
    setIsDia(false);
    selectVi(undefined);
  }, [selectedSystem]);

  useEffect(() => {
    if (isDia && fields.cmd === LookingGlassCommands.PCAP) {
      selectVi(dia);
    }
  }, [isDia, fields.cmd]);

  useEffect(() => {
    if (
      isDia &&
      fields.cmd === LookingGlassCommands.PCAP &&
      l2Interfaces?.length
    ) {
      setField("l2Interface", l2Interfaces[0].name);
      setField("l2InterfaceLabel", l2Interfaces[0].customer_port_name);
    }
  }, [l2Interfaces, isDia, fields.cmd]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>Looking Glass</div>
        <div
          className={classNames(styles.form, formIsDisabled && styles.disabled)}
        >
          <DropDownInput
            id="controlPanelSourceLocation"
            selected={{
              key: selectedSystem?.system || "",
              value: selectedSystem?.location,
            }}
            itemsList={userSystems.map((s) => ({
              key: s.system || "",
              value: s.location,
            }))}
            onChange={handleLocationUpdate}
            label="Source Location"
            isMedium
            disabled={userSystems.length === 0}
            error={errors?.location}
          />
          {!!dia && (
            <Checkbox
              isChecked={isDia}
              onChange={() => {
                setIsDia(!isDia);
              }}
              label="Use DIA"
            />
          )}
          <RadioGroup
            value={fields.cmd}
            setActiveValue={(cmd) => setField("cmd", cmd)}
            options={CMD_LIST}
            isNotEditable={formIsDisabled}
          />
          {fields.cmd !== LookingGlassCommands.PCAP && (
            <Input
              label="Target IP"
              medium
              value={fields.ip}
              placeholder="1.1.1.1"
              onChange={({ target }) => setField("ip", target.value)}
              disabled={formIsDisabled}
              error={errors?.ip}
            />
          )}
          {fields.cmd === LookingGlassCommands.TRACERT && (
            <DropDownInput
              id={"controlPanelProtocol"}
              selected={{
                key: fields.protocol,
                value: fields.protocol,
              }}
              itemsList={PROTOCOL_LIST.map((p) => ({
                key: p,
                value: p,
              }))}
              onChange={(value) => setField("protocol", value.key)}
              label="Protocol"
              isMedium
            />
          )}
          {fields.cmd === LookingGlassCommands.MTU && (
            <>
              <Input
                label="MTU max"
                name="max_mtu"
                placeholder=""
                value={fields.max_mtu}
                medium
                type="number"
                min={64}
                max={20000}
                onChange={(e) => setField("max_mtu", e.target.value)}
                error={errors?.max_mtu}
                errorWithTooltip
              />
              <Input
                label="MTU min"
                name="min_mtu"
                placeholder=""
                value={fields.min_mtu}
                medium
                type="number"
                min={64}
                max={20000}
                onChange={(e) => setField("min_mtu", e.target.value)}
                error={errors?.min_mtu}
                errorWithTooltip
              />
            </>
          )}
          {fields.cmd === LookingGlassCommands.PCAP && (
            <>
              <DropDownInput
                id="pcapType"
                selected={{
                  key: fields.pcapType,
                  value: fields.pcapType,
                }}
                itemsList={PCAP_TYPE_LIST.map((l) => ({
                  key: l,
                  value: l,
                }))}
                onChange={(item) => setField("pcapType", item.value)}
                label="Type"
                isMedium
                disabled={userSystems.length === 0}
                error={errors?.pcapType}
              />
              <Input
                label="Capture size"
                name="size"
                placeholder=""
                value={fields.size}
                medium
                type="number"
                min={256}
                max={10000}
                onChange={(e) => setField("size", e.target.value)}
                error={errors?.size}
                errorWithTooltip
              />
              <Input
                label="Packet Count"
                name="maxPackets"
                placeholder=""
                value={fields.maxPackets}
                medium
                type="number"
                min={200}
                max={10000}
                onChange={(e) => setField("maxPackets", e.target.value)}
                error={errors?.maxPackets}
                errorWithTooltip
              />
              <VIDropdown
                systemName={selectedSystem?.system}
                vrf={{ vrf_type: "tenant", name: tenant }}
                selected={{ name: vi?.name }}
                onSelect={(val) => {
                  selectVi(val);
                  setField("l2Interface", "");
                  setField("l2InterfaceLabel", "");
                }}
                disabled={!selectedSystem || isDia}
                filterBySystemName
              />
              <DropDownInput
                id="customerPort"
                selected={{
                  key: fields.l2Interface,
                  value: fields.l2InterfaceLabel,
                }}
                itemsList={
                  l2Interfaces?.map((l2) => ({
                    key: l2.name,
                    value: l2.customer_port_name,
                  })) ?? []
                }
                onChange={(item) => {
                  setField("l2Interface", item.key);
                  setField("l2InterfaceLabel", item.value);
                }}
                label="Customer Port"
                isMedium
                disabled={!vi || !l2Interfaces?.length}
                error={errors?.l2Interface}
              />
              <Input
                label="Timeout in seconds"
                name="timeoutSec"
                placeholder=""
                value={fields.timeoutSec}
                medium
                type="number"
                min={1}
                max={20}
                onChange={(e) => setField("timeoutSec", e.target.value)}
                error={errors?.timeoutSec}
                errorWithTooltip
              />
            </>
          )}
        </div>
      </div>
      <RunButton onClick={handleCommandExecution} />
    </div>
  );
};

type Props = {
  onClick: () => void;
};
const RunButton: FC<Props> = ({ onClick }) => {
  const { isDemo, isZayo } = useUserContext();

  const { commandExecStatus, userSystems } = useLookingGlassContext();

  const { state: execStatus } = commandExecStatus;

  const getButtonContent = () => {
    switch (execStatus) {
      case "pending":
        return <LoaderIcon height={18} color={WHITE} />;
      case "error":
        return "Run again";
      default:
        return "Run";
    }
  };

  return (
    <Button
      className={styles.runBtn}
      isPrimaryBtn
      onClick={onClick}
      disabled={
        execStatus === "pending" ||
        userSystems.length === 0 ||
        (isDemo && isZayo)
      }
    >
      {getButtonContent()}
    </Button>
  );
};

export default ControlPanel;
