type TabsProps = {
  tabs: Array<{ key: string; label: string }>;
  activeTab: string;
  setActiveTab: (tab: string) => void;
};

export const Tabs = ({ tabs, activeTab, setActiveTab }: TabsProps) => (
  <div style={{ display: "flex", gap: "16px" }}>
    {tabs.map((tab) => (
      <div
        key={tab.key}
        style={{
          cursor: "pointer",
          fontWeight: activeTab === tab.key ? "bold" : "normal",
          borderBottom: activeTab === tab.key ? "2px solid #6610f2" : "none",
        }}
        onClick={() => setActiveTab(tab.key)}
      >
        {tab.label}
      </div>
    ))}
  </div>
);
