import { CellProps } from "react-table";
import styles from "./orderedPortsTableHeader.module.scss";
import Label from "../../../components/common/table/Label";
import { CustomerPortType } from "../../Network/UnitedConnections/types";
import { getDateWithTime } from "../../../helpers/common/dateHelpers";
import { UserThumbnail } from "../../UsersPage/components/shared/UserThumbnail";
import SystemIcon from "../../../components/icons/SystemIcon";
import { getBandwidthStringForTable } from "../../../helpers/getBandwidthStringForTable";

export const orderedPortsTableHeader = (): Array<any> => {
  return [
    {
      Header: "Timestamp (request created)",
      accessor: "created_at",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return getDateWithTime(value) || "";
      },
    },
    {
      Header: "Tenant name",
      accessor: "tenant_name",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return <UserThumbnail username={value || ""} />;
      },
    },
    {
      Header: "NaaS Location",
      accessor: "labels.naas_location",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return value || "-";
      },
    },
    {
      Header: "Building type",
      accessor: "labels.building_type",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return value || "-";
      },
    },
    {
      Header: "Building",
      accessor: "labels.building",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return value || "-";
      },
    },
    {
      Header: "Operator",
      accessor: "labels.operator",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return value || "-";
      },
    },
    {
      Header: "Port Type",
      accessor: "requested_bw",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return value ? getBandwidthStringForTable(value) : "-";
      },
    },
    {
      Header: "System",
      accessor: "system_name",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return (
          <Label>
            <span className={styles.title}>
              <SystemIcon /> {value || "-"}
            </span>
          </Label>
        );
      },
    },
    {
      Header: "Port",
      accessor: "l2_parent_name",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return value?.match(/^(.+)\.(.+)$/)?.[1] || "-"; // port name
      },
    },
    {
      Header: "Vlan",
      accessor: "l2_parent_name",
      id: "vlan",
      Cell: ({ cell: { value } }: CellProps<CustomerPortType>) => {
        return value?.match(/^(.+)\.(.+)$/)?.[2] || "-"; // VLAN
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof CustomerPortType,
  }));
};
