import { NATIVE } from "../../../../../helpers/consts";
import { VlanData } from "./CloudsTabInfo";

export const parsePortVlan = ({
  vlan_ctag_id,
  vlan_id,
}: Pick<VlanData, "vlan_ctag_id" | "vlan_id">) => {
  if (!vlan_ctag_id && !vlan_id) return undefined;

  if (vlan_id && !vlan_ctag_id) return NATIVE;
  if (vlan_ctag_id) return vlan_ctag_id;

  return undefined;
};
