/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import {
  FilterAndSort,
  FWRuleType,
  FWDiaRuleType,
  DIAFWRuleEdit,
} from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import AbstractCrudContextContainer, {
  createContextAndUseWithExtra,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";
import {
  BLOCK_ALL_GROUP,
  EMPTY_RESULT,
  FIREWALL_RULE_GROUP,
  SEGMENTATION_GROUP,
} from "../../helpers/common/constantsAlias";
import withTimerangeContext from "../../helpers/hocs/withTimerangeContext";
import {
  RequestStatus,
  TimerangeContextType,
  UserGroup,
} from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";

import {
  MAX_FW_PRIORITY_FOR_MANUAL_CREATION,
  LIMIT_FW_PRIORITY_FOR_BLOCK_SECTION_END,
} from "../../pages/Firewall/config/FirewallRuleConfig";
import { userApi } from "../../helpers/api/UserApi";
import { getFQDNData } from "../../pages/WebFilteringPage/helpers/getFQDNData";
import { TenantFQDNCategory } from "../../pages/WebFilteringPage/types";
import { VirtualInetrfaceGate } from "../../helpers/api/TenantVirtualInterfaceApi/types";
import { Res } from "../../helpers/api/Api";
import { getGroupedDiaFW } from "./getGroupedDiaFW";
import { tenantVirtualInterfacesApi } from "../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";

type FWRuleGroups = {
  [FIREWALL_RULE_GROUP]: Array<FWRuleType>;
  [SEGMENTATION_GROUP]: Array<FWRuleType>;
  [BLOCK_ALL_GROUP]: Array<FWRuleType>;
};

type DiaFWRuleGroups = {
  [key: string]: Array<FWDiaRuleType>;
};

const DEFAULT_FW_GROUPS: FWRuleGroups = {
  [FIREWALL_RULE_GROUP]: [],
  [SEGMENTATION_GROUP]: [],
  [BLOCK_ALL_GROUP]: [],
};

const DEFAULT_DIA_GROUPS: DiaFWRuleGroups = {};

export type UserGroups = {
  tenant: Array<any>;
  global: Array<any>;
};

type IState = CrudState<FWRuleType, FWRuleType> & {
  fwGroups?: FWRuleGroups;
  fwGroupsStatus: RequestStatus;
  lastFetchedTenant: string;
  selectedFwRule: FWRuleType | FWDiaRuleType | undefined;
  userGroups: UserGroups | undefined;
  contentFilterCategories: Array<TenantFQDNCategory>;
  webThreatsFilterCategories: Array<TenantFQDNCategory>;
  diaGroups?: DiaFWRuleGroups;
  gates: Array<VirtualInetrfaceGate>;
};

type IFunc = {
  fetchGroups: (
    tenant?: string,
    params?: FilterAndSort,
    isDia?: boolean
  ) => void;
  removeDeletedRule: (id: number) => void;
  selectFWRule: (fw: FWRuleType | undefined) => void;
  changeFWRuleEnable: (fw?: any | undefined) => void;
  fetchUserGroups: (tenant: string) => void;
  fetchFQDNData: (tenant: string) => void;
  editRule: (
    params: Partial<FWRuleType>,
    rule_name: string | number,
    tenant?: string,
    isDia?: DIAFWRuleEdit
  ) => Promise<Res<any>>;
};

export type FWDeleteArgs = FWRuleType & {
  tenant: string;
};

const [Context, useContext] = createContextAndUseWithExtra<
  IState,
  IFunc & CrudFunc<FWRuleType, FWRuleType>,
  TimerangeContextType
>();

export const useFirewallContext = useContext;

type Props = React.PropsWithChildren<{
  timerangeContext: TimerangeContextType;
}>;

class FirewallContextContainer extends AbstractCrudContextContainer<
  FWRuleType,
  FWRuleType,
  IState,
  FWRuleType
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.funcs = {
      fetchGroups: this.fetchGroups,
      removeDeletedRule: this.removeDeletedRule,
      selectFWRule: this.selectFWRule,
      changeFWRuleEnable: this.changeFWEnable,
      fetchUserGroups: this.fetchUserGroups,
      fetchFQDNData: this.fetchFQDNData,
      editRule: this.editRule,
    };
  }

  changeFWEnable = async (fw?: FWRuleType | undefined) => {
    if (fw) {
      await this.editRule(
        { enable: !fw.enable, priority: fw.priority },
        fw.name,
        this.state.lastFetchedTenant
      );
      await this.fetchGroups(
        this.state.lastFetchedTenant,
        undefined,
        !!fw.gate_vi_name || !!fw.gate_vi_names?.length
      );
    }
  };

  getFWGroupByPriority = (priority: number): string => {
    if (priority < MAX_FW_PRIORITY_FOR_MANUAL_CREATION)
      return FIREWALL_RULE_GROUP;
    if (priority >= LIMIT_FW_PRIORITY_FOR_BLOCK_SECTION_END)
      return BLOCK_ALL_GROUP;
    return SEGMENTATION_GROUP;
  };

  checkFWRuleBelongsToGroup = (fw: FWRuleType, group: string): boolean => {
    return this.getFWGroupByPriority(fw.priority) === group;
  };

  selectFWRule = async (fw: FWRuleType | undefined) => {
    this.setState({ selectedFwRule: fw });
  };

  listReq = async (params?: FilterAndSort, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const res = await configApi.getFWRules(tenant, params);
    // TEMP SOLUTION FOR SORTING
    if (res.ok) {
      const filteredResult = res.result?.filter(
        (rule) => !rule.gate_vi_name && !rule.gate_vi_names?.length
      );
      const sortedResult = filteredResult?.sort(
        (prev, next) => prev.priority - next.priority || prev.id - next.id
      );
      return { ...res, result: sortedResult };
    }
    return res;
  };

  filterRules = async (
    res: Res<Array<FWRuleType | FWDiaRuleType>>,
    isDia = false,
    tenant: string
  ): Promise<FWRuleGroups | DiaFWRuleGroups> => {
    const filteredResult = res.result?.filter((rule) =>
      isDia
        ? rule.gate_vi_name || rule.gate_vi_names?.length
        : !rule.gate_vi_name && !rule.gate_vi_names?.length
    );
    const sortedResult =
      filteredResult?.sort(
        (prev, next) => prev.priority - next.priority || prev.id - next.id
      ) || [];
    const newGroups = isDia ? DEFAULT_DIA_GROUPS : DEFAULT_FW_GROUPS;

    if (!isDia) {
      newGroups[FIREWALL_RULE_GROUP] = sortedResult.filter((rule) =>
        this.checkFWRuleBelongsToGroup(rule, FIREWALL_RULE_GROUP)
      );
      newGroups[SEGMENTATION_GROUP] = sortedResult.filter((rule) =>
        this.checkFWRuleBelongsToGroup(rule, SEGMENTATION_GROUP)
      );
      newGroups[BLOCK_ALL_GROUP] = sortedResult.filter((rule) =>
        this.checkFWRuleBelongsToGroup(rule, BLOCK_ALL_GROUP)
      );
      return newGroups as FWRuleGroups;
    }

    const gateRes = await tenantVirtualInterfacesApi.getVirtualInterfacesGate(
      tenant
    );
    if (gateRes.ok && gateRes.result) {
      const gates = gateRes.result.items.filter((gate) => gate.is_dia);
      this.setState({ gates });
    }
    return getGroupedDiaFW(sortedResult as Array<FWDiaRuleType>);
  };

  fetchGroups = async (
    tenant?: string,
    params?: FilterAndSort,
    isDia = false
  ) => {
    if (!tenant) return EMPTY_RESULT;
    this.setState({ fwGroupsStatus: setPending("Fetching") });
    const res = await configApi.getFWRules(tenant, params);

    if (res.ok && res.result) {
      const newGroups = await this.filterRules(res, isDia, tenant);
      const updatedState = isDia
        ? {
            diaGroups: newGroups as DiaFWRuleGroups,
          }
        : {
            fwGroups: newGroups as FWRuleGroups,
          };
      this.setState({
        ...updatedState,
        lastFetchedTenant: tenant,
      });
    }
    this.setState({
      fwGroupsStatus: resToState(res),
      lastFetchedTenant: tenant,
    });
  };

  selectedReq = async (rule_name: string | number, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    return await configApi.getFWRule(tenant, rule_name as string);
  };

  addReq = async (params: any, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const query =
      params?.gate_vi_name || params?.gate_vi_names?.length
        ? configApi.addFWRuleDia(tenant, params as any)
        : configApi.addFWRule(tenant, params as any);
    const res = await query;
    if (res.ok) {
      await this.fetchGroups(
        this.state.lastFetchedTenant,
        undefined,
        !!params?.gate_vi_name || !!params.gate_vi_names?.length
      );
    }
    return res;
  };

  removeReq = async (rule: FWRuleType, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const res = await configApi.deleteFWRule(tenant, rule.name.toString());
    if (res.ok) {
      await this.fetchGroups(
        this.state.lastFetchedTenant,
        undefined,
        !!rule?.gate_vi_name || !!rule.gate_vi_names?.length
      );
    }
    return res;
  };

  removeDeletedRule = (id: number) => {
    const list = this.state.list.filter((r) => r.id !== id);
    this.setState({ list });
  };

  editReq = async () => {
    return { ok: false };
  };

  editRule = async (
    params: Partial<FWRuleType>,
    rule_name: string | number,
    tenant?: string,
    diaParams?: DIAFWRuleEdit
  ) => {
    if (!tenant || !params.priority) return EMPTY_RESULT;
    const ruleGroup = this.getFWGroupByPriority(
      params.priority
    ) as keyof FWRuleGroups;
    const groupsState = this.state.fwGroups;
    const groupsBackup = this.state.fwGroups;
    if (groupsState) {
      const newGroup = groupsState[ruleGroup].map((rule) => {
        if (rule.name === rule_name) {
          rule = { ...rule, ...params };
        }
        return rule;
      });
      newGroup.sort((a, b) => a.priority - b.priority);
      groupsState[ruleGroup] = newGroup;
      this.setState({ fwGroups: groupsState });
    }
    const res = await configApi.editFWRule(tenant, rule_name as string, params);

    if (diaParams) {
      await configApi.editFWRuleDia(tenant, rule_name as string, diaParams);
    }

    if (res && res.error) {
      this.setState({ fwGroups: groupsBackup });
    }
    if (res.ok) {
      await this.fetchGroups(
        this.state.lastFetchedTenant,
        undefined,
        !!params?.gate_vi_name || !!params.gate_vi_names?.length
      );
    }
    return res;
  };

  fetchUserGroups = async (tenant: string) => {
    const { ok, result } = await userApi.getUsersGroupsList(tenant);
    if (ok && result) {
      this.setState({
        userGroups: {
          global: await this.fetchGlobalGroups(),
          tenant: result.items.map((group: UserGroup) => group.name),
        },
      });
    }
  };

  fetchGlobalGroups = async () => {
    const { ok, result } = await userApi.getGlobalUsersGroupsList();
    if (ok && result) {
      return result.items.map((group: UserGroup) => group.name);
    }
    return [];
  };

  fetchFQDNData = async (tenant: string) => {
    const res = await getFQDNData(tenant);
    if (res.ok) {
      const {
        contentFilterCategories,
        webThreatsFilterCategories,
      } = res.result;

      this.setState({
        contentFilterCategories,
        webThreatsFilterCategories,
      });
    }
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.funcs,
          ...this.baseFuncs,
          ...this.props.timerangeContext,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default withTimerangeContext(FirewallContextContainer);
