import React, { FC, useEffect, useMemo, useState } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { firewallTableHeader } from "../../components/common/table/TableHeaders/Services/firewallTableHeader";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import FirewallContextContainer, {
  useFirewallContext,
} from "../../contexts/servicesContext/FirewallContext";
import { IS_DEMO } from "../../globalFlags";
import { FWRuleType } from "../../helpers/api/apiTypes";
import {
  ADD,
  EDIT,
  SEGMENTATION_GROUP,
} from "../../helpers/common/constantsAlias";
import { withContexts } from "../../helpers/hocs/withContexts";
import { withLayout } from "../../helpers/hocs/withLayout";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import { PageProps } from "../../helpers/types";
import FirewallRuleConfig from "./config/FirewallRuleConfig";
import SubnetTable from "./SubnetTable";
import styles from "./Firewall.module.scss";
import TableGrouped from "../../components/common/table/newTable/TableGrouped";
import { ACTIVE_FW_QUERY_PARAM } from "../../helpers/navigation";
import {
  REPORTS,
  SERVICES_FW,
  SERVICES_FW_DIA,
} from "../../helpers/navigation/entries";
import { useHistory } from "react-router-dom";
import { CellHoverTooltip } from "./CellHoverTooltip";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";

const FirewallContent: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    fetchGroups,
    fwGroups = { "": [] },
    fwGroupsStatus,
    remove,
    removeStatus,
    selectedFwRule,
    removeDeletedRule,
    selectFWRule,
    changeFWRuleEnable,
    fetchUserGroups,
    userGroups,
    fetchFQDNData,
  } = useFirewallContext();
  const { selectedTenant } = useGlobalFilterContext();

  const [showAdd, setShowAdd] = useState(false);
  const history = useHistory();

  const [tryDelete, selectedToDelete, sureDeleteProps] = useDeleteDialog<any>(
    remove,
    removeStatus,
    () => {
      selectFWRule(undefined);
      selectedFwRule && removeDeletedRule(selectedFwRule.id);
    }
  );

  useEffect(() => {
    if (selectedTenant) {
      fetchGroups(selectedTenant);
      !userGroups?.global.length &&
        !userGroups?.tenant.length &&
        fetchUserGroups(selectedTenant);
      fetchFQDNData(selectedTenant);
    }
  }, [selectedTenant]);

  const rowActions: ActionFuncs<FWRuleType> = useMemo(
    () => ({
      onEdit: selectFWRule,
      onDelete: (rowOriginal) => {
        tryDelete(rowOriginal, selectedTenant);
      },
      onDiveIn: (rowOriginal: any) => {
        history.push(
          REPORTS().path + `?${ACTIVE_FW_QUERY_PARAM}=${rowOriginal.name}`
        );
      },
      hoverTooltip: (row: FWRuleType) => <CellHoverTooltip rowOriginal={row} />,
    }),
    [selectedTenant, tryDelete]
  );

  if (showAdd) {
    return (
      <FirewallRuleConfig
        type={ADD}
        onClose={() => setShowAdd(false)}
        data={selectedFwRule}
      />
    );
  }

  if (selectedFwRule) {
    return (
      <FirewallRuleConfig
        type={EDIT}
        onClose={() => selectFWRule(undefined)}
        data={selectedFwRule}
      />
    );
  }

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Firewall Rule"}
        name={`${selectedToDelete?.name || JSON.stringify(selectedToDelete)}`}
      />
      <div className={styles.container}>
        <Tabs tabs={[SERVICES_FW(), SERVICES_FW_DIA()]} />
        <ServicesRedirectPlaceholder type="firewall">
          <TableWrapper
            titleProps={{
              title: "Rules",
              children: <AddButton onClick={() => setShowAdd(true)} />,
            }}
            dataStatus={fwGroupsStatus}
            isEmpty={!Boolean(fwGroups)}
            tablePlaceholder={["rules", "rule"]}
          >
            <TableGrouped
              columns={firewallTableHeader()}
              header={TableHeader}
              data={fwGroups}
              gridColumnTemplate={`120px 1fr 200px repeat(4, 1fr) 80px`}
              rowActions={rowActions}
              onClick={selectFWRule}
              collapsed={[SEGMENTATION_GROUP]}
              clickFeatures={[
                {
                  elementClass: "toggle",
                  clb: changeFWRuleEnable,
                },
              ]}
              gridTemplateRows={"auto max-content auto"}
              commonActions
            />
          </TableWrapper>
        </ServicesRedirectPlaceholder>
        {IS_DEMO && (
          <div className={styles.subnetTable}>
            <SubnetTable />
          </div>
        )}
      </div>
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(FirewallContent, [FirewallContextContainer])
);
