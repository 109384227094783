import { BrowserRouter } from "react-router-dom";
import NiroContextContainer from "./contexts/NiroContext";
import { ReactElement } from "react";
import { Router } from "./components/Router";
import UserContextContainer from "./contexts/UserContext";
import GlobalFilterContextContainer from "./contexts/GlobalFilterContext";
import AuthContextContainer from "./contexts/AuthContext";
import TutorialContextContainer from "./contexts/TutorialContext";
import NotificationsContextContainer from "./contexts/NotificationsContext";
import { PricingDialogProvider } from "./contexts/pricingDialogContext/PricingDialogContext";
import { PackagesContextContainer } from "./contexts/PackagesContext";
import OneSystemContextContainer from "./contexts/systemsContext/OneSystemContext";
import ErrorBoundary from "./routing/ErrorBoundary";

function App(): ReactElement {
  return (
    <ErrorBoundary>
      <AuthContextContainer>
        <UserContextContainer>
          <NotificationsContextContainer>
            <GlobalFilterContextContainer>
              <NiroContextContainer>
                <TutorialContextContainer>
                  <PricingDialogProvider>
                    <PackagesContextContainer>
                      <OneSystemContextContainer>
                        <BrowserRouter>
                          <Router />
                        </BrowserRouter>
                      </OneSystemContextContainer>
                    </PackagesContextContainer>
                  </PricingDialogProvider>
                </TutorialContextContainer>
              </NiroContextContainer>
            </GlobalFilterContextContainer>
          </NotificationsContextContainer>
        </UserContextContainer>
      </AuthContextContainer>
    </ErrorBoundary>
  );
}

export default App;
