import React, { useState, useEffect } from "react";
import styles from "./NotificationPopup.module.scss";
import Button from "../common/buttons/Button";
import { NotificationTypeToRouteMap } from "./NotificationTypeToRouteMap";
import EmptyConnectionsPlaceholderIconSmall from "../icons/EmptyConnectionsPlaceholderIconSmall";

type NotificationPopupProps = {
  notifications: Array<any>;
  onClose: () => void;
};

function NotificationPopup({ notifications, onClose }: NotificationPopupProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (notifications.length > 0) {
      setCurrentIndex((prev) =>
        prev >= notifications.length ? notifications.length - 1 : prev
      );
    }
  }, [notifications]);

  if (notifications.length === 0) return null;

  const safeIndex =
    currentIndex < 0
      ? 0
      : currentIndex >= notifications.length
      ? notifications.length - 1
      : currentIndex;

  const currentNotification = notifications[safeIndex];
  if (!currentNotification) return null;

  const handleDoItLater = () => {
    if (safeIndex < notifications.length - 1) {
      setCurrentIndex(safeIndex + 1);
    } else {
      onClose();
    }
  };

  const handleAddNow = () => {
    const route = NotificationTypeToRouteMap[currentNotification.type] || "/";
    if (window.location.pathname !== route) {
      window.location.pathname = route;
    }
    onClose();
  };

  return (
    <div className={styles.notificationPopup}>
      <div className={styles.notificationPopupHeader}>
        {notifications.length > 1 && (
          <div
            className={`${styles.notificationPopupIndicators} ${
              notifications.length > 5 ? styles.twoRows : styles.oneRow
            }`}
          >
            {notifications.map((_, i) => (
              <div
                key={i}
                className={`${styles.notificationPopupIndicator} ${
                  i === safeIndex ? styles.notificationPopupIndicatorActive : ""
                }`}
                onClick={() => setCurrentIndex(i)}
              />
            ))}
          </div>
        )}
        <button className={styles.notificationPopupClose} onClick={onClose}>
          X
        </button>
      </div>

      <div className={styles.notificationPopupContent}>
        <div className={styles.notificationContentIcon}>
          <EmptyConnectionsPlaceholderIconSmall />
        </div>
        <div className={styles.notificationContentText}>
          <div className={styles.notificationPopupTitle}>
            {currentNotification.title}
          </div>
          <div className={styles.notificationPopupMessage}>
            {currentNotification.text}
          </div>
        </div>
      </div>

      <div className={styles.notificationPopupFooter}>
        <div className={styles.notificationPopupCounter}>
          {safeIndex + 1}/{notifications.length}
        </div>
        <div className={styles.notificationPopupFooterButtons}>
          <button
            className={styles.notificationPopupButtonLater}
            onClick={handleDoItLater}
          >
            Skip for now
          </button>
          <Button onClick={handleAddNow} isPrimaryBtn>
            Add now
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotificationPopup;
