import { FC, useEffect, useState } from "react";
import UserInfo from "./components/UserInfo";
import Details from "./components/Details";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./UserDetails.module.scss";
import { User } from "../../../../helpers/types";
import { ConnectionContextContainer } from "../../../Network/Connections/ConnectionContext";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { useWireGuardContext } from "../../../../components/common/WireGuardComponents/WireGuardContext";
type Props = { user?: User | null; tenant: string | undefined };
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";

const UserDetails: FC<Props> = ({ user, tenant }) => {
  const {
    fetchUserDetails,
    fetchCurrSystem,
    currSystem,
    fetchDataNew,
    tunnelsInfo,
    nameDevices,
  } = useWireGuardContext();
  const [device, setDevice] = useState<string | undefined>(nameDevices?.[0]);

  useEffect(() => {
    if (user && tenant) {
      fetchCurrSystem(tenant);
      fetchDataNew(tenant, user.username);
      fetchUserDetails(tenant, user.username, currSystem);
    }
  }, [user, tenant]);

  if (!user) return <PageLoader />;
  return (
    <div>
      <UserInfo
        className={styles.basics}
        device={device || ""}
        onChange={setDevice}
        user={user}
      />
      <Details
        className={styles.basics}
        activeDevice={nameDevices?.[0] || "-"}
        deviceNames={nameDevices}
        details={tunnelsInfo}
      />
    </div>
  );
};

export default withContexts<Props>(UserDetails, [ConnectionContextContainer]);
