import React, { FC, useEffect, useMemo, useState } from "react";
import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./CustomerPortItem.module.scss";
import { mapStringToItem } from "../../../../../../../../helpers/mapStringToItem";
import DropdownBasic from "../../../../../../../../components/common/Dropdown/DropdownBasic";
import PortVlanIcon from "../../../../../../../Network/UnitedConnections/components/Ports/icons/PortVlanIcon";
import { GREY_DISABLED } from "../../../../../../../../helpers/common/colorAliases";
import { DropdownItem } from "../../../../../../../../helpers/types";
import {
  TenantCustomerPort,
  TenantPort,
} from "../../../../../../../Network/UnitedConnections/types";
import IPAddress from "../../../../common/formComponents/IPAddress";
import Input from "../../../../../../../../components/common/formComponents/Input";
import PortBaseIcon from "../../../../../../../Network/UnitedConnections/components/Ports/icons/PortBaseIcon";
import { classNames } from "../../../../../../../../helpers/common/classNames";
import { getPortConnections } from "../../../../../../../Network/UnitedConnections/components/Ports/PortItem/PortItem";
import { useUnitedConnectionContext } from "../../../../../../../Network/UnitedConnections/UnitedConnectionContext";
import { formVlanData } from "../../../../../../../Network/UnitedConnections/components/Clouds/CloudsTabInfo";
import { PortVlan } from "../../../../../../../VLANPage/types";
import { CLOUD_CONNECTIONS, DIA_UNITED_CONNECTIONS } from "../../../../consts";
import {
  CONNECTION_DEFAULT_BANDWIDTH_VALUES,
  NATIVE,
} from "../../../../../../../../helpers/consts";
import { PortData } from "../../../../../../../Network/UnitedConnections/components/Connections/AddDirect";
import { Checkbox } from "../../../../../../../../components/common/formComponents/Checkbox";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: any;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  ports?: Array<TenantCustomerPort>;
  parentPorts?: Array<TenantPort>;
  selectedPort?: TenantCustomerPort;
  selectPort?: (name: string) => void;
  withIP?: boolean;
  disabled?: boolean;
  excluded?: Array<string>;
  onErrorChange?: any;
  commonFields?: any;
  isAzureKeyActive?: boolean;
};

const ONLY_CUSTOMER_PORT = [CLOUD_CONNECTIONS, DIA_UNITED_CONNECTIONS];

type VlanType = PortData["vlan_id"];

const CustomerPortItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  ports,
  parentPorts,
  selectedPort,
  withIP,
  disabled,
  excluded,
  onErrorChange,
  commonFields,
  isAzureKeyActive,
}) => {
  const { fetchConnections, connections } = useUnitedConnectionContext();
  const selectedPortNames = useMemo(() => {
    return (
      commonFields?.connections?.map((connection: any) => connection.name) || []
    );
  }, [commonFields]);
  const availablePorts: Array<TenantCustomerPort> =
    ports?.filter(
      (port) =>
        !selectedPortNames.includes(port.name) &&
        port.assigned_bw > port.used_bw
    ) || [];

  const portsNames = availablePorts?.length
    ? availablePorts.map((port) => port.name)
    : [];

  const [vlanList, setVlanList] = useState<Array<string>>([]);
  const [usedVlanListIsVisible, setUsedVlanListIsVisible] = useState<boolean>(
    false
  );
  const [nativeInUsed, setNativeInUsed] = useState<boolean>(false);
  const [vlanError, setVlanError] = useState<boolean>(
    !fields.vlan_id && nativeInUsed
  );

  useEffect(() => {
    !connections.length && fetchConnections();
  }, []);

  const filteredConnections = useMemo(
    () => getPortConnections(connections, fields.name),
    [fields.name, connections.length]
  );

  useEffect(() => {
    fields.name && updateVlanList(fields.name);
    setNativeInUsed(filteredVlanList.some((el) => el === NATIVE));
  }, [fields.name]);

  useEffect(() => {
    if (selectedPort === fields.name) {
      onChange("name", "");
    }
  }, [selectedPort]);

  const updateVlanList = (val: string) => {
    const customSelectedPort = ports?.find((port) => port.name === val);
    const selectedParent = parentPorts?.find(
      (parent) => parent.name === customSelectedPort?.l2_parent_name
    );
    if (!selectedParent?.trunk || nativeInUsed) {
      setVlanList([]);
    } else {
      setVlanList([NATIVE]);
    }
  };

  const parseAllVlanIds = (
    connection: any,
    selectedPortName: string,
    isCtagInstead?: boolean
  ): Array<VlanType> => {
    const vlanIds: Array<VlanType> = [];

    connection?.members?.forEach((item: any) => {
      if (
        ["port_vlan", "qinq_port_vlan"].includes(item.interface_type) &&
        item.interfaces.length
      ) {
        const vlanInterface = item.interfaces as Array<PortVlan>;

        vlanInterface.forEach((el) => {
          if (el.customer_port_name !== selectedPortName) return;

          const vlanData = formVlanData(el);
          const vlanIdToCheck = isCtagInstead
            ? vlanData.vlan_ctag_id
            : vlanData.vlan_id;

          if (!vlanIdToCheck) {
            vlanIds.push(NATIVE);
          }

          if (vlanIdToCheck && !vlanIds.includes(vlanIdToCheck)) {
            vlanIds.push(vlanIdToCheck);
          }
        });
      }
    });

    return vlanIds;
  };

  const filteredVlanList: Array<VlanType> = useMemo(() => {
    const vlanList: Array<VlanType> = [];
    const filtered = [...filteredConnections];

    filtered.forEach((connection) => {
      const vlanIds = parseAllVlanIds(connection, fields.name, true);
      vlanIds.forEach((vlanId) => {
        if (vlanId !== undefined && !vlanList.includes(vlanId)) {
          vlanList.push(vlanId);
        }
      });
    });

    return vlanList;
  }, [fields.name, filteredConnections.length]);

  const handlePortSelect = async (val: DropdownItem) => {
    onChange("name", val?.key || "");
  };

  const handleChangeBW = (val: string) => {
    onChange("max_bandwidth", val);
  };

  const handleVlanId = (v: string | DropdownItem) => {
    const value = typeof v === "string" ? v : v.key;
    onChange("vlan_id", value);

    const hasError =
      filteredVlanList.includes(parseInt(value)) ||
      ((!value || value.toLowerCase() === "native") && nativeInUsed);

    setVlanError(hasError);
    onErrorChange(hasError);
  };

  const updatedVlanList = vlanList.length === 0 ? [NATIVE] : vlanList;

  const getVlanValue = () => {
    if ((isEdit && fields.isForCreate) || !isEdit) {
      return fields.vlan_id || "";
    }
    return fields.vlan_ctag_id || NATIVE;
  };

  const getVlanDisabledState = () => {
    if (!fields.name) {
      return true;
    }
    if (!isEdit) {
      return disabled;
    }
    return !fields.isForCreate;
  };

  return (
    <ItemContainer
      type={type}
      title={""}
      idx={idx}
      onDelete={
        commonFields?.connections?.length > 2
          ? () => onDelete(idx, fields)
          : undefined
      }
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      <DropdownBasic
        id={"name"}
        onChange={handlePortSelect}
        selected={mapStringToItem(fields.name)}
        label="Port"
        itemsList={portsNames.map(mapStringToItem)}
        errorWithTooltip
        isMedium
        disabled={(isEdit && !fields.isForCreate) || disabled}
        error={errors?.[idx + "name"]}
        icon={
          vlanList.length ? (
            <PortVlanIcon color={GREY_DISABLED} />
          ) : (
            <PortBaseIcon color={GREY_DISABLED} />
          )
        }
        listClassName={styles.list}
      />
      <div
        className={classNames(
          styles.vlanWrapper,
          !vlanList.length && styles.empty
        )}
      >
        <Input
          id={"vlan_id"}
          onChange={(e) => handleVlanId(e.target.value)}
          handleFieldChange={onChange}
          value={getVlanValue()}
          label="VLAN"
          valuesList={updatedVlanList}
          medium
          disabled={getVlanDisabledState()}
          error={vlanError ? "Already in use" : ""}
          errorWithTooltip
          name={"vlan_id"}
          listWrapperClass={styles.vlanList}
          onClick={() => setUsedVlanListIsVisible(!usedVlanListIsVisible)}
          onBlur={() => setUsedVlanListIsVisible(false)}
        />
        {usedVlanListIsVisible && filteredConnections.length ? (
          <div className={styles.usedVlan}>
            <div className={styles.title}>In use:</div>
            {filteredVlanList.map((vlan_id) => (
              <div key={vlan_id}>
                {vlan_id !== undefined ? vlan_id : NATIVE}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className={styles.bw}>
        <DropdownBasic
          id={"bw"}
          label="BW"
          selected={mapStringToItem(fields.max_bandwidth)}
          isMedium
          itemsList={CONNECTION_DEFAULT_BANDWIDTH_VALUES.map(mapStringToItem)}
          onChange={(e) => handleChangeBW(e.key)}
          disabled={disabled || isAzureKeyActive}
        />
      </div>
      <IPAddress
        field={fields.ip_addresses}
        onChange={onChange}
        error={errors?.[idx + "ip_addresses"]}
        withIP={withIP}
        label={"IP address"}
        placeholder={"x.x.x.x/x"}
        isEdit={isEdit}
      />
      {/* Temp.remove until the backend is ready
      {withIP && (
        <div className={styles.checkboxWrapper}>
          <Checkbox
            isChecked={fields.internet_access}
            onChange={() =>
              onChange("internet_access", !fields.internet_access)
            }
            label={"Internet access"}
            disabled={isEdit}
          />
          <div className={styles.trackedInfo}>
            This connection is able to exit the internet
          </div>
        </div>
      )} */}
    </ItemContainer>
  );
};

export default CustomerPortItem;
