import { MIN_RATE_VALUE } from "../../pages/DDoSSystemPage/DDoSConfig/constants";
import { CreateDDoS } from "../../pages/DDoSSystemPage/DDoSConfig/types";
import { MAX_FW_PRIORITY_FOR_MANUAL_CREATION } from "../../pages/Firewall/config/FirewallRuleConfig";
import Validator from "./Validator";

export default function validateDDoS(
  data: CreateDDoS,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.name, "name");
  validator.checkMinMax(
    data.priority,
    [1, MAX_FW_PRIORITY_FOR_MANUAL_CREATION],
    "priority"
  );

  data.name && validator.checkLength(data.name, [1, 128], "name");

  data.name && validator.checkNameFormat(data.name, "name");

  data.src_network &&
    validator.checkIPWithPrefixAll(data.src_network, "src_network");
  data.dst_network &&
    validator.checkIPWithPrefixAll(data.dst_network, "dst_network");

  validator.checkEmpty(data.flood_cooldown_rate, "flood_cooldown_rate");
  validator.checkEmpty(data.flood_detect_rate, "flood_detect_rate");
  validator.checkEmpty(data.hysteresis_seconds, "hysteresis_seconds");
  validator.checkEmpty(data.flood_rate, "flood_rate");
  validator.checkMin(
    data.flood_cooldown_rate,
    MIN_RATE_VALUE,
    "flood_cooldown_rate"
  );
  validator.checkMin(
    data.flood_detect_rate,
    MIN_RATE_VALUE,
    "flood_detect_rate"
  );
  validator.checkMin(data.flood_rate, MIN_RATE_VALUE, "flood_rate");
  validator.checkTrue(
    +data.flood_cooldown_rate <= +data.flood_rate,
    "flood_cooldown_rate",
    "Cooldown rate should be lower or equal than Flood rate"
  );

  if (data.options?.length) {
    data.options.forEach((option, optionIndex) => {
      validator.checkEmpty(option.name, `name_${optionIndex}`);
      validator.checkEmpty(
        option.flood_cooldown_rate,
        `flood_cooldown_rate_${optionIndex}`
      );
      validator.checkEmpty(
        option.flood_detect_rate,
        `flood_detect_rate_${optionIndex}`
      );
      validator.checkEmpty(
        option.hysteresis_seconds,
        `hysteresis_seconds_${optionIndex}`
      );
      validator.checkEmpty(option.flood_rate, `flood_rate_${optionIndex}`);
      validator.checkMin(
        option.flood_cooldown_rate,
        MIN_RATE_VALUE,
        `flood_cooldown_rate_${optionIndex}`
      );
      validator.checkMin(
        option.flood_detect_rate,
        MIN_RATE_VALUE,
        `flood_detect_rate_${optionIndex}`
      );
      validator.checkMin(
        option.flood_rate,
        MIN_RATE_VALUE,
        `flood_rate_${optionIndex}`
      );
      validator.checkTrue(
        +option.flood_cooldown_rate <= +option.flood_rate,
        `flood_cooldown_rate_${optionIndex}`,
        "Cooldown rate should be lower or equal than Flood rate"
      );
    });
  }

  return validator;
}
