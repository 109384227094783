import { FC, useEffect, useState } from "react";
import Device from "./Device";
import { fetchDevices } from "../../helpers/insights";
import { arubaApi } from "../../helpers/api/ArubaApi";
import { withContexts } from "../../helpers/hocs/withContexts";
import TrafficContextContainer from "../TrafficDashboard/TrafficContext";

const TrendsPage: FC<{ tabs: any }> = ({ tabs: Tabs }) => {
  const [list, setList] = useState<Array<any>>([]);

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    const twoHours = 7200;

    const params = {
      startTime: (now - twoHours).toString(),
      endTime: now.toString(),
      granularity: "hour",
      trafficType: "all_traffic",
    };

    fetchDevices().then(async (data) => {
      setList(data);

      const nePks = data?.map((item: any) => item.id);
      const res = await arubaApi.getInterface(params, { nePks });
      const interfaces: Array<any> = res.result || [];

      const items =
        data?.map((item: any) => {
          item.interfaces = interfaces
            .filter((info) => info.nepk === item.id)
            .map((info) => info.INTERFACE);

          return item;
        }) || [];

      setList(items);
    });
  }, []);

  return (
    <>
      <Tabs />

      {list?.map((item, idx) => {
        return <Device key={idx} {...item} />;
      })}
    </>
  );
};

export default withContexts<{ tabs: any }>(TrendsPage, [
  TrafficContextContainer,
]);
