import { useEffect, useState } from "react";
import styles from "./DDoSDIAConfig.module.scss";
import { ADD, EDIT } from "../../../helpers/common/constantsAlias";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import sendRequest from "../../../helpers/sendRequest";
import DialogBtmButtons from "../../../components/dialogs/common/DialogBtmButtons";
import { isEditMode } from "../../../helpers/isEditMode";
import validateDDoS from "../../../helpers/validators/DDoSValidator";
import { MAX_FW_PRIORITY_FOR_MANUAL_CREATION } from "../../PBRPage/PBRConfig/PBRConfig";
import { generatePriority } from "../../Firewall/config/FirewallRuleConfig";
import { CollapsableTwoColumnLayout } from "../../../components/leftInfoBlock/LayoutTwoColumnFactory";
import SystemsSection from "./SystemsSection";
import {
  getFlatData,
  getPreparedData,
} from "../../DDoSSystemPage/DDoSConfig/helpers";
import { CreateDDoS } from "../../DDoSSystemPage/DDoSConfig/types";
import OptionsSection from "./OptionsSection";
import ActionSection from "../../DDoSSystemPage/DDoSConfig/ActionSection";
import GeneralSection from "../../DDoSSystemPage/DDoSConfig/GeneralSection";
import DDoSInfo from "../../DDoSSystemPage/DDoSConfig/DDoSInfo";
import { useDDoSDIAContext } from "../../../contexts/servicesContext/DDoSDIAContext";
import { DDoSType, VRFType } from "../../../helpers/api/apiTypes";
import { VRF_INTERNET } from "../../../helpers/consts";
import { checkIsFullSystem } from "../../../components/common/navigation/OneSystemTabs";
import { System } from "../../Systems/Provisioning/types";
import { DDoSDIAConfigProps, SourceInterfaces } from "./types";
import { DDOS_DIA_CONFIG_DEFAULT_FIELDS } from "./constants";
import RuleSection from "./RuleSection";
import { DDOS_CONFIG_DEFAULT_FIELDS } from "../../DDoSSystemPage/DDoSConfig/constants";

const DDoSDIAConfig = ({
  onClose,
  type,
  data,
  systems = [],
  systemList,
  systemListRequestStatus,
  sourceInterfaces = {},
  isTemplate = false,
  groupName,
  viewOnly,
}: DDoSDIAConfigProps) => {
  const [selectedSystems, setSelectedSystems] = useState<Array<string>>(
    systems
  );
  const [
    selectedSourceInterfaces,
    setSelectedSourceInterfaces,
  ] = useState<SourceInterfaces>(sourceInterfaces);

  const {
    fetchList,
    addMultiple,
    addStatus,
    editMultiple,
    editStatus,
    ddosGroups = {},
    selectedDDoS,
  } = useDDoSDIAContext();
  const { userGroups } = useFirewallContext();

  const [fields, handleFieldChange] = useFormField<CreateDDoS>(
    getFlatData(data) ||
      (isTemplate ? DDOS_DIA_CONFIG_DEFAULT_FIELDS : DDOS_CONFIG_DEFAULT_FIELDS)
  );
  const [errors, validate] = useValidation<CreateDDoS>(validateDDoS, [fields]);

  useEffect(() => {
    fetchList(isTemplate);
  }, []);

  useEffect(() => {
    if (!selectedSystems.length) {
      setSelectedSystems(
        systemList
          ?.filter((system) => checkIsFullSystem((system as System).type))
          .map((system) => system.name) ?? []
      );
    }
  }, [systemList]);

  useEffect(() => {
    if (ddosGroups) {
      const priority = data
        ? data.priority
        : generatePriority(Object.values(ddosGroups).flat());
      handleFieldChange("priority", priority);
    }
  }, [ddosGroups]);

  const handleAdd = async () => {
    const { isOk } = validate();

    if (!isOk && selectedSystems.length) return;

    const newFields = selectedSystems.reduce(
      (res: Record<string, Partial<DDoSType>>, curr) => {
        const ingressVI = selectedSourceInterfaces[curr];
        res[curr] = {
          ...getPreparedData({ ...fields, ingress_vi: ingressVI }),
          scope_type: isTemplate ? "tenant_template" : "system_global",
        };
        return res;
      },
      {}
    );
    await sendRequest(
      isOk,
      addMultiple(newFields, { selectedSystems, isTemplate }),
      onClose
    );
  };

  const handleEdit = async () => {
    if (!data) return;
    const { isOk } = validate();
    if (!isOk && selectedSystems.length) return;

    const newFields = selectedSystems.reduce(
      (res: Record<string, Partial<DDoSType>>, curr) => {
        const ingressVI = selectedSourceInterfaces[curr];
        res[curr] = {
          ...getPreparedData({ ...fields, ingress_vi: ingressVI }),
          scope_type: isTemplate ? "tenant_template" : "system_global",
        };
        return res;
      },
      {}
    );

    await sendRequest(
      isOk,
      editMultiple(newFields, {
        selectedSystems,
        isTemplate,
        ddosName: groupName,
      }),
      onClose
    );
  };

  const gridTemplate = "1fr 0.5fr 0.5fr";

  return (
    <CollapsableTwoColumnLayout
      InfoBlock={() => (
        <DDoSInfo tenant={VRF_INTERNET} selectedDDoS={selectedDDoS} />
      )}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <GeneralSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            maxPriority={MAX_FW_PRIORITY_FOR_MANUAL_CREATION}
            gridTemplate={gridTemplate}
            viewOnly={viewOnly}
          />
          <SystemsSection
            className={styles.contentWrapper}
            gridTemplate={gridTemplate}
            errors={errors}
            selectedSystems={selectedSystems}
            setSelectedSystems={setSelectedSystems}
            systemList={systemList?.map((system) => system.name)}
            systemListRequestStatus={systemListRequestStatus}
            viewOnly={viewOnly}
          />
          <RuleSection
            fields={fields}
            className={styles.contentWrapper}
            errors={errors}
            onChange={handleFieldChange}
            groups={userGroups}
            gridTemplate={gridTemplate}
            selectedVRF={
              {
                name: VRF_INTERNET,
                vrf_type: VRF_INTERNET,
              } as VRFType
            }
            systems={
              systemList?.filter((system) =>
                selectedSystems.includes(system.name)
              ) as Array<System>
            }
            selectedSourceInterfaces={selectedSourceInterfaces}
            setSelectedSourceInterfaces={setSelectedSourceInterfaces}
            viewOnly={viewOnly}
          />
          <ActionSection
            className={styles.contentWrapper}
            fields={fields}
            onChange={handleFieldChange}
            gridTemplate={gridTemplate}
            errors={errors}
            viewOnly={viewOnly}
          />
          {isTemplate && (
            <OptionsSection
              className={styles.contentWrapper}
              fields={fields}
              onChange={handleFieldChange}
              gridTemplate={gridTemplate}
              errors={errors}
              viewOnly={viewOnly}
            />
          )}
        </div>
        <div className={styles.footer}>
          <DialogBtmButtons
            controls={
              viewOnly
                ? {
                    cancelText: "Cancel",
                    onCancel: onClose,
                  }
                : {
                    okText: isEditMode(type) ? EDIT : ADD,
                    onOk: isEditMode(type) ? handleEdit : handleAdd,
                    cancelText: "Cancel",
                    onCancel: onClose,
                  }
            }
            errorDisplay={addStatus || editStatus}
          />
        </div>
      </div>
    </CollapsableTwoColumnLayout>
  );
};

export default DDoSDIAConfig;
