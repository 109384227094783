import { useLocation } from "react-router-dom";
import {
  APPLICATIONS_STRING,
  CYBER_THREATS_STRING,
  DNS_STRING,
  NETWORK,
  OTHER,
} from "../../../../helpers/consts";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  APPLICATIONS,
  CYBER_THREATS,
  DNS,
  INSIGHTS,
  TOPOLOGY,
  TRAFFIC,
} from "../../../../helpers/navigation/entries";

export const getIsApplicationOrNetworkURL = () => {
  const location = useLocation();
  const path = location.pathname;

  if (path.includes(formPathFromArray([INSIGHTS(), APPLICATIONS(), DNS()]))) {
    return DNS_STRING;
  }
  if (
    path.includes(formPathFromArray([INSIGHTS(), APPLICATIONS()])) ||
    path.includes(formPathFromArray([INSIGHTS(), TOPOLOGY()]))
  ) {
    return APPLICATIONS_STRING;
  } else if (path.includes(formPathFromArray([INSIGHTS(), TRAFFIC()]))) {
    return NETWORK;
  } else if (path.includes(formPathFromArray([INSIGHTS(), CYBER_THREATS()]))) {
    return CYBER_THREATS_STRING;
  } else {
    return OTHER;
  }
};
