import { CustomerPortType } from "../../Network/UnitedConnections/types";
import parseEnrichedData from "../../../helpers/ASNEnrichedDataParser";
import {
  APPROVED_CAPITAL,
  DECLINED_CAPITAL,
  PENDING_CAPITAL,
} from "../../../helpers/consts";
import StatusLabel from "../../Systems/Provisioning/table/StatusLabel";
import styles from "./asnBottomInventoryHeader.module.scss";
import ReactTooltip from "react-tooltip";

export const renderItemWithPopup = (
  length: number,
  ips: Array<string>,
  className: any
) => {
  if (length <= 0) return <div className={className}>{length}</div>;
  return (
    <div>
      <div data-tip data-for={`tooltip-${className}-${length}`}>
        <div className={styles.underPopupElement}>
          <div className={className}>{length}</div>
        </div>
      </div>
      <ReactTooltip
        id={`tooltip-${className}-${length}`}
        aria-haspopup="true"
        place="top"
        type="light"
        className={styles.tooltip}
      >
        <div className={styles.tooltipTitle}>Available IPs:</div>
        {ips.map((ip, idx) => (
          <div key={idx} className={styles.tooltipValue}>
            {ip}
          </div>
        ))}
      </ReactTooltip>
    </div>
  );
};

export const asnBottomInventoryHeader = (): Array<any> => {
  return [
    {
      Header: "ASN",
      accessor: "asn",
      Cell: ({ cell: { value } }: any) => {
        return value || "";
      },
    },
    {
      Header: "AS-Name",
      accessor: "as_name_parsed", // non-existing accessor to avoid duplication, we use parser
      Cell: ({ row }: any) => {
        const { asName } = parseEnrichedData(row.original.enriched_data);
        return asName || "";
      },
    },
    {
      Header: "IP Prefixes (#)",
      accessor: "ip_prefixes", // non-existing accessor to avoid duplication, we use parser
      Cell: ({ row }: any) => {
        const ipv4_length = row.original.ipv4_prefix_list?.length;
        const ipv6_length = row.original.ipv6_prefix_list?.length;
        return (
          <div className={styles.prefixesWrapper}>
            {renderItemWithPopup(
              ipv4_length,
              row.original.ipv4_prefix_list,
              styles.ipv4
            )}
            {renderItemWithPopup(
              ipv6_length,
              row.original.ipv6_prefix_list,
              styles.ipv6
            )}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "approved",
      Cell: ({ row }: any) => {
        const asn = row.original;
        let text = "N/A";
        if (!asn.approved && !asn.labels?.reason_for_decline) {
          text = PENDING_CAPITAL;
        }
        if (!asn.approved && asn.labels?.reason_for_decline) {
          text = DECLINED_CAPITAL;
        }
        if (asn.approved) {
          text = APPROVED_CAPITAL;
        }
        return <StatusLabel status={text} />;
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof CustomerPortType,
  }));
};
