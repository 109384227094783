import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import { withContexts } from "../../helpers/hocs/withContexts";
import TenantVirtualInterfaceContextContainer from "../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import FirewallContextContainer from "../../contexts/servicesContext/FirewallContext";
import VRFSelectorContextContainer from "../../contexts/systemsContext/VRFSelectorContext";
import DDoSDIATable from "./DDoSDIATable";
import DDoSDIAContextContainer from "../../contexts/servicesContext/DDoSDIAContext";
import DDoSContextContainer from "../../contexts/servicesContext/DDoSContext";

const DDoSDIAPage = ({ tabs: Tabs }: PageProps) => {
  return (
    <div>
      {Tabs && <Tabs />}
      <DDoSDIATable />
    </div>
  );
};
export default withLayout<PageProps>(
  withContexts(DDoSDIAPage, [
    DDoSContextContainer,
    DDoSDIAContextContainer,
    FirewallContextContainer,
    TenantVirtualInterfaceContextContainer,
    VRFSelectorContextContainer,
  ])
);
