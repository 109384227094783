import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CloudRouterTab } from "../../../components/common/navigation/Network/CloudRouterTab";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../../helpers/navigation";
import { ARPS, BGP } from "../../../helpers/navigation/entries";
import ServiceBGP from "../../../pages/ServiceBGP";
import ARPsPage from "../../../pages/ARPsPage";

export default function Dia(): ReactElement {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(ARPS(), path)} />
      </Route>
      <Route path={path + "/" + ARPS().path}>
        <ARPsPage
          tabs={(props) => <CloudRouterTab {...props} isCloud={false} />}
        />
      </Route>
      <Route path={path + "/" + BGP().path}>
        <ServiceBGP
          tabs={(props) => <CloudRouterTab {...props} isCloud={false} />}
          isCloud={false}
        />
      </Route>
    </Switch>
  );
}
