import { FC } from "react";
import { PageProps } from "../../helpers/types";
import styles from "./ServiceBGPContent.module.scss";
import ServicesBGPPage from "./components/ServiceBGPPage/ServicesBGPPage";
import { withLayout } from "../../helpers/hocs/withLayout";

type BGPContentProps = PageProps & {
  isCloud?: boolean;
};

const BGPContent = ({ tabs: Tabs, isCloud }: BGPContentProps) => {
  return (
    <div className={styles.container}>
      <Tabs showTimeRange />
      <ServicesBGPPage isCloud={isCloud} />
    </div>
  );
};

export default withLayout<BGPContentProps>(BGPContent);
