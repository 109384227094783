import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { PRes } from "../../helpers/api/Api";
import { configApi } from "../../helpers/api/ConfigApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import {
  APPROVED_CAPITAL,
  DECLINED_CAPITAL,
  PENDING_CAPITAL,
} from "../../helpers/consts";
import { RequestStatus } from "../../helpers/types";
import { CustomerPortType } from "../Network/UnitedConnections/types";

type RequestStatuses = {
  state: "pending" | "ok" | "error" | "idle";
  message?: string;
};
type State = {
  list: Array<any>;
  combinedPortsList: Array<any>;
  asnList: Array<any>;
  combinedASNList: Array<any>;
  asnForSpecificTenantList: Array<any>;
  asnForSpecificTenantCount: number;
  count: number;
  asnCount: number;
  listStatus?: RequestStatus;
  asnListStatus?: RequestStatus;
  deleteRequestStatus?: RequestStatus;
  selectedOrderedPortsMode?: string;
  selectedASNMode?: string;
  singleASNInfo: {
    asn: number;
    enriched_data: string;
    ipv4_prefix_list: any;
    ipv6_prefix_list: any;
  };
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchData: () => Promise<void>;
  fetchASNData: () => Promise<void>;
  fetchASNForSpecificTenantData: (tenant: string) => Promise<void>;
  approveOrDeclineASN: (
    tenant: string,
    name: string,
    approved: boolean,
    reasonForDecline?: string
  ) => PRes<any>;
  registerNewASN: (tenant: string, name: string) => PRes<any>;
  deleteCustomerPort: (tenant: string, name: string) => PRes<any>;
  deleteASN: (tenant: string, asn: string) => PRes<any>;
  getSingleASN: (asn: string) => Promise<void>;
  setSelectedOrderedPortsMode: (mode: string) => void;
  setSelectedASNMode: (mode: string) => void;
};

const [OrderedPortsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useOrderedPortsContext = useContext;

export default class OrderedPortsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = OrderedPortsContext;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      list: [],
      combinedPortsList: [],
      count: 0,
      asnList: [],
      combinedASNList: [],
      asnForSpecificTenantList: [],
      asnForSpecificTenantCount: 0,
      asnCount: 0,
      singleASNInfo: {
        asn: 0,
        enriched_data: "",
        ipv4_prefix_list: undefined,
        ipv6_prefix_list: undefined,
      },
      listStatus: { state: "idle" },
      asnListStatus: { state: "idle" },
      deleteRequestStatus: { state: "idle" },
      selectedOrderedPortsMode: PENDING_CAPITAL,
      selectedASNMode: PENDING_CAPITAL,
      state: "idle",
    };
  }

  fetchData = async (): Promise<void> => {
    this.setState({ listStatus: setPending("Fetching") });
    const res = await configApi.getOrderedPortsList();
    if (res.ok && res.result) {
      const { selectedOrderedPortsMode } = this.state;

      const filteredList = res.result.filter(
        (port: CustomerPortType) =>
          (selectedOrderedPortsMode === PENDING_CAPITAL
            ? port.request_status === "pending"
            : port.request_status === "assigned") && port.labels?.building_type // building_type means only customer ports
      );

      this.setState({
        combinedPortsList: res.result,
        list: filteredList,
        count: filteredList.length || 0,
      });
    }

    this.setState({ listStatus: resToState(res) });
  };

  fetchASNData = async (): Promise<void> => {
    this.setState({ asnListStatus: setPending("Fetching") });

    const res = await configApi.getASNList();
    if (res.ok && res.result) {
      const { selectedASNMode } = this.state;

      const filteredList = res.result.filter((asn: any) => {
        if (selectedASNMode === PENDING_CAPITAL) {
          return !asn.approved && !asn.labels?.reason_for_decline;
        }
        if (selectedASNMode === DECLINED_CAPITAL) {
          return !asn.approved && asn.labels?.reason_for_decline;
        }
        if (selectedASNMode === APPROVED_CAPITAL) {
          return asn.approved;
        }
        return false;
      });

      this.setState({
        combinedASNList: res.result,
        asnList: filteredList,
        asnCount: filteredList.length,
      });
    }

    this.setState({ asnListStatus: resToState(res) });
  };

  fetchASNForSpecificTenantData = async (tenant: string): Promise<void> => {
    this.setState({ asnListStatus: setPending("Fetching") });

    const res = await configApi.getASNListForSpecificTenant(tenant);
    if (res.ok && res.result) {
      this.setState({
        asnForSpecificTenantList: res.result,
        asnForSpecificTenantCount: res.result.length,
      });
    }

    this.setState({ asnListStatus: resToState(res) });
  };

  approveOrDeclineASN = async (
    tenant: string,
    name: string,
    approved: boolean,
    reasonForDecline?: string
  ): PRes<any> => {
    const res = await configApi.approveOrDeclineASN(
      tenant,
      name,
      approved,
      reasonForDecline
    );
    if (res.ok) {
      await this.fetchASNData();
    }
    return res;
  };

  registerNewASN = async (tenant: string, asn: string): PRes<any> => {
    const res = await configApi.registerNewASN(tenant, asn);
    if (res.ok) {
      await this.fetchASNData();
      await this.fetchASNForSpecificTenantData(tenant);
    }
    return res;
  };

  setSelectedOrderedPortsMode = (mode: string) => {
    this.setState({ selectedOrderedPortsMode: mode });
  };

  setSelectedASNMode = (mode: string) => {
    this.setState({ selectedASNMode: mode });
  };

  deleteCustomerPort = async (tenant: string, name: string): PRes<any> => {
    this.setState({ deleteRequestStatus: setPending("Fetching") });
    const res = await configApi.deleteCustomerPort(tenant, name);
    this.setState({ deleteRequestStatus: resToState(res) });
    if (res.ok) {
      await this.fetchData();
    }
    return res;
  };

  deleteASN = async (tenant: string, asn: string): PRes<any> => {
    this.setState({ deleteRequestStatus: setPending("Fetching") });
    const res = await configApi.deleteASN(tenant, asn);
    this.setState({ deleteRequestStatus: resToState(res) });
    if (res.ok) {
      await this.fetchASNData();
      await this.fetchASNForSpecificTenantData(tenant);
    }
    return res;
  };

  getSingleASN = async (asn: string): Promise<void> => {
    const res = await configApi.getSingleASN(asn);
    if (res.ok) {
      this.setState({ singleASNInfo: res.result });
    }
  };

  funcs = {
    fetchData: this.fetchData,
    fetchASNData: this.fetchASNData,
    fetchASNForSpecificTenantData: this.fetchASNForSpecificTenantData,
    deleteCustomerPort: this.deleteCustomerPort,
    deleteASN: this.deleteASN,
    getSingleASN: this.getSingleASN,
    approveOrDeclineASN: this.approveOrDeclineASN,
    registerNewASN: this.registerNewASN,
    setSelectedOrderedPortsMode: this.setSelectedOrderedPortsMode,
    setSelectedASNMode: this.setSelectedASNMode,
  };
}
