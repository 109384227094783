import { FC, useEffect, useMemo, useState } from "react";

import DropDownInput, {
  mapStringToItem,
} from "../../../../../../../components/common/formComponents/DropDownInput";
import { withContexts } from "../../../../../../../helpers/hocs/withContexts";
import Input, {
  types,
} from "../../../../../../../components/common/formComponents/Input";

import styles from "./styles.module.scss";
import LocationIcon from "../../../../../../../components/icons/LocationIcon";
import { REGIONS } from "../../utils/regions";
import CaretOutline from "../../../../../../../components/icons/CaretOutline";
import { CloudFieldsProps } from "../../components/CloudForm/AddCloudForm";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import InputWithStatus from "../../../../../../../components/common/InputWithStatus/InputWithStatus";
import VnetContextContainer, { useVnetContext } from "./VnetContext";
import { AzureAccessKey } from "../../../../../types";
import { TokenPlug } from "../TokenPlug";
import { useValidation } from "../../../../../../../helpers/validators/Validator";
import { CloudCredentialsFields } from "../../../../../../CloudsConnectivityPage/components/CloudCredentials";
import validateCloudCredentials from "../../../../../../../helpers/validators/CloudCredentialsValidator";
import CloudsConnectivityContext, {
  useCloudsConnectivityContext,
} from "../../../../../../CloudsConnectivityPage/CloudsConnectivityContext";
import { classNames } from "../../../../../../../helpers/common/classNames";
import { AZURE_LOWERCASE } from "../../../../../../../helpers/consts";

type Props = {
  onChange: any;
  fields: CloudFieldsProps;
  error?: string;
};

const DEFAULT_FIELDS: AzureAccessKey = {
  tenant_id: "",
  client_id: "",
  client_secret: "",
  subscription_id: "",
  description: "",
};

const VnetList: FC<Props> = ({ fields: externalFields, onChange, error }) => {
  const { user } = useUserContext();
  const { list = [], fetchList, listStatus, groups } = useVnetContext();
  const {
    tokens,
    correctTokens,
    getAccessKeys,
    deleteAccessKey,
    createAccessKey,
  } = useCloudsConnectivityContext();

  const [fields, handleFieldChange, resetFields] = useFormField(DEFAULT_FIELDS);
  const [showList, setShowList] = useState<boolean>(false);
  const [errors, validate] = useValidation<CloudCredentialsFields>(
    validateCloudCredentials,
    [fields]
  );
  const type = AZURE_LOWERCASE;
  const isToken = correctTokens?.[type];

  useEffect(() => {
    if (!user) return;

    getAccessKeys(user.name, type);
    return () => {
      resetFields(DEFAULT_FIELDS);
    };
  }, [user]);

  useEffect(() => {
    if (tokens && type) {
      const fields = tokens[type];
      if (fields) {
        correctTokens?.[type]
          ? resetFields(fields)
          : resetFields(DEFAULT_FIELDS);
      }
    }
  }, [tokens, type, correctTokens]);

  useEffect(() => {
    if (externalFields.region && isToken && user?.name) {
      setShowList(true);
      fetchList(externalFields.region, user.name);
    }
  }, [externalFields.region, user?.name]);

  const handleCheckBlur = async () => {
    const noToken =
      !isToken &&
      fields.client_id &&
      fields.client_secret &&
      fields.subscription_id;
    if (noToken) {
      const { isOk } = validate();
      if (isOk && user?.name) {
        if (tokens?.[type]) await deleteAccessKey(user.name, type);
        await createAccessKey(fields, user.name, type);
      }
    }
  };

  const regionsList = useMemo(() => (isToken ? REGIONS[type] : []), [isToken]);

  const getTokenInputs = () => {
    const tokenIsExpired = correctTokens?.[type] === false;
    const titleStyle = classNames(
      styles.title,
      tokenIsExpired && styles.orange
    );
    return (
      <div className={styles.tokenFields}>
        <div className={titleStyle}>
          {tokenIsExpired
            ? "Token is expired/incorrect. Please update:"
            : "Token"}
        </div>
        <div className={styles.field}>
          <Input
            value={fields.tenant_id}
            name={"tenant_id"}
            onChange={(e) => handleFieldChange("tenant_id", e.target.value)}
            isFullValue
            medium
            error={errors?.tenant_id}
            placeholder="Tenant Id"
            onBlur={handleCheckBlur}
            errorWithTooltip
          />
          <Input
            value={fields.client_id}
            name={"client_id"}
            onChange={(e) => handleFieldChange("client_id", e.target.value)}
            isFullValue
            medium
            error={errors?.client_id}
            placeholder="Client Id"
            onBlur={handleCheckBlur}
            errorWithTooltip
          />
          <Input
            value={fields.client_secret}
            name={"client_secret"}
            onChange={(e) => handleFieldChange("client_secret", e.target.value)}
            isFullValue
            medium
            error={errors?.client_secret}
            placeholder="Client Secret Id"
            onBlur={handleCheckBlur}
            type={types.PASSWORD}
            errorWithTooltip
          />
          <Input
            value={fields.subscription_id}
            name={"subscription_id"}
            onChange={(e) =>
              handleFieldChange("subscription_id", e.target.value)
            }
            isFullValue
            medium
            error={errors?.subscription_id}
            placeholder="Subsription Id"
            onBlur={handleCheckBlur}
            errorWithTooltip
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {!isToken || !correctTokens?.[type] ? getTokenInputs() : <TokenPlug />}
      <DropDownInput
        id={"vneListCloudRegion"}
        selected={mapStringToItem(externalFields.region)}
        isMedium
        onChange={(val) => onChange("region", val.key)}
        label="Cloud region"
        itemsList={regionsList.map(mapStringToItem)}
        icon={<LocationIcon />}
        customDropdownIcon={
          externalFields.region ? <CaretOutline /> : undefined
        }
        error={showList && error ? "" : error}
      />
      {showList && (
        <InputWithStatus
          status={listStatus}
          pendingMessage={"Discovering Vnets"}
        >
          <DropdownBasic
            id={"vneListVnet"}
            listClassName={styles.dropdownList}
            onChange={(val) => {
              onChange("vpc_id", val.key);
              onChange("resource_group_name", groups?.[val.key]);
            }}
            selected={mapStringToItem(externalFields.vpc_id)}
            label="Vnet"
            itemsList={list.map((key) => mapStringToItem(key))}
            isMedium
            error={listStatus.state === "error" ? listStatus.message : error}
          />
        </InputWithStatus>
      )}
    </>
  );
};

export default withContexts<Props>(VnetList, [
  VnetContextContainer,
  CloudsConnectivityContext,
]);
