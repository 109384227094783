import React, { FC, useEffect } from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { withWizardToolLayout } from "../../../helpers/hocs/withWizardToolLayout";
import { PageProps } from "../../../helpers/types";
import StepCounter from "../common/StepCounter";
import ConnectionsConfigurator from "./components/ConnectionsConfigurator";
import ConnectionsScheme from "./components/ConnectionsScheme/ConnectionsScheme";
import { useConnectionsContext } from "./ConnectionsContext";
import styles from "./ConnectionStep.module.scss";

const ConnectionStep: FC = () => {
  const { fetchData } = useConnectionsContext();
  const { user } = useUserContext();
  useEffect(() => {
    if (user) {
      fetchData(user.name);
    }
  }, [user]);

  return (
    <div className={styles.wrapper}>
      <StepCounter />
      <ConnectionsScheme />
      <ConnectionsConfigurator />
    </div>
  );
};

export default withWizardToolLayout<PageProps>(ConnectionStep);
