import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import {
  ZAYO_CONNECTIONS,
  CONNECTIVITY,
  CONNECTIONS_UNITED,
  CLOUD_ROUTER,
  REMOTE_CONFIG,
  REMOTE_USERS,
  USERS,
  LOOKING_GLASS,
  DIA_ROUTER,
} from "../../helpers/navigation/entries";
import { Routes } from "../../pages/common";
import { RouteEntry } from "../../pages/types";
import { useUserContext } from "../../contexts/UserContext";
import Connections from "../../pages/ZayoPages/Connections";
import Connectivity from "./components/Connectivity";
import CloudRouter from "./components/CloudRouter";
import IPsecRemoteConfig from "./components/IPsecRemoteConfig";
import TenantRemoteUsers from "./components/RemoteUsers";
import UnitedConnections from "../../pages/Network/UnitedConnections";
import LookingGlassRoutes from "./components/LookingGlass";
import Dia from "./components/Dia";

export default function Network(): ReactElement {
  const { isZayo, isDemo } = useUserContext();
  const path = useMatchPath();
  const defPath = isZayo || isDemo ? CONNECTIVITY() : CLOUD_ROUTER();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(defPath, path)} />
      </Route>
      <Routes
        routes={servicesRoutes}
        tabs={(<></>) as any}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
}

const servicesRoutes: Array<RouteEntry> = [
  { pathFunc: ZAYO_CONNECTIONS, component: Connections },
  { pathFunc: CONNECTIVITY, component: Connectivity },
  { pathFunc: CLOUD_ROUTER, component: CloudRouter },
  { pathFunc: DIA_ROUTER, component: Dia },
  { pathFunc: LOOKING_GLASS, component: LookingGlassRoutes },
  { pathFunc: REMOTE_CONFIG, component: IPsecRemoteConfig },
  { pathFunc: REMOTE_USERS, component: TenantRemoteUsers },
  { pathFunc: USERS, component: TenantRemoteUsers },
  { pathFunc: CONNECTIONS_UNITED, component: UnitedConnections },
];
