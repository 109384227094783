import { networkApi } from "./../networkApi";
import { useCallback, useEffect, useState } from "react";
import { RequestState } from "../../types";
import { ERROR, OK, PENDING } from "../../common/RequestStateHelpers";
import { useUserContext } from "../../../contexts/UserContext";

type Type = [Array<string> | null, RequestState];

export const useSegmentsList = (): Type => {
  const { user } = useUserContext();

  const [requestStatus, setRequestStatus] = useState<RequestState>(PENDING);
  const [list, setList] = useState<Array<string> | null>(null);

  const fetchList = useCallback(async (tenant: string) => {
    const { ok, result } = await networkApi.getSegmentations(tenant);

    const isError = !ok || !result;

    const segments = result?.items
      ? result?.items.map((seg) => seg.name)
      : null;

    setList(isError ? null : segments);
    setRequestStatus(isError ? ERROR : OK);
  }, []);

  useEffect(() => {
    if (user) {
      fetchList(user.name);
    }
  }, [user]);

  return [list, requestStatus];
};
