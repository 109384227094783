export enum LookingGlassCommands {
  TRACERT = "tracert",
  PING = "ping",
  MTU = "mtu",
  ROUTE_LOOKUP = "route_lookup",
  PCAP = "pcap",
}

export type LookingGlassFields = {
  ip: string;
  location: string;
  cmd: LookingGlassCommands;
  protocol: string;
  max_mtu: number;
  min_mtu: number;
  pcapType: string;
  size: number;
  maxPackets: number;
  l2Interface: string;
  l2InterfaceLabel: string;
  timeoutSec: number;
  isDia?: boolean;
};
