import React, { ReactElement } from "react";

function EmptyConnectionsPlaceholderIcon(): ReactElement {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 170 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="44"
        y="42"
        width="78"
        height="81"
        rx="6"
        fill="white"
        stroke="#D5D7EB"
        strokeWidth="2"
      />
      <rect
        x="36"
        y="41"
        width="94"
        height="73"
        rx="6"
        fill="#DED9FF"
        stroke="#BDB2FF"
        strokeWidth="1.4"
      />
      <rect
        x="36"
        y="41"
        width="94"
        height="73"
        rx="6"
        fill="white"
        stroke="#D5D7EB"
        strokeWidth="2"
      />
      <rect x="36" y="50" width="94" height="1.4" fill="#D5D7EB" />
      <path
        d="M49 71L117 71"
        stroke="#D5D7EB"
        strokeWidth="2"
        strokeDasharray="2.8 2.8"
      />
      <path
        d="M49 81L117 81"
        stroke="#D5D7EB"
        strokeWidth="2"
        strokeDasharray="2.8 2.8"
      />
      <path
        d="M49 91L117 91"
        stroke="#D5D7EB"
        strokeWidth="2"
        strokeDasharray="2.8 2.8"
      />
      <rect x="48" y="68" width="3" height="26" fill="white" />
      <rect x="114" y="68" width="3" height="26" fill="white" />
      <circle cx="118.353" cy="119.431" r="21.519" fill="#F6F4FF" />
      <circle
        cx="118.353"
        cy="119.431"
        r="20.519"
        stroke="#6B55F0"
        strokeOpacity="0.3"
        strokeWidth="2"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.583 110.824H117.123V118.202L109.746 118.202V120.661H117.123V128.039H119.583V120.661H126.961V118.202L119.583 118.202V110.824Z"
        fill="#6B55F0"
      />
    </svg>
  );
}

export default EmptyConnectionsPlaceholderIcon;
