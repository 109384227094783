import { FC } from "react";
import styles from "./ASNHeader.module.scss";
import IconBlankButton from "../../../components/common/buttons/IconBlankButton";
import { classNames } from "../../../helpers/common/classNames";
import CaretUp from "../../../components/icons/CaretUp";
import AddButton from "../../../components/common/buttons/AddButton";

type Props = {
  title: string;
  isCollapsible: boolean;
  toggleCollapse: () => void;
  isCollapsed: boolean;
  className: string;
  amount: number;
  onRegisterASN: () => void;
};

const ASNHeader: FC<Props> = ({
  title,
  isCollapsible,
  toggleCollapse,
  isCollapsed,
  className,
  amount,
  onRegisterASN,
}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        isCollapsed && styles.fullBorder
      )}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.tableControls}>
        <AddButton
          onClick={onRegisterASN}
          className={styles.addButton}
          label="Set up new"
        />
        {isCollapsible && <div className={styles.amountLabel}>{amount}</div>}
        {isCollapsible ? (
          <>
            <IconBlankButton
              id={"asnBottomCollapse"}
              onClick={toggleCollapse}
              className={classNames(
                styles.collapseBtn,
                !isCollapsed && styles.rotate
              )}
              icon={CaretUp}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};
export default ASNHeader;
