/* eslint-disable prettier/prettier */
import React, { FC, ReactNode, useEffect, useState } from "react";

import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { RequestStatus, ServiceType } from "../../helpers/types";
import { tenantApi } from "../../helpers/api/TenantApi";
import { userApi } from "../../helpers/api/UserApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import {
  getFullServiceList,
  getModifiedServicesList,
} from "./helpers/getModifiedServicesList";
import {
  Service,
  ServiceAPI,
  ServicesPreferencesAPI,
  TenantConsumersCount,
} from "./types";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";

type IFunc = {
  fetchServices: (tenant: string) => Promise<void>;
  editService: (service: ServiceAPI, tenant: string) => Promise<boolean>;
  selectService: (service?: Service) => void;
  fetchTenantConsumersCount: (tenant: string) => Promise<void>;
};

type IState = {
  list?: Array<Service>;
  getService: (type: ServiceType) => Service | undefined;
  groupedList: { [key: string]: Array<Service> };
  fetchStatus?: RequestStatus;
  editStatus?: RequestStatus;
  selectedService?: Service;
  error?: string;
  tenantConsumersCount: TenantConsumersCount;
};

type Props = {
  children: ReactNode;
};

const [ServicesPreferencesContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useServicesPreferencesContext = useContext;

// for now always display enabled cloud router without possibility to change https://gitlab.com/insidepacket/planning/-/issues/3461
const injectMockServices = (defaultServices: ServicesPreferencesAPI) => {
  const services = [...defaultServices.services];

  let cloudRouter = services.find((s) => s.type === "mesh");
  if (!cloudRouter) {
    const natIndex = services.findIndex((s) => s.type === "nat");
    cloudRouter = {
      enable: true,
      type: "mesh",
      data: {},
    };
    services.splice(natIndex, 0, cloudRouter);
  }
  return services;
};

export const ServicesPreferencesContextContainer: FC<Props> = ({
  children,
}) => {
  const [groupedList, setGroupedList] = useState<{
    [key: string]: Array<Service>;
  }>({});
  const [list, setList] = useState<Array<Service>>([]);
  const [fetchStatus, setFetchStatus] = useState<RequestStatus | undefined>();
  const [selectedService, setSelectedService] = useState<Service | undefined>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [tenantConsumersCount, setTenantConsumersCount] = useState<
    TenantConsumersCount | undefined
  >(undefined);

  const getService = (type: ServiceType) => {
    return list.find((s) => s.type === type);
  };

  const { selectedTenant } = useGlobalFilterContext();
  const { fetchServicesStatus } = useTenantContext();

  const fetchServices = async (tenant: string): Promise<void> => {
    setFetchStatus(setPending("Fetching"));
    const res = await tenantApi.getEnabledServices(tenant);
    setFetchStatus(resToState(res));
    if (res.ok && res.result) {
      const services = injectMockServices(res.result);

      setList(getFullServiceList(services));
      setGroupedList(getModifiedServicesList(services));
    }
  };

  const editService = async (
    service: ServiceAPI,
    tenant: string
  ): Promise<boolean> => {
    if (service.type === "ids") {
      setError("Advanced Threats service is not part of your current License");
      return false;
    }
    setFetchStatus(setPending("Fetching"));
    const payload: ServicesPreferencesAPI = { services: [service] };
    const res = await tenantApi.editEnabledServices(tenant, payload);

    if (res.ok) {
      fetchServices(tenant);
    }

    setFetchStatus(resToState(res));
    return res.ok;
  };

  const selectService = (service?: Service): void =>
    setSelectedService(service);

  const fetchTenantConsumersCount = async (tenant: string) => {
    const users = await fetchTenantUsersCount(tenant);
    const locations = await fetchTenantLocationsCount(tenant);
    setTenantConsumersCount({ users, locations });
  };

  const fetchTenantUsersCount = async (tenant: string) => {
    const res = await userApi.getTenantUsers(tenant);
    if (res.ok && res.result?.length) {
      return res.result.length;
    }
    return 0;
  };

  const fetchTenantLocationsCount = async (tenant: string) => {
    const res = await tenantApi.getTenantLocations(tenant);
    if (res && res.length) {
      return res.length;
    }
    return 0;
  };

  useEffect(() => {
    if (selectedTenant) {
      fetchServices(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  return (
    <ServicesPreferencesContext.Provider
      value={{
        fetchServices,
        groupedList,
        fetchStatus,
        editService,
        selectService,
        selectedService,
        list,
        getService,
        error,
        fetchTenantConsumersCount,
        tenantConsumersCount,
      }}
    >
      {children}
    </ServicesPreferencesContext.Provider>
  );
};
