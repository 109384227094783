import React from "react";

import NavigationTabs from "./NavigationTabs";
import {
  EVENTS,
  CLOUD_RESOURCES,
  SYSTEMS,
  IPAM,
  MESH_NETWORK,
  SITES,
  DIA_DDOS,
} from "../../../helpers/navigation/entries";
import styles from "./SystemsTabs.module.scss";
import TimerangOverlayComponent from "../timerange/TimerangeOverlayComponent";
import { classNames } from "../../../helpers/common/classNames";
import { useUserContext } from "../../../contexts/UserContext";

export const SystemsTabs: React.FC<{
  showTimeRange?: boolean;
  className?: string;
}> = ({ showTimeRange, className }) => {
  const { isDemo } = useUserContext();
  return (
    <div className={classNames(styles.systemTabsWrapper, className)}>
      <NavigationTabs
        entries={
          isDemo
            ? [
                SYSTEMS(),
                IPAM(),
                DIA_DDOS(),
                SITES(),
                MESH_NETWORK(),
                CLOUD_RESOURCES(),
                EVENTS(),
              ]
            : [
                SYSTEMS(),
                IPAM(),
                DIA_DDOS(),
                SITES(),
                CLOUD_RESOURCES(),
                EVENTS(),
              ]
        }
      />
      <div className={styles.controlsWrapper}>
        {showTimeRange && (
          <TimerangOverlayComponent
            id={"systemsTabs"}
            className={styles.timerangeWrapper}
          />
          //
        )}
      </div>
    </div>
  );
};
