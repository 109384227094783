import React, { FC, useEffect } from "react";
import styles from "./AddCloud.module.scss";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_PORTS,
  INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/consts";
import {
  ChangeConnectionsFields,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { getDataObjectCopy } from "../../../../../helpers/getDataObjectCopy";
import { useValidation } from "../../../../../helpers/validators/Validator";
import validateConnection from "../../../../../helpers/validators/ZayoConnectionValidator";
import {
  ADD,
  CANCEL,
  EDIT,
} from "../../../../../helpers/common/constantsAlias";
import DialogBtmButtons from "../../../../../components/dialogs/common/DialogBtmButtons";
import { withContexts } from "../../../../../helpers/hocs/withContexts";
import Input from "../../../../../components/common/formComponents/Input";
import ZPort from "./ZPort";
import { useUnitedConnectionContext } from "../../UnitedConnectionContext";
import ConnectionConfiguratorContext from "../../../../ZayoPages/Connections/ConnectionConfigurator/ConnectionConfiguratorContext";
import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import { parseVlan } from "../Clouds/CloudsTabInfo";
import { parseBandwidth } from "../../../../../helpers/parseBandwidth";
import { calcBandwidthWithUnits } from "../../../../../helpers/calcBandwidthWithUnits";
import { AWS_LOWERCASE, AZURE_LOWERCASE } from "../../../../../helpers/consts";
import { updateConnectionsFromInventory } from "./updateConnectionsFromInventory";
import { usePricingDialog } from "../../../../../contexts/pricingDialogContext/PricingDialogContext";
import { PRICING_PRODUCTS } from "../../../../../contexts/pricingDialogContext/constants";
import { useUserContext } from "../../../../../contexts/UserContext";

type Props = {
  onClose: () => void;
  addFrom?: string;
  isEdit?: boolean;
  connectionData?: VirtualInterfaceApi;
};

const AddCloud: FC<Props> = ({ onClose, addFrom, isEdit, connectionData }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    parentPorts,
    customerPorts,
    getCustomerPorts,
    addCloudStatus,
    addCloudConnection,
    selectPort,
    selectedPort,
    regions,
    getRegions,
    updateCustomerPort,
    fetchConnections,
  } = useUnitedConnectionContext();
  const { openDialog } = usePricingDialog();
  const { fetchNotifications } = useUserContext();
  useEffect(() => {
    if (selectedTenant) {
      !customerPorts.length && getCustomerPorts();
      !regions.length && getRegions();
    }
  }, [selectedTenant]);

  const handleClose = () => {
    onClose();
  };

  // Fields
  const [fields, onChangeFields, resetFields] = useFormField(
    INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS
  );

  function getParsedConnectionData() {
    if (!connectionData) return INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS;
    const isCloud = [AWS_LOWERCASE, AZURE_LOWERCASE].includes(
      connectionData.labels?.remote_type
    );
    if (isCloud) return INITIAL_CONNECTIONS_WITH_PORTS_Z_FIELDS;
    const { name, vlan_id, max_bandwidth, alias, vlan_ctag_id } = parseVlan(
      connectionData,
      true
    );
    const portData = {
      Connection_type: "directConnect",
      innerType: CUSTOMER_PORTS,
      max_bandwidth,
      vlan_id,
      vlan_ctag_id,
      name,
      internet_access: connectionData.labels?.internet_access,
      ip_addresses: connectionData?.ip_addresses || undefined,
      alias,
    };
    return { connections: [portData] };
  }

  useEffect(() => {
    return () => {
      fields.connections[0] = {
        innerType: CUSTOMER_PORTS,
      };
    };
  }, []);

  useEffect(() => {
    const updatedFields = updateConnectionsFromInventory(
      fields,
      addFrom,
      customerPorts
    );
    onChangeFields("connections", updatedFields.connections);
  }, [addFrom, customerPorts]);

  useEffect(() => {
    if (connectionData) {
      const connections = getParsedConnectionData();
      connections.connections = connections.connections.map((connection) => {
        const { value, unit } = parseBandwidth(connection.max_bandwidth);
        return {
          ...connection,
          max_bandwidth: `${value} ${unit}`,
        };
      });
      resetFields({ ...connections, name: connectionData.name });
    }
  }, [connectionData]);
  // Change fields
  const handleChangeConnectionsFields: ChangeConnectionsFields = (
    idx: number,
    connectionFields: FieldsType
  ): void => {
    const connections = getDataObjectCopy(fields.connections);
    connections[idx] = { ...connectionFields };
    onChangeFields("connections", connections);
  };

  // Change BW Fields
  const handleChangeBW = (idx: number, value: string): void => {
    const newConnections = fields.connections.map((el: FieldsType) => {
      return {
        ...el,
        max_bandwidth: value,
      };
    });
    onChangeFields("connections", newConnections);
  };

  // errors
  const [errors, validate] = useValidation(validateConnection, [
    {
      ...fields,
      isCloudRouter: true,
    },
  ]);

  const handleAddConnections = async () => {
    if (isEdit) {
      await handleEditConnections();
      return;
    }

    const { isOk } = validate();
    if (!selectedTenant || !isOk) return;

    let customerPort = undefined;
    let cloudConnection = undefined;

    const updatedConnections = fields.connections.map((el: FieldsType) => {
      const { value, unit } = parseBandwidth(el.max_bandwidth);
      const max_bandwidth = String(calcBandwidthWithUnits(value, unit));

      if (el.innerType === CLOUD_CONNECTIONS) cloudConnection = el;
      if (el.innerType === CUSTOMER_PORTS) customerPort = el;

      return {
        ...el,
        max_bandwidth,
      };
    });

    if (cloudConnection) {
      const { ok: isSigned } = await openDialog(
        PRICING_PRODUCTS.CR_CLOUD_LINK,
        cloudConnection
      );

      if (!isSigned) return;
    }

    if (customerPort) {
      const { ok: isSigned } = await openDialog(
        PRICING_PRODUCTS.CLOUD_ROUTER,
        customerPort
      );

      if (!isSigned) return;
    }

    const added = await addCloudConnection(
      fields.name,
      updatedConnections,
      addFrom
    );
    if (added) {
      onClose();
      fetchConnections();
      getCustomerPorts();
      fetchNotifications(selectedTenant);
    }
  };

  const handleEditConnections = async () => {
    const port = fields.connections[0];
    if (port.innerType !== CUSTOMER_PORTS) return;

    const updatedConnections = fields.connections.map((el: FieldsType) => {
      const { value, unit } = parseBandwidth(el.max_bandwidth);
      const max_bandwidth = calcBandwidthWithUnits(value, unit);
      return {
        ...el,
        max_bandwidth,
        alias: el.alias ?? "",
        name: el.name ?? "",
      };
    });

    const updated = await updateCustomerPort(
      updatedConnections[0].name,
      updatedConnections[0].alias,
      updatedConnections[0].max_bandwidth,
      true
    );

    if (updated) {
      onClose();
      fetchConnections();
      getCustomerPorts();
      fetchNotifications(selectedTenant || "");
    }
  };

  return (
    <div className={styles.mainBlock}>
      <div className={styles.title}>Connection</div>
      <div className={styles.wrapper}>
        <ZPort
          fields={fields.connections}
          onChange={handleChangeConnectionsFields}
          onDelete={() => console.log("delete")}
          className={styles.zPort}
          errors={errors}
          handleChangeBW={handleChangeBW}
          withIP
          noControls
          ports={customerPorts}
          parentPorts={parentPorts}
          selectedPort={selectedPort}
          selectPort={selectPort}
          regions={regions}
          isEdit={isEdit}
          addFrom={addFrom}
        />
      </div>
      <div className={styles.footer}>
        <Input
          name="name"
          className={isEdit ? styles.disabled : undefined}
          placeholder="Connection name:"
          errorWithTooltip
          isPlaceholderAlwaysVisible
          value={fields.name}
          medium
          error={errors && errors["name"]}
          onChange={(e) => onChangeFields("name", e.target.value)}
        />
        <DialogBtmButtons
          className={styles.controls}
          controls={{
            okText: isEdit ? EDIT : ADD,
            onOk: handleAddConnections,
            cancelText: CANCEL,
            onCancel: handleClose,
          }}
          errorDisplay={addCloudStatus}
        />
      </div>
    </div>
  );
};
export default withContexts<Props>(AddCloud, [ConnectionConfiguratorContext]);
