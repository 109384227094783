import React, { FC, useEffect, useState } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import Input from "../../../components/common/formComponents/Input";
import { NewDialogProps } from "../../../helpers/types";
import { PortType } from "../types";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import PortDialogContextContainer, {
  usePortDialogContext,
} from "./PortDialogContext";
import styles from "./PortDialog.module.scss";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import InterfacesContextContainer, {
  useInterfacesContext,
} from "./InterfacesContext";
import LabelArray from "../../../components/common/table/LabelArray";
import PortIcon from "../../../components/icons/PortIcon";
import { usePortsContext } from "../PortsContext";

const PortDialog: FC<NewDialogProps<PortType>> = ({ onClose, data }) => {
  const { editDescription, editMinPorts, editStatus } = usePortDialogContext();
  const { systemName } = usePortsContext();
  const {
    fetchLagList,
    list_of_lag,
    list_of_ports,
    free_ports_list,
    addPortToLAG,
    removePortFromLAG,
  } = useInterfacesContext();
  const [visiblePorts, setVisiblePorts] = useState<Array<string>>(
    free_ports_list || []
  );

  const handleHideUsedPort = (port: string) => {
    const ports = visiblePorts.filter((vPort) => vPort !== port);
    setVisiblePorts(ports);
  };

  useEffect(() => {
    if (systemName) {
      fetchLagList(systemName, fields?.name);
    }
  }, []);

  useEffect(() => {
    if (list_of_ports?.length) {
      handleFieldChange("ports", list_of_ports);
      setPrevAssignedPorts(list_of_ports);
    }
  }, [list_of_ports]);

  useEffect(() => {
    if (free_ports_list?.length) setVisiblePorts(free_ports_list);
  }, [free_ports_list]);

  const [fields, handleFieldChange] = useFormField<PortType>(data as PortType);
  const handleSubmit = async () => {
    if (!systemName || !data) {
      onClose();
      return;
    }

    const portsToAdd = fields.ports?.filter(
      (port) => !prevAssignedPorts.includes(port)
    );
    const portsToRemove = prevAssignedPorts.filter(
      (port) => !fields.ports?.includes(port)
    );

    const promises = [];
    if (portsToAdd) {
      promises.push(addPortToLAG(systemName, portsToAdd, fields.name));
    }
    if (portsToRemove) {
      promises.push(removePortFromLAG(systemName, portsToRemove));
    }
    if (fields.lag_name) {
      promises.push(addPortToLAG(systemName, [fields.name], fields.lag_name));
    }

    if (fields.labels?.description !== data.labels?.description) {
      promises.push(editDescription(data.name, fields));
    }

    if (fields.min_ports !== data.min_ports) {
      promises.push(editMinPorts(data.name, fields));
    }

    const res = await Promise.all<Promise<void> | Promise<boolean>>(promises);

    if (res.every((item) => typeof item !== "boolean" || !!item)) onClose();
  };

  const removePort = (label: string) => {
    const prevPortsList = fields.ports;
    const newPortsArray = prevPortsList?.filter(
      (port: string) => port !== label
    );
    handleFieldChange("ports", newPortsArray);
    setVisiblePorts(visiblePorts.concat(label));
  };

  const controls = getDialogControls({
    dialogTitle: "edit",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const [prevAssignedPorts, setPrevAssignedPorts] = useState<Array<string>>(
    list_of_ports || []
  );

  useEffect(() => {
    if (data) fetchLagList(data.system_name, data.name);
  }, []);

  const isLag = data?.name?.startsWith("lag") ? true : false;
  const isLacpLag = fields?.lag_type?.toLowerCase() === "lacp";
  const leftColumnTitle = isLag ? "LAG" : "PORT";
  const leftColumn = <SummaryBlock title={leftColumnTitle} data={fields} />;

  return (
    <DialogTemplate
      title={"Edit"}
      onClose={onClose}
      controls={controls}
      errorDisplay={editStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        <Input
          label="Alias Name"
          name="name"
          placeholder=""
          value={fields.name}
          medium
          onChange={(e) => handleFieldChange("name", e.target.value)}
          disabled
        />
        {isLag && (
          <>
            <DropdownBasic
              id={"type"}
              onChange={(val) => {
                handleFieldChange("lag_type", val.key.toLowerCase());
              }}
              selected={mapStringToItem(fields.lag_type)}
              label="Type"
              itemsList={["LACP", "STATIC"].map((key) => mapStringToItem(key))}
              isMedium
            />
            <DropdownBasic
              id={"lag_ports"}
              placeholder="Select Port to assign"
              onChange={(val) => {
                if (fields.ports) {
                  handleFieldChange("ports", [...fields.ports, val.key]);
                } else {
                  handleFieldChange("ports", [val.key]);
                }
                handleHideUsedPort(val.key);
              }}
              itemsList={visiblePorts?.map(mapStringToItem) || []}
              isMedium
            />
            {fields.ports && (
              <LabelArray
                values={fields.ports}
                isRemovable
                onClick={removePort}
                labelIcon={<PortIcon />}
                withWrap
                singleLabelClassname={styles.singleLabelClassname}
              />
            )}
          </>
        )}
        <Input
          label="Description"
          name="description"
          placeholder=""
          value={fields.labels?.description}
          medium
          onChange={(e) =>
            handleFieldChange("labels", { description: e.target.value })
          }
          isFullValue
        />
        {!isLag && (
          <DropdownBasic
            id={"lag"}
            onChange={(val) => handleFieldChange("lag_name", val.key)}
            selected={mapStringToItem(fields.lag_name)}
            label="LAG Membership"
            itemsList={["NONE", ...(list_of_lag || [])].map((key) =>
              mapStringToItem(key)
            )}
            isMedium
          />
        )}
      </div>
      <div className={styles.column}>
        <DropdownBasic
          id={"type"}
          onChange={(val) => handleFieldChange("administrative_state", val.key)}
          selected={mapStringToItem(fields.administrative_state)}
          label="Admin State"
          itemsList={["Up", "Down"].map((key) => mapStringToItem(key))}
          isMedium
          disabled
        />
        <Input
          label="MTU"
          name="mtu"
          placeholder=""
          value={fields.mtu}
          medium
          onChange={(e) => handleFieldChange("mtu", e.target.value)}
          disabled
        />
        {isLag && (
          <Input
            label="Minimum ports"
            name="min_ports"
            placeholder=""
            value={fields.min_ports}
            medium
            onChange={(e) => handleFieldChange("min_ports", e.target.value)}
          />
        )}
        {isLacpLag && (
          <Input
            label="Traffic ports"
            name="nof_ports"
            placeholder=""
            value={fields.nof_ports}
            medium
            onChange={(e) => handleFieldChange("nof_ports", e.target.value)}
          />
        )}
      </div>
    </DialogTemplate>
  );
};

export default withContexts<NewDialogProps<PortType>>(PortDialog, [
  PortDialogContextContainer,
  InterfacesContextContainer,
]);
