export enum SystemsViewMode {
  map = "map",
  table = "table",
}

export type SystemNodeCreate = {
  name: string;
  address: string;
  location: string;
  description: string;
};

export type SystemCreate = {
  name: string;
  nodes: Array<SystemNodeCreate>;
  location: string;
  wg_conf: { [key: string]: any };
};

export type SystemNode = {
  id: number;
  name: string;
  address: string;
  ha_domain_name?: string;
  role: string;
  type: string;
  version: string;
  status: string;
  location: string;
  description: string;
  secure_type?: string;
  vendor: string;
  model: string;
  serial: string;
  virtual: boolean;
  cpu_type: string;
  cpu_socket_count: number;
  cpu_core_per_socket: number;
  cpu_cores_total: number;
  mem_total: number;
  disk_total: number;
  ha_status: string;
  health_score: number;
  build_number: number;
};

export type SystemAPI = {
  name: string;
  active_node: string;
  status: string;
  max_nodes?: number;
  nodes?: Array<SystemNode>;
  location: string;
  wg_conf: { [key: string]: any };
  subRows?: Array<any>;
};

export type SystemUpdate = {
  system_name: string;
  data: SystemNodeUpdate;
};

export type SystemNodeUpdate = {
  status: string;
  add_nodes: Array<SystemNodeCreate>;
};

export type System = {
  name: string;
  active_node: string;
  status: string;
  max_nodes?: number;
  subRows?: Array<SystemNode>;
  nodes?: any;
} & SystemNode;

export type Location = {
  name: string;
  longitude: number;
  latitude: number;
};

export type SystemNodeEdit = {
  location?: string;
  description?: string;
};
