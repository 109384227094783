import React, { ReactElement } from "react";

function NotificationIcon(): ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.4"
        cx="16"
        cy="16"
        r="15.25"
        stroke="#B3B4C5"
        strokeWidth="1.5"
      />
      <g clipPath="url(#clip0_36317_63393)">
        <path d="M19.125 21H12.875V22.25H19.125V21Z" fill="white" />
        <path d="M17.875 23.5H14.125V24.75H17.875V23.5Z" fill="white" />
        <path
          d="M16.0003 7.25C14.3427 7.25 12.753 7.90848 11.5809 9.08058C10.4088 10.2527 9.75035 11.8424 9.75035 13.5C9.70808 14.4083 9.88208 15.3137 10.258 16.1417C10.634 16.9696 11.2011 17.6965 11.9128 18.2625C12.5378 18.8438 12.8753 19.175 12.8753 19.75H14.1253C14.1253 18.6 13.4316 17.9562 12.7566 17.3375C12.1724 16.8902 11.7069 16.3064 11.4007 15.6374C11.0945 14.9684 10.9571 14.2345 11.0003 13.5C11.0003 12.1739 11.5271 10.9021 12.4648 9.96447C13.4025 9.02678 14.6743 8.5 16.0003 8.5C17.3264 8.5 18.5982 9.02678 19.5359 9.96447C20.4736 10.9021 21.0003 12.1739 21.0003 13.5C21.0429 14.235 20.9045 14.9693 20.5972 15.6383C20.2899 16.3074 19.8231 16.8908 19.2378 17.3375C18.5691 17.9625 17.8753 18.5875 17.8753 19.75H19.1253C19.1253 19.175 19.4566 18.8438 20.0878 18.2563C20.7991 17.6912 21.366 16.9654 21.7419 16.1386C22.1179 15.3117 22.2921 14.4074 22.2503 13.5C22.2503 12.6792 22.0887 11.8665 21.7746 11.1082C21.4605 10.3499 21.0001 9.66095 20.4198 9.08058C19.8394 8.50022 19.1504 8.03984 18.3921 7.72575C17.6338 7.41166 16.8211 7.25 16.0003 7.25Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0004 11.0984C14.6749 11.0984 13.6004 12.173 13.6004 13.4984H12.4004C12.4004 11.5102 14.0122 9.89844 16.0004 9.89844V11.0984Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_36317_63393">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NotificationIcon;
